import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  list: [],
  page: 1,
  total: 0,
  itemMessage: {
    time: '',
    sale: '',
    endTime: ''
  },
  modalShow: false
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.ITEM_MESSAGE:
      return state.set('itemMessage', action.value)
    case constants.MODAL_SHOW:
      return state.set('modalShow', action.show)
    case constants.PAGE:
      return state.set('page', action.page)
    case constants.LIST:
      return state.merge({
        list: action.reload
          ? fromJS(action.list)
          : state.get('list').concat(fromJS(action.list)),
        total: action.total || state.total
      })
    default:
      return state
  }
}
