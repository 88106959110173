import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actionCreators } from '../store'
import { Location, LocationBox } from '../style'
import { Select, message } from 'antd'
import { Region } from '@/statics/region.js'
import BMap from 'BMap'
const Option = Select.Option

const cityList = []
let districtList = []

class Positioning extends PureComponent {
  componentWillMount() {
    const {
      currLcoHandle,
      locationNameHandle,
      locationCodeHandle,
      areaIdChange,
      areaLevelChange
    } = this.props
    // 判断本地是否存储了上次登录的定位信息。http://localhost:9521/
    if (
      sessionStorage.getItem('areaname') &&
      sessionStorage.getItem('areacode')
    ) {
      locationNameHandle(sessionStorage.getItem('areaname'))
      locationCodeHandle(sessionStorage.getItem('areacode'))
      areaIdChange(sessionStorage.getItem('area_id'))
      areaLevelChange(sessionStorage.getItem('level'))
    } else if (
      localStorage.getItem('areaname') &&
      localStorage.getItem('areacode')
    ) {
      locationNameHandle(localStorage.getItem('areaname'))
      locationCodeHandle(localStorage.getItem('areacode'))
      areaIdChange(localStorage.getItem('area_id'))
      areaLevelChange(localStorage.getItem('level'))
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            let lat = position.coords.latitude
            let lng = position.coords.longitude
            const pointBak = new BMap.Point(lng, lat)
            const convertor = new BMap.Convertor()
            convertor.translate([pointBak], 1, 5, function(resPoint) {
              if (resPoint && resPoint.points && resPoint.points.length > 0) {
                lng = resPoint.points[0].lng
                lat = resPoint.points[0].lat
              }
              // 获取当前位置并记录
              currLcoHandle(lng, lat)
            })
          },
          function(error) {
            switch (error.code) {
              case 1:
                message.error('位置服务被拒绝，请允许浏览器获取位置信息', 5)
                break
              case 2:
                message.error('暂时获取不到位置信息。', 5)
                break
              case 3:
                message.error('获取位置信息超时。', 5)
                break
              default:
                message.error('未知错误。', 5)
                break
            }
            // 百度地图获取经纬度信息，有偏差
            let geolocation = new BMap.Geolocation()
            geolocation.getCurrentPosition(
              function(r) {
                if (this.getStatus() === 0) {
                  // 获取当前位置并记录
                  currLcoHandle(r.point.lng, r.point.lat)
                } else {
                  message.error('暂时获取不到位置信息。', 5)
                }
              },
              { enableHighAccuracy: true }
            )
          },
          { timeout: 2000, enableHighAccuracy: false }
        )
      } else {
        message.error('你的浏览器不支持获取地理位置信息,位置可能不准确', 5)
        // 百度地图获取经纬度信息，有偏差
        let geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(
          function(r) {
            if (this.getStatus() === 0) {
              // 获取当前位置并记录
              currLcoHandle(r.point.lng, r.point.lat)
            } else {
              message.error('暂时获取不到位置信息。', 5)
            }
          },
          { enableHighAccuracy: true }
        )
      }
    }

    Region.province.forEach((item, index) => {
      cityList.push(item.cities)
    })
  }
  provinceOptions = () => {
    return Region.province.map((item, index) => (
      <Option key={index} value={item.area_code}>
        {item.area_name}
      </Option>
    ))
  }
  cityOptions = cityIndex => {
    if (cityIndex !== '') {
      return cityList[cityIndex].map((item, index) => (
        <Option key={index} value={item.area_code}>
          {item.area_name}
        </Option>
      ))
    } else {
      return null
    }
  }
  districtOptions = (cityIndex, districtIndex) => {
    if (cityIndex !== '' || districtIndex !== '') {
      return districtList.map((item, index) => (
        <Option key={index} value={item.area_code}>
          {item.area_name}
        </Option>
      ))
    } else {
      return null
    }
  }
  handleChange(value, param) {
    const { changeAreaName, getIndex, changeAreaCode } = this.props
    getIndex(param.key, 'city')
    changeAreaName(param.props.children, 'province')
    changeAreaName('市', 'city')
    changeAreaName('区/县', 'district')
    changeAreaCode(value, 'province')
    changeAreaCode('', 'city')
    changeAreaCode('', 'district')
    districtList = []
  }
  handleChangecity(value, param) {
    const { changeAreaName, getIndex, changeAreaCode } = this.props
    getIndex(param.key, 'district')
    changeAreaName(param.props.children, 'city')
    changeAreaName('区/县', 'district')
    changeAreaCode(value, 'city')
    changeAreaCode('', 'district')
    districtList = cityList[this.props.cityIndex][param.key].areas
  }
  handleChangedist(value, param) {
    this.props.changeAreaCode(value, 'district')
    this.props.changeAreaName(param.props.children, 'district')
  }
  clearSelect() {
    const { changeAreaName, getIndex, changeAreaCode } = this.props
    changeAreaName('省', 'province')
    changeAreaName('市', 'city')
    changeAreaName('区/县', 'district')
    getIndex('', 'city')
    getIndex('', 'district')
    changeAreaCode('', 'province')
    changeAreaCode('', 'city')
    changeAreaCode('', 'district')
  }
  changeLocation() {
    const {
      proinceName,
      cityName,
      districtName,
      provinceCode,
      cityCode,
      districtCode,
      handleMouseLeave,
      locationNameHandle,
      locationCodeHandle,
      setAreaSessionHandle
    } = this.props
    if (districtCode !== '') {
      locationNameHandle(districtName)
      locationCodeHandle(districtCode)
      setAreaSessionHandle(districtName, districtCode)
      handleMouseLeave()
      this.clearSelect()
    } else if (cityCode !== '') {
      locationNameHandle(cityName)
      locationCodeHandle(cityCode)
      setAreaSessionHandle(cityName, cityCode)
      handleMouseLeave()
      this.clearSelect()
    } else if (provinceCode !== '') {
      locationNameHandle(proinceName)
      locationCodeHandle(provinceCode)
      setAreaSessionHandle(proinceName, provinceCode)
      handleMouseLeave()
      this.clearSelect()
    } else {
      message.error('请至少选择省级地区')
    }
  }
  render() {
    const {
      show,
      handleMouseEnter,
      handleMouseLeave,
      proinceName,
      cityName,
      districtName,
      cityIndex,
      districtIndex,
      locationName
    } = this.props

    return (
      <Location
        style={{ fontSize: '16px', color: '#333333', position: 'relative' }}
        onClick={handleMouseEnter}
      >
        <div>
          <img
            alt="定位"
            style={{ marginLeft: 10, verticalAlign: 'middle' }}
            src={require('@/statics/location.png')}
          />
          <span
            style={{
              position: 'relative',
              top: '8px',
              display: 'inline-block',
              color: '#fff',
              width: '66px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {locationName}
          </span>
        </div>

        <LocationBox shows={show}>
          <Select
            placeholder="省"
            value={proinceName}
            style={{ width: '110px' }}
            onChange={(value, param) => this.handleChange(value, param)}
          >
            {this.provinceOptions()}
          </Select>
          <Select
            placeholder="市"
            notFoundContent="暂无数据"
            value={cityName}
            style={{ width: '110px', marginLeft: '4px' }}
            onChange={(value, param) => this.handleChangecity(value, param)}
          >
            {this.cityOptions(cityIndex)}
          </Select>
          <Select
            placeholder="区/县"
            value={districtName}
            notFoundContent="暂无数据"
            style={{ width: '110px', marginLeft: '4px' }}
            onChange={(value, param) => this.handleChangedist(value, param)}
          >
            {this.districtOptions(cityIndex, districtIndex)}
          </Select>
          <span
            onClick={event => {
              event.stopPropagation()
              this.changeLocation()
            }}
            style={{ marginLeft: '8px', fontSize: '14px' }}
          >
            更换
          </span>
          <span
            onClick={event => {
              event.stopPropagation()
              this.clearSelect()
              handleMouseLeave()
            }}
            style={{ marginLeft: '4px', fontSize: '14px' }}
          >
            取消
          </span>
        </LocationBox>
        <div id="allmap" />
      </Location>
    )
  }
}

const mapState = state => ({
  show: state.getIn(['header', 'locShows']),
  provinceIndex: state.getIn(['header', 'provinceIndex']),
  cityIndex: state.getIn(['header', 'cityIndex']),
  districtIndex: state.getIn(['header', 'districtIndex']),
  proinceName: state.getIn(['header', 'proinceName']),
  cityName: state.getIn(['header', 'cityName']),
  districtName: state.getIn(['header', 'districtName']),
  provinceCode: state.getIn(['header', 'provinceCode']),
  cityCode: state.getIn(['header', 'cityCode']),
  districtCode: state.getIn(['header', 'districtCode']),
  locationName: state.getIn(['header', 'locationName']),
  locationCode: state.getIn(['header', 'locationCode'])
})

const mapDispatch = dispatch => ({
  handleMouseEnter() {
    dispatch(actionCreators.changeLocshow(true))
  },
  handleMouseLeave() {
    dispatch(actionCreators.changeLocshow(false))
  },
  getIndex(index, type) {
    if (type === 'city') {
      dispatch(actionCreators.changeCityIndex(index))
    } else if (type === 'district') {
      dispatch(actionCreators.changeDistrictIndex(index))
    }
  },
  changeAreaName(name, type) {
    if (type === 'province') {
      dispatch(actionCreators.changeProvinceName(name))
    } else if (type === 'city') {
      dispatch(actionCreators.changeCityName(name))
    } else if (type === 'district') {
      dispatch(actionCreators.changeDistrictName(name))
    }
  },
  changeAreaCode(code, type) {
    if (type === 'province') {
      dispatch(actionCreators.changeProvinceCode(code))
    } else if (type === 'city') {
      dispatch(actionCreators.changeCityCode(code))
    } else if (type === 'district') {
      dispatch(actionCreators.changeDistrictCode(code))
    }
  },
  locationNameHandle(name) {
    dispatch(actionCreators.changeLocationName(name))
  },
  locationCodeHandle(code) {
    dispatch(actionCreators.changeLocationCode(code))
  },
  currLcoHandle(lng, lat) {
    dispatch(actionCreators.getCurrLocation(lng, lat))
  },
  areaIdChange(code) {
    dispatch(actionCreators.changeAreaId(code))
  },
  areaLevelChange(level) {
    dispatch(actionCreators.changeAreaLevel(level))
  },
  setAreaSessionHandle(areaname, areacode) {
    dispatch(actionCreators.setAreaSession(areaname, areacode))
  }
})

export default connect(
  mapState,
  mapDispatch
)(withRouter(Positioning))
