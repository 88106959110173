import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  mobile: '',
  setPwd: false
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.LOGIN_MOBILE:
      return state.set('mobile', action.value)
    case constants.SETPWD:
      return state.set('setPwd', action.value)
    default:
      return state
  }
}
