export const Area={
  "RECORDS": [
    {
      "area_id": "1",
      "area_code": "110000",
      "area_name": "北京市",
      "level": "1",
      "city_code": "010",
      "center": "116.407394,39.904211",
      "parent_id": "0"
    },
    {
      "area_id": "2",
      "area_code": "110100",
      "area_name": "北京城区",
      "level": "2",
      "city_code": "010",
      "center": "116.407394,39.904211",
      "parent_id": "1"
    },
    {
      "area_id": "3",
      "area_code": "110101",
      "area_name": "东城区",
      "level": "3",
      "city_code": "010",
      "center": "116.41649,39.928341",
      "parent_id": "2"
    },
    {
      "area_id": "4",
      "area_code": "110102",
      "area_name": "西城区",
      "level": "3",
      "city_code": "010",
      "center": "116.365873,39.912235",
      "parent_id": "2"
    },
    {
      "area_id": "5",
      "area_code": "110105",
      "area_name": "朝阳区",
      "level": "3",
      "city_code": "010",
      "center": "116.443205,39.921506",
      "parent_id": "2"
    },
    {
      "area_id": "6",
      "area_code": "110106",
      "area_name": "丰台区",
      "level": "3",
      "city_code": "010",
      "center": "116.287039,39.858421",
      "parent_id": "2"
    },
    {
      "area_id": "7",
      "area_code": "110107",
      "area_name": "石景山区",
      "level": "3",
      "city_code": "010",
      "center": "116.222933,39.906611",
      "parent_id": "2"
    },
    {
      "area_id": "8",
      "area_code": "110108",
      "area_name": "海淀区",
      "level": "3",
      "city_code": "010",
      "center": "116.298262,39.95993",
      "parent_id": "2"
    },
    {
      "area_id": "9",
      "area_code": "110109",
      "area_name": "门头沟区",
      "level": "3",
      "city_code": "010",
      "center": "116.101719,39.940338",
      "parent_id": "2"
    },
    {
      "area_id": "10",
      "area_code": "110111",
      "area_name": "房山区",
      "level": "3",
      "city_code": "010",
      "center": "116.143486,39.748823",
      "parent_id": "2"
    },
    {
      "area_id": "11",
      "area_code": "110112",
      "area_name": "通州区",
      "level": "3",
      "city_code": "010",
      "center": "116.656434,39.909946",
      "parent_id": "2"
    },
    {
      "area_id": "12",
      "area_code": "110113",
      "area_name": "顺义区",
      "level": "3",
      "city_code": "010",
      "center": "116.654642,40.130211",
      "parent_id": "2"
    },
    {
      "area_id": "13",
      "area_code": "110114",
      "area_name": "昌平区",
      "level": "3",
      "city_code": "010",
      "center": "116.231254,40.220804",
      "parent_id": "2"
    },
    {
      "area_id": "14",
      "area_code": "110115",
      "area_name": "大兴区",
      "level": "3",
      "city_code": "010",
      "center": "116.341483,39.726917",
      "parent_id": "2"
    },
    {
      "area_id": "15",
      "area_code": "110116",
      "area_name": "怀柔区",
      "level": "3",
      "city_code": "010",
      "center": "116.631931,40.316053",
      "parent_id": "2"
    },
    {
      "area_id": "16",
      "area_code": "110117",
      "area_name": "平谷区",
      "level": "3",
      "city_code": "010",
      "center": "117.121351,40.140595",
      "parent_id": "2"
    },
    {
      "area_id": "17",
      "area_code": "110118",
      "area_name": "密云区",
      "level": "3",
      "city_code": "010",
      "center": "116.843047,40.376894",
      "parent_id": "2"
    },
    {
      "area_id": "18",
      "area_code": "110119",
      "area_name": "延庆区",
      "level": "3",
      "city_code": "010",
      "center": "115.974981,40.456591",
      "parent_id": "2"
    },
    {
      "area_id": "19",
      "area_code": "120000",
      "area_name": "天津市",
      "level": "1",
      "city_code": "022",
      "center": "117.200983,39.084158",
      "parent_id": "0"
    },
    {
      "area_id": "20",
      "area_code": "120100",
      "area_name": "天津城区",
      "level": "2",
      "city_code": "022",
      "center": "117.200983,39.084158",
      "parent_id": "19"
    },
    {
      "area_id": "21",
      "area_code": "120101",
      "area_name": "和平区",
      "level": "3",
      "city_code": "022",
      "center": "117.214699,39.117196",
      "parent_id": "20"
    },
    {
      "area_id": "22",
      "area_code": "120102",
      "area_name": "河东区",
      "level": "3",
      "city_code": "022",
      "center": "117.251584,39.128294",
      "parent_id": "20"
    },
    {
      "area_id": "23",
      "area_code": "120103",
      "area_name": "河西区",
      "level": "3",
      "city_code": "022",
      "center": "117.223371,39.109563",
      "parent_id": "20"
    },
    {
      "area_id": "24",
      "area_code": "120104",
      "area_name": "南开区",
      "level": "3",
      "city_code": "022",
      "center": "117.150738,39.138205",
      "parent_id": "20"
    },
    {
      "area_id": "25",
      "area_code": "120105",
      "area_name": "河北区",
      "level": "3",
      "city_code": "022",
      "center": "117.196648,39.147869",
      "parent_id": "20"
    },
    {
      "area_id": "26",
      "area_code": "120106",
      "area_name": "红桥区",
      "level": "3",
      "city_code": "022",
      "center": "117.151533,39.167345",
      "parent_id": "20"
    },
    {
      "area_id": "27",
      "area_code": "120110",
      "area_name": "东丽区",
      "level": "3",
      "city_code": "022",
      "center": "117.31362,39.086802",
      "parent_id": "20"
    },
    {
      "area_id": "28",
      "area_code": "120111",
      "area_name": "西青区",
      "level": "3",
      "city_code": "022",
      "center": "117.008826,39.141152",
      "parent_id": "20"
    },
    {
      "area_id": "29",
      "area_code": "120112",
      "area_name": "津南区",
      "level": "3",
      "city_code": "022",
      "center": "117.35726,38.937928",
      "parent_id": "20"
    },
    {
      "area_id": "30",
      "area_code": "120113",
      "area_name": "北辰区",
      "level": "3",
      "city_code": "022",
      "center": "117.135488,39.224791",
      "parent_id": "20"
    },
    {
      "area_id": "31",
      "area_code": "120114",
      "area_name": "武清区",
      "level": "3",
      "city_code": "022",
      "center": "117.044387,39.384119",
      "parent_id": "20"
    },
    {
      "area_id": "32",
      "area_code": "120115",
      "area_name": "宝坻区",
      "level": "3",
      "city_code": "022",
      "center": "117.309874,39.717564",
      "parent_id": "20"
    },
    {
      "area_id": "33",
      "area_code": "120116",
      "area_name": "滨海新区",
      "level": "3",
      "city_code": "022",
      "center": "117.698407,39.01727",
      "parent_id": "20"
    },
    {
      "area_id": "34",
      "area_code": "120117",
      "area_name": "宁河区",
      "level": "3",
      "city_code": "022",
      "center": "117.826724,39.330087",
      "parent_id": "20"
    },
    {
      "area_id": "35",
      "area_code": "120118",
      "area_name": "静海区",
      "level": "3",
      "city_code": "022",
      "center": "116.974232,38.94745",
      "parent_id": "20"
    },
    {
      "area_id": "36",
      "area_code": "120119",
      "area_name": "蓟州区",
      "level": "3",
      "city_code": "022",
      "center": "117.408296,40.045851",
      "parent_id": "20"
    },
    {
      "area_id": "37",
      "area_code": "130000",
      "area_name": "河北省",
      "level": "1",
      "city_code": "[]",
      "center": "114.530235,38.037433",
      "parent_id": "0"
    },
    {
      "area_id": "38",
      "area_code": "130100",
      "area_name": "石家庄市",
      "level": "2",
      "city_code": "0311",
      "center": "114.514793,38.042228",
      "parent_id": "37"
    },
    {
      "area_id": "39",
      "area_code": "130102",
      "area_name": "长安区",
      "level": "3",
      "city_code": "0311",
      "center": "114.539395,38.036347",
      "parent_id": "38"
    },
    {
      "area_id": "40",
      "area_code": "130104",
      "area_name": "桥西区",
      "level": "3",
      "city_code": "0311",
      "center": "114.461088,38.004193",
      "parent_id": "38"
    },
    {
      "area_id": "41",
      "area_code": "130105",
      "area_name": "新华区",
      "level": "3",
      "city_code": "0311",
      "center": "114.463377,38.05095",
      "parent_id": "38"
    },
    {
      "area_id": "42",
      "area_code": "130107",
      "area_name": "井陉矿区",
      "level": "3",
      "city_code": "0311",
      "center": "114.062062,38.065151",
      "parent_id": "38"
    },
    {
      "area_id": "43",
      "area_code": "130108",
      "area_name": "裕华区",
      "level": "3",
      "city_code": "0311",
      "center": "114.531202,38.00643",
      "parent_id": "38"
    },
    {
      "area_id": "44",
      "area_code": "130109",
      "area_name": "藁城区",
      "level": "3",
      "city_code": "0311",
      "center": "114.847023,38.021453",
      "parent_id": "38"
    },
    {
      "area_id": "45",
      "area_code": "130110",
      "area_name": "鹿泉区",
      "level": "3",
      "city_code": "0311",
      "center": "114.313654,38.085953",
      "parent_id": "38"
    },
    {
      "area_id": "46",
      "area_code": "130111",
      "area_name": "栾城区",
      "level": "3",
      "city_code": "0311",
      "center": "114.648318,37.900199",
      "parent_id": "38"
    },
    {
      "area_id": "47",
      "area_code": "130121",
      "area_name": "井陉县",
      "level": "3",
      "city_code": "0311",
      "center": "114.145242,38.032118",
      "parent_id": "38"
    },
    {
      "area_id": "48",
      "area_code": "130123",
      "area_name": "正定县",
      "level": "3",
      "city_code": "0311",
      "center": "114.570941,38.146444",
      "parent_id": "38"
    },
    {
      "area_id": "49",
      "area_code": "130125",
      "area_name": "行唐县",
      "level": "3",
      "city_code": "0311",
      "center": "114.552714,38.438377",
      "parent_id": "38"
    },
    {
      "area_id": "50",
      "area_code": "130126",
      "area_name": "灵寿县",
      "level": "3",
      "city_code": "0311",
      "center": "114.382614,38.308665",
      "parent_id": "38"
    },
    {
      "area_id": "51",
      "area_code": "130127",
      "area_name": "高邑县",
      "level": "3",
      "city_code": "0311",
      "center": "114.611121,37.615534",
      "parent_id": "38"
    },
    {
      "area_id": "52",
      "area_code": "130128",
      "area_name": "深泽县",
      "level": "3",
      "city_code": "0311",
      "center": "115.20092,38.184033",
      "parent_id": "38"
    },
    {
      "area_id": "53",
      "area_code": "130129",
      "area_name": "赞皇县",
      "level": "3",
      "city_code": "0311",
      "center": "114.386111,37.665663",
      "parent_id": "38"
    },
    {
      "area_id": "54",
      "area_code": "130130",
      "area_name": "无极县",
      "level": "3",
      "city_code": "0311",
      "center": "114.97634,38.179192",
      "parent_id": "38"
    },
    {
      "area_id": "55",
      "area_code": "130131",
      "area_name": "平山县",
      "level": "3",
      "city_code": "0311",
      "center": "114.195918,38.247888",
      "parent_id": "38"
    },
    {
      "area_id": "56",
      "area_code": "130132",
      "area_name": "元氏县",
      "level": "3",
      "city_code": "0311",
      "center": "114.525409,37.766513",
      "parent_id": "38"
    },
    {
      "area_id": "57",
      "area_code": "130133",
      "area_name": "赵县",
      "level": "3",
      "city_code": "0311",
      "center": "114.776297,37.756578",
      "parent_id": "38"
    },
    {
      "area_id": "58",
      "area_code": "130181",
      "area_name": "辛集市",
      "level": "3",
      "city_code": "0311",
      "center": "115.217658,37.943121",
      "parent_id": "38"
    },
    {
      "area_id": "59",
      "area_code": "130183",
      "area_name": "晋州市",
      "level": "3",
      "city_code": "0311",
      "center": "115.044213,38.033671",
      "parent_id": "38"
    },
    {
      "area_id": "60",
      "area_code": "130184",
      "area_name": "新乐市",
      "level": "3",
      "city_code": "0311",
      "center": "114.683776,38.343319",
      "parent_id": "38"
    },
    {
      "area_id": "61",
      "area_code": "130200",
      "area_name": "唐山市",
      "level": "2",
      "city_code": "0315",
      "center": "118.180193,39.630867",
      "parent_id": "37"
    },
    {
      "area_id": "62",
      "area_code": "130202",
      "area_name": "路南区",
      "level": "3",
      "city_code": "0315",
      "center": "118.154354,39.625058",
      "parent_id": "61"
    },
    {
      "area_id": "63",
      "area_code": "130203",
      "area_name": "路北区",
      "level": "3",
      "city_code": "0315",
      "center": "118.200692,39.624437",
      "parent_id": "61"
    },
    {
      "area_id": "64",
      "area_code": "130204",
      "area_name": "古冶区",
      "level": "3",
      "city_code": "0315",
      "center": "118.447635,39.733578",
      "parent_id": "61"
    },
    {
      "area_id": "65",
      "area_code": "130205",
      "area_name": "开平区",
      "level": "3",
      "city_code": "0315",
      "center": "118.261841,39.671001",
      "parent_id": "61"
    },
    {
      "area_id": "66",
      "area_code": "130207",
      "area_name": "丰南区",
      "level": "3",
      "city_code": "0315",
      "center": "118.085169,39.576031",
      "parent_id": "61"
    },
    {
      "area_id": "67",
      "area_code": "130208",
      "area_name": "丰润区",
      "level": "3",
      "city_code": "0315",
      "center": "118.162215,39.832582",
      "parent_id": "61"
    },
    {
      "area_id": "68",
      "area_code": "130209",
      "area_name": "曹妃甸区",
      "level": "3",
      "city_code": "0315",
      "center": "118.460379,39.27307",
      "parent_id": "61"
    },
    {
      "area_id": "69",
      "area_code": "130223",
      "area_name": "滦县",
      "level": "3",
      "city_code": "0315",
      "center": "118.703598,39.740593",
      "parent_id": "61"
    },
    {
      "area_id": "70",
      "area_code": "130224",
      "area_name": "滦南县",
      "level": "3",
      "city_code": "0315",
      "center": "118.682379,39.518996",
      "parent_id": "61"
    },
    {
      "area_id": "71",
      "area_code": "130225",
      "area_name": "乐亭县",
      "level": "3",
      "city_code": "0315",
      "center": "118.912571,39.425608",
      "parent_id": "61"
    },
    {
      "area_id": "72",
      "area_code": "130227",
      "area_name": "迁西县",
      "level": "3",
      "city_code": "0315",
      "center": "118.314715,40.1415",
      "parent_id": "61"
    },
    {
      "area_id": "73",
      "area_code": "130229",
      "area_name": "玉田县",
      "level": "3",
      "city_code": "0315",
      "center": "117.738658,39.900401",
      "parent_id": "61"
    },
    {
      "area_id": "74",
      "area_code": "130281",
      "area_name": "遵化市",
      "level": "3",
      "city_code": "0315",
      "center": "117.965892,40.189201",
      "parent_id": "61"
    },
    {
      "area_id": "75",
      "area_code": "130283",
      "area_name": "迁安市",
      "level": "3",
      "city_code": "0315",
      "center": "118.701144,39.999174",
      "parent_id": "61"
    },
    {
      "area_id": "76",
      "area_code": "130300",
      "area_name": "秦皇岛市",
      "level": "2",
      "city_code": "0335",
      "center": "119.518197,39.888701",
      "parent_id": "37"
    },
    {
      "area_id": "77",
      "area_code": "130302",
      "area_name": "海港区",
      "level": "3",
      "city_code": "0335",
      "center": "119.564962,39.94756",
      "parent_id": "76"
    },
    {
      "area_id": "78",
      "area_code": "130303",
      "area_name": "山海关区",
      "level": "3",
      "city_code": "0335",
      "center": "119.775799,39.978848",
      "parent_id": "76"
    },
    {
      "area_id": "79",
      "area_code": "130304",
      "area_name": "北戴河区",
      "level": "3",
      "city_code": "0335",
      "center": "119.484522,39.834596",
      "parent_id": "76"
    },
    {
      "area_id": "80",
      "area_code": "130306",
      "area_name": "抚宁区",
      "level": "3",
      "city_code": "0335",
      "center": "119.244847,39.876253",
      "parent_id": "76"
    },
    {
      "area_id": "81",
      "area_code": "130321",
      "area_name": "青龙满族自治县",
      "level": "3",
      "city_code": "0335",
      "center": "118.949684,40.407578",
      "parent_id": "76"
    },
    {
      "area_id": "82",
      "area_code": "130322",
      "area_name": "昌黎县",
      "level": "3",
      "city_code": "0335",
      "center": "119.199551,39.700911",
      "parent_id": "76"
    },
    {
      "area_id": "83",
      "area_code": "130324",
      "area_name": "卢龙县",
      "level": "3",
      "city_code": "0335",
      "center": "118.892986,39.891946",
      "parent_id": "76"
    },
    {
      "area_id": "84",
      "area_code": "130400",
      "area_name": "邯郸市",
      "level": "2",
      "city_code": "0310",
      "center": "114.538959,36.625594",
      "parent_id": "37"
    },
    {
      "area_id": "85",
      "area_code": "130402",
      "area_name": "邯山区",
      "level": "3",
      "city_code": "0310",
      "center": "114.531002,36.594313",
      "parent_id": "84"
    },
    {
      "area_id": "86",
      "area_code": "130403",
      "area_name": "丛台区",
      "level": "3",
      "city_code": "0310",
      "center": "114.492896,36.636409",
      "parent_id": "84"
    },
    {
      "area_id": "87",
      "area_code": "130404",
      "area_name": "复兴区",
      "level": "3",
      "city_code": "0310",
      "center": "114.462061,36.639033",
      "parent_id": "84"
    },
    {
      "area_id": "88",
      "area_code": "130406",
      "area_name": "峰峰矿区",
      "level": "3",
      "city_code": "0310",
      "center": "114.212802,36.419739",
      "parent_id": "84"
    },
    {
      "area_id": "89",
      "area_code": "130423",
      "area_name": "临漳县",
      "level": "3",
      "city_code": "0310",
      "center": "114.619536,36.335025",
      "parent_id": "84"
    },
    {
      "area_id": "90",
      "area_code": "130424",
      "area_name": "成安县",
      "level": "3",
      "city_code": "0310",
      "center": "114.670032,36.444317",
      "parent_id": "84"
    },
    {
      "area_id": "91",
      "area_code": "130425",
      "area_name": "大名县",
      "level": "3",
      "city_code": "0310",
      "center": "115.147814,36.285616",
      "parent_id": "84"
    },
    {
      "area_id": "92",
      "area_code": "130426",
      "area_name": "涉县",
      "level": "3",
      "city_code": "0310",
      "center": "113.6914,36.584994",
      "parent_id": "84"
    },
    {
      "area_id": "93",
      "area_code": "130427",
      "area_name": "磁县",
      "level": "3",
      "city_code": "0310",
      "center": "114.373946,36.374011",
      "parent_id": "84"
    },
    {
      "area_id": "94",
      "area_code": "130407",
      "area_name": "肥乡区",
      "level": "3",
      "city_code": "0310",
      "center": "114.800166,36.548131",
      "parent_id": "84"
    },
    {
      "area_id": "95",
      "area_code": "130408",
      "area_name": "永年区",
      "level": "3",
      "city_code": "0310",
      "center": "114.543832,36.743966",
      "parent_id": "84"
    },
    {
      "area_id": "96",
      "area_code": "130430",
      "area_name": "邱县",
      "level": "3",
      "city_code": "0310",
      "center": "115.200589,36.811148",
      "parent_id": "84"
    },
    {
      "area_id": "97",
      "area_code": "130431",
      "area_name": "鸡泽县",
      "level": "3",
      "city_code": "0310",
      "center": "114.889376,36.91034",
      "parent_id": "84"
    },
    {
      "area_id": "98",
      "area_code": "130432",
      "area_name": "广平县",
      "level": "3",
      "city_code": "0310",
      "center": "114.948606,36.483484",
      "parent_id": "84"
    },
    {
      "area_id": "99",
      "area_code": "130433",
      "area_name": "馆陶县",
      "level": "3",
      "city_code": "0310",
      "center": "115.282467,36.547556",
      "parent_id": "84"
    },
    {
      "area_id": "100",
      "area_code": "130434",
      "area_name": "魏县",
      "level": "3",
      "city_code": "0310",
      "center": "114.93892,36.359868",
      "parent_id": "84"
    },
    {
      "area_id": "101",
      "area_code": "130435",
      "area_name": "曲周县",
      "level": "3",
      "city_code": "0310",
      "center": "114.957504,36.76607",
      "parent_id": "84"
    },
    {
      "area_id": "102",
      "area_code": "130481",
      "area_name": "武安市",
      "level": "3",
      "city_code": "0310",
      "center": "114.203697,36.696506",
      "parent_id": "84"
    },
    {
      "area_id": "103",
      "area_code": "130500",
      "area_name": "邢台市",
      "level": "2",
      "city_code": "0319",
      "center": "114.504677,37.070834",
      "parent_id": "37"
    },
    {
      "area_id": "104",
      "area_code": "130502",
      "area_name": "桥东区",
      "level": "3",
      "city_code": "0319",
      "center": "114.507058,37.071287",
      "parent_id": "103"
    },
    {
      "area_id": "105",
      "area_code": "130503",
      "area_name": "桥西区",
      "level": "3",
      "city_code": "0319",
      "center": "114.468601,37.059827",
      "parent_id": "103"
    },
    {
      "area_id": "106",
      "area_code": "130521",
      "area_name": "邢台县",
      "level": "3",
      "city_code": "0319",
      "center": "114.561132,37.05073",
      "parent_id": "103"
    },
    {
      "area_id": "107",
      "area_code": "130522",
      "area_name": "临城县",
      "level": "3",
      "city_code": "0319",
      "center": "114.498761,37.444498",
      "parent_id": "103"
    },
    {
      "area_id": "108",
      "area_code": "130523",
      "area_name": "内丘县",
      "level": "3",
      "city_code": "0319",
      "center": "114.512128,37.286669",
      "parent_id": "103"
    },
    {
      "area_id": "109",
      "area_code": "130524",
      "area_name": "柏乡县",
      "level": "3",
      "city_code": "0319",
      "center": "114.693425,37.482422",
      "parent_id": "103"
    },
    {
      "area_id": "110",
      "area_code": "130525",
      "area_name": "隆尧县",
      "level": "3",
      "city_code": "0319",
      "center": "114.770419,37.350172",
      "parent_id": "103"
    },
    {
      "area_id": "111",
      "area_code": "130526",
      "area_name": "任县",
      "level": "3",
      "city_code": "0319",
      "center": "114.671936,37.120982",
      "parent_id": "103"
    },
    {
      "area_id": "112",
      "area_code": "130527",
      "area_name": "南和县",
      "level": "3",
      "city_code": "0319",
      "center": "114.683863,37.005017",
      "parent_id": "103"
    },
    {
      "area_id": "113",
      "area_code": "130528",
      "area_name": "宁晋县",
      "level": "3",
      "city_code": "0319",
      "center": "114.93992,37.624564",
      "parent_id": "103"
    },
    {
      "area_id": "114",
      "area_code": "130529",
      "area_name": "巨鹿县",
      "level": "3",
      "city_code": "0319",
      "center": "115.037477,37.221112",
      "parent_id": "103"
    },
    {
      "area_id": "115",
      "area_code": "130530",
      "area_name": "新河县",
      "level": "3",
      "city_code": "0319",
      "center": "115.250907,37.520862",
      "parent_id": "103"
    },
    {
      "area_id": "116",
      "area_code": "130531",
      "area_name": "广宗县",
      "level": "3",
      "city_code": "0319",
      "center": "115.142626,37.074661",
      "parent_id": "103"
    },
    {
      "area_id": "117",
      "area_code": "130532",
      "area_name": "平乡县",
      "level": "3",
      "city_code": "0319",
      "center": "115.030075,37.063148",
      "parent_id": "103"
    },
    {
      "area_id": "118",
      "area_code": "130533",
      "area_name": "威县",
      "level": "3",
      "city_code": "0319",
      "center": "115.266703,36.975478",
      "parent_id": "103"
    },
    {
      "area_id": "119",
      "area_code": "130534",
      "area_name": "清河县",
      "level": "3",
      "city_code": "0319",
      "center": "115.667208,37.039991",
      "parent_id": "103"
    },
    {
      "area_id": "120",
      "area_code": "130535",
      "area_name": "临西县",
      "level": "3",
      "city_code": "0319",
      "center": "115.501048,36.870811",
      "parent_id": "103"
    },
    {
      "area_id": "121",
      "area_code": "130581",
      "area_name": "南宫市",
      "level": "3",
      "city_code": "0319",
      "center": "115.408747,37.359264",
      "parent_id": "103"
    },
    {
      "area_id": "122",
      "area_code": "130582",
      "area_name": "沙河市",
      "level": "3",
      "city_code": "0319",
      "center": "114.503339,36.854929",
      "parent_id": "103"
    },
    {
      "area_id": "123",
      "area_code": "130600",
      "area_name": "保定市",
      "level": "2",
      "city_code": "0312",
      "center": "115.464589,38.874434",
      "parent_id": "37"
    },
    {
      "area_id": "124",
      "area_code": "130602",
      "area_name": "竞秀区",
      "level": "3",
      "city_code": "0312",
      "center": "115.45877,38.877449",
      "parent_id": "123"
    },
    {
      "area_id": "125",
      "area_code": "130606",
      "area_name": "莲池区",
      "level": "3",
      "city_code": "0312",
      "center": "115.497097,38.883582",
      "parent_id": "123"
    },
    {
      "area_id": "126",
      "area_code": "130607",
      "area_name": "满城区",
      "level": "3",
      "city_code": "0312",
      "center": "115.322334,38.949119",
      "parent_id": "123"
    },
    {
      "area_id": "127",
      "area_code": "130608",
      "area_name": "清苑区",
      "level": "3",
      "city_code": "0312",
      "center": "115.489959,38.765148",
      "parent_id": "123"
    },
    {
      "area_id": "128",
      "area_code": "130609",
      "area_name": "徐水区",
      "level": "3",
      "city_code": "0312",
      "center": "115.655774,39.018736",
      "parent_id": "123"
    },
    {
      "area_id": "129",
      "area_code": "130623",
      "area_name": "涞水县",
      "level": "3",
      "city_code": "0312",
      "center": "115.713904,39.394316",
      "parent_id": "123"
    },
    {
      "area_id": "130",
      "area_code": "130624",
      "area_name": "阜平县",
      "level": "3",
      "city_code": "0312",
      "center": "114.195104,38.849152",
      "parent_id": "123"
    },
    {
      "area_id": "131",
      "area_code": "130626",
      "area_name": "定兴县",
      "level": "3",
      "city_code": "0312",
      "center": "115.808296,39.263145",
      "parent_id": "123"
    },
    {
      "area_id": "132",
      "area_code": "130627",
      "area_name": "唐县",
      "level": "3",
      "city_code": "0312",
      "center": "114.982972,38.748203",
      "parent_id": "123"
    },
    {
      "area_id": "133",
      "area_code": "130628",
      "area_name": "高阳县",
      "level": "3",
      "city_code": "0312",
      "center": "115.778965,38.700088",
      "parent_id": "123"
    },
    {
      "area_id": "134",
      "area_code": "130629",
      "area_name": "容城县",
      "level": "3",
      "city_code": "0312",
      "center": "115.861657,39.042784",
      "parent_id": "123"
    },
    {
      "area_id": "135",
      "area_code": "130630",
      "area_name": "涞源县",
      "level": "3",
      "city_code": "0312",
      "center": "114.694283,39.360247",
      "parent_id": "123"
    },
    {
      "area_id": "136",
      "area_code": "130631",
      "area_name": "望都县",
      "level": "3",
      "city_code": "0312",
      "center": "115.155128,38.695842",
      "parent_id": "123"
    },
    {
      "area_id": "137",
      "area_code": "130632",
      "area_name": "安新县",
      "level": "3",
      "city_code": "0312",
      "center": "115.935603,38.935369",
      "parent_id": "123"
    },
    {
      "area_id": "138",
      "area_code": "130633",
      "area_name": "易县",
      "level": "3",
      "city_code": "0312",
      "center": "115.497457,39.349393",
      "parent_id": "123"
    },
    {
      "area_id": "139",
      "area_code": "130634",
      "area_name": "曲阳县",
      "level": "3",
      "city_code": "0312",
      "center": "114.745008,38.622248",
      "parent_id": "123"
    },
    {
      "area_id": "140",
      "area_code": "130635",
      "area_name": "蠡县",
      "level": "3",
      "city_code": "0312",
      "center": "115.583854,38.488055",
      "parent_id": "123"
    },
    {
      "area_id": "141",
      "area_code": "130636",
      "area_name": "顺平县",
      "level": "3",
      "city_code": "0312",
      "center": "115.13547,38.837487",
      "parent_id": "123"
    },
    {
      "area_id": "142",
      "area_code": "130637",
      "area_name": "博野县",
      "level": "3",
      "city_code": "0312",
      "center": "115.46438,38.457364",
      "parent_id": "123"
    },
    {
      "area_id": "143",
      "area_code": "130638",
      "area_name": "雄县",
      "level": "3",
      "city_code": "0312",
      "center": "116.10865,38.99455",
      "parent_id": "123"
    },
    {
      "area_id": "144",
      "area_code": "130681",
      "area_name": "涿州市",
      "level": "3",
      "city_code": "0312",
      "center": "115.974422,39.485282",
      "parent_id": "123"
    },
    {
      "area_id": "145",
      "area_code": "130682",
      "area_name": "定州市",
      "level": "3",
      "city_code": "0312",
      "center": "114.990392,38.516461",
      "parent_id": "123"
    },
    {
      "area_id": "146",
      "area_code": "130683",
      "area_name": "安国市",
      "level": "3",
      "city_code": "0312",
      "center": "115.326646,38.418439",
      "parent_id": "123"
    },
    {
      "area_id": "147",
      "area_code": "130684",
      "area_name": "高碑店市",
      "level": "3",
      "city_code": "0312",
      "center": "115.873886,39.326839",
      "parent_id": "123"
    },
    {
      "area_id": "148",
      "area_code": "130700",
      "area_name": "张家口市",
      "level": "2",
      "city_code": "0313",
      "center": "114.886252,40.768493",
      "parent_id": "37"
    },
    {
      "area_id": "149",
      "area_code": "130702",
      "area_name": "桥东区",
      "level": "3",
      "city_code": "0313",
      "center": "114.894189,40.788434",
      "parent_id": "148"
    },
    {
      "area_id": "150",
      "area_code": "130703",
      "area_name": "桥西区",
      "level": "3",
      "city_code": "0313",
      "center": "114.869657,40.819581",
      "parent_id": "148"
    },
    {
      "area_id": "151",
      "area_code": "130705",
      "area_name": "宣化区",
      "level": "3",
      "city_code": "0313",
      "center": "115.099494,40.608793",
      "parent_id": "148"
    },
    {
      "area_id": "152",
      "area_code": "130706",
      "area_name": "下花园区",
      "level": "3",
      "city_code": "0313",
      "center": "115.287352,40.502652",
      "parent_id": "148"
    },
    {
      "area_id": "153",
      "area_code": "130708",
      "area_name": "万全区",
      "level": "3",
      "city_code": "0313",
      "center": "114.740557,40.766965",
      "parent_id": "148"
    },
    {
      "area_id": "154",
      "area_code": "130709",
      "area_name": "崇礼区",
      "level": "3",
      "city_code": "0313",
      "center": "115.282668,40.974675",
      "parent_id": "148"
    },
    {
      "area_id": "155",
      "area_code": "130722",
      "area_name": "张北县",
      "level": "3",
      "city_code": "0313",
      "center": "114.720077,41.158596",
      "parent_id": "148"
    },
    {
      "area_id": "156",
      "area_code": "130723",
      "area_name": "康保县",
      "level": "3",
      "city_code": "0313",
      "center": "114.600404,41.852368",
      "parent_id": "148"
    },
    {
      "area_id": "157",
      "area_code": "130724",
      "area_name": "沽源县",
      "level": "3",
      "city_code": "0313",
      "center": "115.688692,41.669668",
      "parent_id": "148"
    },
    {
      "area_id": "158",
      "area_code": "130725",
      "area_name": "尚义县",
      "level": "3",
      "city_code": "0313",
      "center": "113.969618,41.076226",
      "parent_id": "148"
    },
    {
      "area_id": "159",
      "area_code": "130726",
      "area_name": "蔚县",
      "level": "3",
      "city_code": "0313",
      "center": "114.588903,39.840842",
      "parent_id": "148"
    },
    {
      "area_id": "160",
      "area_code": "130727",
      "area_name": "阳原县",
      "level": "3",
      "city_code": "0313",
      "center": "114.150348,40.104663",
      "parent_id": "148"
    },
    {
      "area_id": "161",
      "area_code": "130728",
      "area_name": "怀安县",
      "level": "3",
      "city_code": "0313",
      "center": "114.385791,40.674193",
      "parent_id": "148"
    },
    {
      "area_id": "162",
      "area_code": "130730",
      "area_name": "怀来县",
      "level": "3",
      "city_code": "0313",
      "center": "115.517861,40.415343",
      "parent_id": "148"
    },
    {
      "area_id": "163",
      "area_code": "130731",
      "area_name": "涿鹿县",
      "level": "3",
      "city_code": "0313",
      "center": "115.205345,40.379562",
      "parent_id": "148"
    },
    {
      "area_id": "164",
      "area_code": "130732",
      "area_name": "赤城县",
      "level": "3",
      "city_code": "0313",
      "center": "115.831498,40.912921",
      "parent_id": "148"
    },
    {
      "area_id": "165",
      "area_code": "130800",
      "area_name": "承德市",
      "level": "2",
      "city_code": "0314",
      "center": "117.962749,40.952942",
      "parent_id": "37"
    },
    {
      "area_id": "166",
      "area_code": "130802",
      "area_name": "双桥区",
      "level": "3",
      "city_code": "0314",
      "center": "117.943466,40.974643",
      "parent_id": "165"
    },
    {
      "area_id": "167",
      "area_code": "130803",
      "area_name": "双滦区",
      "level": "3",
      "city_code": "0314",
      "center": "117.799888,40.959236",
      "parent_id": "165"
    },
    {
      "area_id": "168",
      "area_code": "130804",
      "area_name": "鹰手营子矿区",
      "level": "3",
      "city_code": "0314",
      "center": "117.659499,40.546361",
      "parent_id": "165"
    },
    {
      "area_id": "169",
      "area_code": "130821",
      "area_name": "承德县",
      "level": "3",
      "city_code": "0314",
      "center": "118.173824,40.768238",
      "parent_id": "165"
    },
    {
      "area_id": "170",
      "area_code": "130822",
      "area_name": "兴隆县",
      "level": "3",
      "city_code": "0314",
      "center": "117.500558,40.417358",
      "parent_id": "165"
    },
    {
      "area_id": "171",
      "area_code": "130881",
      "area_name": "平泉市",
      "level": "3",
      "city_code": "0314",
      "center": "118.701951,41.018405",
      "parent_id": "165"
    },
    {
      "area_id": "172",
      "area_code": "130824",
      "area_name": "滦平县",
      "level": "3",
      "city_code": "0314",
      "center": "117.332801,40.941482",
      "parent_id": "165"
    },
    {
      "area_id": "173",
      "area_code": "130825",
      "area_name": "隆化县",
      "level": "3",
      "city_code": "0314",
      "center": "117.738937,41.313791",
      "parent_id": "165"
    },
    {
      "area_id": "174",
      "area_code": "130826",
      "area_name": "丰宁满族自治县",
      "level": "3",
      "city_code": "0314",
      "center": "116.646051,41.209069",
      "parent_id": "165"
    },
    {
      "area_id": "175",
      "area_code": "130827",
      "area_name": "宽城满族自治县",
      "level": "3",
      "city_code": "0314",
      "center": "118.485313,40.611391",
      "parent_id": "165"
    },
    {
      "area_id": "176",
      "area_code": "130828",
      "area_name": "围场满族蒙古族自治县",
      "level": "3",
      "city_code": "0314",
      "center": "117.760159,41.938529",
      "parent_id": "165"
    },
    {
      "area_id": "177",
      "area_code": "130900",
      "area_name": "沧州市",
      "level": "2",
      "city_code": "0317",
      "center": "116.838834,38.304477",
      "parent_id": "37"
    },
    {
      "area_id": "178",
      "area_code": "130902",
      "area_name": "新华区",
      "level": "3",
      "city_code": "0317",
      "center": "116.866284,38.314416",
      "parent_id": "177"
    },
    {
      "area_id": "179",
      "area_code": "130903",
      "area_name": "运河区",
      "level": "3",
      "city_code": "0317",
      "center": "116.843673,38.283749",
      "parent_id": "177"
    },
    {
      "area_id": "180",
      "area_code": "130921",
      "area_name": "沧县",
      "level": "3",
      "city_code": "0317",
      "center": "117.007478,38.219856",
      "parent_id": "177"
    },
    {
      "area_id": "181",
      "area_code": "130922",
      "area_name": "青县",
      "level": "3",
      "city_code": "0317",
      "center": "116.804305,38.583021",
      "parent_id": "177"
    },
    {
      "area_id": "182",
      "area_code": "130923",
      "area_name": "东光县",
      "level": "3",
      "city_code": "0317",
      "center": "116.537067,37.888248",
      "parent_id": "177"
    },
    {
      "area_id": "183",
      "area_code": "130924",
      "area_name": "海兴县",
      "level": "3",
      "city_code": "0317",
      "center": "117.497651,38.143169",
      "parent_id": "177"
    },
    {
      "area_id": "184",
      "area_code": "130925",
      "area_name": "盐山县",
      "level": "3",
      "city_code": "0317",
      "center": "117.230602,38.058087",
      "parent_id": "177"
    },
    {
      "area_id": "185",
      "area_code": "130926",
      "area_name": "肃宁县",
      "level": "3",
      "city_code": "0317",
      "center": "115.829758,38.422801",
      "parent_id": "177"
    },
    {
      "area_id": "186",
      "area_code": "130927",
      "area_name": "南皮县",
      "level": "3",
      "city_code": "0317",
      "center": "116.708347,38.038421",
      "parent_id": "177"
    },
    {
      "area_id": "187",
      "area_code": "130928",
      "area_name": "吴桥县",
      "level": "3",
      "city_code": "0317",
      "center": "116.391508,37.627661",
      "parent_id": "177"
    },
    {
      "area_id": "188",
      "area_code": "130929",
      "area_name": "献县",
      "level": "3",
      "city_code": "0317",
      "center": "116.122725,38.190185",
      "parent_id": "177"
    },
    {
      "area_id": "189",
      "area_code": "130930",
      "area_name": "孟村回族自治县",
      "level": "3",
      "city_code": "0317",
      "center": "117.104298,38.053409",
      "parent_id": "177"
    },
    {
      "area_id": "190",
      "area_code": "130981",
      "area_name": "泊头市",
      "level": "3",
      "city_code": "0317",
      "center": "116.578367,38.083437",
      "parent_id": "177"
    },
    {
      "area_id": "191",
      "area_code": "130982",
      "area_name": "任丘市",
      "level": "3",
      "city_code": "0317",
      "center": "116.082917,38.683591",
      "parent_id": "177"
    },
    {
      "area_id": "192",
      "area_code": "130983",
      "area_name": "黄骅市",
      "level": "3",
      "city_code": "0317",
      "center": "117.329949,38.371402",
      "parent_id": "177"
    },
    {
      "area_id": "193",
      "area_code": "130984",
      "area_name": "河间市",
      "level": "3",
      "city_code": "0317",
      "center": "116.099517,38.446624",
      "parent_id": "177"
    },
    {
      "area_id": "194",
      "area_code": "131000",
      "area_name": "廊坊市",
      "level": "2",
      "city_code": "0316",
      "center": "116.683752,39.538047",
      "parent_id": "37"
    },
    {
      "area_id": "195",
      "area_code": "131002",
      "area_name": "安次区",
      "level": "3",
      "city_code": "0316",
      "center": "116.694544,39.502569",
      "parent_id": "194"
    },
    {
      "area_id": "196",
      "area_code": "131003",
      "area_name": "广阳区",
      "level": "3",
      "city_code": "0316",
      "center": "116.71069,39.522786",
      "parent_id": "194"
    },
    {
      "area_id": "197",
      "area_code": "131022",
      "area_name": "固安县",
      "level": "3",
      "city_code": "0316",
      "center": "116.298657,39.438214",
      "parent_id": "194"
    },
    {
      "area_id": "198",
      "area_code": "131023",
      "area_name": "永清县",
      "level": "3",
      "city_code": "0316",
      "center": "116.50568,39.330689",
      "parent_id": "194"
    },
    {
      "area_id": "199",
      "area_code": "131024",
      "area_name": "香河县",
      "level": "3",
      "city_code": "0316",
      "center": "117.006093,39.761424",
      "parent_id": "194"
    },
    {
      "area_id": "200",
      "area_code": "131025",
      "area_name": "大城县",
      "level": "3",
      "city_code": "0316",
      "center": "116.653793,38.705449",
      "parent_id": "194"
    },
    {
      "area_id": "201",
      "area_code": "131026",
      "area_name": "文安县",
      "level": "3",
      "city_code": "0316",
      "center": "116.457898,38.87292",
      "parent_id": "194"
    },
    {
      "area_id": "202",
      "area_code": "131028",
      "area_name": "大厂回族自治县",
      "level": "3",
      "city_code": "0316",
      "center": "116.989574,39.886547",
      "parent_id": "194"
    },
    {
      "area_id": "203",
      "area_code": "131081",
      "area_name": "霸州市",
      "level": "3",
      "city_code": "0316",
      "center": "116.391484,39.125744",
      "parent_id": "194"
    },
    {
      "area_id": "204",
      "area_code": "131082",
      "area_name": "三河市",
      "level": "3",
      "city_code": "0316",
      "center": "117.078294,39.982718",
      "parent_id": "194"
    },
    {
      "area_id": "205",
      "area_code": "131100",
      "area_name": "衡水市",
      "level": "2",
      "city_code": "0318",
      "center": "115.670177,37.73892",
      "parent_id": "37"
    },
    {
      "area_id": "206",
      "area_code": "131102",
      "area_name": "桃城区",
      "level": "3",
      "city_code": "0318",
      "center": "115.67545,37.735465",
      "parent_id": "205"
    },
    {
      "area_id": "207",
      "area_code": "131103",
      "area_name": "冀州区",
      "level": "3",
      "city_code": "0318",
      "center": "115.579308,37.550856",
      "parent_id": "205"
    },
    {
      "area_id": "208",
      "area_code": "131121",
      "area_name": "枣强县",
      "level": "3",
      "city_code": "0318",
      "center": "115.724259,37.513417",
      "parent_id": "205"
    },
    {
      "area_id": "209",
      "area_code": "131122",
      "area_name": "武邑县",
      "level": "3",
      "city_code": "0318",
      "center": "115.887531,37.801665",
      "parent_id": "205"
    },
    {
      "area_id": "210",
      "area_code": "131123",
      "area_name": "武强县",
      "level": "3",
      "city_code": "0318",
      "center": "115.982461,38.041368",
      "parent_id": "205"
    },
    {
      "area_id": "211",
      "area_code": "131124",
      "area_name": "饶阳县",
      "level": "3",
      "city_code": "0318",
      "center": "115.725833,38.235892",
      "parent_id": "205"
    },
    {
      "area_id": "212",
      "area_code": "131125",
      "area_name": "安平县",
      "level": "3",
      "city_code": "0318",
      "center": "115.519278,38.234501",
      "parent_id": "205"
    },
    {
      "area_id": "213",
      "area_code": "131126",
      "area_name": "故城县",
      "level": "3",
      "city_code": "0318",
      "center": "115.965874,37.347409",
      "parent_id": "205"
    },
    {
      "area_id": "214",
      "area_code": "131127",
      "area_name": "景县",
      "level": "3",
      "city_code": "0318",
      "center": "116.270648,37.69229",
      "parent_id": "205"
    },
    {
      "area_id": "215",
      "area_code": "131128",
      "area_name": "阜城县",
      "level": "3",
      "city_code": "0318",
      "center": "116.175262,37.862505",
      "parent_id": "205"
    },
    {
      "area_id": "216",
      "area_code": "131182",
      "area_name": "深州市",
      "level": "3",
      "city_code": "0318",
      "center": "115.559574,38.001535",
      "parent_id": "205"
    },
    {
      "area_id": "217",
      "area_code": "140000",
      "area_name": "山西省",
      "level": "1",
      "city_code": "[]",
      "center": "112.562678,37.873499",
      "parent_id": "0"
    },
    {
      "area_id": "218",
      "area_code": "140100",
      "area_name": "太原市",
      "level": "2",
      "city_code": "0351",
      "center": "112.548879,37.87059",
      "parent_id": "217"
    },
    {
      "area_id": "219",
      "area_code": "140105",
      "area_name": "小店区",
      "level": "3",
      "city_code": "0351",
      "center": "112.565659,37.736525",
      "parent_id": "218"
    },
    {
      "area_id": "220",
      "area_code": "140106",
      "area_name": "迎泽区",
      "level": "3",
      "city_code": "0351",
      "center": "112.5634,37.863451",
      "parent_id": "218"
    },
    {
      "area_id": "221",
      "area_code": "140107",
      "area_name": "杏花岭区",
      "level": "3",
      "city_code": "0351",
      "center": "112.570604,37.893955",
      "parent_id": "218"
    },
    {
      "area_id": "222",
      "area_code": "140108",
      "area_name": "尖草坪区",
      "level": "3",
      "city_code": "0351",
      "center": "112.486691,37.940387",
      "parent_id": "218"
    },
    {
      "area_id": "223",
      "area_code": "140109",
      "area_name": "万柏林区",
      "level": "3",
      "city_code": "0351",
      "center": "112.515937,37.85958",
      "parent_id": "218"
    },
    {
      "area_id": "224",
      "area_code": "140110",
      "area_name": "晋源区",
      "level": "3",
      "city_code": "0351",
      "center": "112.47794,37.715193",
      "parent_id": "218"
    },
    {
      "area_id": "225",
      "area_code": "140121",
      "area_name": "清徐县",
      "level": "3",
      "city_code": "0351",
      "center": "112.358667,37.607443",
      "parent_id": "218"
    },
    {
      "area_id": "226",
      "area_code": "140122",
      "area_name": "阳曲县",
      "level": "3",
      "city_code": "0351",
      "center": "112.672952,38.058488",
      "parent_id": "218"
    },
    {
      "area_id": "227",
      "area_code": "140123",
      "area_name": "娄烦县",
      "level": "3",
      "city_code": "0351",
      "center": "111.797083,38.067932",
      "parent_id": "218"
    },
    {
      "area_id": "228",
      "area_code": "140181",
      "area_name": "古交市",
      "level": "3",
      "city_code": "0351",
      "center": "112.175853,37.907129",
      "parent_id": "218"
    },
    {
      "area_id": "229",
      "area_code": "140200",
      "area_name": "大同市",
      "level": "2",
      "city_code": "0352",
      "center": "113.300129,40.076763",
      "parent_id": "217"
    },
    {
      "area_id": "230",
      "area_code": "140202",
      "area_name": "城区",
      "level": "3",
      "city_code": "0352",
      "center": "113.298026,40.075666",
      "parent_id": "229"
    },
    {
      "area_id": "231",
      "area_code": "140203",
      "area_name": "矿区",
      "level": "3",
      "city_code": "0352",
      "center": "113.177206,40.036858",
      "parent_id": "229"
    },
    {
      "area_id": "232",
      "area_code": "140211",
      "area_name": "南郊区",
      "level": "3",
      "city_code": "0352",
      "center": "113.149693,40.005404",
      "parent_id": "229"
    },
    {
      "area_id": "233",
      "area_code": "140212",
      "area_name": "新荣区",
      "level": "3",
      "city_code": "0352",
      "center": "113.140004,40.255866",
      "parent_id": "229"
    },
    {
      "area_id": "234",
      "area_code": "140221",
      "area_name": "阳高县",
      "level": "3",
      "city_code": "0352",
      "center": "113.748944,40.361059",
      "parent_id": "229"
    },
    {
      "area_id": "235",
      "area_code": "140222",
      "area_name": "天镇县",
      "level": "3",
      "city_code": "0352",
      "center": "114.090867,40.420237",
      "parent_id": "229"
    },
    {
      "area_id": "236",
      "area_code": "140223",
      "area_name": "广灵县",
      "level": "3",
      "city_code": "0352",
      "center": "114.282758,39.760281",
      "parent_id": "229"
    },
    {
      "area_id": "237",
      "area_code": "140224",
      "area_name": "灵丘县",
      "level": "3",
      "city_code": "0352",
      "center": "114.23435,39.442406",
      "parent_id": "229"
    },
    {
      "area_id": "238",
      "area_code": "140225",
      "area_name": "浑源县",
      "level": "3",
      "city_code": "0352",
      "center": "113.699475,39.693406",
      "parent_id": "229"
    },
    {
      "area_id": "239",
      "area_code": "140226",
      "area_name": "左云县",
      "level": "3",
      "city_code": "0352",
      "center": "112.703008,40.013442",
      "parent_id": "229"
    },
    {
      "area_id": "240",
      "area_code": "140227",
      "area_name": "大同县",
      "level": "3",
      "city_code": "0352",
      "center": "113.61244,40.040294",
      "parent_id": "229"
    },
    {
      "area_id": "241",
      "area_code": "140300",
      "area_name": "阳泉市",
      "level": "2",
      "city_code": "0353",
      "center": "113.580519,37.856971",
      "parent_id": "217"
    },
    {
      "area_id": "242",
      "area_code": "140302",
      "area_name": "城区",
      "level": "3",
      "city_code": "0353",
      "center": "113.600669,37.847436",
      "parent_id": "241"
    },
    {
      "area_id": "243",
      "area_code": "140303",
      "area_name": "矿区",
      "level": "3",
      "city_code": "0353",
      "center": "113.555279,37.868494",
      "parent_id": "241"
    },
    {
      "area_id": "244",
      "area_code": "140311",
      "area_name": "郊区",
      "level": "3",
      "city_code": "0353",
      "center": "113.594163,37.944679",
      "parent_id": "241"
    },
    {
      "area_id": "245",
      "area_code": "140321",
      "area_name": "平定县",
      "level": "3",
      "city_code": "0353",
      "center": "113.630107,37.804988",
      "parent_id": "241"
    },
    {
      "area_id": "246",
      "area_code": "140322",
      "area_name": "盂县",
      "level": "3",
      "city_code": "0353",
      "center": "113.41233,38.085619",
      "parent_id": "241"
    },
    {
      "area_id": "247",
      "area_code": "140400",
      "area_name": "长治市",
      "level": "2",
      "city_code": "0355",
      "center": "113.116404,36.195409",
      "parent_id": "217"
    },
    {
      "area_id": "248",
      "area_code": "140402",
      "area_name": "城区",
      "level": "3",
      "city_code": "0355",
      "center": "113.123088,36.20353",
      "parent_id": "247"
    },
    {
      "area_id": "249",
      "area_code": "140411",
      "area_name": "郊区",
      "level": "3",
      "city_code": "0355",
      "center": "113.101211,36.218388",
      "parent_id": "247"
    },
    {
      "area_id": "250",
      "area_code": "140421",
      "area_name": "长治县",
      "level": "3",
      "city_code": "0355",
      "center": "113.051407,36.052858",
      "parent_id": "247"
    },
    {
      "area_id": "251",
      "area_code": "140423",
      "area_name": "襄垣县",
      "level": "3",
      "city_code": "0355",
      "center": "113.051491,36.535817",
      "parent_id": "247"
    },
    {
      "area_id": "252",
      "area_code": "140424",
      "area_name": "屯留县",
      "level": "3",
      "city_code": "0355",
      "center": "112.891998,36.315663",
      "parent_id": "247"
    },
    {
      "area_id": "253",
      "area_code": "140425",
      "area_name": "平顺县",
      "level": "3",
      "city_code": "0355",
      "center": "113.435961,36.200179",
      "parent_id": "247"
    },
    {
      "area_id": "254",
      "area_code": "140426",
      "area_name": "黎城县",
      "level": "3",
      "city_code": "0355",
      "center": "113.387155,36.502328",
      "parent_id": "247"
    },
    {
      "area_id": "255",
      "area_code": "140427",
      "area_name": "壶关县",
      "level": "3",
      "city_code": "0355",
      "center": "113.207049,36.115448",
      "parent_id": "247"
    },
    {
      "area_id": "256",
      "area_code": "140428",
      "area_name": "长子县",
      "level": "3",
      "city_code": "0355",
      "center": "112.8779,36.122334",
      "parent_id": "247"
    },
    {
      "area_id": "257",
      "area_code": "140429",
      "area_name": "武乡县",
      "level": "3",
      "city_code": "0355",
      "center": "112.864561,36.837625",
      "parent_id": "247"
    },
    {
      "area_id": "258",
      "area_code": "140430",
      "area_name": "沁县",
      "level": "3",
      "city_code": "0355",
      "center": "112.699226,36.756063",
      "parent_id": "247"
    },
    {
      "area_id": "259",
      "area_code": "140431",
      "area_name": "沁源县",
      "level": "3",
      "city_code": "0355",
      "center": "112.337446,36.5002",
      "parent_id": "247"
    },
    {
      "area_id": "260",
      "area_code": "140481",
      "area_name": "潞城市",
      "level": "3",
      "city_code": "0355",
      "center": "113.228852,36.334104",
      "parent_id": "247"
    },
    {
      "area_id": "261",
      "area_code": "140500",
      "area_name": "晋城市",
      "level": "2",
      "city_code": "0356",
      "center": "112.851486,35.490684",
      "parent_id": "217"
    },
    {
      "area_id": "262",
      "area_code": "140502",
      "area_name": "城区",
      "level": "3",
      "city_code": "0356",
      "center": "112.853555,35.501571",
      "parent_id": "261"
    },
    {
      "area_id": "263",
      "area_code": "140521",
      "area_name": "沁水县",
      "level": "3",
      "city_code": "0356",
      "center": "112.186738,35.690141",
      "parent_id": "261"
    },
    {
      "area_id": "264",
      "area_code": "140522",
      "area_name": "阳城县",
      "level": "3",
      "city_code": "0356",
      "center": "112.414738,35.486029",
      "parent_id": "261"
    },
    {
      "area_id": "265",
      "area_code": "140524",
      "area_name": "陵川县",
      "level": "3",
      "city_code": "0356",
      "center": "113.280688,35.775685",
      "parent_id": "261"
    },
    {
      "area_id": "266",
      "area_code": "140525",
      "area_name": "泽州县",
      "level": "3",
      "city_code": "0356",
      "center": "112.899137,35.617221",
      "parent_id": "261"
    },
    {
      "area_id": "267",
      "area_code": "140581",
      "area_name": "高平市",
      "level": "3",
      "city_code": "0356",
      "center": "112.92392,35.797997",
      "parent_id": "261"
    },
    {
      "area_id": "268",
      "area_code": "140600",
      "area_name": "朔州市",
      "level": "2",
      "city_code": "0349",
      "center": "112.432991,39.331855",
      "parent_id": "217"
    },
    {
      "area_id": "269",
      "area_code": "140602",
      "area_name": "朔城区",
      "level": "3",
      "city_code": "0349",
      "center": "112.432312,39.319519",
      "parent_id": "268"
    },
    {
      "area_id": "270",
      "area_code": "140603",
      "area_name": "平鲁区",
      "level": "3",
      "city_code": "0349",
      "center": "112.28833,39.512155",
      "parent_id": "268"
    },
    {
      "area_id": "271",
      "area_code": "140621",
      "area_name": "山阴县",
      "level": "3",
      "city_code": "0349",
      "center": "112.816413,39.527893",
      "parent_id": "268"
    },
    {
      "area_id": "272",
      "area_code": "140622",
      "area_name": "应县",
      "level": "3",
      "city_code": "0349",
      "center": "113.191098,39.554247",
      "parent_id": "268"
    },
    {
      "area_id": "273",
      "area_code": "140623",
      "area_name": "右玉县",
      "level": "3",
      "city_code": "0349",
      "center": "112.466989,39.989063",
      "parent_id": "268"
    },
    {
      "area_id": "274",
      "area_code": "140624",
      "area_name": "怀仁县",
      "level": "3",
      "city_code": "0349",
      "center": "113.131717,39.821627",
      "parent_id": "268"
    },
    {
      "area_id": "275",
      "area_code": "140700",
      "area_name": "晋中市",
      "level": "2",
      "city_code": "0354",
      "center": "112.752652,37.687357",
      "parent_id": "217"
    },
    {
      "area_id": "276",
      "area_code": "140702",
      "area_name": "榆次区",
      "level": "3",
      "city_code": "0354",
      "center": "112.708224,37.697794",
      "parent_id": "275"
    },
    {
      "area_id": "277",
      "area_code": "140721",
      "area_name": "榆社县",
      "level": "3",
      "city_code": "0354",
      "center": "112.975209,37.070916",
      "parent_id": "275"
    },
    {
      "area_id": "278",
      "area_code": "140722",
      "area_name": "左权县",
      "level": "3",
      "city_code": "0354",
      "center": "113.379403,37.082943",
      "parent_id": "275"
    },
    {
      "area_id": "279",
      "area_code": "140723",
      "area_name": "和顺县",
      "level": "3",
      "city_code": "0354",
      "center": "113.570415,37.32957",
      "parent_id": "275"
    },
    {
      "area_id": "280",
      "area_code": "140724",
      "area_name": "昔阳县",
      "level": "3",
      "city_code": "0354",
      "center": "113.706977,37.61253",
      "parent_id": "275"
    },
    {
      "area_id": "281",
      "area_code": "140725",
      "area_name": "寿阳县",
      "level": "3",
      "city_code": "0354",
      "center": "113.176373,37.895191",
      "parent_id": "275"
    },
    {
      "area_id": "282",
      "area_code": "140726",
      "area_name": "太谷县",
      "level": "3",
      "city_code": "0354",
      "center": "112.551305,37.421307",
      "parent_id": "275"
    },
    {
      "area_id": "283",
      "area_code": "140727",
      "area_name": "祁县",
      "level": "3",
      "city_code": "0354",
      "center": "112.335542,37.357869",
      "parent_id": "275"
    },
    {
      "area_id": "284",
      "area_code": "140728",
      "area_name": "平遥县",
      "level": "3",
      "city_code": "0354",
      "center": "112.176136,37.189421",
      "parent_id": "275"
    },
    {
      "area_id": "285",
      "area_code": "140729",
      "area_name": "灵石县",
      "level": "3",
      "city_code": "0354",
      "center": "111.77864,36.847927",
      "parent_id": "275"
    },
    {
      "area_id": "286",
      "area_code": "140781",
      "area_name": "介休市",
      "level": "3",
      "city_code": "0354",
      "center": "111.916711,37.026944",
      "parent_id": "275"
    },
    {
      "area_id": "287",
      "area_code": "140800",
      "area_name": "运城市",
      "level": "2",
      "city_code": "0359",
      "center": "111.00746,35.026516",
      "parent_id": "217"
    },
    {
      "area_id": "288",
      "area_code": "140802",
      "area_name": "盐湖区",
      "level": "3",
      "city_code": "0359",
      "center": "110.998272,35.015101",
      "parent_id": "287"
    },
    {
      "area_id": "289",
      "area_code": "140821",
      "area_name": "临猗县",
      "level": "3",
      "city_code": "0359",
      "center": "110.774547,35.144277",
      "parent_id": "287"
    },
    {
      "area_id": "290",
      "area_code": "140822",
      "area_name": "万荣县",
      "level": "3",
      "city_code": "0359",
      "center": "110.838024,35.415253",
      "parent_id": "287"
    },
    {
      "area_id": "291",
      "area_code": "140823",
      "area_name": "闻喜县",
      "level": "3",
      "city_code": "0359",
      "center": "111.22472,35.356644",
      "parent_id": "287"
    },
    {
      "area_id": "292",
      "area_code": "140824",
      "area_name": "稷山县",
      "level": "3",
      "city_code": "0359",
      "center": "110.983333,35.604025",
      "parent_id": "287"
    },
    {
      "area_id": "293",
      "area_code": "140825",
      "area_name": "新绛县",
      "level": "3",
      "city_code": "0359",
      "center": "111.224734,35.616251",
      "parent_id": "287"
    },
    {
      "area_id": "294",
      "area_code": "140826",
      "area_name": "绛县",
      "level": "3",
      "city_code": "0359",
      "center": "111.568236,35.49119",
      "parent_id": "287"
    },
    {
      "area_id": "295",
      "area_code": "140827",
      "area_name": "垣曲县",
      "level": "3",
      "city_code": "0359",
      "center": "111.670108,35.297369",
      "parent_id": "287"
    },
    {
      "area_id": "296",
      "area_code": "140828",
      "area_name": "夏县",
      "level": "3",
      "city_code": "0359",
      "center": "111.220456,35.141363",
      "parent_id": "287"
    },
    {
      "area_id": "297",
      "area_code": "140829",
      "area_name": "平陆县",
      "level": "3",
      "city_code": "0359",
      "center": "111.194133,34.82926",
      "parent_id": "287"
    },
    {
      "area_id": "298",
      "area_code": "140830",
      "area_name": "芮城县",
      "level": "3",
      "city_code": "0359",
      "center": "110.694369,34.693579",
      "parent_id": "287"
    },
    {
      "area_id": "299",
      "area_code": "140881",
      "area_name": "永济市",
      "level": "3",
      "city_code": "0359",
      "center": "110.447543,34.8671",
      "parent_id": "287"
    },
    {
      "area_id": "300",
      "area_code": "140882",
      "area_name": "河津市",
      "level": "3",
      "city_code": "0359",
      "center": "110.712063,35.596383",
      "parent_id": "287"
    },
    {
      "area_id": "301",
      "area_code": "140900",
      "area_name": "忻州市",
      "level": "2",
      "city_code": "0350",
      "center": "112.734174,38.416663",
      "parent_id": "217"
    },
    {
      "area_id": "302",
      "area_code": "140902",
      "area_name": "忻府区",
      "level": "3",
      "city_code": "0350",
      "center": "112.746046,38.404242",
      "parent_id": "301"
    },
    {
      "area_id": "303",
      "area_code": "140921",
      "area_name": "定襄县",
      "level": "3",
      "city_code": "0350",
      "center": "112.957237,38.473506",
      "parent_id": "301"
    },
    {
      "area_id": "304",
      "area_code": "140922",
      "area_name": "五台县",
      "level": "3",
      "city_code": "0350",
      "center": "113.255309,38.728315",
      "parent_id": "301"
    },
    {
      "area_id": "305",
      "area_code": "140923",
      "area_name": "代县",
      "level": "3",
      "city_code": "0350",
      "center": "112.960282,39.066917",
      "parent_id": "301"
    },
    {
      "area_id": "306",
      "area_code": "140924",
      "area_name": "繁峙县",
      "level": "3",
      "city_code": "0350",
      "center": "113.265563,39.188811",
      "parent_id": "301"
    },
    {
      "area_id": "307",
      "area_code": "140925",
      "area_name": "宁武县",
      "level": "3",
      "city_code": "0350",
      "center": "112.304722,39.001524",
      "parent_id": "301"
    },
    {
      "area_id": "308",
      "area_code": "140926",
      "area_name": "静乐县",
      "level": "3",
      "city_code": "0350",
      "center": "111.939498,38.359306",
      "parent_id": "301"
    },
    {
      "area_id": "309",
      "area_code": "140927",
      "area_name": "神池县",
      "level": "3",
      "city_code": "0350",
      "center": "112.211296,39.090552",
      "parent_id": "301"
    },
    {
      "area_id": "310",
      "area_code": "140928",
      "area_name": "五寨县",
      "level": "3",
      "city_code": "0350",
      "center": "111.846904,38.910726",
      "parent_id": "301"
    },
    {
      "area_id": "311",
      "area_code": "140929",
      "area_name": "岢岚县",
      "level": "3",
      "city_code": "0350",
      "center": "111.57285,38.70418",
      "parent_id": "301"
    },
    {
      "area_id": "312",
      "area_code": "140930",
      "area_name": "河曲县",
      "level": "3",
      "city_code": "0350",
      "center": "111.138472,39.384482",
      "parent_id": "301"
    },
    {
      "area_id": "313",
      "area_code": "140931",
      "area_name": "保德县",
      "level": "3",
      "city_code": "0350",
      "center": "111.086564,39.022487",
      "parent_id": "301"
    },
    {
      "area_id": "314",
      "area_code": "140932",
      "area_name": "偏关县",
      "level": "3",
      "city_code": "0350",
      "center": "111.508831,39.436306",
      "parent_id": "301"
    },
    {
      "area_id": "315",
      "area_code": "140981",
      "area_name": "原平市",
      "level": "3",
      "city_code": "0350",
      "center": "112.711058,38.731402",
      "parent_id": "301"
    },
    {
      "area_id": "316",
      "area_code": "141000",
      "area_name": "临汾市",
      "level": "2",
      "city_code": "0357",
      "center": "111.518975,36.088005",
      "parent_id": "217"
    },
    {
      "area_id": "317",
      "area_code": "141002",
      "area_name": "尧都区",
      "level": "3",
      "city_code": "0357",
      "center": "111.579554,36.07884",
      "parent_id": "316"
    },
    {
      "area_id": "318",
      "area_code": "141021",
      "area_name": "曲沃县",
      "level": "3",
      "city_code": "0357",
      "center": "111.47586,35.641086",
      "parent_id": "316"
    },
    {
      "area_id": "319",
      "area_code": "141022",
      "area_name": "翼城县",
      "level": "3",
      "city_code": "0357",
      "center": "111.718951,35.738576",
      "parent_id": "316"
    },
    {
      "area_id": "320",
      "area_code": "141023",
      "area_name": "襄汾县",
      "level": "3",
      "city_code": "0357",
      "center": "111.441725,35.876293",
      "parent_id": "316"
    },
    {
      "area_id": "321",
      "area_code": "141024",
      "area_name": "洪洞县",
      "level": "3",
      "city_code": "0357",
      "center": "111.674965,36.253747",
      "parent_id": "316"
    },
    {
      "area_id": "322",
      "area_code": "141025",
      "area_name": "古县",
      "level": "3",
      "city_code": "0357",
      "center": "111.920465,36.266914",
      "parent_id": "316"
    },
    {
      "area_id": "323",
      "area_code": "141026",
      "area_name": "安泽县",
      "level": "3",
      "city_code": "0357",
      "center": "112.250144,36.147787",
      "parent_id": "316"
    },
    {
      "area_id": "324",
      "area_code": "141027",
      "area_name": "浮山县",
      "level": "3",
      "city_code": "0357",
      "center": "111.848883,35.968124",
      "parent_id": "316"
    },
    {
      "area_id": "325",
      "area_code": "141028",
      "area_name": "吉县",
      "level": "3",
      "city_code": "0357",
      "center": "110.681763,36.098188",
      "parent_id": "316"
    },
    {
      "area_id": "326",
      "area_code": "141029",
      "area_name": "乡宁县",
      "level": "3",
      "city_code": "0357",
      "center": "110.847021,35.970389",
      "parent_id": "316"
    },
    {
      "area_id": "327",
      "area_code": "141030",
      "area_name": "大宁县",
      "level": "3",
      "city_code": "0357",
      "center": "110.75291,36.465102",
      "parent_id": "316"
    },
    {
      "area_id": "328",
      "area_code": "141031",
      "area_name": "隰县",
      "level": "3",
      "city_code": "0357",
      "center": "110.940637,36.69333",
      "parent_id": "316"
    },
    {
      "area_id": "329",
      "area_code": "141032",
      "area_name": "永和县",
      "level": "3",
      "city_code": "0357",
      "center": "110.632006,36.759507",
      "parent_id": "316"
    },
    {
      "area_id": "330",
      "area_code": "141033",
      "area_name": "蒲县",
      "level": "3",
      "city_code": "0357",
      "center": "111.096439,36.411826",
      "parent_id": "316"
    },
    {
      "area_id": "331",
      "area_code": "141034",
      "area_name": "汾西县",
      "level": "3",
      "city_code": "0357",
      "center": "111.56395,36.652854",
      "parent_id": "316"
    },
    {
      "area_id": "332",
      "area_code": "141081",
      "area_name": "侯马市",
      "level": "3",
      "city_code": "0357",
      "center": "111.372002,35.619105",
      "parent_id": "316"
    },
    {
      "area_id": "333",
      "area_code": "141082",
      "area_name": "霍州市",
      "level": "3",
      "city_code": "0357",
      "center": "111.755398,36.56893",
      "parent_id": "316"
    },
    {
      "area_id": "334",
      "area_code": "141100",
      "area_name": "吕梁市",
      "level": "2",
      "city_code": "0358",
      "center": "111.144699,37.519126",
      "parent_id": "217"
    },
    {
      "area_id": "335",
      "area_code": "141102",
      "area_name": "离石区",
      "level": "3",
      "city_code": "0358",
      "center": "111.150695,37.51786",
      "parent_id": "334"
    },
    {
      "area_id": "336",
      "area_code": "141121",
      "area_name": "文水县",
      "level": "3",
      "city_code": "0358",
      "center": "112.028866,37.438101",
      "parent_id": "334"
    },
    {
      "area_id": "337",
      "area_code": "141122",
      "area_name": "交城县",
      "level": "3",
      "city_code": "0358",
      "center": "112.156064,37.551963",
      "parent_id": "334"
    },
    {
      "area_id": "338",
      "area_code": "141123",
      "area_name": "兴县",
      "level": "3",
      "city_code": "0358",
      "center": "111.127667,38.462389",
      "parent_id": "334"
    },
    {
      "area_id": "339",
      "area_code": "141124",
      "area_name": "临县",
      "level": "3",
      "city_code": "0358",
      "center": "110.992093,37.950758",
      "parent_id": "334"
    },
    {
      "area_id": "340",
      "area_code": "141125",
      "area_name": "柳林县",
      "level": "3",
      "city_code": "0358",
      "center": "110.889007,37.429772",
      "parent_id": "334"
    },
    {
      "area_id": "341",
      "area_code": "141126",
      "area_name": "石楼县",
      "level": "3",
      "city_code": "0358",
      "center": "110.834634,36.99857",
      "parent_id": "334"
    },
    {
      "area_id": "342",
      "area_code": "141127",
      "area_name": "岚县",
      "level": "3",
      "city_code": "0358",
      "center": "111.671917,38.279299",
      "parent_id": "334"
    },
    {
      "area_id": "343",
      "area_code": "141128",
      "area_name": "方山县",
      "level": "3",
      "city_code": "0358",
      "center": "111.244098,37.894631",
      "parent_id": "334"
    },
    {
      "area_id": "344",
      "area_code": "141129",
      "area_name": "中阳县",
      "level": "3",
      "city_code": "0358",
      "center": "111.179657,37.357058",
      "parent_id": "334"
    },
    {
      "area_id": "345",
      "area_code": "141130",
      "area_name": "交口县",
      "level": "3",
      "city_code": "0358",
      "center": "111.181151,36.982186",
      "parent_id": "334"
    },
    {
      "area_id": "346",
      "area_code": "141181",
      "area_name": "孝义市",
      "level": "3",
      "city_code": "0358",
      "center": "111.778818,37.146294",
      "parent_id": "334"
    },
    {
      "area_id": "347",
      "area_code": "141182",
      "area_name": "汾阳市",
      "level": "3",
      "city_code": "0358",
      "center": "111.770477,37.261756",
      "parent_id": "334"
    },
    {
      "area_id": "348",
      "area_code": "150000",
      "area_name": "内蒙古自治区",
      "level": "1",
      "city_code": "[]",
      "center": "111.76629,40.81739",
      "parent_id": "0"
    },
    {
      "area_id": "349",
      "area_code": "150100",
      "area_name": "呼和浩特市",
      "level": "2",
      "city_code": "0471",
      "center": "111.749995,40.842356",
      "parent_id": "348"
    },
    {
      "area_id": "350",
      "area_code": "150102",
      "area_name": "新城区",
      "level": "3",
      "city_code": "0471",
      "center": "111.665544,40.858289",
      "parent_id": "349"
    },
    {
      "area_id": "351",
      "area_code": "150103",
      "area_name": "回民区",
      "level": "3",
      "city_code": "0471",
      "center": "111.623692,40.808608",
      "parent_id": "349"
    },
    {
      "area_id": "352",
      "area_code": "150104",
      "area_name": "玉泉区",
      "level": "3",
      "city_code": "0471",
      "center": "111.673881,40.753655",
      "parent_id": "349"
    },
    {
      "area_id": "353",
      "area_code": "150105",
      "area_name": "赛罕区",
      "level": "3",
      "city_code": "0471",
      "center": "111.701355,40.792667",
      "parent_id": "349"
    },
    {
      "area_id": "354",
      "area_code": "150121",
      "area_name": "土默特左旗",
      "level": "3",
      "city_code": "0471",
      "center": "111.163902,40.729572",
      "parent_id": "349"
    },
    {
      "area_id": "355",
      "area_code": "150122",
      "area_name": "托克托县",
      "level": "3",
      "city_code": "0471",
      "center": "111.194312,40.277431",
      "parent_id": "349"
    },
    {
      "area_id": "356",
      "area_code": "150123",
      "area_name": "和林格尔县",
      "level": "3",
      "city_code": "0471",
      "center": "111.821843,40.378787",
      "parent_id": "349"
    },
    {
      "area_id": "357",
      "area_code": "150124",
      "area_name": "清水河县",
      "level": "3",
      "city_code": "0471",
      "center": "111.647609,39.921095",
      "parent_id": "349"
    },
    {
      "area_id": "358",
      "area_code": "150125",
      "area_name": "武川县",
      "level": "3",
      "city_code": "0471",
      "center": "111.451303,41.096471",
      "parent_id": "349"
    },
    {
      "area_id": "359",
      "area_code": "150200",
      "area_name": "包头市",
      "level": "2",
      "city_code": "0472",
      "center": "109.953504,40.621157",
      "parent_id": "348"
    },
    {
      "area_id": "360",
      "area_code": "150202",
      "area_name": "东河区",
      "level": "3",
      "city_code": "0472",
      "center": "110.044106,40.576319",
      "parent_id": "359"
    },
    {
      "area_id": "361",
      "area_code": "150203",
      "area_name": "昆都仑区",
      "level": "3",
      "city_code": "0472",
      "center": "109.837707,40.642578",
      "parent_id": "359"
    },
    {
      "area_id": "362",
      "area_code": "150204",
      "area_name": "青山区",
      "level": "3",
      "city_code": "0472",
      "center": "109.901572,40.643246",
      "parent_id": "359"
    },
    {
      "area_id": "363",
      "area_code": "150205",
      "area_name": "石拐区",
      "level": "3",
      "city_code": "0472",
      "center": "110.060254,40.681748",
      "parent_id": "359"
    },
    {
      "area_id": "364",
      "area_code": "150206",
      "area_name": "白云鄂博矿区",
      "level": "3",
      "city_code": "0472",
      "center": "109.973803,41.769511",
      "parent_id": "359"
    },
    {
      "area_id": "365",
      "area_code": "150207",
      "area_name": "九原区",
      "level": "3",
      "city_code": "0472",
      "center": "109.967449,40.610561",
      "parent_id": "359"
    },
    {
      "area_id": "366",
      "area_code": "150221",
      "area_name": "土默特右旗",
      "level": "3",
      "city_code": "0472",
      "center": "110.524262,40.569426",
      "parent_id": "359"
    },
    {
      "area_id": "367",
      "area_code": "150222",
      "area_name": "固阳县",
      "level": "3",
      "city_code": "0472",
      "center": "110.060514,41.034105",
      "parent_id": "359"
    },
    {
      "area_id": "368",
      "area_code": "150223",
      "area_name": "达尔罕茂明安联合旗",
      "level": "3",
      "city_code": "0472",
      "center": "110.432626,41.698992",
      "parent_id": "359"
    },
    {
      "area_id": "369",
      "area_code": "150300",
      "area_name": "乌海市",
      "level": "2",
      "city_code": "0473",
      "center": "106.794216,39.655248",
      "parent_id": "348"
    },
    {
      "area_id": "370",
      "area_code": "150302",
      "area_name": "海勃湾区",
      "level": "3",
      "city_code": "0473",
      "center": "106.822778,39.691156",
      "parent_id": "369"
    },
    {
      "area_id": "371",
      "area_code": "150303",
      "area_name": "海南区",
      "level": "3",
      "city_code": "0473",
      "center": "106.891424,39.441364",
      "parent_id": "369"
    },
    {
      "area_id": "372",
      "area_code": "150304",
      "area_name": "乌达区",
      "level": "3",
      "city_code": "0473",
      "center": "106.726099,39.505925",
      "parent_id": "369"
    },
    {
      "area_id": "373",
      "area_code": "150400",
      "area_name": "赤峰市",
      "level": "2",
      "city_code": "0476",
      "center": "118.88694,42.257843",
      "parent_id": "348"
    },
    {
      "area_id": "374",
      "area_code": "150402",
      "area_name": "红山区",
      "level": "3",
      "city_code": "0476",
      "center": "118.953854,42.296588",
      "parent_id": "373"
    },
    {
      "area_id": "375",
      "area_code": "150403",
      "area_name": "元宝山区",
      "level": "3",
      "city_code": "0476",
      "center": "119.288611,42.038902",
      "parent_id": "373"
    },
    {
      "area_id": "376",
      "area_code": "150404",
      "area_name": "松山区",
      "level": "3",
      "city_code": "0476",
      "center": "118.916208,42.299798",
      "parent_id": "373"
    },
    {
      "area_id": "377",
      "area_code": "150421",
      "area_name": "阿鲁科尔沁旗",
      "level": "3",
      "city_code": "0476",
      "center": "120.0657,43.872298",
      "parent_id": "373"
    },
    {
      "area_id": "378",
      "area_code": "150422",
      "area_name": "巴林左旗",
      "level": "3",
      "city_code": "0476",
      "center": "119.362931,43.960889",
      "parent_id": "373"
    },
    {
      "area_id": "379",
      "area_code": "150423",
      "area_name": "巴林右旗",
      "level": "3",
      "city_code": "0476",
      "center": "118.66518,43.534414",
      "parent_id": "373"
    },
    {
      "area_id": "380",
      "area_code": "150424",
      "area_name": "林西县",
      "level": "3",
      "city_code": "0476",
      "center": "118.05545,43.61812",
      "parent_id": "373"
    },
    {
      "area_id": "381",
      "area_code": "150425",
      "area_name": "克什克腾旗",
      "level": "3",
      "city_code": "0476",
      "center": "117.545797,43.264988",
      "parent_id": "373"
    },
    {
      "area_id": "382",
      "area_code": "150426",
      "area_name": "翁牛特旗",
      "level": "3",
      "city_code": "0476",
      "center": "119.00658,42.936188",
      "parent_id": "373"
    },
    {
      "area_id": "383",
      "area_code": "150428",
      "area_name": "喀喇沁旗",
      "level": "3",
      "city_code": "0476",
      "center": "118.701937,41.927363",
      "parent_id": "373"
    },
    {
      "area_id": "384",
      "area_code": "150429",
      "area_name": "宁城县",
      "level": "3",
      "city_code": "0476",
      "center": "119.318876,41.601375",
      "parent_id": "373"
    },
    {
      "area_id": "385",
      "area_code": "150430",
      "area_name": "敖汉旗",
      "level": "3",
      "city_code": "0476",
      "center": "119.921603,42.290781",
      "parent_id": "373"
    },
    {
      "area_id": "386",
      "area_code": "150500",
      "area_name": "通辽市",
      "level": "2",
      "city_code": "0475",
      "center": "122.243444,43.652889",
      "parent_id": "348"
    },
    {
      "area_id": "387",
      "area_code": "150502",
      "area_name": "科尔沁区",
      "level": "3",
      "city_code": "0475",
      "center": "122.255671,43.623078",
      "parent_id": "386"
    },
    {
      "area_id": "388",
      "area_code": "150521",
      "area_name": "科尔沁左翼中旗",
      "level": "3",
      "city_code": "0475",
      "center": "123.312264,44.126625",
      "parent_id": "386"
    },
    {
      "area_id": "389",
      "area_code": "150522",
      "area_name": "科尔沁左翼后旗",
      "level": "3",
      "city_code": "0475",
      "center": "122.35677,42.935105",
      "parent_id": "386"
    },
    {
      "area_id": "390",
      "area_code": "150523",
      "area_name": "开鲁县",
      "level": "3",
      "city_code": "0475",
      "center": "121.319308,43.601244",
      "parent_id": "386"
    },
    {
      "area_id": "391",
      "area_code": "150524",
      "area_name": "库伦旗",
      "level": "3",
      "city_code": "0475",
      "center": "121.8107,42.735656",
      "parent_id": "386"
    },
    {
      "area_id": "392",
      "area_code": "150525",
      "area_name": "奈曼旗",
      "level": "3",
      "city_code": "0475",
      "center": "120.658282,42.867226",
      "parent_id": "386"
    },
    {
      "area_id": "393",
      "area_code": "150526",
      "area_name": "扎鲁特旗",
      "level": "3",
      "city_code": "0475",
      "center": "120.911676,44.556389",
      "parent_id": "386"
    },
    {
      "area_id": "394",
      "area_code": "150581",
      "area_name": "霍林郭勒市",
      "level": "3",
      "city_code": "0475",
      "center": "119.68187,45.533962",
      "parent_id": "386"
    },
    {
      "area_id": "395",
      "area_code": "150600",
      "area_name": "鄂尔多斯市",
      "level": "2",
      "city_code": "0477",
      "center": "109.781327,39.608266",
      "parent_id": "348"
    },
    {
      "area_id": "396",
      "area_code": "150602",
      "area_name": "东胜区",
      "level": "3",
      "city_code": "0477",
      "center": "109.963333,39.822593",
      "parent_id": "395"
    },
    {
      "area_id": "397",
      "area_code": "150603",
      "area_name": "康巴什区",
      "level": "3",
      "city_code": "0477",
      "center": "109.790076,39.607472",
      "parent_id": "395"
    },
    {
      "area_id": "398",
      "area_code": "150621",
      "area_name": "达拉特旗",
      "level": "3",
      "city_code": "0477",
      "center": "110.033833,40.412438",
      "parent_id": "395"
    },
    {
      "area_id": "399",
      "area_code": "150622",
      "area_name": "准格尔旗",
      "level": "3",
      "city_code": "0477",
      "center": "111.240171,39.864361",
      "parent_id": "395"
    },
    {
      "area_id": "400",
      "area_code": "150623",
      "area_name": "鄂托克前旗",
      "level": "3",
      "city_code": "0477",
      "center": "107.477514,38.182362",
      "parent_id": "395"
    },
    {
      "area_id": "401",
      "area_code": "150624",
      "area_name": "鄂托克旗",
      "level": "3",
      "city_code": "0477",
      "center": "107.97616,39.08965",
      "parent_id": "395"
    },
    {
      "area_id": "402",
      "area_code": "150625",
      "area_name": "杭锦旗",
      "level": "3",
      "city_code": "0477",
      "center": "108.736208,39.833309",
      "parent_id": "395"
    },
    {
      "area_id": "403",
      "area_code": "150626",
      "area_name": "乌审旗",
      "level": "3",
      "city_code": "0477",
      "center": "108.817607,38.604136",
      "parent_id": "395"
    },
    {
      "area_id": "404",
      "area_code": "150627",
      "area_name": "伊金霍洛旗",
      "level": "3",
      "city_code": "0477",
      "center": "109.74774,39.564659",
      "parent_id": "395"
    },
    {
      "area_id": "405",
      "area_code": "150700",
      "area_name": "呼伦贝尔市",
      "level": "2",
      "city_code": "0470",
      "center": "119.765558,49.211576",
      "parent_id": "348"
    },
    {
      "area_id": "406",
      "area_code": "150702",
      "area_name": "海拉尔区",
      "level": "3",
      "city_code": "0470",
      "center": "119.736176,49.212188",
      "parent_id": "405"
    },
    {
      "area_id": "407",
      "area_code": "150703",
      "area_name": "扎赉诺尔区",
      "level": "3",
      "city_code": "0470",
      "center": "117.670248,49.510375",
      "parent_id": "405"
    },
    {
      "area_id": "408",
      "area_code": "150721",
      "area_name": "阿荣旗",
      "level": "3",
      "city_code": "0470",
      "center": "123.459049,48.126584",
      "parent_id": "405"
    },
    {
      "area_id": "409",
      "area_code": "150722",
      "area_name": "莫力达瓦达斡尔族自治旗",
      "level": "3",
      "city_code": "0470",
      "center": "124.519023,48.477728",
      "parent_id": "405"
    },
    {
      "area_id": "410",
      "area_code": "150723",
      "area_name": "鄂伦春自治旗",
      "level": "3",
      "city_code": "0470",
      "center": "123.726201,50.591842",
      "parent_id": "405"
    },
    {
      "area_id": "411",
      "area_code": "150724",
      "area_name": "鄂温克族自治旗",
      "level": "3",
      "city_code": "0470",
      "center": "119.755239,49.146592",
      "parent_id": "405"
    },
    {
      "area_id": "412",
      "area_code": "150725",
      "area_name": "陈巴尔虎旗",
      "level": "3",
      "city_code": "0470",
      "center": "119.424026,49.328916",
      "parent_id": "405"
    },
    {
      "area_id": "413",
      "area_code": "150726",
      "area_name": "新巴尔虎左旗",
      "level": "3",
      "city_code": "0470",
      "center": "118.269819,48.218241",
      "parent_id": "405"
    },
    {
      "area_id": "414",
      "area_code": "150727",
      "area_name": "新巴尔虎右旗",
      "level": "3",
      "city_code": "0470",
      "center": "116.82369,48.672101",
      "parent_id": "405"
    },
    {
      "area_id": "415",
      "area_code": "150781",
      "area_name": "满洲里市",
      "level": "3",
      "city_code": "0470",
      "center": "117.378529,49.597841",
      "parent_id": "405"
    },
    {
      "area_id": "416",
      "area_code": "150782",
      "area_name": "牙克石市",
      "level": "3",
      "city_code": "0470",
      "center": "120.711775,49.285629",
      "parent_id": "405"
    },
    {
      "area_id": "417",
      "area_code": "150783",
      "area_name": "扎兰屯市",
      "level": "3",
      "city_code": "0470",
      "center": "122.737467,48.013733",
      "parent_id": "405"
    },
    {
      "area_id": "418",
      "area_code": "150784",
      "area_name": "额尔古纳市",
      "level": "3",
      "city_code": "0470",
      "center": "120.180506,50.243102",
      "parent_id": "405"
    },
    {
      "area_id": "419",
      "area_code": "150785",
      "area_name": "根河市",
      "level": "3",
      "city_code": "0470",
      "center": "121.520388,50.780344",
      "parent_id": "405"
    },
    {
      "area_id": "420",
      "area_code": "150800",
      "area_name": "巴彦淖尔市",
      "level": "2",
      "city_code": "0478",
      "center": "107.387657,40.743213",
      "parent_id": "348"
    },
    {
      "area_id": "421",
      "area_code": "150802",
      "area_name": "临河区",
      "level": "3",
      "city_code": "0478",
      "center": "107.363918,40.751187",
      "parent_id": "420"
    },
    {
      "area_id": "422",
      "area_code": "150821",
      "area_name": "五原县",
      "level": "3",
      "city_code": "0478",
      "center": "108.267561,41.088421",
      "parent_id": "420"
    },
    {
      "area_id": "423",
      "area_code": "150822",
      "area_name": "磴口县",
      "level": "3",
      "city_code": "0478",
      "center": "107.008248,40.330523",
      "parent_id": "420"
    },
    {
      "area_id": "424",
      "area_code": "150823",
      "area_name": "乌拉特前旗",
      "level": "3",
      "city_code": "0478",
      "center": "108.652114,40.737018",
      "parent_id": "420"
    },
    {
      "area_id": "425",
      "area_code": "150824",
      "area_name": "乌拉特中旗",
      "level": "3",
      "city_code": "0478",
      "center": "108.513645,41.587732",
      "parent_id": "420"
    },
    {
      "area_id": "426",
      "area_code": "150825",
      "area_name": "乌拉特后旗",
      "level": "3",
      "city_code": "0478",
      "center": "107.074621,41.084282",
      "parent_id": "420"
    },
    {
      "area_id": "427",
      "area_code": "150826",
      "area_name": "杭锦后旗",
      "level": "3",
      "city_code": "0478",
      "center": "107.151245,40.88602",
      "parent_id": "420"
    },
    {
      "area_id": "428",
      "area_code": "150900",
      "area_name": "乌兰察布市",
      "level": "2",
      "city_code": "0474",
      "center": "113.132584,40.994785",
      "parent_id": "348"
    },
    {
      "area_id": "429",
      "area_code": "150902",
      "area_name": "集宁区",
      "level": "3",
      "city_code": "0474",
      "center": "113.116453,41.034134",
      "parent_id": "428"
    },
    {
      "area_id": "430",
      "area_code": "150921",
      "area_name": "卓资县",
      "level": "3",
      "city_code": "0474",
      "center": "112.577528,40.894691",
      "parent_id": "428"
    },
    {
      "area_id": "431",
      "area_code": "150922",
      "area_name": "化德县",
      "level": "3",
      "city_code": "0474",
      "center": "114.010437,41.90456",
      "parent_id": "428"
    },
    {
      "area_id": "432",
      "area_code": "150923",
      "area_name": "商都县",
      "level": "3",
      "city_code": "0474",
      "center": "113.577816,41.562113",
      "parent_id": "428"
    },
    {
      "area_id": "433",
      "area_code": "150924",
      "area_name": "兴和县",
      "level": "3",
      "city_code": "0474",
      "center": "113.834173,40.872301",
      "parent_id": "428"
    },
    {
      "area_id": "434",
      "area_code": "150925",
      "area_name": "凉城县",
      "level": "3",
      "city_code": "0474",
      "center": "112.503971,40.531555",
      "parent_id": "428"
    },
    {
      "area_id": "435",
      "area_code": "150926",
      "area_name": "察哈尔右翼前旗",
      "level": "3",
      "city_code": "0474",
      "center": "113.214733,40.785631",
      "parent_id": "428"
    },
    {
      "area_id": "436",
      "area_code": "150927",
      "area_name": "察哈尔右翼中旗",
      "level": "3",
      "city_code": "0474",
      "center": "112.635577,41.277462",
      "parent_id": "428"
    },
    {
      "area_id": "437",
      "area_code": "150928",
      "area_name": "察哈尔右翼后旗",
      "level": "3",
      "city_code": "0474",
      "center": "113.191035,41.436069",
      "parent_id": "428"
    },
    {
      "area_id": "438",
      "area_code": "150929",
      "area_name": "四子王旗",
      "level": "3",
      "city_code": "0474",
      "center": "111.706617,41.533462",
      "parent_id": "428"
    },
    {
      "area_id": "439",
      "area_code": "150981",
      "area_name": "丰镇市",
      "level": "3",
      "city_code": "0474",
      "center": "113.109892,40.436983",
      "parent_id": "428"
    },
    {
      "area_id": "440",
      "area_code": "152200",
      "area_name": "兴安盟",
      "level": "2",
      "city_code": "0482",
      "center": "122.037657,46.082462",
      "parent_id": "348"
    },
    {
      "area_id": "441",
      "area_code": "152201",
      "area_name": "乌兰浩特市",
      "level": "3",
      "city_code": "0482",
      "center": "122.093123,46.072731",
      "parent_id": "440"
    },
    {
      "area_id": "442",
      "area_code": "152202",
      "area_name": "阿尔山市",
      "level": "3",
      "city_code": "0482",
      "center": "119.943575,47.17744",
      "parent_id": "440"
    },
    {
      "area_id": "443",
      "area_code": "152221",
      "area_name": "科尔沁右翼前旗",
      "level": "3",
      "city_code": "0482",
      "center": "121.952621,46.079833",
      "parent_id": "440"
    },
    {
      "area_id": "444",
      "area_code": "152222",
      "area_name": "科尔沁右翼中旗",
      "level": "3",
      "city_code": "0482",
      "center": "121.47653,45.060837",
      "parent_id": "440"
    },
    {
      "area_id": "445",
      "area_code": "152223",
      "area_name": "扎赉特旗",
      "level": "3",
      "city_code": "0482",
      "center": "122.899656,46.723237",
      "parent_id": "440"
    },
    {
      "area_id": "446",
      "area_code": "152224",
      "area_name": "突泉县",
      "level": "3",
      "city_code": "0482",
      "center": "121.593799,45.38193",
      "parent_id": "440"
    },
    {
      "area_id": "447",
      "area_code": "152500",
      "area_name": "锡林郭勒盟",
      "level": "2",
      "city_code": "0479",
      "center": "116.048222,43.933454",
      "parent_id": "348"
    },
    {
      "area_id": "448",
      "area_code": "152501",
      "area_name": "二连浩特市",
      "level": "3",
      "city_code": "0479",
      "center": "111.951002,43.6437",
      "parent_id": "447"
    },
    {
      "area_id": "449",
      "area_code": "152502",
      "area_name": "锡林浩特市",
      "level": "3",
      "city_code": "0479",
      "center": "116.086029,43.933403",
      "parent_id": "447"
    },
    {
      "area_id": "450",
      "area_code": "152522",
      "area_name": "阿巴嘎旗",
      "level": "3",
      "city_code": "0479",
      "center": "114.950248,44.022995",
      "parent_id": "447"
    },
    {
      "area_id": "451",
      "area_code": "152523",
      "area_name": "苏尼特左旗",
      "level": "3",
      "city_code": "0479",
      "center": "113.667248,43.85988",
      "parent_id": "447"
    },
    {
      "area_id": "452",
      "area_code": "152524",
      "area_name": "苏尼特右旗",
      "level": "3",
      "city_code": "0479",
      "center": "112.641783,42.742892",
      "parent_id": "447"
    },
    {
      "area_id": "453",
      "area_code": "152525",
      "area_name": "东乌珠穆沁旗",
      "level": "3",
      "city_code": "0479",
      "center": "116.974494,45.498221",
      "parent_id": "447"
    },
    {
      "area_id": "454",
      "area_code": "152526",
      "area_name": "西乌珠穆沁旗",
      "level": "3",
      "city_code": "0479",
      "center": "117.608911,44.587882",
      "parent_id": "447"
    },
    {
      "area_id": "455",
      "area_code": "152527",
      "area_name": "太仆寺旗",
      "level": "3",
      "city_code": "0479",
      "center": "115.282986,41.877135",
      "parent_id": "447"
    },
    {
      "area_id": "456",
      "area_code": "152528",
      "area_name": "镶黄旗",
      "level": "3",
      "city_code": "0479",
      "center": "113.847287,42.232371",
      "parent_id": "447"
    },
    {
      "area_id": "457",
      "area_code": "152529",
      "area_name": "正镶白旗",
      "level": "3",
      "city_code": "0479",
      "center": "115.029848,42.28747",
      "parent_id": "447"
    },
    {
      "area_id": "458",
      "area_code": "152530",
      "area_name": "正蓝旗",
      "level": "3",
      "city_code": "0479",
      "center": "115.99247,42.241638",
      "parent_id": "447"
    },
    {
      "area_id": "459",
      "area_code": "152531",
      "area_name": "多伦县",
      "level": "3",
      "city_code": "0479",
      "center": "116.485555,42.203591",
      "parent_id": "447"
    },
    {
      "area_id": "460",
      "area_code": "152900",
      "area_name": "阿拉善盟",
      "level": "2",
      "city_code": "0483",
      "center": "105.728957,38.851921",
      "parent_id": "348"
    },
    {
      "area_id": "461",
      "area_code": "152921",
      "area_name": "阿拉善左旗",
      "level": "3",
      "city_code": "0483",
      "center": "105.666275,38.833389",
      "parent_id": "460"
    },
    {
      "area_id": "462",
      "area_code": "152922",
      "area_name": "阿拉善右旗",
      "level": "3",
      "city_code": "0483",
      "center": "101.666917,39.216185",
      "parent_id": "460"
    },
    {
      "area_id": "463",
      "area_code": "152923",
      "area_name": "额济纳旗",
      "level": "3",
      "city_code": "0483",
      "center": "101.055731,41.95455",
      "parent_id": "460"
    },
    {
      "area_id": "464",
      "area_code": "210000",
      "area_name": "辽宁省",
      "level": "1",
      "city_code": "[]",
      "center": "123.431382,41.836175",
      "parent_id": "0"
    },
    {
      "area_id": "465",
      "area_code": "210100",
      "area_name": "沈阳市",
      "level": "2",
      "city_code": "024",
      "center": "123.465035,41.677284",
      "parent_id": "464"
    },
    {
      "area_id": "466",
      "area_code": "210102",
      "area_name": "和平区",
      "level": "3",
      "city_code": "024",
      "center": "123.420368,41.789833",
      "parent_id": "465"
    },
    {
      "area_id": "467",
      "area_code": "210103",
      "area_name": "沈河区",
      "level": "3",
      "city_code": "024",
      "center": "123.458691,41.796177",
      "parent_id": "465"
    },
    {
      "area_id": "468",
      "area_code": "210104",
      "area_name": "大东区",
      "level": "3",
      "city_code": "024",
      "center": "123.469948,41.805137",
      "parent_id": "465"
    },
    {
      "area_id": "469",
      "area_code": "210105",
      "area_name": "皇姑区",
      "level": "3",
      "city_code": "024",
      "center": "123.442378,41.824516",
      "parent_id": "465"
    },
    {
      "area_id": "470",
      "area_code": "210106",
      "area_name": "铁西区",
      "level": "3",
      "city_code": "024",
      "center": "123.333968,41.820807",
      "parent_id": "465"
    },
    {
      "area_id": "471",
      "area_code": "210111",
      "area_name": "苏家屯区",
      "level": "3",
      "city_code": "024",
      "center": "123.344062,41.664757",
      "parent_id": "465"
    },
    {
      "area_id": "472",
      "area_code": "210112",
      "area_name": "浑南区",
      "level": "3",
      "city_code": "024",
      "center": "123.449714,41.714914",
      "parent_id": "465"
    },
    {
      "area_id": "473",
      "area_code": "210113",
      "area_name": "沈北新区",
      "level": "3",
      "city_code": "024",
      "center": "123.583196,41.912487",
      "parent_id": "465"
    },
    {
      "area_id": "474",
      "area_code": "210114",
      "area_name": "于洪区",
      "level": "3",
      "city_code": "024",
      "center": "123.308119,41.793721",
      "parent_id": "465"
    },
    {
      "area_id": "475",
      "area_code": "210115",
      "area_name": "辽中区",
      "level": "3",
      "city_code": "024",
      "center": "122.765409,41.516826",
      "parent_id": "465"
    },
    {
      "area_id": "476",
      "area_code": "210123",
      "area_name": "康平县",
      "level": "3",
      "city_code": "024",
      "center": "123.343699,42.72793",
      "parent_id": "465"
    },
    {
      "area_id": "477",
      "area_code": "210124",
      "area_name": "法库县",
      "level": "3",
      "city_code": "024",
      "center": "123.440294,42.50108",
      "parent_id": "465"
    },
    {
      "area_id": "478",
      "area_code": "210181",
      "area_name": "新民市",
      "level": "3",
      "city_code": "024",
      "center": "122.836723,41.985186",
      "parent_id": "465"
    },
    {
      "area_id": "479",
      "area_code": "210200",
      "area_name": "大连市",
      "level": "2",
      "city_code": "0411",
      "center": "121.614848,38.914086",
      "parent_id": "464"
    },
    {
      "area_id": "480",
      "area_code": "210202",
      "area_name": "中山区",
      "level": "3",
      "city_code": "0411",
      "center": "121.644926,38.918574",
      "parent_id": "479"
    },
    {
      "area_id": "481",
      "area_code": "210203",
      "area_name": "西岗区",
      "level": "3",
      "city_code": "0411",
      "center": "121.612324,38.914687",
      "parent_id": "479"
    },
    {
      "area_id": "482",
      "area_code": "210204",
      "area_name": "沙河口区",
      "level": "3",
      "city_code": "0411",
      "center": "121.594297,38.904788",
      "parent_id": "479"
    },
    {
      "area_id": "483",
      "area_code": "210211",
      "area_name": "甘井子区",
      "level": "3",
      "city_code": "0411",
      "center": "121.525466,38.953343",
      "parent_id": "479"
    },
    {
      "area_id": "484",
      "area_code": "210212",
      "area_name": "旅顺口区",
      "level": "3",
      "city_code": "0411",
      "center": "121.261953,38.851705",
      "parent_id": "479"
    },
    {
      "area_id": "485",
      "area_code": "210213",
      "area_name": "金州区",
      "level": "3",
      "city_code": "0411",
      "center": "121.782655,39.050001",
      "parent_id": "479"
    },
    {
      "area_id": "486",
      "area_code": "210214",
      "area_name": "普兰店区",
      "level": "3",
      "city_code": "0411",
      "center": "121.938269,39.392095",
      "parent_id": "479"
    },
    {
      "area_id": "487",
      "area_code": "210224",
      "area_name": "长海县",
      "level": "3",
      "city_code": "0411",
      "center": "122.588494,39.272728",
      "parent_id": "479"
    },
    {
      "area_id": "488",
      "area_code": "210281",
      "area_name": "瓦房店市",
      "level": "3",
      "city_code": "0411",
      "center": "121.979543,39.626897",
      "parent_id": "479"
    },
    {
      "area_id": "489",
      "area_code": "210283",
      "area_name": "庄河市",
      "level": "3",
      "city_code": "0411",
      "center": "122.967424,39.680843",
      "parent_id": "479"
    },
    {
      "area_id": "490",
      "area_code": "210300",
      "area_name": "鞍山市",
      "level": "2",
      "city_code": "0412",
      "center": "122.994329,41.108647",
      "parent_id": "464"
    },
    {
      "area_id": "491",
      "area_code": "210302",
      "area_name": "铁东区",
      "level": "3",
      "city_code": "0412",
      "center": "122.991052,41.089933",
      "parent_id": "490"
    },
    {
      "area_id": "492",
      "area_code": "210303",
      "area_name": "铁西区",
      "level": "3",
      "city_code": "0412",
      "center": "122.969629,41.119884",
      "parent_id": "490"
    },
    {
      "area_id": "493",
      "area_code": "210304",
      "area_name": "立山区",
      "level": "3",
      "city_code": "0412",
      "center": "123.029091,41.150401",
      "parent_id": "490"
    },
    {
      "area_id": "494",
      "area_code": "210311",
      "area_name": "千山区",
      "level": "3",
      "city_code": "0412",
      "center": "122.944751,41.068901",
      "parent_id": "490"
    },
    {
      "area_id": "495",
      "area_code": "210321",
      "area_name": "台安县",
      "level": "3",
      "city_code": "0412",
      "center": "122.436196,41.412767",
      "parent_id": "490"
    },
    {
      "area_id": "496",
      "area_code": "210323",
      "area_name": "岫岩满族自治县",
      "level": "3",
      "city_code": "0412",
      "center": "123.280935,40.29088",
      "parent_id": "490"
    },
    {
      "area_id": "497",
      "area_code": "210381",
      "area_name": "海城市",
      "level": "3",
      "city_code": "0412",
      "center": "122.685217,40.882377",
      "parent_id": "490"
    },
    {
      "area_id": "498",
      "area_code": "210400",
      "area_name": "抚顺市",
      "level": "2",
      "city_code": "0413",
      "center": "123.957208,41.880872",
      "parent_id": "464"
    },
    {
      "area_id": "499",
      "area_code": "210402",
      "area_name": "新抚区",
      "level": "3",
      "city_code": "0413",
      "center": "123.912872,41.862026",
      "parent_id": "498"
    },
    {
      "area_id": "500",
      "area_code": "210403",
      "area_name": "东洲区",
      "level": "3",
      "city_code": "0413",
      "center": "124.038685,41.853191",
      "parent_id": "498"
    },
    {
      "area_id": "501",
      "area_code": "210404",
      "area_name": "望花区",
      "level": "3",
      "city_code": "0413",
      "center": "123.784225,41.853641",
      "parent_id": "498"
    },
    {
      "area_id": "502",
      "area_code": "210411",
      "area_name": "顺城区",
      "level": "3",
      "city_code": "0413",
      "center": "123.945075,41.883235",
      "parent_id": "498"
    },
    {
      "area_id": "503",
      "area_code": "210421",
      "area_name": "抚顺县",
      "level": "3",
      "city_code": "0413",
      "center": "124.097978,41.922644",
      "parent_id": "498"
    },
    {
      "area_id": "504",
      "area_code": "210422",
      "area_name": "新宾满族自治县",
      "level": "3",
      "city_code": "0413",
      "center": "125.039978,41.734256",
      "parent_id": "498"
    },
    {
      "area_id": "505",
      "area_code": "210423",
      "area_name": "清原满族自治县",
      "level": "3",
      "city_code": "0413",
      "center": "124.924083,42.100538",
      "parent_id": "498"
    },
    {
      "area_id": "506",
      "area_code": "210500",
      "area_name": "本溪市",
      "level": "2",
      "city_code": "0414",
      "center": "123.685142,41.486981",
      "parent_id": "464"
    },
    {
      "area_id": "507",
      "area_code": "210502",
      "area_name": "平山区",
      "level": "3",
      "city_code": "0414",
      "center": "123.769088,41.299587",
      "parent_id": "506"
    },
    {
      "area_id": "508",
      "area_code": "210503",
      "area_name": "溪湖区",
      "level": "3",
      "city_code": "0414",
      "center": "123.767646,41.329219",
      "parent_id": "506"
    },
    {
      "area_id": "509",
      "area_code": "210504",
      "area_name": "明山区",
      "level": "3",
      "city_code": "0414",
      "center": "123.817214,41.308719",
      "parent_id": "506"
    },
    {
      "area_id": "510",
      "area_code": "210505",
      "area_name": "南芬区",
      "level": "3",
      "city_code": "0414",
      "center": "123.744802,41.100445",
      "parent_id": "506"
    },
    {
      "area_id": "511",
      "area_code": "210521",
      "area_name": "本溪满族自治县",
      "level": "3",
      "city_code": "0414",
      "center": "124.120635,41.302009",
      "parent_id": "506"
    },
    {
      "area_id": "512",
      "area_code": "210522",
      "area_name": "桓仁满族自治县",
      "level": "3",
      "city_code": "0414",
      "center": "125.361007,41.267127",
      "parent_id": "506"
    },
    {
      "area_id": "513",
      "area_code": "210600",
      "area_name": "丹东市",
      "level": "2",
      "city_code": "0415",
      "center": "124.35445,40.000787",
      "parent_id": "464"
    },
    {
      "area_id": "514",
      "area_code": "210602",
      "area_name": "元宝区",
      "level": "3",
      "city_code": "0415",
      "center": "124.395661,40.136434",
      "parent_id": "513"
    },
    {
      "area_id": "515",
      "area_code": "210603",
      "area_name": "振兴区",
      "level": "3",
      "city_code": "0415",
      "center": "124.383237,40.129944",
      "parent_id": "513"
    },
    {
      "area_id": "516",
      "area_code": "210604",
      "area_name": "振安区",
      "level": "3",
      "city_code": "0415",
      "center": "124.470034,40.201553",
      "parent_id": "513"
    },
    {
      "area_id": "517",
      "area_code": "210624",
      "area_name": "宽甸满族自治县",
      "level": "3",
      "city_code": "0415",
      "center": "124.783659,40.731316",
      "parent_id": "513"
    },
    {
      "area_id": "518",
      "area_code": "210681",
      "area_name": "东港市",
      "level": "3",
      "city_code": "0415",
      "center": "124.152705,39.863008",
      "parent_id": "513"
    },
    {
      "area_id": "519",
      "area_code": "210682",
      "area_name": "凤城市",
      "level": "3",
      "city_code": "0415",
      "center": "124.066919,40.452297",
      "parent_id": "513"
    },
    {
      "area_id": "520",
      "area_code": "210700",
      "area_name": "锦州市",
      "level": "2",
      "city_code": "0416",
      "center": "121.126846,41.095685",
      "parent_id": "464"
    },
    {
      "area_id": "521",
      "area_code": "210702",
      "area_name": "古塔区",
      "level": "3",
      "city_code": "0416",
      "center": "121.128279,41.117245",
      "parent_id": "520"
    },
    {
      "area_id": "522",
      "area_code": "210703",
      "area_name": "凌河区",
      "level": "3",
      "city_code": "0416",
      "center": "121.150877,41.114989",
      "parent_id": "520"
    },
    {
      "area_id": "523",
      "area_code": "210711",
      "area_name": "太和区",
      "level": "3",
      "city_code": "0416",
      "center": "121.103892,41.109147",
      "parent_id": "520"
    },
    {
      "area_id": "524",
      "area_code": "210726",
      "area_name": "黑山县",
      "level": "3",
      "city_code": "0416",
      "center": "122.126292,41.653593",
      "parent_id": "520"
    },
    {
      "area_id": "525",
      "area_code": "210727",
      "area_name": "义县",
      "level": "3",
      "city_code": "0416",
      "center": "121.23908,41.533086",
      "parent_id": "520"
    },
    {
      "area_id": "526",
      "area_code": "210781",
      "area_name": "凌海市",
      "level": "3",
      "city_code": "0416",
      "center": "121.35549,41.160567",
      "parent_id": "520"
    },
    {
      "area_id": "527",
      "area_code": "210782",
      "area_name": "北镇市",
      "level": "3",
      "city_code": "0416",
      "center": "121.777395,41.58844",
      "parent_id": "520"
    },
    {
      "area_id": "528",
      "area_code": "210800",
      "area_name": "营口市",
      "level": "2",
      "city_code": "0417",
      "center": "122.219458,40.625364",
      "parent_id": "464"
    },
    {
      "area_id": "529",
      "area_code": "210802",
      "area_name": "站前区",
      "level": "3",
      "city_code": "0417",
      "center": "122.259033,40.672563",
      "parent_id": "528"
    },
    {
      "area_id": "530",
      "area_code": "210803",
      "area_name": "西市区",
      "level": "3",
      "city_code": "0417",
      "center": "122.206419,40.666213",
      "parent_id": "528"
    },
    {
      "area_id": "531",
      "area_code": "210804",
      "area_name": "鲅鱼圈区",
      "level": "3",
      "city_code": "0417",
      "center": "122.121521,40.226661",
      "parent_id": "528"
    },
    {
      "area_id": "532",
      "area_code": "210811",
      "area_name": "老边区",
      "level": "3",
      "city_code": "0417",
      "center": "122.380087,40.680191",
      "parent_id": "528"
    },
    {
      "area_id": "533",
      "area_code": "210881",
      "area_name": "盖州市",
      "level": "3",
      "city_code": "0417",
      "center": "122.349012,40.40074",
      "parent_id": "528"
    },
    {
      "area_id": "534",
      "area_code": "210882",
      "area_name": "大石桥市",
      "level": "3",
      "city_code": "0417",
      "center": "122.509006,40.644482",
      "parent_id": "528"
    },
    {
      "area_id": "535",
      "area_code": "210900",
      "area_name": "阜新市",
      "level": "2",
      "city_code": "0418",
      "center": "121.670273,42.021602",
      "parent_id": "464"
    },
    {
      "area_id": "536",
      "area_code": "210902",
      "area_name": "海州区",
      "level": "3",
      "city_code": "0418",
      "center": "121.657638,42.011162",
      "parent_id": "535"
    },
    {
      "area_id": "537",
      "area_code": "210903",
      "area_name": "新邱区",
      "level": "3",
      "city_code": "0418",
      "center": "121.792535,42.087632",
      "parent_id": "535"
    },
    {
      "area_id": "538",
      "area_code": "210904",
      "area_name": "太平区",
      "level": "3",
      "city_code": "0418",
      "center": "121.678604,42.010669",
      "parent_id": "535"
    },
    {
      "area_id": "539",
      "area_code": "210905",
      "area_name": "清河门区",
      "level": "3",
      "city_code": "0418",
      "center": "121.416105,41.7831",
      "parent_id": "535"
    },
    {
      "area_id": "540",
      "area_code": "210911",
      "area_name": "细河区",
      "level": "3",
      "city_code": "0418",
      "center": "121.68054,42.025494",
      "parent_id": "535"
    },
    {
      "area_id": "541",
      "area_code": "210921",
      "area_name": "阜新蒙古族自治县",
      "level": "3",
      "city_code": "0418",
      "center": "121.757901,42.065175",
      "parent_id": "535"
    },
    {
      "area_id": "542",
      "area_code": "210922",
      "area_name": "彰武县",
      "level": "3",
      "city_code": "0418",
      "center": "122.538793,42.386543",
      "parent_id": "535"
    },
    {
      "area_id": "543",
      "area_code": "211000",
      "area_name": "辽阳市",
      "level": "2",
      "city_code": "0419",
      "center": "123.236974,41.267794",
      "parent_id": "464"
    },
    {
      "area_id": "544",
      "area_code": "211002",
      "area_name": "白塔区",
      "level": "3",
      "city_code": "0419",
      "center": "123.174325,41.270347",
      "parent_id": "543"
    },
    {
      "area_id": "545",
      "area_code": "211003",
      "area_name": "文圣区",
      "level": "3",
      "city_code": "0419",
      "center": "123.231408,41.283754",
      "parent_id": "543"
    },
    {
      "area_id": "546",
      "area_code": "211004",
      "area_name": "宏伟区",
      "level": "3",
      "city_code": "0419",
      "center": "123.196672,41.217649",
      "parent_id": "543"
    },
    {
      "area_id": "547",
      "area_code": "211005",
      "area_name": "弓长岭区",
      "level": "3",
      "city_code": "0419",
      "center": "123.419803,41.151847",
      "parent_id": "543"
    },
    {
      "area_id": "548",
      "area_code": "211011",
      "area_name": "太子河区",
      "level": "3",
      "city_code": "0419",
      "center": "123.18144,41.295023",
      "parent_id": "543"
    },
    {
      "area_id": "549",
      "area_code": "211021",
      "area_name": "辽阳县",
      "level": "3",
      "city_code": "0419",
      "center": "123.105694,41.205329",
      "parent_id": "543"
    },
    {
      "area_id": "550",
      "area_code": "211081",
      "area_name": "灯塔市",
      "level": "3",
      "city_code": "0419",
      "center": "123.339312,41.426372",
      "parent_id": "543"
    },
    {
      "area_id": "551",
      "area_code": "211100",
      "area_name": "盘锦市",
      "level": "2",
      "city_code": "0427",
      "center": "122.170584,40.719847",
      "parent_id": "464"
    },
    {
      "area_id": "552",
      "area_code": "211102",
      "area_name": "双台子区",
      "level": "3",
      "city_code": "0427",
      "center": "122.039787,41.19965",
      "parent_id": "551"
    },
    {
      "area_id": "553",
      "area_code": "211103",
      "area_name": "兴隆台区",
      "level": "3",
      "city_code": "0427",
      "center": "122.070769,41.119898",
      "parent_id": "551"
    },
    {
      "area_id": "554",
      "area_code": "211104",
      "area_name": "大洼区",
      "level": "3",
      "city_code": "0427",
      "center": "122.082574,41.002279",
      "parent_id": "551"
    },
    {
      "area_id": "555",
      "area_code": "211122",
      "area_name": "盘山县",
      "level": "3",
      "city_code": "0427",
      "center": "121.996411,41.242639",
      "parent_id": "551"
    },
    {
      "area_id": "556",
      "area_code": "211200",
      "area_name": "铁岭市",
      "level": "2",
      "city_code": "0410",
      "center": "123.726035,42.223828",
      "parent_id": "464"
    },
    {
      "area_id": "557",
      "area_code": "211202",
      "area_name": "银州区",
      "level": "3",
      "city_code": "0410",
      "center": "123.842305,42.286129",
      "parent_id": "556"
    },
    {
      "area_id": "558",
      "area_code": "211204",
      "area_name": "清河区",
      "level": "3",
      "city_code": "0410",
      "center": "124.159191,42.546565",
      "parent_id": "556"
    },
    {
      "area_id": "559",
      "area_code": "211221",
      "area_name": "铁岭县",
      "level": "3",
      "city_code": "0410",
      "center": "123.728933,42.223395",
      "parent_id": "556"
    },
    {
      "area_id": "560",
      "area_code": "211223",
      "area_name": "西丰县",
      "level": "3",
      "city_code": "0410",
      "center": "124.727392,42.73803",
      "parent_id": "556"
    },
    {
      "area_id": "561",
      "area_code": "211224",
      "area_name": "昌图县",
      "level": "3",
      "city_code": "0410",
      "center": "124.111099,42.785791",
      "parent_id": "556"
    },
    {
      "area_id": "562",
      "area_code": "211281",
      "area_name": "调兵山市",
      "level": "3",
      "city_code": "0410",
      "center": "123.567117,42.467521",
      "parent_id": "556"
    },
    {
      "area_id": "563",
      "area_code": "211282",
      "area_name": "开原市",
      "level": "3",
      "city_code": "0410",
      "center": "124.038268,42.546307",
      "parent_id": "556"
    },
    {
      "area_id": "564",
      "area_code": "211300",
      "area_name": "朝阳市",
      "level": "2",
      "city_code": "0421",
      "center": "120.450879,41.573762",
      "parent_id": "464"
    },
    {
      "area_id": "565",
      "area_code": "211302",
      "area_name": "双塔区",
      "level": "3",
      "city_code": "0421",
      "center": "120.453744,41.565627",
      "parent_id": "564"
    },
    {
      "area_id": "566",
      "area_code": "211303",
      "area_name": "龙城区",
      "level": "3",
      "city_code": "0421",
      "center": "120.413376,41.576749",
      "parent_id": "564"
    },
    {
      "area_id": "567",
      "area_code": "211321",
      "area_name": "朝阳县",
      "level": "3",
      "city_code": "0421",
      "center": "120.389754,41.497825",
      "parent_id": "564"
    },
    {
      "area_id": "568",
      "area_code": "211322",
      "area_name": "建平县",
      "level": "3",
      "city_code": "0421",
      "center": "119.64328,41.403128",
      "parent_id": "564"
    },
    {
      "area_id": "569",
      "area_code": "211324",
      "area_name": "喀喇沁左翼蒙古族自治县",
      "level": "3",
      "city_code": "0421",
      "center": "119.741223,41.12815",
      "parent_id": "564"
    },
    {
      "area_id": "570",
      "area_code": "211381",
      "area_name": "北票市",
      "level": "3",
      "city_code": "0421",
      "center": "120.77073,41.800683",
      "parent_id": "564"
    },
    {
      "area_id": "571",
      "area_code": "211382",
      "area_name": "凌源市",
      "level": "3",
      "city_code": "0421",
      "center": "119.401574,41.245445",
      "parent_id": "564"
    },
    {
      "area_id": "572",
      "area_code": "211400",
      "area_name": "葫芦岛市",
      "level": "2",
      "city_code": "0429",
      "center": "120.836939,40.71104",
      "parent_id": "464"
    },
    {
      "area_id": "573",
      "area_code": "211402",
      "area_name": "连山区",
      "level": "3",
      "city_code": "0429",
      "center": "120.869231,40.774461",
      "parent_id": "572"
    },
    {
      "area_id": "574",
      "area_code": "211403",
      "area_name": "龙港区",
      "level": "3",
      "city_code": "0429",
      "center": "120.893786,40.735519",
      "parent_id": "572"
    },
    {
      "area_id": "575",
      "area_code": "211404",
      "area_name": "南票区",
      "level": "3",
      "city_code": "0429",
      "center": "120.749727,41.107107",
      "parent_id": "572"
    },
    {
      "area_id": "576",
      "area_code": "211421",
      "area_name": "绥中县",
      "level": "3",
      "city_code": "0429",
      "center": "120.344311,40.32558",
      "parent_id": "572"
    },
    {
      "area_id": "577",
      "area_code": "211422",
      "area_name": "建昌县",
      "level": "3",
      "city_code": "0429",
      "center": "119.837124,40.824367",
      "parent_id": "572"
    },
    {
      "area_id": "578",
      "area_code": "211481",
      "area_name": "兴城市",
      "level": "3",
      "city_code": "0429",
      "center": "120.756479,40.609731",
      "parent_id": "572"
    },
    {
      "area_id": "579",
      "area_code": "220000",
      "area_name": "吉林省",
      "level": "1",
      "city_code": "[]",
      "center": "125.32568,43.897016",
      "parent_id": "0"
    },
    {
      "area_id": "580",
      "area_code": "220100",
      "area_name": "长春市",
      "level": "2",
      "city_code": "0431",
      "center": "125.323513,43.817251",
      "parent_id": "579"
    },
    {
      "area_id": "581",
      "area_code": "220102",
      "area_name": "南关区",
      "level": "3",
      "city_code": "0431",
      "center": "125.350173,43.863989",
      "parent_id": "580"
    },
    {
      "area_id": "582",
      "area_code": "220103",
      "area_name": "宽城区",
      "level": "3",
      "city_code": "0431",
      "center": "125.326581,43.943612",
      "parent_id": "580"
    },
    {
      "area_id": "583",
      "area_code": "220104",
      "area_name": "朝阳区",
      "level": "3",
      "city_code": "0431",
      "center": "125.288254,43.833762",
      "parent_id": "580"
    },
    {
      "area_id": "584",
      "area_code": "220105",
      "area_name": "二道区",
      "level": "3",
      "city_code": "0431",
      "center": "125.374327,43.865577",
      "parent_id": "580"
    },
    {
      "area_id": "585",
      "area_code": "220106",
      "area_name": "绿园区",
      "level": "3",
      "city_code": "0431",
      "center": "125.256135,43.880975",
      "parent_id": "580"
    },
    {
      "area_id": "586",
      "area_code": "220112",
      "area_name": "双阳区",
      "level": "3",
      "city_code": "0431",
      "center": "125.664662,43.525311",
      "parent_id": "580"
    },
    {
      "area_id": "587",
      "area_code": "220113",
      "area_name": "九台区",
      "level": "3",
      "city_code": "0431",
      "center": "125.839573,44.151742",
      "parent_id": "580"
    },
    {
      "area_id": "588",
      "area_code": "220122",
      "area_name": "农安县",
      "level": "3",
      "city_code": "0431",
      "center": "125.184887,44.432763",
      "parent_id": "580"
    },
    {
      "area_id": "589",
      "area_code": "220182",
      "area_name": "榆树市",
      "level": "3",
      "city_code": "0431",
      "center": "126.533187,44.840318",
      "parent_id": "580"
    },
    {
      "area_id": "590",
      "area_code": "220183",
      "area_name": "德惠市",
      "level": "3",
      "city_code": "0431",
      "center": "125.728755,44.522056",
      "parent_id": "580"
    },
    {
      "area_id": "591",
      "area_code": "220200",
      "area_name": "吉林市",
      "level": "2",
      "city_code": "0432",
      "center": "126.549572,43.837883",
      "parent_id": "579"
    },
    {
      "area_id": "592",
      "area_code": "220202",
      "area_name": "昌邑区",
      "level": "3",
      "city_code": "0432",
      "center": "126.574709,43.881818",
      "parent_id": "591"
    },
    {
      "area_id": "593",
      "area_code": "220203",
      "area_name": "龙潭区",
      "level": "3",
      "city_code": "0432",
      "center": "126.562197,43.910802",
      "parent_id": "591"
    },
    {
      "area_id": "594",
      "area_code": "220204",
      "area_name": "船营区",
      "level": "3",
      "city_code": "0432",
      "center": "126.540966,43.833445",
      "parent_id": "591"
    },
    {
      "area_id": "595",
      "area_code": "220211",
      "area_name": "丰满区",
      "level": "3",
      "city_code": "0432",
      "center": "126.562274,43.821601",
      "parent_id": "591"
    },
    {
      "area_id": "596",
      "area_code": "220221",
      "area_name": "永吉县",
      "level": "3",
      "city_code": "0432",
      "center": "126.497741,43.672582",
      "parent_id": "591"
    },
    {
      "area_id": "597",
      "area_code": "220281",
      "area_name": "蛟河市",
      "level": "3",
      "city_code": "0432",
      "center": "127.344229,43.724007",
      "parent_id": "591"
    },
    {
      "area_id": "598",
      "area_code": "220282",
      "area_name": "桦甸市",
      "level": "3",
      "city_code": "0432",
      "center": "126.746309,42.972096",
      "parent_id": "591"
    },
    {
      "area_id": "599",
      "area_code": "220283",
      "area_name": "舒兰市",
      "level": "3",
      "city_code": "0432",
      "center": "126.965607,44.406105",
      "parent_id": "591"
    },
    {
      "area_id": "600",
      "area_code": "220284",
      "area_name": "磐石市",
      "level": "3",
      "city_code": "0432",
      "center": "126.060427,42.946285",
      "parent_id": "591"
    },
    {
      "area_id": "601",
      "area_code": "220300",
      "area_name": "四平市",
      "level": "2",
      "city_code": "0434",
      "center": "124.350398,43.166419",
      "parent_id": "579"
    },
    {
      "area_id": "602",
      "area_code": "220302",
      "area_name": "铁西区",
      "level": "3",
      "city_code": "0434",
      "center": "124.345722,43.146155",
      "parent_id": "601"
    },
    {
      "area_id": "603",
      "area_code": "220303",
      "area_name": "铁东区",
      "level": "3",
      "city_code": "0434",
      "center": "124.409591,43.162105",
      "parent_id": "601"
    },
    {
      "area_id": "604",
      "area_code": "220322",
      "area_name": "梨树县",
      "level": "3",
      "city_code": "0434",
      "center": "124.33539,43.30706",
      "parent_id": "601"
    },
    {
      "area_id": "605",
      "area_code": "220323",
      "area_name": "伊通满族自治县",
      "level": "3",
      "city_code": "0434",
      "center": "125.305393,43.345754",
      "parent_id": "601"
    },
    {
      "area_id": "606",
      "area_code": "220381",
      "area_name": "公主岭市",
      "level": "3",
      "city_code": "0434",
      "center": "124.822929,43.504676",
      "parent_id": "601"
    },
    {
      "area_id": "607",
      "area_code": "220382",
      "area_name": "双辽市",
      "level": "3",
      "city_code": "0434",
      "center": "123.502723,43.518302",
      "parent_id": "601"
    },
    {
      "area_id": "608",
      "area_code": "220400",
      "area_name": "辽源市",
      "level": "2",
      "city_code": "0437",
      "center": "125.14366,42.887766",
      "parent_id": "579"
    },
    {
      "area_id": "609",
      "area_code": "220402",
      "area_name": "龙山区",
      "level": "3",
      "city_code": "0437",
      "center": "125.136627,42.90158",
      "parent_id": "608"
    },
    {
      "area_id": "610",
      "area_code": "220403",
      "area_name": "西安区",
      "level": "3",
      "city_code": "0437",
      "center": "125.149281,42.927324",
      "parent_id": "608"
    },
    {
      "area_id": "611",
      "area_code": "220421",
      "area_name": "东丰县",
      "level": "3",
      "city_code": "0437",
      "center": "125.531021,42.677371",
      "parent_id": "608"
    },
    {
      "area_id": "612",
      "area_code": "220422",
      "area_name": "东辽县",
      "level": "3",
      "city_code": "0437",
      "center": "124.991424,42.92625",
      "parent_id": "608"
    },
    {
      "area_id": "613",
      "area_code": "220500",
      "area_name": "通化市",
      "level": "2",
      "city_code": "0435",
      "center": "125.939697,41.728401",
      "parent_id": "579"
    },
    {
      "area_id": "614",
      "area_code": "220502",
      "area_name": "东昌区",
      "level": "3",
      "city_code": "0435",
      "center": "125.927101,41.702859",
      "parent_id": "613"
    },
    {
      "area_id": "615",
      "area_code": "220503",
      "area_name": "二道江区",
      "level": "3",
      "city_code": "0435",
      "center": "126.042678,41.774044",
      "parent_id": "613"
    },
    {
      "area_id": "616",
      "area_code": "220521",
      "area_name": "通化县",
      "level": "3",
      "city_code": "0435",
      "center": "125.759259,41.679808",
      "parent_id": "613"
    },
    {
      "area_id": "617",
      "area_code": "220523",
      "area_name": "辉南县",
      "level": "3",
      "city_code": "0435",
      "center": "126.046783,42.684921",
      "parent_id": "613"
    },
    {
      "area_id": "618",
      "area_code": "220524",
      "area_name": "柳河县",
      "level": "3",
      "city_code": "0435",
      "center": "125.744735,42.284605",
      "parent_id": "613"
    },
    {
      "area_id": "619",
      "area_code": "220581",
      "area_name": "梅河口市",
      "level": "3",
      "city_code": "0435",
      "center": "125.710859,42.539253",
      "parent_id": "613"
    },
    {
      "area_id": "620",
      "area_code": "220582",
      "area_name": "集安市",
      "level": "3",
      "city_code": "0435",
      "center": "126.19403,41.125307",
      "parent_id": "613"
    },
    {
      "area_id": "621",
      "area_code": "220600",
      "area_name": "白山市",
      "level": "2",
      "city_code": "0439",
      "center": "126.41473,41.943972",
      "parent_id": "579"
    },
    {
      "area_id": "622",
      "area_code": "220602",
      "area_name": "浑江区",
      "level": "3",
      "city_code": "0439",
      "center": "126.416093,41.945409",
      "parent_id": "621"
    },
    {
      "area_id": "623",
      "area_code": "220605",
      "area_name": "江源区",
      "level": "3",
      "city_code": "0439",
      "center": "126.591178,42.056747",
      "parent_id": "621"
    },
    {
      "area_id": "624",
      "area_code": "220621",
      "area_name": "抚松县",
      "level": "3",
      "city_code": "0439",
      "center": "127.449763,42.221207",
      "parent_id": "621"
    },
    {
      "area_id": "625",
      "area_code": "220622",
      "area_name": "靖宇县",
      "level": "3",
      "city_code": "0439",
      "center": "126.813583,42.388896",
      "parent_id": "621"
    },
    {
      "area_id": "626",
      "area_code": "220623",
      "area_name": "长白朝鲜族自治县",
      "level": "3",
      "city_code": "0439",
      "center": "128.200789,41.420018",
      "parent_id": "621"
    },
    {
      "area_id": "627",
      "area_code": "220681",
      "area_name": "临江市",
      "level": "3",
      "city_code": "0439",
      "center": "126.918087,41.811979",
      "parent_id": "621"
    },
    {
      "area_id": "628",
      "area_code": "220700",
      "area_name": "松原市",
      "level": "2",
      "city_code": "0438",
      "center": "124.825042,45.141548",
      "parent_id": "579"
    },
    {
      "area_id": "629",
      "area_code": "220702",
      "area_name": "宁江区",
      "level": "3",
      "city_code": "0438",
      "center": "124.86562,45.209915",
      "parent_id": "628"
    },
    {
      "area_id": "630",
      "area_code": "220721",
      "area_name": "前郭尔罗斯蒙古族自治县",
      "level": "3",
      "city_code": "0438",
      "center": "124.823417,45.118061",
      "parent_id": "628"
    },
    {
      "area_id": "631",
      "area_code": "220722",
      "area_name": "长岭县",
      "level": "3",
      "city_code": "0438",
      "center": "123.967483,44.275895",
      "parent_id": "628"
    },
    {
      "area_id": "632",
      "area_code": "220723",
      "area_name": "乾安县",
      "level": "3",
      "city_code": "0438",
      "center": "124.041139,45.003773",
      "parent_id": "628"
    },
    {
      "area_id": "633",
      "area_code": "220781",
      "area_name": "扶余市",
      "level": "3",
      "city_code": "0438",
      "center": "126.049803,44.9892",
      "parent_id": "628"
    },
    {
      "area_id": "634",
      "area_code": "220800",
      "area_name": "白城市",
      "level": "2",
      "city_code": "0436",
      "center": "122.838714,45.619884",
      "parent_id": "579"
    },
    {
      "area_id": "635",
      "area_code": "220802",
      "area_name": "洮北区",
      "level": "3",
      "city_code": "0436",
      "center": "122.851029,45.621716",
      "parent_id": "634"
    },
    {
      "area_id": "636",
      "area_code": "220821",
      "area_name": "镇赉县",
      "level": "3",
      "city_code": "0436",
      "center": "123.199607,45.84835",
      "parent_id": "634"
    },
    {
      "area_id": "637",
      "area_code": "220822",
      "area_name": "通榆县",
      "level": "3",
      "city_code": "0436",
      "center": "123.088238,44.81291",
      "parent_id": "634"
    },
    {
      "area_id": "638",
      "area_code": "220881",
      "area_name": "洮南市",
      "level": "3",
      "city_code": "0436",
      "center": "122.798579,45.356807",
      "parent_id": "634"
    },
    {
      "area_id": "639",
      "area_code": "220882",
      "area_name": "大安市",
      "level": "3",
      "city_code": "0436",
      "center": "124.292626,45.506996",
      "parent_id": "634"
    },
    {
      "area_id": "640",
      "area_code": "222400",
      "area_name": "延边朝鲜族自治州",
      "level": "2",
      "city_code": "1433",
      "center": "129.471868,42.909408",
      "parent_id": "579"
    },
    {
      "area_id": "641",
      "area_code": "222401",
      "area_name": "延吉市",
      "level": "3",
      "city_code": "1433",
      "center": "129.508804,42.89125",
      "parent_id": "640"
    },
    {
      "area_id": "642",
      "area_code": "222402",
      "area_name": "图们市",
      "level": "3",
      "city_code": "1433",
      "center": "129.84371,42.968044",
      "parent_id": "640"
    },
    {
      "area_id": "643",
      "area_code": "222403",
      "area_name": "敦化市",
      "level": "3",
      "city_code": "1433",
      "center": "128.232131,43.372642",
      "parent_id": "640"
    },
    {
      "area_id": "644",
      "area_code": "222404",
      "area_name": "珲春市",
      "level": "3",
      "city_code": "1433",
      "center": "130.366036,42.862821",
      "parent_id": "640"
    },
    {
      "area_id": "645",
      "area_code": "222405",
      "area_name": "龙井市",
      "level": "3",
      "city_code": "1433",
      "center": "129.427066,42.76631",
      "parent_id": "640"
    },
    {
      "area_id": "646",
      "area_code": "222406",
      "area_name": "和龙市",
      "level": "3",
      "city_code": "1433",
      "center": "129.010106,42.546675",
      "parent_id": "640"
    },
    {
      "area_id": "647",
      "area_code": "222424",
      "area_name": "汪清县",
      "level": "3",
      "city_code": "1433",
      "center": "129.771607,43.312522",
      "parent_id": "640"
    },
    {
      "area_id": "648",
      "area_code": "222426",
      "area_name": "安图县",
      "level": "3",
      "city_code": "1433",
      "center": "128.899772,43.11195",
      "parent_id": "640"
    },
    {
      "area_id": "649",
      "area_code": "230000",
      "area_name": "黑龙江省",
      "level": "1",
      "city_code": "[]",
      "center": "126.661665,45.742366",
      "parent_id": "0"
    },
    {
      "area_id": "650",
      "area_code": "230100",
      "area_name": "哈尔滨市",
      "level": "2",
      "city_code": "0451",
      "center": "126.534967,45.803775",
      "parent_id": "649"
    },
    {
      "area_id": "651",
      "area_code": "230102",
      "area_name": "道里区",
      "level": "3",
      "city_code": "0451",
      "center": "126.616973,45.75577",
      "parent_id": "650"
    },
    {
      "area_id": "652",
      "area_code": "230103",
      "area_name": "南岗区",
      "level": "3",
      "city_code": "0451",
      "center": "126.668784,45.760174",
      "parent_id": "650"
    },
    {
      "area_id": "653",
      "area_code": "230104",
      "area_name": "道外区",
      "level": "3",
      "city_code": "0451",
      "center": "126.64939,45.792057",
      "parent_id": "650"
    },
    {
      "area_id": "654",
      "area_code": "230108",
      "area_name": "平房区",
      "level": "3",
      "city_code": "0451",
      "center": "126.637611,45.597911",
      "parent_id": "650"
    },
    {
      "area_id": "655",
      "area_code": "230109",
      "area_name": "松北区",
      "level": "3",
      "city_code": "0451",
      "center": "126.516914,45.794504",
      "parent_id": "650"
    },
    {
      "area_id": "656",
      "area_code": "230110",
      "area_name": "香坊区",
      "level": "3",
      "city_code": "0451",
      "center": "126.662593,45.707716",
      "parent_id": "650"
    },
    {
      "area_id": "657",
      "area_code": "230111",
      "area_name": "呼兰区",
      "level": "3",
      "city_code": "0451",
      "center": "126.587905,45.889457",
      "parent_id": "650"
    },
    {
      "area_id": "658",
      "area_code": "230112",
      "area_name": "阿城区",
      "level": "3",
      "city_code": "0451",
      "center": "126.958098,45.548669",
      "parent_id": "650"
    },
    {
      "area_id": "659",
      "area_code": "230113",
      "area_name": "双城区",
      "level": "3",
      "city_code": "0451",
      "center": "126.312624,45.383218",
      "parent_id": "650"
    },
    {
      "area_id": "660",
      "area_code": "230123",
      "area_name": "依兰县",
      "level": "3",
      "city_code": "0451",
      "center": "129.567877,46.325419",
      "parent_id": "650"
    },
    {
      "area_id": "661",
      "area_code": "230124",
      "area_name": "方正县",
      "level": "3",
      "city_code": "0451",
      "center": "128.829536,45.851694",
      "parent_id": "650"
    },
    {
      "area_id": "662",
      "area_code": "230125",
      "area_name": "宾县",
      "level": "3",
      "city_code": "0451",
      "center": "127.466634,45.745917",
      "parent_id": "650"
    },
    {
      "area_id": "663",
      "area_code": "230126",
      "area_name": "巴彦县",
      "level": "3",
      "city_code": "0451",
      "center": "127.403781,46.086549",
      "parent_id": "650"
    },
    {
      "area_id": "664",
      "area_code": "230127",
      "area_name": "木兰县",
      "level": "3",
      "city_code": "0451",
      "center": "128.043466,45.950582",
      "parent_id": "650"
    },
    {
      "area_id": "665",
      "area_code": "230128",
      "area_name": "通河县",
      "level": "3",
      "city_code": "0451",
      "center": "128.746124,45.990205",
      "parent_id": "650"
    },
    {
      "area_id": "666",
      "area_code": "230129",
      "area_name": "延寿县",
      "level": "3",
      "city_code": "0451",
      "center": "128.331643,45.451897",
      "parent_id": "650"
    },
    {
      "area_id": "667",
      "area_code": "230183",
      "area_name": "尚志市",
      "level": "3",
      "city_code": "0451",
      "center": "128.009894,45.209586",
      "parent_id": "650"
    },
    {
      "area_id": "668",
      "area_code": "230184",
      "area_name": "五常市",
      "level": "3",
      "city_code": "0451",
      "center": "127.167618,44.931991",
      "parent_id": "650"
    },
    {
      "area_id": "669",
      "area_code": "230200",
      "area_name": "齐齐哈尔市",
      "level": "2",
      "city_code": "0452",
      "center": "123.918186,47.354348",
      "parent_id": "649"
    },
    {
      "area_id": "670",
      "area_code": "230202",
      "area_name": "龙沙区",
      "level": "3",
      "city_code": "0452",
      "center": "123.957531,47.317308",
      "parent_id": "669"
    },
    {
      "area_id": "671",
      "area_code": "230203",
      "area_name": "建华区",
      "level": "3",
      "city_code": "0452",
      "center": "123.955464,47.354364",
      "parent_id": "669"
    },
    {
      "area_id": "672",
      "area_code": "230204",
      "area_name": "铁锋区",
      "level": "3",
      "city_code": "0452",
      "center": "123.978293,47.340517",
      "parent_id": "669"
    },
    {
      "area_id": "673",
      "area_code": "230205",
      "area_name": "昂昂溪区",
      "level": "3",
      "city_code": "0452",
      "center": "123.8224,47.15516",
      "parent_id": "669"
    },
    {
      "area_id": "674",
      "area_code": "230206",
      "area_name": "富拉尔基区",
      "level": "3",
      "city_code": "0452",
      "center": "123.629189,47.208843",
      "parent_id": "669"
    },
    {
      "area_id": "675",
      "area_code": "230207",
      "area_name": "碾子山区",
      "level": "3",
      "city_code": "0452",
      "center": "122.887775,47.516872",
      "parent_id": "669"
    },
    {
      "area_id": "676",
      "area_code": "230208",
      "area_name": "梅里斯达斡尔族区",
      "level": "3",
      "city_code": "0452",
      "center": "123.75291,47.309537",
      "parent_id": "669"
    },
    {
      "area_id": "677",
      "area_code": "230221",
      "area_name": "龙江县",
      "level": "3",
      "city_code": "0452",
      "center": "123.205323,47.338665",
      "parent_id": "669"
    },
    {
      "area_id": "678",
      "area_code": "230223",
      "area_name": "依安县",
      "level": "3",
      "city_code": "0452",
      "center": "125.306278,47.893548",
      "parent_id": "669"
    },
    {
      "area_id": "679",
      "area_code": "230224",
      "area_name": "泰来县",
      "level": "3",
      "city_code": "0452",
      "center": "123.416631,46.393694",
      "parent_id": "669"
    },
    {
      "area_id": "680",
      "area_code": "230225",
      "area_name": "甘南县",
      "level": "3",
      "city_code": "0452",
      "center": "123.507429,47.922405",
      "parent_id": "669"
    },
    {
      "area_id": "681",
      "area_code": "230227",
      "area_name": "富裕县",
      "level": "3",
      "city_code": "0452",
      "center": "124.473793,47.774347",
      "parent_id": "669"
    },
    {
      "area_id": "682",
      "area_code": "230229",
      "area_name": "克山县",
      "level": "3",
      "city_code": "0452",
      "center": "125.875705,48.037031",
      "parent_id": "669"
    },
    {
      "area_id": "683",
      "area_code": "230230",
      "area_name": "克东县",
      "level": "3",
      "city_code": "0452",
      "center": "126.24872,48.04206",
      "parent_id": "669"
    },
    {
      "area_id": "684",
      "area_code": "230231",
      "area_name": "拜泉县",
      "level": "3",
      "city_code": "0452",
      "center": "126.100213,47.595851",
      "parent_id": "669"
    },
    {
      "area_id": "685",
      "area_code": "230281",
      "area_name": "讷河市",
      "level": "3",
      "city_code": "0452",
      "center": "124.88287,48.466592",
      "parent_id": "669"
    },
    {
      "area_id": "686",
      "area_code": "230300",
      "area_name": "鸡西市",
      "level": "2",
      "city_code": "0467",
      "center": "130.969333,45.295075",
      "parent_id": "649"
    },
    {
      "area_id": "687",
      "area_code": "230302",
      "area_name": "鸡冠区",
      "level": "3",
      "city_code": "0467",
      "center": "130.981185,45.304355",
      "parent_id": "686"
    },
    {
      "area_id": "688",
      "area_code": "230303",
      "area_name": "恒山区",
      "level": "3",
      "city_code": "0467",
      "center": "130.904963,45.210668",
      "parent_id": "686"
    },
    {
      "area_id": "689",
      "area_code": "230304",
      "area_name": "滴道区",
      "level": "3",
      "city_code": "0467",
      "center": "130.843613,45.348763",
      "parent_id": "686"
    },
    {
      "area_id": "690",
      "area_code": "230305",
      "area_name": "梨树区",
      "level": "3",
      "city_code": "0467",
      "center": "130.69699,45.092046",
      "parent_id": "686"
    },
    {
      "area_id": "691",
      "area_code": "230306",
      "area_name": "城子河区",
      "level": "3",
      "city_code": "0467",
      "center": "131.011304,45.33697",
      "parent_id": "686"
    },
    {
      "area_id": "692",
      "area_code": "230307",
      "area_name": "麻山区",
      "level": "3",
      "city_code": "0467",
      "center": "130.478187,45.212088",
      "parent_id": "686"
    },
    {
      "area_id": "693",
      "area_code": "230321",
      "area_name": "鸡东县",
      "level": "3",
      "city_code": "0467",
      "center": "131.124079,45.260412",
      "parent_id": "686"
    },
    {
      "area_id": "694",
      "area_code": "230381",
      "area_name": "虎林市",
      "level": "3",
      "city_code": "0467",
      "center": "132.93721,45.762685",
      "parent_id": "686"
    },
    {
      "area_id": "695",
      "area_code": "230382",
      "area_name": "密山市",
      "level": "3",
      "city_code": "0467",
      "center": "131.846635,45.529774",
      "parent_id": "686"
    },
    {
      "area_id": "696",
      "area_code": "230400",
      "area_name": "鹤岗市",
      "level": "2",
      "city_code": "0468",
      "center": "130.297943,47.350189",
      "parent_id": "649"
    },
    {
      "area_id": "697",
      "area_code": "230402",
      "area_name": "向阳区",
      "level": "3",
      "city_code": "0468",
      "center": "130.294235,47.342468",
      "parent_id": "696"
    },
    {
      "area_id": "698",
      "area_code": "230403",
      "area_name": "工农区",
      "level": "3",
      "city_code": "0468",
      "center": "130.274684,47.31878",
      "parent_id": "696"
    },
    {
      "area_id": "699",
      "area_code": "230404",
      "area_name": "南山区",
      "level": "3",
      "city_code": "0468",
      "center": "130.286788,47.315174",
      "parent_id": "696"
    },
    {
      "area_id": "700",
      "area_code": "230405",
      "area_name": "兴安区",
      "level": "3",
      "city_code": "0468",
      "center": "130.239245,47.252849",
      "parent_id": "696"
    },
    {
      "area_id": "701",
      "area_code": "230406",
      "area_name": "东山区",
      "level": "3",
      "city_code": "0468",
      "center": "130.317002,47.338537",
      "parent_id": "696"
    },
    {
      "area_id": "702",
      "area_code": "230407",
      "area_name": "兴山区",
      "level": "3",
      "city_code": "0468",
      "center": "130.303481,47.357702",
      "parent_id": "696"
    },
    {
      "area_id": "703",
      "area_code": "230421",
      "area_name": "萝北县",
      "level": "3",
      "city_code": "0468",
      "center": "130.85155,47.576444",
      "parent_id": "696"
    },
    {
      "area_id": "704",
      "area_code": "230422",
      "area_name": "绥滨县",
      "level": "3",
      "city_code": "0468",
      "center": "131.852759,47.289115",
      "parent_id": "696"
    },
    {
      "area_id": "705",
      "area_code": "230500",
      "area_name": "双鸭山市",
      "level": "2",
      "city_code": "0469",
      "center": "131.141195,46.676418",
      "parent_id": "649"
    },
    {
      "area_id": "706",
      "area_code": "230502",
      "area_name": "尖山区",
      "level": "3",
      "city_code": "0469",
      "center": "131.158415,46.64635",
      "parent_id": "705"
    },
    {
      "area_id": "707",
      "area_code": "230503",
      "area_name": "岭东区",
      "level": "3",
      "city_code": "0469",
      "center": "131.164723,46.592721",
      "parent_id": "705"
    },
    {
      "area_id": "708",
      "area_code": "230505",
      "area_name": "四方台区",
      "level": "3",
      "city_code": "0469",
      "center": "131.337592,46.597264",
      "parent_id": "705"
    },
    {
      "area_id": "709",
      "area_code": "230506",
      "area_name": "宝山区",
      "level": "3",
      "city_code": "0469",
      "center": "131.401589,46.577167",
      "parent_id": "705"
    },
    {
      "area_id": "710",
      "area_code": "230521",
      "area_name": "集贤县",
      "level": "3",
      "city_code": "0469",
      "center": "131.141311,46.728412",
      "parent_id": "705"
    },
    {
      "area_id": "711",
      "area_code": "230522",
      "area_name": "友谊县",
      "level": "3",
      "city_code": "0469",
      "center": "131.808063,46.767299",
      "parent_id": "705"
    },
    {
      "area_id": "712",
      "area_code": "230523",
      "area_name": "宝清县",
      "level": "3",
      "city_code": "0469",
      "center": "132.196853,46.327457",
      "parent_id": "705"
    },
    {
      "area_id": "713",
      "area_code": "230524",
      "area_name": "饶河县",
      "level": "3",
      "city_code": "0469",
      "center": "134.013872,46.798163",
      "parent_id": "705"
    },
    {
      "area_id": "714",
      "area_code": "230600",
      "area_name": "大庆市",
      "level": "2",
      "city_code": "0459",
      "center": "125.103784,46.589309",
      "parent_id": "649"
    },
    {
      "area_id": "715",
      "area_code": "230602",
      "area_name": "萨尔图区",
      "level": "3",
      "city_code": "0459",
      "center": "125.135591,46.629092",
      "parent_id": "714"
    },
    {
      "area_id": "716",
      "area_code": "230603",
      "area_name": "龙凤区",
      "level": "3",
      "city_code": "0459",
      "center": "125.135326,46.562247",
      "parent_id": "714"
    },
    {
      "area_id": "717",
      "area_code": "230604",
      "area_name": "让胡路区",
      "level": "3",
      "city_code": "0459",
      "center": "124.870596,46.652357",
      "parent_id": "714"
    },
    {
      "area_id": "718",
      "area_code": "230605",
      "area_name": "红岗区",
      "level": "3",
      "city_code": "0459",
      "center": "124.891039,46.398418",
      "parent_id": "714"
    },
    {
      "area_id": "719",
      "area_code": "230606",
      "area_name": "大同区",
      "level": "3",
      "city_code": "0459",
      "center": "124.812364,46.039827",
      "parent_id": "714"
    },
    {
      "area_id": "720",
      "area_code": "230621",
      "area_name": "肇州县",
      "level": "3",
      "city_code": "0459",
      "center": "125.268643,45.699066",
      "parent_id": "714"
    },
    {
      "area_id": "721",
      "area_code": "230622",
      "area_name": "肇源县",
      "level": "3",
      "city_code": "0459",
      "center": "125.078223,45.51932",
      "parent_id": "714"
    },
    {
      "area_id": "722",
      "area_code": "230623",
      "area_name": "林甸县",
      "level": "3",
      "city_code": "0459",
      "center": "124.863603,47.171717",
      "parent_id": "714"
    },
    {
      "area_id": "723",
      "area_code": "230624",
      "area_name": "杜尔伯特蒙古族自治县",
      "level": "3",
      "city_code": "0459",
      "center": "124.442572,46.862817",
      "parent_id": "714"
    },
    {
      "area_id": "724",
      "area_code": "230700",
      "area_name": "伊春市",
      "level": "2",
      "city_code": "0458",
      "center": "128.841125,47.727535",
      "parent_id": "649"
    },
    {
      "area_id": "725",
      "area_code": "230702",
      "area_name": "伊春区",
      "level": "3",
      "city_code": "0458",
      "center": "128.907257,47.728237",
      "parent_id": "724"
    },
    {
      "area_id": "726",
      "area_code": "230703",
      "area_name": "南岔区",
      "level": "3",
      "city_code": "0458",
      "center": "129.283467,47.138034",
      "parent_id": "724"
    },
    {
      "area_id": "727",
      "area_code": "230704",
      "area_name": "友好区",
      "level": "3",
      "city_code": "0458",
      "center": "128.836291,47.841032",
      "parent_id": "724"
    },
    {
      "area_id": "728",
      "area_code": "230705",
      "area_name": "西林区",
      "level": "3",
      "city_code": "0458",
      "center": "129.312851,47.480735",
      "parent_id": "724"
    },
    {
      "area_id": "729",
      "area_code": "230706",
      "area_name": "翠峦区",
      "level": "3",
      "city_code": "0458",
      "center": "128.669754,47.726394",
      "parent_id": "724"
    },
    {
      "area_id": "730",
      "area_code": "230707",
      "area_name": "新青区",
      "level": "3",
      "city_code": "0458",
      "center": "129.533599,48.290455",
      "parent_id": "724"
    },
    {
      "area_id": "731",
      "area_code": "230708",
      "area_name": "美溪区",
      "level": "3",
      "city_code": "0458",
      "center": "129.129314,47.63509",
      "parent_id": "724"
    },
    {
      "area_id": "732",
      "area_code": "230709",
      "area_name": "金山屯区",
      "level": "3",
      "city_code": "0458",
      "center": "129.429117,47.413074",
      "parent_id": "724"
    },
    {
      "area_id": "733",
      "area_code": "230710",
      "area_name": "五营区",
      "level": "3",
      "city_code": "0458",
      "center": "129.245343,48.10791",
      "parent_id": "724"
    },
    {
      "area_id": "734",
      "area_code": "230711",
      "area_name": "乌马河区",
      "level": "3",
      "city_code": "0458",
      "center": "128.799477,47.727687",
      "parent_id": "724"
    },
    {
      "area_id": "735",
      "area_code": "230712",
      "area_name": "汤旺河区",
      "level": "3",
      "city_code": "0458",
      "center": "129.571108,48.454651",
      "parent_id": "724"
    },
    {
      "area_id": "736",
      "area_code": "230713",
      "area_name": "带岭区",
      "level": "3",
      "city_code": "0458",
      "center": "129.020888,47.028379",
      "parent_id": "724"
    },
    {
      "area_id": "737",
      "area_code": "230714",
      "area_name": "乌伊岭区",
      "level": "3",
      "city_code": "0458",
      "center": "129.43792,48.590322",
      "parent_id": "724"
    },
    {
      "area_id": "738",
      "area_code": "230715",
      "area_name": "红星区",
      "level": "3",
      "city_code": "0458",
      "center": "129.390983,48.239431",
      "parent_id": "724"
    },
    {
      "area_id": "739",
      "area_code": "230716",
      "area_name": "上甘岭区",
      "level": "3",
      "city_code": "0458",
      "center": "129.02426,47.974707",
      "parent_id": "724"
    },
    {
      "area_id": "740",
      "area_code": "230722",
      "area_name": "嘉荫县",
      "level": "3",
      "city_code": "0458",
      "center": "130.403134,48.888972",
      "parent_id": "724"
    },
    {
      "area_id": "741",
      "area_code": "230781",
      "area_name": "铁力市",
      "level": "3",
      "city_code": "0458",
      "center": "128.032424,46.986633",
      "parent_id": "724"
    },
    {
      "area_id": "742",
      "area_code": "230800",
      "area_name": "佳木斯市",
      "level": "2",
      "city_code": "0454",
      "center": "130.318878,46.799777",
      "parent_id": "649"
    },
    {
      "area_id": "743",
      "area_code": "230803",
      "area_name": "向阳区",
      "level": "3",
      "city_code": "0454",
      "center": "130.365346,46.80779",
      "parent_id": "742"
    },
    {
      "area_id": "744",
      "area_code": "230804",
      "area_name": "前进区",
      "level": "3",
      "city_code": "0454",
      "center": "130.375062,46.814102",
      "parent_id": "742"
    },
    {
      "area_id": "745",
      "area_code": "230805",
      "area_name": "东风区",
      "level": "3",
      "city_code": "0454",
      "center": "130.403664,46.822571",
      "parent_id": "742"
    },
    {
      "area_id": "746",
      "area_code": "230811",
      "area_name": "郊区",
      "level": "3",
      "city_code": "0454",
      "center": "130.327194,46.810085",
      "parent_id": "742"
    },
    {
      "area_id": "747",
      "area_code": "230822",
      "area_name": "桦南县",
      "level": "3",
      "city_code": "0454",
      "center": "130.553343,46.239184",
      "parent_id": "742"
    },
    {
      "area_id": "748",
      "area_code": "230826",
      "area_name": "桦川县",
      "level": "3",
      "city_code": "0454",
      "center": "130.71908,47.023001",
      "parent_id": "742"
    },
    {
      "area_id": "749",
      "area_code": "230828",
      "area_name": "汤原县",
      "level": "3",
      "city_code": "0454",
      "center": "129.905072,46.730706",
      "parent_id": "742"
    },
    {
      "area_id": "750",
      "area_code": "230881",
      "area_name": "同江市",
      "level": "3",
      "city_code": "0454",
      "center": "132.510919,47.642707",
      "parent_id": "742"
    },
    {
      "area_id": "751",
      "area_code": "230882",
      "area_name": "富锦市",
      "level": "3",
      "city_code": "0454",
      "center": "132.037686,47.250107",
      "parent_id": "742"
    },
    {
      "area_id": "752",
      "area_code": "230883",
      "area_name": "抚远市",
      "level": "3",
      "city_code": "0454",
      "center": "134.307884,48.364687",
      "parent_id": "742"
    },
    {
      "area_id": "753",
      "area_code": "230900",
      "area_name": "七台河市",
      "level": "2",
      "city_code": "0464",
      "center": "131.003082,45.771396",
      "parent_id": "649"
    },
    {
      "area_id": "754",
      "area_code": "230902",
      "area_name": "新兴区",
      "level": "3",
      "city_code": "0464",
      "center": "130.932143,45.81593",
      "parent_id": "753"
    },
    {
      "area_id": "755",
      "area_code": "230903",
      "area_name": "桃山区",
      "level": "3",
      "city_code": "0464",
      "center": "131.020202,45.765705",
      "parent_id": "753"
    },
    {
      "area_id": "756",
      "area_code": "230904",
      "area_name": "茄子河区",
      "level": "3",
      "city_code": "0464",
      "center": "131.068075,45.785215",
      "parent_id": "753"
    },
    {
      "area_id": "757",
      "area_code": "230921",
      "area_name": "勃利县",
      "level": "3",
      "city_code": "0464",
      "center": "130.59217,45.755063",
      "parent_id": "753"
    },
    {
      "area_id": "758",
      "area_code": "231000",
      "area_name": "牡丹江市",
      "level": "2",
      "city_code": "0453",
      "center": "129.633168,44.551653",
      "parent_id": "649"
    },
    {
      "area_id": "759",
      "area_code": "231002",
      "area_name": "东安区",
      "level": "3",
      "city_code": "0453",
      "center": "129.626641,44.58136",
      "parent_id": "758"
    },
    {
      "area_id": "760",
      "area_code": "231003",
      "area_name": "阳明区",
      "level": "3",
      "city_code": "0453",
      "center": "129.635615,44.596104",
      "parent_id": "758"
    },
    {
      "area_id": "761",
      "area_code": "231004",
      "area_name": "爱民区",
      "level": "3",
      "city_code": "0453",
      "center": "129.591537,44.596042",
      "parent_id": "758"
    },
    {
      "area_id": "762",
      "area_code": "231005",
      "area_name": "西安区",
      "level": "3",
      "city_code": "0453",
      "center": "129.616058,44.577625",
      "parent_id": "758"
    },
    {
      "area_id": "763",
      "area_code": "231025",
      "area_name": "林口县",
      "level": "3",
      "city_code": "0453",
      "center": "130.284033,45.278046",
      "parent_id": "758"
    },
    {
      "area_id": "764",
      "area_code": "231081",
      "area_name": "绥芬河市",
      "level": "3",
      "city_code": "0453",
      "center": "131.152545,44.412308",
      "parent_id": "758"
    },
    {
      "area_id": "765",
      "area_code": "231083",
      "area_name": "海林市",
      "level": "3",
      "city_code": "0453",
      "center": "129.380481,44.594213",
      "parent_id": "758"
    },
    {
      "area_id": "766",
      "area_code": "231084",
      "area_name": "宁安市",
      "level": "3",
      "city_code": "0453",
      "center": "129.482851,44.34072",
      "parent_id": "758"
    },
    {
      "area_id": "767",
      "area_code": "231085",
      "area_name": "穆棱市",
      "level": "3",
      "city_code": "0453",
      "center": "130.524436,44.918813",
      "parent_id": "758"
    },
    {
      "area_id": "768",
      "area_code": "231086",
      "area_name": "东宁市",
      "level": "3",
      "city_code": "0453",
      "center": "131.122915,44.087585",
      "parent_id": "758"
    },
    {
      "area_id": "769",
      "area_code": "231100",
      "area_name": "黑河市",
      "level": "2",
      "city_code": "0456",
      "center": "127.528293,50.245129",
      "parent_id": "649"
    },
    {
      "area_id": "770",
      "area_code": "231102",
      "area_name": "爱辉区",
      "level": "3",
      "city_code": "0456",
      "center": "127.50045,50.252105",
      "parent_id": "769"
    },
    {
      "area_id": "771",
      "area_code": "231121",
      "area_name": "嫩江县",
      "level": "3",
      "city_code": "0456",
      "center": "125.221192,49.185766",
      "parent_id": "769"
    },
    {
      "area_id": "772",
      "area_code": "231123",
      "area_name": "逊克县",
      "level": "3",
      "city_code": "0456",
      "center": "128.478749,49.564252",
      "parent_id": "769"
    },
    {
      "area_id": "773",
      "area_code": "231124",
      "area_name": "孙吴县",
      "level": "3",
      "city_code": "0456",
      "center": "127.336303,49.425647",
      "parent_id": "769"
    },
    {
      "area_id": "774",
      "area_code": "231181",
      "area_name": "北安市",
      "level": "3",
      "city_code": "0456",
      "center": "126.490864,48.241365",
      "parent_id": "769"
    },
    {
      "area_id": "775",
      "area_code": "231182",
      "area_name": "五大连池市",
      "level": "3",
      "city_code": "0456",
      "center": "126.205516,48.517257",
      "parent_id": "769"
    },
    {
      "area_id": "776",
      "area_code": "231200",
      "area_name": "绥化市",
      "level": "2",
      "city_code": "0455",
      "center": "126.968887,46.653845",
      "parent_id": "649"
    },
    {
      "area_id": "777",
      "area_code": "231202",
      "area_name": "北林区",
      "level": "3",
      "city_code": "0455",
      "center": "126.985504,46.6375",
      "parent_id": "776"
    },
    {
      "area_id": "778",
      "area_code": "231221",
      "area_name": "望奎县",
      "level": "3",
      "city_code": "0455",
      "center": "126.486075,46.832719",
      "parent_id": "776"
    },
    {
      "area_id": "779",
      "area_code": "231222",
      "area_name": "兰西县",
      "level": "3",
      "city_code": "0455",
      "center": "126.288117,46.25245",
      "parent_id": "776"
    },
    {
      "area_id": "780",
      "area_code": "231223",
      "area_name": "青冈县",
      "level": "3",
      "city_code": "0455",
      "center": "126.099195,46.70391",
      "parent_id": "776"
    },
    {
      "area_id": "781",
      "area_code": "231224",
      "area_name": "庆安县",
      "level": "3",
      "city_code": "0455",
      "center": "127.507824,46.880102",
      "parent_id": "776"
    },
    {
      "area_id": "782",
      "area_code": "231225",
      "area_name": "明水县",
      "level": "3",
      "city_code": "0455",
      "center": "125.906301,47.173426",
      "parent_id": "776"
    },
    {
      "area_id": "783",
      "area_code": "231226",
      "area_name": "绥棱县",
      "level": "3",
      "city_code": "0455",
      "center": "127.114832,47.236015",
      "parent_id": "776"
    },
    {
      "area_id": "784",
      "area_code": "231281",
      "area_name": "安达市",
      "level": "3",
      "city_code": "0455",
      "center": "125.346156,46.419633",
      "parent_id": "776"
    },
    {
      "area_id": "785",
      "area_code": "231282",
      "area_name": "肇东市",
      "level": "3",
      "city_code": "0455",
      "center": "125.961814,46.051126",
      "parent_id": "776"
    },
    {
      "area_id": "786",
      "area_code": "231283",
      "area_name": "海伦市",
      "level": "3",
      "city_code": "0455",
      "center": "126.930106,47.45117",
      "parent_id": "776"
    },
    {
      "area_id": "787",
      "area_code": "232700",
      "area_name": "大兴安岭地区",
      "level": "2",
      "city_code": "0457",
      "center": "124.711526,52.335262",
      "parent_id": "649"
    },
    {
      "area_id": "788",
      "area_code": "232701",
      "area_name": "加格达奇区",
      "level": "3",
      "city_code": "0457",
      "center": "124.139595,50.408735",
      "parent_id": "787"
    },
    {
      "area_id": "789",
      "area_code": "232721",
      "area_name": "呼玛县",
      "level": "3",
      "city_code": "0457",
      "center": "126.652396,51.726091",
      "parent_id": "787"
    },
    {
      "area_id": "790",
      "area_code": "232722",
      "area_name": "塔河县",
      "level": "3",
      "city_code": "0457",
      "center": "124.709996,52.334456",
      "parent_id": "787"
    },
    {
      "area_id": "791",
      "area_code": "232723",
      "area_name": "漠河县",
      "level": "3",
      "city_code": "0457",
      "center": "122.538591,52.972272",
      "parent_id": "787"
    },
    {
      "area_id": "792",
      "area_code": "310000",
      "area_name": "上海市",
      "level": "1",
      "city_code": "021",
      "center": "121.473662,31.230372",
      "parent_id": "0"
    },
    {
      "area_id": "793",
      "area_code": "310100",
      "area_name": "上海城区",
      "level": "2",
      "city_code": "021",
      "center": "121.473662,31.230372",
      "parent_id": "792"
    },
    {
      "area_id": "794",
      "area_code": "310101",
      "area_name": "黄浦区",
      "level": "3",
      "city_code": "021",
      "center": "121.484428,31.231739",
      "parent_id": "793"
    },
    {
      "area_id": "795",
      "area_code": "310104",
      "area_name": "徐汇区",
      "level": "3",
      "city_code": "021",
      "center": "121.436128,31.188464",
      "parent_id": "793"
    },
    {
      "area_id": "796",
      "area_code": "310105",
      "area_name": "长宁区",
      "level": "3",
      "city_code": "021",
      "center": "121.424622,31.220372",
      "parent_id": "793"
    },
    {
      "area_id": "797",
      "area_code": "310106",
      "area_name": "静安区",
      "level": "3",
      "city_code": "021",
      "center": "121.447453,31.227906",
      "parent_id": "793"
    },
    {
      "area_id": "798",
      "area_code": "310107",
      "area_name": "普陀区",
      "level": "3",
      "city_code": "021",
      "center": "121.395514,31.249603",
      "parent_id": "793"
    },
    {
      "area_id": "799",
      "area_code": "310109",
      "area_name": "虹口区",
      "level": "3",
      "city_code": "021",
      "center": "121.505133,31.2646",
      "parent_id": "793"
    },
    {
      "area_id": "800",
      "area_code": "310110",
      "area_name": "杨浦区",
      "level": "3",
      "city_code": "021",
      "center": "121.525727,31.259822",
      "parent_id": "793"
    },
    {
      "area_id": "801",
      "area_code": "310112",
      "area_name": "闵行区",
      "level": "3",
      "city_code": "021",
      "center": "121.380831,31.1129",
      "parent_id": "793"
    },
    {
      "area_id": "802",
      "area_code": "310113",
      "area_name": "宝山区",
      "level": "3",
      "city_code": "021",
      "center": "121.489612,31.405457",
      "parent_id": "793"
    },
    {
      "area_id": "803",
      "area_code": "310114",
      "area_name": "嘉定区",
      "level": "3",
      "city_code": "021",
      "center": "121.265374,31.375869",
      "parent_id": "793"
    },
    {
      "area_id": "804",
      "area_code": "310115",
      "area_name": "浦东新区",
      "level": "3",
      "city_code": "021",
      "center": "121.544379,31.221517",
      "parent_id": "793"
    },
    {
      "area_id": "805",
      "area_code": "310116",
      "area_name": "金山区",
      "level": "3",
      "city_code": "021",
      "center": "121.342455,30.741798",
      "parent_id": "793"
    },
    {
      "area_id": "806",
      "area_code": "310117",
      "area_name": "松江区",
      "level": "3",
      "city_code": "021",
      "center": "121.227747,31.032243",
      "parent_id": "793"
    },
    {
      "area_id": "807",
      "area_code": "310118",
      "area_name": "青浦区",
      "level": "3",
      "city_code": "021",
      "center": "121.124178,31.150681",
      "parent_id": "793"
    },
    {
      "area_id": "808",
      "area_code": "310120",
      "area_name": "奉贤区",
      "level": "3",
      "city_code": "021",
      "center": "121.474055,30.917766",
      "parent_id": "793"
    },
    {
      "area_id": "809",
      "area_code": "310151",
      "area_name": "崇明区",
      "level": "3",
      "city_code": "021",
      "center": "121.397421,31.623728",
      "parent_id": "793"
    },
    {
      "area_id": "810",
      "area_code": "320000",
      "area_name": "江苏省",
      "level": "1",
      "city_code": "[]",
      "center": "118.762765,32.060875",
      "parent_id": "0"
    },
    {
      "area_id": "811",
      "area_code": "320100",
      "area_name": "南京市",
      "level": "2",
      "city_code": "025",
      "center": "118.796682,32.05957",
      "parent_id": "810"
    },
    {
      "area_id": "812",
      "area_code": "320102",
      "area_name": "玄武区",
      "level": "3",
      "city_code": "025",
      "center": "118.797757,32.048498",
      "parent_id": "811"
    },
    {
      "area_id": "813",
      "area_code": "320104",
      "area_name": "秦淮区",
      "level": "3",
      "city_code": "025",
      "center": "118.79476,32.039113",
      "parent_id": "811"
    },
    {
      "area_id": "814",
      "area_code": "320105",
      "area_name": "建邺区",
      "level": "3",
      "city_code": "025",
      "center": "118.731793,32.003731",
      "parent_id": "811"
    },
    {
      "area_id": "815",
      "area_code": "320106",
      "area_name": "鼓楼区",
      "level": "3",
      "city_code": "025",
      "center": "118.770182,32.066601",
      "parent_id": "811"
    },
    {
      "area_id": "816",
      "area_code": "320111",
      "area_name": "浦口区",
      "level": "3",
      "city_code": "025",
      "center": "118.628003,32.058903",
      "parent_id": "811"
    },
    {
      "area_id": "817",
      "area_code": "320113",
      "area_name": "栖霞区",
      "level": "3",
      "city_code": "025",
      "center": "118.909153,32.096388",
      "parent_id": "811"
    },
    {
      "area_id": "818",
      "area_code": "320114",
      "area_name": "雨花台区",
      "level": "3",
      "city_code": "025",
      "center": "118.779051,31.99126",
      "parent_id": "811"
    },
    {
      "area_id": "819",
      "area_code": "320115",
      "area_name": "江宁区",
      "level": "3",
      "city_code": "025",
      "center": "118.840015,31.952612",
      "parent_id": "811"
    },
    {
      "area_id": "820",
      "area_code": "320116",
      "area_name": "六合区",
      "level": "3",
      "city_code": "025",
      "center": "118.822132,32.323584",
      "parent_id": "811"
    },
    {
      "area_id": "821",
      "area_code": "320117",
      "area_name": "溧水区",
      "level": "3",
      "city_code": "025",
      "center": "119.028288,31.651099",
      "parent_id": "811"
    },
    {
      "area_id": "822",
      "area_code": "320118",
      "area_name": "高淳区",
      "level": "3",
      "city_code": "025",
      "center": "118.89222,31.327586",
      "parent_id": "811"
    },
    {
      "area_id": "823",
      "area_code": "320200",
      "area_name": "无锡市",
      "level": "2",
      "city_code": "0510",
      "center": "120.31191,31.491169",
      "parent_id": "810"
    },
    {
      "area_id": "824",
      "area_code": "320205",
      "area_name": "锡山区",
      "level": "3",
      "city_code": "0510",
      "center": "120.357858,31.589715",
      "parent_id": "823"
    },
    {
      "area_id": "825",
      "area_code": "320206",
      "area_name": "惠山区",
      "level": "3",
      "city_code": "0510",
      "center": "120.298433,31.680335",
      "parent_id": "823"
    },
    {
      "area_id": "826",
      "area_code": "320211",
      "area_name": "滨湖区",
      "level": "3",
      "city_code": "0510",
      "center": "120.283811,31.527276",
      "parent_id": "823"
    },
    {
      "area_id": "827",
      "area_code": "320213",
      "area_name": "梁溪区",
      "level": "3",
      "city_code": "0510",
      "center": "120.303108,31.566155",
      "parent_id": "823"
    },
    {
      "area_id": "828",
      "area_code": "320214",
      "area_name": "新吴区",
      "level": "3",
      "city_code": "0510",
      "center": "120.352782,31.550966",
      "parent_id": "823"
    },
    {
      "area_id": "829",
      "area_code": "320281",
      "area_name": "江阴市",
      "level": "3",
      "city_code": "0510",
      "center": "120.286129,31.921345",
      "parent_id": "823"
    },
    {
      "area_id": "830",
      "area_code": "320282",
      "area_name": "宜兴市",
      "level": "3",
      "city_code": "0510",
      "center": "119.823308,31.340637",
      "parent_id": "823"
    },
    {
      "area_id": "831",
      "area_code": "320300",
      "area_name": "徐州市",
      "level": "2",
      "city_code": "0516",
      "center": "117.284124,34.205768",
      "parent_id": "810"
    },
    {
      "area_id": "832",
      "area_code": "320302",
      "area_name": "鼓楼区",
      "level": "3",
      "city_code": "0516",
      "center": "117.185576,34.288646",
      "parent_id": "831"
    },
    {
      "area_id": "833",
      "area_code": "320303",
      "area_name": "云龙区",
      "level": "3",
      "city_code": "0516",
      "center": "117.251076,34.253164",
      "parent_id": "831"
    },
    {
      "area_id": "834",
      "area_code": "320305",
      "area_name": "贾汪区",
      "level": "3",
      "city_code": "0516",
      "center": "117.464958,34.436936",
      "parent_id": "831"
    },
    {
      "area_id": "835",
      "area_code": "320311",
      "area_name": "泉山区",
      "level": "3",
      "city_code": "0516",
      "center": "117.194469,34.225522",
      "parent_id": "831"
    },
    {
      "area_id": "836",
      "area_code": "320312",
      "area_name": "铜山区",
      "level": "3",
      "city_code": "0516",
      "center": "117.169461,34.180779",
      "parent_id": "831"
    },
    {
      "area_id": "837",
      "area_code": "320321",
      "area_name": "丰县",
      "level": "3",
      "city_code": "0516",
      "center": "116.59539,34.693906",
      "parent_id": "831"
    },
    {
      "area_id": "838",
      "area_code": "320322",
      "area_name": "沛县",
      "level": "3",
      "city_code": "0516",
      "center": "116.936353,34.760761",
      "parent_id": "831"
    },
    {
      "area_id": "839",
      "area_code": "320324",
      "area_name": "睢宁县",
      "level": "3",
      "city_code": "0516",
      "center": "117.941563,33.912597",
      "parent_id": "831"
    },
    {
      "area_id": "840",
      "area_code": "320381",
      "area_name": "新沂市",
      "level": "3",
      "city_code": "0516",
      "center": "118.354537,34.36958",
      "parent_id": "831"
    },
    {
      "area_id": "841",
      "area_code": "320382",
      "area_name": "邳州市",
      "level": "3",
      "city_code": "0516",
      "center": "118.012531,34.338888",
      "parent_id": "831"
    },
    {
      "area_id": "842",
      "area_code": "320400",
      "area_name": "常州市",
      "level": "2",
      "city_code": "0519",
      "center": "119.974061,31.811226",
      "parent_id": "810"
    },
    {
      "area_id": "843",
      "area_code": "320402",
      "area_name": "天宁区",
      "level": "3",
      "city_code": "0519",
      "center": "119.999219,31.792787",
      "parent_id": "842"
    },
    {
      "area_id": "844",
      "area_code": "320404",
      "area_name": "钟楼区",
      "level": "3",
      "city_code": "0519",
      "center": "119.902369,31.802089",
      "parent_id": "842"
    },
    {
      "area_id": "845",
      "area_code": "320411",
      "area_name": "新北区",
      "level": "3",
      "city_code": "0519",
      "center": "119.971697,31.830427",
      "parent_id": "842"
    },
    {
      "area_id": "846",
      "area_code": "320412",
      "area_name": "武进区",
      "level": "3",
      "city_code": "0519",
      "center": "119.942437,31.701187",
      "parent_id": "842"
    },
    {
      "area_id": "847",
      "area_code": "320413",
      "area_name": "金坛区",
      "level": "3",
      "city_code": "0519",
      "center": "119.597811,31.723219",
      "parent_id": "842"
    },
    {
      "area_id": "848",
      "area_code": "320481",
      "area_name": "溧阳市",
      "level": "3",
      "city_code": "0519",
      "center": "119.48421,31.416911",
      "parent_id": "842"
    },
    {
      "area_id": "849",
      "area_code": "320500",
      "area_name": "苏州市",
      "level": "2",
      "city_code": "0512",
      "center": "120.585728,31.2974",
      "parent_id": "810"
    },
    {
      "area_id": "850",
      "area_code": "320505",
      "area_name": "虎丘区",
      "level": "3",
      "city_code": "0512",
      "center": "120.434238,31.329601",
      "parent_id": "849"
    },
    {
      "area_id": "851",
      "area_code": "320506",
      "area_name": "吴中区",
      "level": "3",
      "city_code": "0512",
      "center": "120.632308,31.263183",
      "parent_id": "849"
    },
    {
      "area_id": "852",
      "area_code": "320507",
      "area_name": "相城区",
      "level": "3",
      "city_code": "0512",
      "center": "120.642626,31.369089",
      "parent_id": "849"
    },
    {
      "area_id": "853",
      "area_code": "320508",
      "area_name": "姑苏区",
      "level": "3",
      "city_code": "0512",
      "center": "120.617369,31.33565",
      "parent_id": "849"
    },
    {
      "area_id": "854",
      "area_code": "320509",
      "area_name": "吴江区",
      "level": "3",
      "city_code": "0512",
      "center": "120.645157,31.138677",
      "parent_id": "849"
    },
    {
      "area_id": "855",
      "area_code": "320581",
      "area_name": "常熟市",
      "level": "3",
      "city_code": "0512",
      "center": "120.752481,31.654375",
      "parent_id": "849"
    },
    {
      "area_id": "856",
      "area_code": "320582",
      "area_name": "张家港市",
      "level": "3",
      "city_code": "0512",
      "center": "120.555982,31.875571",
      "parent_id": "849"
    },
    {
      "area_id": "857",
      "area_code": "320583",
      "area_name": "昆山市",
      "level": "3",
      "city_code": "0512",
      "center": "120.980736,31.385597",
      "parent_id": "849"
    },
    {
      "area_id": "858",
      "area_code": "320585",
      "area_name": "太仓市",
      "level": "3",
      "city_code": "0512",
      "center": "121.13055,31.457735",
      "parent_id": "849"
    },
    {
      "area_id": "859",
      "area_code": "320600",
      "area_name": "南通市",
      "level": "2",
      "city_code": "0513",
      "center": "120.894676,31.981143",
      "parent_id": "810"
    },
    {
      "area_id": "860",
      "area_code": "320602",
      "area_name": "崇川区",
      "level": "3",
      "city_code": "0513",
      "center": "120.857434,32.009875",
      "parent_id": "859"
    },
    {
      "area_id": "861",
      "area_code": "320611",
      "area_name": "港闸区",
      "level": "3",
      "city_code": "0513",
      "center": "120.818526,32.032441",
      "parent_id": "859"
    },
    {
      "area_id": "862",
      "area_code": "320612",
      "area_name": "通州区",
      "level": "3",
      "city_code": "0513",
      "center": "121.073828,32.06568",
      "parent_id": "859"
    },
    {
      "area_id": "863",
      "area_code": "320621",
      "area_name": "海安县",
      "level": "3",
      "city_code": "0513",
      "center": "120.467343,32.533572",
      "parent_id": "859"
    },
    {
      "area_id": "864",
      "area_code": "320623",
      "area_name": "如东县",
      "level": "3",
      "city_code": "0513",
      "center": "121.185201,32.331765",
      "parent_id": "859"
    },
    {
      "area_id": "865",
      "area_code": "320681",
      "area_name": "启东市",
      "level": "3",
      "city_code": "0513",
      "center": "121.655432,31.793278",
      "parent_id": "859"
    },
    {
      "area_id": "866",
      "area_code": "320682",
      "area_name": "如皋市",
      "level": "3",
      "city_code": "0513",
      "center": "120.573803,32.371562",
      "parent_id": "859"
    },
    {
      "area_id": "867",
      "area_code": "320684",
      "area_name": "海门市",
      "level": "3",
      "city_code": "0513",
      "center": "121.18181,31.869483",
      "parent_id": "859"
    },
    {
      "area_id": "868",
      "area_code": "320700",
      "area_name": "连云港市",
      "level": "2",
      "city_code": "0518",
      "center": "119.221611,34.596653",
      "parent_id": "810"
    },
    {
      "area_id": "869",
      "area_code": "320703",
      "area_name": "连云区",
      "level": "3",
      "city_code": "0518",
      "center": "119.338788,34.760249",
      "parent_id": "868"
    },
    {
      "area_id": "870",
      "area_code": "320706",
      "area_name": "海州区",
      "level": "3",
      "city_code": "0518",
      "center": "119.163509,34.572274",
      "parent_id": "868"
    },
    {
      "area_id": "871",
      "area_code": "320707",
      "area_name": "赣榆区",
      "level": "3",
      "city_code": "0518",
      "center": "119.17333,34.841348",
      "parent_id": "868"
    },
    {
      "area_id": "872",
      "area_code": "320722",
      "area_name": "东海县",
      "level": "3",
      "city_code": "0518",
      "center": "118.752842,34.542308",
      "parent_id": "868"
    },
    {
      "area_id": "873",
      "area_code": "320723",
      "area_name": "灌云县",
      "level": "3",
      "city_code": "0518",
      "center": "119.239381,34.284381",
      "parent_id": "868"
    },
    {
      "area_id": "874",
      "area_code": "320724",
      "area_name": "灌南县",
      "level": "3",
      "city_code": "0518",
      "center": "119.315651,34.087134",
      "parent_id": "868"
    },
    {
      "area_id": "875",
      "area_code": "320800",
      "area_name": "淮安市",
      "level": "2",
      "city_code": "0517",
      "center": "119.113185,33.551052",
      "parent_id": "810"
    },
    {
      "area_id": "876",
      "area_code": "320812",
      "area_name": "清江浦区",
      "level": "3",
      "city_code": "0517",
      "center": "119.026718,33.552627",
      "parent_id": "875"
    },
    {
      "area_id": "877",
      "area_code": "320803",
      "area_name": "淮安区",
      "level": "3",
      "city_code": "0517",
      "center": "119.141099,33.502868",
      "parent_id": "875"
    },
    {
      "area_id": "878",
      "area_code": "320804",
      "area_name": "淮阴区",
      "level": "3",
      "city_code": "0517",
      "center": "119.034725,33.631892",
      "parent_id": "875"
    },
    {
      "area_id": "879",
      "area_code": "320813",
      "area_name": "洪泽区",
      "level": "3",
      "city_code": "0517",
      "center": "118.873241,33.294214",
      "parent_id": "875"
    },
    {
      "area_id": "880",
      "area_code": "320826",
      "area_name": "涟水县",
      "level": "3",
      "city_code": "0517",
      "center": "119.260227,33.781331",
      "parent_id": "875"
    },
    {
      "area_id": "881",
      "area_code": "320830",
      "area_name": "盱眙县",
      "level": "3",
      "city_code": "0517",
      "center": "118.54436,33.011971",
      "parent_id": "875"
    },
    {
      "area_id": "882",
      "area_code": "320831",
      "area_name": "金湖县",
      "level": "3",
      "city_code": "0517",
      "center": "119.020584,33.025433",
      "parent_id": "875"
    },
    {
      "area_id": "883",
      "area_code": "320900",
      "area_name": "盐城市",
      "level": "2",
      "city_code": "0515",
      "center": "120.163107,33.347708",
      "parent_id": "810"
    },
    {
      "area_id": "884",
      "area_code": "320902",
      "area_name": "亭湖区",
      "level": "3",
      "city_code": "0515",
      "center": "120.197358,33.390536",
      "parent_id": "883"
    },
    {
      "area_id": "885",
      "area_code": "320903",
      "area_name": "盐都区",
      "level": "3",
      "city_code": "0515",
      "center": "120.153712,33.338283",
      "parent_id": "883"
    },
    {
      "area_id": "886",
      "area_code": "320904",
      "area_name": "大丰区",
      "level": "3",
      "city_code": "0515",
      "center": "120.50085,33.200333",
      "parent_id": "883"
    },
    {
      "area_id": "887",
      "area_code": "320921",
      "area_name": "响水县",
      "level": "3",
      "city_code": "0515",
      "center": "119.578364,34.199479",
      "parent_id": "883"
    },
    {
      "area_id": "888",
      "area_code": "320922",
      "area_name": "滨海县",
      "level": "3",
      "city_code": "0515",
      "center": "119.82083,33.990334",
      "parent_id": "883"
    },
    {
      "area_id": "889",
      "area_code": "320923",
      "area_name": "阜宁县",
      "level": "3",
      "city_code": "0515",
      "center": "119.802527,33.759325",
      "parent_id": "883"
    },
    {
      "area_id": "890",
      "area_code": "320924",
      "area_name": "射阳县",
      "level": "3",
      "city_code": "0515",
      "center": "120.229986,33.758361",
      "parent_id": "883"
    },
    {
      "area_id": "891",
      "area_code": "320925",
      "area_name": "建湖县",
      "level": "3",
      "city_code": "0515",
      "center": "119.7886,33.439067",
      "parent_id": "883"
    },
    {
      "area_id": "892",
      "area_code": "320981",
      "area_name": "东台市",
      "level": "3",
      "city_code": "0515",
      "center": "120.320328,32.868426",
      "parent_id": "883"
    },
    {
      "area_id": "893",
      "area_code": "321000",
      "area_name": "扬州市",
      "level": "2",
      "city_code": "0514",
      "center": "119.412939,32.394209",
      "parent_id": "810"
    },
    {
      "area_id": "894",
      "area_code": "321002",
      "area_name": "广陵区",
      "level": "3",
      "city_code": "0514",
      "center": "119.431849,32.39472",
      "parent_id": "893"
    },
    {
      "area_id": "895",
      "area_code": "321003",
      "area_name": "邗江区",
      "level": "3",
      "city_code": "0514",
      "center": "119.397994,32.377655",
      "parent_id": "893"
    },
    {
      "area_id": "896",
      "area_code": "321012",
      "area_name": "江都区",
      "level": "3",
      "city_code": "0514",
      "center": "119.569989,32.434672",
      "parent_id": "893"
    },
    {
      "area_id": "897",
      "area_code": "321023",
      "area_name": "宝应县",
      "level": "3",
      "city_code": "0514",
      "center": "119.360729,33.240391",
      "parent_id": "893"
    },
    {
      "area_id": "898",
      "area_code": "321081",
      "area_name": "仪征市",
      "level": "3",
      "city_code": "0514",
      "center": "119.184766,32.272258",
      "parent_id": "893"
    },
    {
      "area_id": "899",
      "area_code": "321084",
      "area_name": "高邮市",
      "level": "3",
      "city_code": "0514",
      "center": "119.459161,32.781659",
      "parent_id": "893"
    },
    {
      "area_id": "900",
      "area_code": "321100",
      "area_name": "镇江市",
      "level": "2",
      "city_code": "0511",
      "center": "119.425836,32.187849",
      "parent_id": "810"
    },
    {
      "area_id": "901",
      "area_code": "321102",
      "area_name": "京口区",
      "level": "3",
      "city_code": "0511",
      "center": "119.47016,32.19828",
      "parent_id": "900"
    },
    {
      "area_id": "902",
      "area_code": "321111",
      "area_name": "润州区",
      "level": "3",
      "city_code": "0511",
      "center": "119.411959,32.195264",
      "parent_id": "900"
    },
    {
      "area_id": "903",
      "area_code": "321112",
      "area_name": "丹徒区",
      "level": "3",
      "city_code": "0511",
      "center": "119.433853,32.131962",
      "parent_id": "900"
    },
    {
      "area_id": "904",
      "area_code": "321181",
      "area_name": "丹阳市",
      "level": "3",
      "city_code": "0511",
      "center": "119.606439,32.010214",
      "parent_id": "900"
    },
    {
      "area_id": "905",
      "area_code": "321182",
      "area_name": "扬中市",
      "level": "3",
      "city_code": "0511",
      "center": "119.797634,32.23483",
      "parent_id": "900"
    },
    {
      "area_id": "906",
      "area_code": "321183",
      "area_name": "句容市",
      "level": "3",
      "city_code": "0511",
      "center": "119.168695,31.944998",
      "parent_id": "900"
    },
    {
      "area_id": "907",
      "area_code": "321200",
      "area_name": "泰州市",
      "level": "2",
      "city_code": "0523",
      "center": "119.922933,32.455536",
      "parent_id": "810"
    },
    {
      "area_id": "908",
      "area_code": "321202",
      "area_name": "海陵区",
      "level": "3",
      "city_code": "0523",
      "center": "119.919424,32.491016",
      "parent_id": "907"
    },
    {
      "area_id": "909",
      "area_code": "321203",
      "area_name": "高港区",
      "level": "3",
      "city_code": "0523",
      "center": "119.881717,32.318821",
      "parent_id": "907"
    },
    {
      "area_id": "910",
      "area_code": "321204",
      "area_name": "姜堰区",
      "level": "3",
      "city_code": "0523",
      "center": "120.127934,32.509155",
      "parent_id": "907"
    },
    {
      "area_id": "911",
      "area_code": "321281",
      "area_name": "兴化市",
      "level": "3",
      "city_code": "0523",
      "center": "119.852541,32.910459",
      "parent_id": "907"
    },
    {
      "area_id": "912",
      "area_code": "321282",
      "area_name": "靖江市",
      "level": "3",
      "city_code": "0523",
      "center": "120.277138,31.982751",
      "parent_id": "907"
    },
    {
      "area_id": "913",
      "area_code": "321283",
      "area_name": "泰兴市",
      "level": "3",
      "city_code": "0523",
      "center": "120.051743,32.171853",
      "parent_id": "907"
    },
    {
      "area_id": "914",
      "area_code": "321300",
      "area_name": "宿迁市",
      "level": "2",
      "city_code": "0527",
      "center": "118.275198,33.963232",
      "parent_id": "810"
    },
    {
      "area_id": "915",
      "area_code": "321302",
      "area_name": "宿城区",
      "level": "3",
      "city_code": "0527",
      "center": "118.242533,33.963029",
      "parent_id": "914"
    },
    {
      "area_id": "916",
      "area_code": "321311",
      "area_name": "宿豫区",
      "level": "3",
      "city_code": "0527",
      "center": "118.330781,33.946822",
      "parent_id": "914"
    },
    {
      "area_id": "917",
      "area_code": "321322",
      "area_name": "沭阳县",
      "level": "3",
      "city_code": "0527",
      "center": "118.804784,34.111022",
      "parent_id": "914"
    },
    {
      "area_id": "918",
      "area_code": "321323",
      "area_name": "泗阳县",
      "level": "3",
      "city_code": "0527",
      "center": "118.703424,33.722478",
      "parent_id": "914"
    },
    {
      "area_id": "919",
      "area_code": "321324",
      "area_name": "泗洪县",
      "level": "3",
      "city_code": "0527",
      "center": "118.223591,33.476051",
      "parent_id": "914"
    },
    {
      "area_id": "920",
      "area_code": "330000",
      "area_name": "浙江省",
      "level": "1",
      "city_code": "[]",
      "center": "120.152585,30.266597",
      "parent_id": "0"
    },
    {
      "area_id": "921",
      "area_code": "330100",
      "area_name": "杭州市",
      "level": "2",
      "city_code": "0571",
      "center": "120.209789,30.24692",
      "parent_id": "920"
    },
    {
      "area_id": "922",
      "area_code": "330102",
      "area_name": "上城区",
      "level": "3",
      "city_code": "0571",
      "center": "120.169312,30.242404",
      "parent_id": "921"
    },
    {
      "area_id": "923",
      "area_code": "330103",
      "area_name": "下城区",
      "level": "3",
      "city_code": "0571",
      "center": "120.180891,30.281677",
      "parent_id": "921"
    },
    {
      "area_id": "924",
      "area_code": "330104",
      "area_name": "江干区",
      "level": "3",
      "city_code": "0571",
      "center": "120.205001,30.257012",
      "parent_id": "921"
    },
    {
      "area_id": "925",
      "area_code": "330105",
      "area_name": "拱墅区",
      "level": "3",
      "city_code": "0571",
      "center": "120.141406,30.319037",
      "parent_id": "921"
    },
    {
      "area_id": "926",
      "area_code": "330106",
      "area_name": "西湖区",
      "level": "3",
      "city_code": "0571",
      "center": "120.130194,30.259463",
      "parent_id": "921"
    },
    {
      "area_id": "927",
      "area_code": "330108",
      "area_name": "滨江区",
      "level": "3",
      "city_code": "0571",
      "center": "120.211623,30.208847",
      "parent_id": "921"
    },
    {
      "area_id": "928",
      "area_code": "330109",
      "area_name": "萧山区",
      "level": "3",
      "city_code": "0571",
      "center": "120.264253,30.183806",
      "parent_id": "921"
    },
    {
      "area_id": "929",
      "area_code": "330110",
      "area_name": "余杭区",
      "level": "3",
      "city_code": "0571",
      "center": "120.299401,30.419045",
      "parent_id": "921"
    },
    {
      "area_id": "930",
      "area_code": "330111",
      "area_name": "富阳区",
      "level": "3",
      "city_code": "0571",
      "center": "119.960076,30.048692",
      "parent_id": "921"
    },
    {
      "area_id": "931",
      "area_code": "330122",
      "area_name": "桐庐县",
      "level": "3",
      "city_code": "0571",
      "center": "119.691467,29.79299",
      "parent_id": "921"
    },
    {
      "area_id": "932",
      "area_code": "330127",
      "area_name": "淳安县",
      "level": "3",
      "city_code": "0571",
      "center": "119.042037,29.608886",
      "parent_id": "921"
    },
    {
      "area_id": "933",
      "area_code": "330182",
      "area_name": "建德市",
      "level": "3",
      "city_code": "0571",
      "center": "119.281231,29.474759",
      "parent_id": "921"
    },
    {
      "area_id": "934",
      "area_code": "330185",
      "area_name": "临安市",
      "level": "3",
      "city_code": "0571",
      "center": "119.724734,30.233873",
      "parent_id": "921"
    },
    {
      "area_id": "935",
      "area_code": "330200",
      "area_name": "宁波市",
      "level": "2",
      "city_code": "0574",
      "center": "121.622485,29.859971",
      "parent_id": "920"
    },
    {
      "area_id": "936",
      "area_code": "330203",
      "area_name": "海曙区",
      "level": "3",
      "city_code": "0574",
      "center": "121.550752,29.874903",
      "parent_id": "935"
    },
    {
      "area_id": "937",
      "area_code": "330205",
      "area_name": "江北区",
      "level": "3",
      "city_code": "0574",
      "center": "121.555081,29.886781",
      "parent_id": "935"
    },
    {
      "area_id": "938",
      "area_code": "330206",
      "area_name": "北仑区",
      "level": "3",
      "city_code": "0574",
      "center": "121.844172,29.899778",
      "parent_id": "935"
    },
    {
      "area_id": "939",
      "area_code": "330211",
      "area_name": "镇海区",
      "level": "3",
      "city_code": "0574",
      "center": "121.596496,29.965203",
      "parent_id": "935"
    },
    {
      "area_id": "940",
      "area_code": "330212",
      "area_name": "鄞州区",
      "level": "3",
      "city_code": "0574",
      "center": "121.546603,29.816511",
      "parent_id": "935"
    },
    {
      "area_id": "941",
      "area_code": "330225",
      "area_name": "象山县",
      "level": "3",
      "city_code": "0574",
      "center": "121.869339,29.476705",
      "parent_id": "935"
    },
    {
      "area_id": "942",
      "area_code": "330226",
      "area_name": "宁海县",
      "level": "3",
      "city_code": "0574",
      "center": "121.429477,29.287939",
      "parent_id": "935"
    },
    {
      "area_id": "943",
      "area_code": "330281",
      "area_name": "余姚市",
      "level": "3",
      "city_code": "0574",
      "center": "121.154629,30.037106",
      "parent_id": "935"
    },
    {
      "area_id": "944",
      "area_code": "330282",
      "area_name": "慈溪市",
      "level": "3",
      "city_code": "0574",
      "center": "121.266561,30.170261",
      "parent_id": "935"
    },
    {
      "area_id": "945",
      "area_code": "330213",
      "area_name": "奉化区",
      "level": "3",
      "city_code": "0574",
      "center": "121.406997,29.655144",
      "parent_id": "935"
    },
    {
      "area_id": "946",
      "area_code": "330300",
      "area_name": "温州市",
      "level": "2",
      "city_code": "0577",
      "center": "120.699361,27.993828",
      "parent_id": "920"
    },
    {
      "area_id": "947",
      "area_code": "330302",
      "area_name": "鹿城区",
      "level": "3",
      "city_code": "0577",
      "center": "120.655271,28.015737",
      "parent_id": "946"
    },
    {
      "area_id": "948",
      "area_code": "330303",
      "area_name": "龙湾区",
      "level": "3",
      "city_code": "0577",
      "center": "120.811213,27.932747",
      "parent_id": "946"
    },
    {
      "area_id": "949",
      "area_code": "330304",
      "area_name": "瓯海区",
      "level": "3",
      "city_code": "0577",
      "center": "120.61491,27.966844",
      "parent_id": "946"
    },
    {
      "area_id": "950",
      "area_code": "330305",
      "area_name": "洞头区",
      "level": "3",
      "city_code": "0577",
      "center": "121.157249,27.836154",
      "parent_id": "946"
    },
    {
      "area_id": "951",
      "area_code": "330324",
      "area_name": "永嘉县",
      "level": "3",
      "city_code": "0577",
      "center": "120.692025,28.153607",
      "parent_id": "946"
    },
    {
      "area_id": "952",
      "area_code": "330326",
      "area_name": "平阳县",
      "level": "3",
      "city_code": "0577",
      "center": "120.565793,27.661918",
      "parent_id": "946"
    },
    {
      "area_id": "953",
      "area_code": "330327",
      "area_name": "苍南县",
      "level": "3",
      "city_code": "0577",
      "center": "120.427619,27.519773",
      "parent_id": "946"
    },
    {
      "area_id": "954",
      "area_code": "330328",
      "area_name": "文成县",
      "level": "3",
      "city_code": "0577",
      "center": "120.091498,27.786996",
      "parent_id": "946"
    },
    {
      "area_id": "955",
      "area_code": "330329",
      "area_name": "泰顺县",
      "level": "3",
      "city_code": "0577",
      "center": "119.717649,27.556884",
      "parent_id": "946"
    },
    {
      "area_id": "956",
      "area_code": "330381",
      "area_name": "瑞安市",
      "level": "3",
      "city_code": "0577",
      "center": "120.655148,27.778657",
      "parent_id": "946"
    },
    {
      "area_id": "957",
      "area_code": "330382",
      "area_name": "乐清市",
      "level": "3",
      "city_code": "0577",
      "center": "120.983906,28.113725",
      "parent_id": "946"
    },
    {
      "area_id": "958",
      "area_code": "330400",
      "area_name": "嘉兴市",
      "level": "2",
      "city_code": "0573",
      "center": "120.75547,30.746191",
      "parent_id": "920"
    },
    {
      "area_id": "959",
      "area_code": "330402",
      "area_name": "南湖区",
      "level": "3",
      "city_code": "0573",
      "center": "120.783024,30.747842",
      "parent_id": "958"
    },
    {
      "area_id": "960",
      "area_code": "330411",
      "area_name": "秀洲区",
      "level": "3",
      "city_code": "0573",
      "center": "120.710082,30.765188",
      "parent_id": "958"
    },
    {
      "area_id": "961",
      "area_code": "330421",
      "area_name": "嘉善县",
      "level": "3",
      "city_code": "0573",
      "center": "120.926028,30.830864",
      "parent_id": "958"
    },
    {
      "area_id": "962",
      "area_code": "330424",
      "area_name": "海盐县",
      "level": "3",
      "city_code": "0573",
      "center": "120.946263,30.526435",
      "parent_id": "958"
    },
    {
      "area_id": "963",
      "area_code": "330481",
      "area_name": "海宁市",
      "level": "3",
      "city_code": "0573",
      "center": "120.680239,30.511539",
      "parent_id": "958"
    },
    {
      "area_id": "964",
      "area_code": "330482",
      "area_name": "平湖市",
      "level": "3",
      "city_code": "0573",
      "center": "121.015142,30.677233",
      "parent_id": "958"
    },
    {
      "area_id": "965",
      "area_code": "330483",
      "area_name": "桐乡市",
      "level": "3",
      "city_code": "0573",
      "center": "120.565098,30.630173",
      "parent_id": "958"
    },
    {
      "area_id": "966",
      "area_code": "330500",
      "area_name": "湖州市",
      "level": "2",
      "city_code": "0572",
      "center": "120.086809,30.89441",
      "parent_id": "920"
    },
    {
      "area_id": "967",
      "area_code": "330502",
      "area_name": "吴兴区",
      "level": "3",
      "city_code": "0572",
      "center": "120.185838,30.857151",
      "parent_id": "966"
    },
    {
      "area_id": "968",
      "area_code": "330503",
      "area_name": "南浔区",
      "level": "3",
      "city_code": "0572",
      "center": "120.418513,30.849689",
      "parent_id": "966"
    },
    {
      "area_id": "969",
      "area_code": "330521",
      "area_name": "德清县",
      "level": "3",
      "city_code": "0572",
      "center": "119.9774,30.54251",
      "parent_id": "966"
    },
    {
      "area_id": "970",
      "area_code": "330522",
      "area_name": "长兴县",
      "level": "3",
      "city_code": "0572",
      "center": "119.910952,31.026665",
      "parent_id": "966"
    },
    {
      "area_id": "971",
      "area_code": "330523",
      "area_name": "安吉县",
      "level": "3",
      "city_code": "0572",
      "center": "119.680353,30.638674",
      "parent_id": "966"
    },
    {
      "area_id": "972",
      "area_code": "330600",
      "area_name": "绍兴市",
      "level": "2",
      "city_code": "0575",
      "center": "120.580364,30.030192",
      "parent_id": "920"
    },
    {
      "area_id": "973",
      "area_code": "330602",
      "area_name": "越城区",
      "level": "3",
      "city_code": "0575",
      "center": "120.582633,29.988244",
      "parent_id": "972"
    },
    {
      "area_id": "974",
      "area_code": "330603",
      "area_name": "柯桥区",
      "level": "3",
      "city_code": "0575",
      "center": "120.495085,30.081929",
      "parent_id": "972"
    },
    {
      "area_id": "975",
      "area_code": "330604",
      "area_name": "上虞区",
      "level": "3",
      "city_code": "0575",
      "center": "120.868122,30.03312",
      "parent_id": "972"
    },
    {
      "area_id": "976",
      "area_code": "330624",
      "area_name": "新昌县",
      "level": "3",
      "city_code": "0575",
      "center": "120.903866,29.499831",
      "parent_id": "972"
    },
    {
      "area_id": "977",
      "area_code": "330681",
      "area_name": "诸暨市",
      "level": "3",
      "city_code": "0575",
      "center": "120.246863,29.708692",
      "parent_id": "972"
    },
    {
      "area_id": "978",
      "area_code": "330683",
      "area_name": "嵊州市",
      "level": "3",
      "city_code": "0575",
      "center": "120.831025,29.56141",
      "parent_id": "972"
    },
    {
      "area_id": "979",
      "area_code": "330700",
      "area_name": "金华市",
      "level": "2",
      "city_code": "0579",
      "center": "119.647229,29.079208",
      "parent_id": "920"
    },
    {
      "area_id": "980",
      "area_code": "330702",
      "area_name": "婺城区",
      "level": "3",
      "city_code": "0579",
      "center": "119.571728,29.0872",
      "parent_id": "979"
    },
    {
      "area_id": "981",
      "area_code": "330703",
      "area_name": "金东区",
      "level": "3",
      "city_code": "0579",
      "center": "119.69278,29.099723",
      "parent_id": "979"
    },
    {
      "area_id": "982",
      "area_code": "330723",
      "area_name": "武义县",
      "level": "3",
      "city_code": "0579",
      "center": "119.816562,28.89267",
      "parent_id": "979"
    },
    {
      "area_id": "983",
      "area_code": "330726",
      "area_name": "浦江县",
      "level": "3",
      "city_code": "0579",
      "center": "119.892222,29.452476",
      "parent_id": "979"
    },
    {
      "area_id": "984",
      "area_code": "330727",
      "area_name": "磐安县",
      "level": "3",
      "city_code": "0579",
      "center": "120.450005,29.054548",
      "parent_id": "979"
    },
    {
      "area_id": "985",
      "area_code": "330781",
      "area_name": "兰溪市",
      "level": "3",
      "city_code": "0579",
      "center": "119.460472,29.2084",
      "parent_id": "979"
    },
    {
      "area_id": "986",
      "area_code": "330782",
      "area_name": "义乌市",
      "level": "3",
      "city_code": "0579",
      "center": "120.075106,29.306775",
      "parent_id": "979"
    },
    {
      "area_id": "987",
      "area_code": "330783",
      "area_name": "东阳市",
      "level": "3",
      "city_code": "0579",
      "center": "120.241566,29.289648",
      "parent_id": "979"
    },
    {
      "area_id": "988",
      "area_code": "330784",
      "area_name": "永康市",
      "level": "3",
      "city_code": "0579",
      "center": "120.047651,28.888555",
      "parent_id": "979"
    },
    {
      "area_id": "989",
      "area_code": "330800",
      "area_name": "衢州市",
      "level": "2",
      "city_code": "0570",
      "center": "118.859457,28.970079",
      "parent_id": "920"
    },
    {
      "area_id": "990",
      "area_code": "330802",
      "area_name": "柯城区",
      "level": "3",
      "city_code": "0570",
      "center": "118.871516,28.96862",
      "parent_id": "989"
    },
    {
      "area_id": "991",
      "area_code": "330803",
      "area_name": "衢江区",
      "level": "3",
      "city_code": "0570",
      "center": "118.95946,28.97978",
      "parent_id": "989"
    },
    {
      "area_id": "992",
      "area_code": "330822",
      "area_name": "常山县",
      "level": "3",
      "city_code": "0570",
      "center": "118.511235,28.901462",
      "parent_id": "989"
    },
    {
      "area_id": "993",
      "area_code": "330824",
      "area_name": "开化县",
      "level": "3",
      "city_code": "0570",
      "center": "118.415495,29.137336",
      "parent_id": "989"
    },
    {
      "area_id": "994",
      "area_code": "330825",
      "area_name": "龙游县",
      "level": "3",
      "city_code": "0570",
      "center": "119.172189,29.028439",
      "parent_id": "989"
    },
    {
      "area_id": "995",
      "area_code": "330881",
      "area_name": "江山市",
      "level": "3",
      "city_code": "0570",
      "center": "118.626991,28.737331",
      "parent_id": "989"
    },
    {
      "area_id": "996",
      "area_code": "330900",
      "area_name": "舟山市",
      "level": "2",
      "city_code": "0580",
      "center": "122.207106,29.985553",
      "parent_id": "920"
    },
    {
      "area_id": "997",
      "area_code": "330902",
      "area_name": "定海区",
      "level": "3",
      "city_code": "0580",
      "center": "122.106773,30.019858",
      "parent_id": "996"
    },
    {
      "area_id": "998",
      "area_code": "330903",
      "area_name": "普陀区",
      "level": "3",
      "city_code": "0580",
      "center": "122.323867,29.97176",
      "parent_id": "996"
    },
    {
      "area_id": "999",
      "area_code": "330921",
      "area_name": "岱山县",
      "level": "3",
      "city_code": "0580",
      "center": "122.226237,30.264139",
      "parent_id": "996"
    },
    {
      "area_id": "1000",
      "area_code": "330922",
      "area_name": "嵊泗县",
      "level": "3",
      "city_code": "0580",
      "center": "122.451382,30.725686",
      "parent_id": "996"
    },
    {
      "area_id": "1001",
      "area_code": "331000",
      "area_name": "台州市",
      "level": "2",
      "city_code": "0576",
      "center": "121.42076,28.65638",
      "parent_id": "920"
    },
    {
      "area_id": "1002",
      "area_code": "331002",
      "area_name": "椒江区",
      "level": "3",
      "city_code": "0576",
      "center": "121.442978,28.672981",
      "parent_id": "1001"
    },
    {
      "area_id": "1003",
      "area_code": "331003",
      "area_name": "黄岩区",
      "level": "3",
      "city_code": "0576",
      "center": "121.261972,28.650083",
      "parent_id": "1001"
    },
    {
      "area_id": "1004",
      "area_code": "331004",
      "area_name": "路桥区",
      "level": "3",
      "city_code": "0576",
      "center": "121.365123,28.582654",
      "parent_id": "1001"
    },
    {
      "area_id": "1005",
      "area_code": "331021",
      "area_name": "玉环市",
      "level": "3",
      "city_code": "0576",
      "center": "121.231805,28.135929",
      "parent_id": "1001"
    },
    {
      "area_id": "1006",
      "area_code": "331022",
      "area_name": "三门县",
      "level": "3",
      "city_code": "0576",
      "center": "121.395711,29.104789",
      "parent_id": "1001"
    },
    {
      "area_id": "1007",
      "area_code": "331023",
      "area_name": "天台县",
      "level": "3",
      "city_code": "0576",
      "center": "121.006595,29.144064",
      "parent_id": "1001"
    },
    {
      "area_id": "1008",
      "area_code": "331024",
      "area_name": "仙居县",
      "level": "3",
      "city_code": "0576",
      "center": "120.728801,28.846966",
      "parent_id": "1001"
    },
    {
      "area_id": "1009",
      "area_code": "331081",
      "area_name": "温岭市",
      "level": "3",
      "city_code": "0576",
      "center": "121.385604,28.372506",
      "parent_id": "1001"
    },
    {
      "area_id": "1010",
      "area_code": "331082",
      "area_name": "临海市",
      "level": "3",
      "city_code": "0576",
      "center": "121.144556,28.858881",
      "parent_id": "1001"
    },
    {
      "area_id": "1011",
      "area_code": "331100",
      "area_name": "丽水市",
      "level": "2",
      "city_code": "0578",
      "center": "119.922796,28.46763",
      "parent_id": "920"
    },
    {
      "area_id": "1012",
      "area_code": "331102",
      "area_name": "莲都区",
      "level": "3",
      "city_code": "0578",
      "center": "119.912626,28.445928",
      "parent_id": "1011"
    },
    {
      "area_id": "1013",
      "area_code": "331121",
      "area_name": "青田县",
      "level": "3",
      "city_code": "0578",
      "center": "120.289478,28.139837",
      "parent_id": "1011"
    },
    {
      "area_id": "1014",
      "area_code": "331122",
      "area_name": "缙云县",
      "level": "3",
      "city_code": "0578",
      "center": "120.091572,28.659283",
      "parent_id": "1011"
    },
    {
      "area_id": "1015",
      "area_code": "331123",
      "area_name": "遂昌县",
      "level": "3",
      "city_code": "0578",
      "center": "119.276103,28.592148",
      "parent_id": "1011"
    },
    {
      "area_id": "1016",
      "area_code": "331124",
      "area_name": "松阳县",
      "level": "3",
      "city_code": "0578",
      "center": "119.481511,28.448803",
      "parent_id": "1011"
    },
    {
      "area_id": "1017",
      "area_code": "331125",
      "area_name": "云和县",
      "level": "3",
      "city_code": "0578",
      "center": "119.573397,28.11579",
      "parent_id": "1011"
    },
    {
      "area_id": "1018",
      "area_code": "331126",
      "area_name": "庆元县",
      "level": "3",
      "city_code": "0578",
      "center": "119.06259,27.61922",
      "parent_id": "1011"
    },
    {
      "area_id": "1019",
      "area_code": "331127",
      "area_name": "景宁畲族自治县",
      "level": "3",
      "city_code": "0578",
      "center": "119.635739,27.9733",
      "parent_id": "1011"
    },
    {
      "area_id": "1020",
      "area_code": "331181",
      "area_name": "龙泉市",
      "level": "3",
      "city_code": "0578",
      "center": "119.141473,28.074649",
      "parent_id": "1011"
    },
    {
      "area_id": "1021",
      "area_code": "340000",
      "area_name": "安徽省",
      "level": "1",
      "city_code": "[]",
      "center": "117.329949,31.733806",
      "parent_id": "0"
    },
    {
      "area_id": "1022",
      "area_code": "340100",
      "area_name": "合肥市",
      "level": "2",
      "city_code": "0551",
      "center": "117.227219,31.820591",
      "parent_id": "1021"
    },
    {
      "area_id": "1023",
      "area_code": "340102",
      "area_name": "瑶海区",
      "level": "3",
      "city_code": "0551",
      "center": "117.309546,31.857917",
      "parent_id": "1022"
    },
    {
      "area_id": "1024",
      "area_code": "340103",
      "area_name": "庐阳区",
      "level": "3",
      "city_code": "0551",
      "center": "117.264786,31.878589",
      "parent_id": "1022"
    },
    {
      "area_id": "1025",
      "area_code": "340104",
      "area_name": "蜀山区",
      "level": "3",
      "city_code": "0551",
      "center": "117.260521,31.85124",
      "parent_id": "1022"
    },
    {
      "area_id": "1026",
      "area_code": "340111",
      "area_name": "包河区",
      "level": "3",
      "city_code": "0551",
      "center": "117.309519,31.793859",
      "parent_id": "1022"
    },
    {
      "area_id": "1027",
      "area_code": "340121",
      "area_name": "长丰县",
      "level": "3",
      "city_code": "0551",
      "center": "117.167564,32.478018",
      "parent_id": "1022"
    },
    {
      "area_id": "1028",
      "area_code": "340122",
      "area_name": "肥东县",
      "level": "3",
      "city_code": "0551",
      "center": "117.469382,31.88794",
      "parent_id": "1022"
    },
    {
      "area_id": "1029",
      "area_code": "340123",
      "area_name": "肥西县",
      "level": "3",
      "city_code": "0551",
      "center": "117.157981,31.706809",
      "parent_id": "1022"
    },
    {
      "area_id": "1030",
      "area_code": "340124",
      "area_name": "庐江县",
      "level": "3",
      "city_code": "0551",
      "center": "117.2882,31.256524",
      "parent_id": "1022"
    },
    {
      "area_id": "1031",
      "area_code": "340181",
      "area_name": "巢湖市",
      "level": "3",
      "city_code": "0551",
      "center": "117.890354,31.624522",
      "parent_id": "1022"
    },
    {
      "area_id": "1032",
      "area_code": "340200",
      "area_name": "芜湖市",
      "level": "2",
      "city_code": "0553",
      "center": "118.432941,31.352859",
      "parent_id": "1021"
    },
    {
      "area_id": "1033",
      "area_code": "340202",
      "area_name": "镜湖区",
      "level": "3",
      "city_code": "0553",
      "center": "118.385009,31.340728",
      "parent_id": "1032"
    },
    {
      "area_id": "1034",
      "area_code": "340203",
      "area_name": "弋江区",
      "level": "3",
      "city_code": "0553",
      "center": "118.372655,31.311756",
      "parent_id": "1032"
    },
    {
      "area_id": "1035",
      "area_code": "340207",
      "area_name": "鸠江区",
      "level": "3",
      "city_code": "0553",
      "center": "118.391734,31.369373",
      "parent_id": "1032"
    },
    {
      "area_id": "1036",
      "area_code": "340208",
      "area_name": "三山区",
      "level": "3",
      "city_code": "0553",
      "center": "118.268101,31.219568",
      "parent_id": "1032"
    },
    {
      "area_id": "1037",
      "area_code": "340221",
      "area_name": "芜湖县",
      "level": "3",
      "city_code": "0553",
      "center": "118.576124,31.134809",
      "parent_id": "1032"
    },
    {
      "area_id": "1038",
      "area_code": "340222",
      "area_name": "繁昌县",
      "level": "3",
      "city_code": "0553",
      "center": "118.198703,31.101782",
      "parent_id": "1032"
    },
    {
      "area_id": "1039",
      "area_code": "340223",
      "area_name": "南陵县",
      "level": "3",
      "city_code": "0553",
      "center": "118.334359,30.914922",
      "parent_id": "1032"
    },
    {
      "area_id": "1040",
      "area_code": "340225",
      "area_name": "无为县",
      "level": "3",
      "city_code": "0553",
      "center": "117.902366,31.303167",
      "parent_id": "1032"
    },
    {
      "area_id": "1041",
      "area_code": "340300",
      "area_name": "蚌埠市",
      "level": "2",
      "city_code": "0552",
      "center": "117.388512,32.91663",
      "parent_id": "1021"
    },
    {
      "area_id": "1042",
      "area_code": "340302",
      "area_name": "龙子湖区",
      "level": "3",
      "city_code": "0552",
      "center": "117.379778,32.950611",
      "parent_id": "1041"
    },
    {
      "area_id": "1043",
      "area_code": "340303",
      "area_name": "蚌山区",
      "level": "3",
      "city_code": "0552",
      "center": "117.373595,32.917048",
      "parent_id": "1041"
    },
    {
      "area_id": "1044",
      "area_code": "340304",
      "area_name": "禹会区",
      "level": "3",
      "city_code": "0552",
      "center": "117.342155,32.929799",
      "parent_id": "1041"
    },
    {
      "area_id": "1045",
      "area_code": "340311",
      "area_name": "淮上区",
      "level": "3",
      "city_code": "0552",
      "center": "117.35933,32.965435",
      "parent_id": "1041"
    },
    {
      "area_id": "1046",
      "area_code": "340321",
      "area_name": "怀远县",
      "level": "3",
      "city_code": "0552",
      "center": "117.205237,32.970031",
      "parent_id": "1041"
    },
    {
      "area_id": "1047",
      "area_code": "340322",
      "area_name": "五河县",
      "level": "3",
      "city_code": "0552",
      "center": "117.879486,33.127823",
      "parent_id": "1041"
    },
    {
      "area_id": "1048",
      "area_code": "340323",
      "area_name": "固镇县",
      "level": "3",
      "city_code": "0552",
      "center": "117.316913,33.31688",
      "parent_id": "1041"
    },
    {
      "area_id": "1049",
      "area_code": "340400",
      "area_name": "淮南市",
      "level": "2",
      "city_code": "0554",
      "center": "117.018399,32.587117",
      "parent_id": "1021"
    },
    {
      "area_id": "1050",
      "area_code": "340402",
      "area_name": "大通区",
      "level": "3",
      "city_code": "0554",
      "center": "117.053314,32.631519",
      "parent_id": "1049"
    },
    {
      "area_id": "1051",
      "area_code": "340403",
      "area_name": "田家庵区",
      "level": "3",
      "city_code": "0554",
      "center": "117.017349,32.647277",
      "parent_id": "1049"
    },
    {
      "area_id": "1052",
      "area_code": "340404",
      "area_name": "谢家集区",
      "level": "3",
      "city_code": "0554",
      "center": "116.859188,32.600037",
      "parent_id": "1049"
    },
    {
      "area_id": "1053",
      "area_code": "340405",
      "area_name": "八公山区",
      "level": "3",
      "city_code": "0554",
      "center": "116.83349,32.631379",
      "parent_id": "1049"
    },
    {
      "area_id": "1054",
      "area_code": "340406",
      "area_name": "潘集区",
      "level": "3",
      "city_code": "0554",
      "center": "116.834715,32.77208",
      "parent_id": "1049"
    },
    {
      "area_id": "1055",
      "area_code": "340421",
      "area_name": "凤台县",
      "level": "3",
      "city_code": "0554",
      "center": "116.71105,32.709444",
      "parent_id": "1049"
    },
    {
      "area_id": "1056",
      "area_code": "340422",
      "area_name": "寿县",
      "level": "3",
      "city_code": "0554",
      "center": "116.798232,32.545109",
      "parent_id": "1049"
    },
    {
      "area_id": "1057",
      "area_code": "340500",
      "area_name": "马鞍山市",
      "level": "2",
      "city_code": "0555",
      "center": "118.507011,31.67044",
      "parent_id": "1021"
    },
    {
      "area_id": "1058",
      "area_code": "340503",
      "area_name": "花山区",
      "level": "3",
      "city_code": "0555",
      "center": "118.492565,31.71971",
      "parent_id": "1057"
    },
    {
      "area_id": "1059",
      "area_code": "340504",
      "area_name": "雨山区",
      "level": "3",
      "city_code": "0555",
      "center": "118.498578,31.682132",
      "parent_id": "1057"
    },
    {
      "area_id": "1060",
      "area_code": "340506",
      "area_name": "博望区",
      "level": "3",
      "city_code": "0555",
      "center": "118.844538,31.558471",
      "parent_id": "1057"
    },
    {
      "area_id": "1061",
      "area_code": "340521",
      "area_name": "当涂县",
      "level": "3",
      "city_code": "0555",
      "center": "118.497972,31.571213",
      "parent_id": "1057"
    },
    {
      "area_id": "1062",
      "area_code": "340522",
      "area_name": "含山县",
      "level": "3",
      "city_code": "0555",
      "center": "118.101421,31.735598",
      "parent_id": "1057"
    },
    {
      "area_id": "1063",
      "area_code": "340523",
      "area_name": "和县",
      "level": "3",
      "city_code": "0555",
      "center": "118.353667,31.742293",
      "parent_id": "1057"
    },
    {
      "area_id": "1064",
      "area_code": "340600",
      "area_name": "淮北市",
      "level": "2",
      "city_code": "0561",
      "center": "116.798265,33.955844",
      "parent_id": "1021"
    },
    {
      "area_id": "1065",
      "area_code": "340602",
      "area_name": "杜集区",
      "level": "3",
      "city_code": "0561",
      "center": "116.828133,33.991451",
      "parent_id": "1064"
    },
    {
      "area_id": "1066",
      "area_code": "340603",
      "area_name": "相山区",
      "level": "3",
      "city_code": "0561",
      "center": "116.794344,33.959892",
      "parent_id": "1064"
    },
    {
      "area_id": "1067",
      "area_code": "340604",
      "area_name": "烈山区",
      "level": "3",
      "city_code": "0561",
      "center": "116.813042,33.895139",
      "parent_id": "1064"
    },
    {
      "area_id": "1068",
      "area_code": "340621",
      "area_name": "濉溪县",
      "level": "3",
      "city_code": "0561",
      "center": "116.766298,33.915477",
      "parent_id": "1064"
    },
    {
      "area_id": "1069",
      "area_code": "340700",
      "area_name": "铜陵市",
      "level": "2",
      "city_code": "0562",
      "center": "117.81154,30.945515",
      "parent_id": "1021"
    },
    {
      "area_id": "1070",
      "area_code": "340705",
      "area_name": "铜官区",
      "level": "3",
      "city_code": "0562",
      "center": "117.85616,30.936272",
      "parent_id": "1069"
    },
    {
      "area_id": "1071",
      "area_code": "340706",
      "area_name": "义安区",
      "level": "3",
      "city_code": "0562",
      "center": "117.791544,30.952823",
      "parent_id": "1069"
    },
    {
      "area_id": "1072",
      "area_code": "340711",
      "area_name": "郊区",
      "level": "3",
      "city_code": "0562",
      "center": "117.768026,30.821069",
      "parent_id": "1069"
    },
    {
      "area_id": "1073",
      "area_code": "340722",
      "area_name": "枞阳县",
      "level": "3",
      "city_code": "0562",
      "center": "117.250594,30.706018",
      "parent_id": "1069"
    },
    {
      "area_id": "1074",
      "area_code": "340800",
      "area_name": "安庆市",
      "level": "2",
      "city_code": "0556",
      "center": "117.115101,30.531919",
      "parent_id": "1021"
    },
    {
      "area_id": "1075",
      "area_code": "340802",
      "area_name": "迎江区",
      "level": "3",
      "city_code": "0556",
      "center": "117.09115,30.511548",
      "parent_id": "1074"
    },
    {
      "area_id": "1076",
      "area_code": "340803",
      "area_name": "大观区",
      "level": "3",
      "city_code": "0556",
      "center": "117.013469,30.553697",
      "parent_id": "1074"
    },
    {
      "area_id": "1077",
      "area_code": "340811",
      "area_name": "宜秀区",
      "level": "3",
      "city_code": "0556",
      "center": "116.987542,30.613332",
      "parent_id": "1074"
    },
    {
      "area_id": "1078",
      "area_code": "340822",
      "area_name": "怀宁县",
      "level": "3",
      "city_code": "0556",
      "center": "116.829475,30.733824",
      "parent_id": "1074"
    },
    {
      "area_id": "1079",
      "area_code": "340824",
      "area_name": "潜山县",
      "level": "3",
      "city_code": "0556",
      "center": "116.581371,30.631136",
      "parent_id": "1074"
    },
    {
      "area_id": "1080",
      "area_code": "340825",
      "area_name": "太湖县",
      "level": "3",
      "city_code": "0556",
      "center": "116.308795,30.45422",
      "parent_id": "1074"
    },
    {
      "area_id": "1081",
      "area_code": "340826",
      "area_name": "宿松县",
      "level": "3",
      "city_code": "0556",
      "center": "116.129105,30.153746",
      "parent_id": "1074"
    },
    {
      "area_id": "1082",
      "area_code": "340827",
      "area_name": "望江县",
      "level": "3",
      "city_code": "0556",
      "center": "116.706498,30.128002",
      "parent_id": "1074"
    },
    {
      "area_id": "1083",
      "area_code": "340828",
      "area_name": "岳西县",
      "level": "3",
      "city_code": "0556",
      "center": "116.359692,30.849762",
      "parent_id": "1074"
    },
    {
      "area_id": "1084",
      "area_code": "340881",
      "area_name": "桐城市",
      "level": "3",
      "city_code": "0556",
      "center": "116.936748,31.035848",
      "parent_id": "1074"
    },
    {
      "area_id": "1085",
      "area_code": "341000",
      "area_name": "黄山市",
      "level": "2",
      "city_code": "0559",
      "center": "118.338272,29.715185",
      "parent_id": "1021"
    },
    {
      "area_id": "1086",
      "area_code": "341002",
      "area_name": "屯溪区",
      "level": "3",
      "city_code": "0559",
      "center": "118.315329,29.696108",
      "parent_id": "1085"
    },
    {
      "area_id": "1087",
      "area_code": "341003",
      "area_name": "黄山区",
      "level": "3",
      "city_code": "0559",
      "center": "118.141567,30.272942",
      "parent_id": "1085"
    },
    {
      "area_id": "1088",
      "area_code": "341004",
      "area_name": "徽州区",
      "level": "3",
      "city_code": "0559",
      "center": "118.336743,29.827271",
      "parent_id": "1085"
    },
    {
      "area_id": "1089",
      "area_code": "341021",
      "area_name": "歙县",
      "level": "3",
      "city_code": "0559",
      "center": "118.415345,29.861379",
      "parent_id": "1085"
    },
    {
      "area_id": "1090",
      "area_code": "341022",
      "area_name": "休宁县",
      "level": "3",
      "city_code": "0559",
      "center": "118.193618,29.784124",
      "parent_id": "1085"
    },
    {
      "area_id": "1091",
      "area_code": "341023",
      "area_name": "黟县",
      "level": "3",
      "city_code": "0559",
      "center": "117.938373,29.924805",
      "parent_id": "1085"
    },
    {
      "area_id": "1092",
      "area_code": "341024",
      "area_name": "祁门县",
      "level": "3",
      "city_code": "0559",
      "center": "117.717396,29.854055",
      "parent_id": "1085"
    },
    {
      "area_id": "1093",
      "area_code": "341100",
      "area_name": "滁州市",
      "level": "2",
      "city_code": "0550",
      "center": "118.327944,32.255636",
      "parent_id": "1021"
    },
    {
      "area_id": "1094",
      "area_code": "341102",
      "area_name": "琅琊区",
      "level": "3",
      "city_code": "0550",
      "center": "118.305961,32.294631",
      "parent_id": "1093"
    },
    {
      "area_id": "1095",
      "area_code": "341103",
      "area_name": "南谯区",
      "level": "3",
      "city_code": "0550",
      "center": "118.41697,32.200197",
      "parent_id": "1093"
    },
    {
      "area_id": "1096",
      "area_code": "341122",
      "area_name": "来安县",
      "level": "3",
      "city_code": "0550",
      "center": "118.435718,32.452199",
      "parent_id": "1093"
    },
    {
      "area_id": "1097",
      "area_code": "341124",
      "area_name": "全椒县",
      "level": "3",
      "city_code": "0550",
      "center": "118.274149,32.08593",
      "parent_id": "1093"
    },
    {
      "area_id": "1098",
      "area_code": "341125",
      "area_name": "定远县",
      "level": "3",
      "city_code": "0550",
      "center": "117.698562,32.530981",
      "parent_id": "1093"
    },
    {
      "area_id": "1099",
      "area_code": "341126",
      "area_name": "凤阳县",
      "level": "3",
      "city_code": "0550",
      "center": "117.531622,32.874735",
      "parent_id": "1093"
    },
    {
      "area_id": "1100",
      "area_code": "341181",
      "area_name": "天长市",
      "level": "3",
      "city_code": "0550",
      "center": "119.004816,32.667571",
      "parent_id": "1093"
    },
    {
      "area_id": "1101",
      "area_code": "341182",
      "area_name": "明光市",
      "level": "3",
      "city_code": "0550",
      "center": "118.018193,32.78196",
      "parent_id": "1093"
    },
    {
      "area_id": "1102",
      "area_code": "341200",
      "area_name": "阜阳市",
      "level": "2",
      "city_code": "1558",
      "center": "115.814504,32.890479",
      "parent_id": "1021"
    },
    {
      "area_id": "1103",
      "area_code": "341202",
      "area_name": "颍州区",
      "level": "3",
      "city_code": "1558",
      "center": "115.806942,32.883468",
      "parent_id": "1102"
    },
    {
      "area_id": "1104",
      "area_code": "341203",
      "area_name": "颍东区",
      "level": "3",
      "city_code": "1558",
      "center": "115.856762,32.912477",
      "parent_id": "1102"
    },
    {
      "area_id": "1105",
      "area_code": "341204",
      "area_name": "颍泉区",
      "level": "3",
      "city_code": "1558",
      "center": "115.80835,32.925211",
      "parent_id": "1102"
    },
    {
      "area_id": "1106",
      "area_code": "341221",
      "area_name": "临泉县",
      "level": "3",
      "city_code": "1558",
      "center": "115.263115,33.039715",
      "parent_id": "1102"
    },
    {
      "area_id": "1107",
      "area_code": "341222",
      "area_name": "太和县",
      "level": "3",
      "city_code": "1558",
      "center": "115.621941,33.160327",
      "parent_id": "1102"
    },
    {
      "area_id": "1108",
      "area_code": "341225",
      "area_name": "阜南县",
      "level": "3",
      "city_code": "1558",
      "center": "115.595643,32.658297",
      "parent_id": "1102"
    },
    {
      "area_id": "1109",
      "area_code": "341226",
      "area_name": "颍上县",
      "level": "3",
      "city_code": "1558",
      "center": "116.256772,32.653211",
      "parent_id": "1102"
    },
    {
      "area_id": "1110",
      "area_code": "341282",
      "area_name": "界首市",
      "level": "3",
      "city_code": "1558",
      "center": "115.374821,33.258244",
      "parent_id": "1102"
    },
    {
      "area_id": "1111",
      "area_code": "341300",
      "area_name": "宿州市",
      "level": "2",
      "city_code": "0557",
      "center": "116.964195,33.647309",
      "parent_id": "1021"
    },
    {
      "area_id": "1112",
      "area_code": "341302",
      "area_name": "埇桥区",
      "level": "3",
      "city_code": "0557",
      "center": "116.977203,33.64059",
      "parent_id": "1111"
    },
    {
      "area_id": "1113",
      "area_code": "341321",
      "area_name": "砀山县",
      "level": "3",
      "city_code": "0557",
      "center": "116.367095,34.442561",
      "parent_id": "1111"
    },
    {
      "area_id": "1114",
      "area_code": "341322",
      "area_name": "萧县",
      "level": "3",
      "city_code": "0557",
      "center": "116.947349,34.188732",
      "parent_id": "1111"
    },
    {
      "area_id": "1115",
      "area_code": "341323",
      "area_name": "灵璧县",
      "level": "3",
      "city_code": "0557",
      "center": "117.549395,33.554604",
      "parent_id": "1111"
    },
    {
      "area_id": "1116",
      "area_code": "341324",
      "area_name": "泗县",
      "level": "3",
      "city_code": "0557",
      "center": "117.910629,33.482982",
      "parent_id": "1111"
    },
    {
      "area_id": "1117",
      "area_code": "341500",
      "area_name": "六安市",
      "level": "2",
      "city_code": "0564",
      "center": "116.520139,31.735456",
      "parent_id": "1021"
    },
    {
      "area_id": "1118",
      "area_code": "341502",
      "area_name": "金安区",
      "level": "3",
      "city_code": "0564",
      "center": "116.539173,31.750119",
      "parent_id": "1117"
    },
    {
      "area_id": "1119",
      "area_code": "341503",
      "area_name": "裕安区",
      "level": "3",
      "city_code": "0564",
      "center": "116.479829,31.738183",
      "parent_id": "1117"
    },
    {
      "area_id": "1120",
      "area_code": "341504",
      "area_name": "叶集区",
      "level": "3",
      "city_code": "0564",
      "center": "115.925271,31.863693",
      "parent_id": "1117"
    },
    {
      "area_id": "1121",
      "area_code": "341522",
      "area_name": "霍邱县",
      "level": "3",
      "city_code": "0564",
      "center": "116.277911,32.353038",
      "parent_id": "1117"
    },
    {
      "area_id": "1122",
      "area_code": "341523",
      "area_name": "舒城县",
      "level": "3",
      "city_code": "0564",
      "center": "116.948736,31.462234",
      "parent_id": "1117"
    },
    {
      "area_id": "1123",
      "area_code": "341524",
      "area_name": "金寨县",
      "level": "3",
      "city_code": "0564",
      "center": "115.934366,31.72717",
      "parent_id": "1117"
    },
    {
      "area_id": "1124",
      "area_code": "341525",
      "area_name": "霍山县",
      "level": "3",
      "city_code": "0564",
      "center": "116.351892,31.410561",
      "parent_id": "1117"
    },
    {
      "area_id": "1125",
      "area_code": "341600",
      "area_name": "亳州市",
      "level": "2",
      "city_code": "0558",
      "center": "115.77867,33.844592",
      "parent_id": "1021"
    },
    {
      "area_id": "1126",
      "area_code": "341602",
      "area_name": "谯城区",
      "level": "3",
      "city_code": "0558",
      "center": "115.779025,33.876235",
      "parent_id": "1125"
    },
    {
      "area_id": "1127",
      "area_code": "341621",
      "area_name": "涡阳县",
      "level": "3",
      "city_code": "0558",
      "center": "116.215665,33.492921",
      "parent_id": "1125"
    },
    {
      "area_id": "1128",
      "area_code": "341622",
      "area_name": "蒙城县",
      "level": "3",
      "city_code": "0558",
      "center": "116.564247,33.26583",
      "parent_id": "1125"
    },
    {
      "area_id": "1129",
      "area_code": "341623",
      "area_name": "利辛县",
      "level": "3",
      "city_code": "0558",
      "center": "116.208564,33.144515",
      "parent_id": "1125"
    },
    {
      "area_id": "1130",
      "area_code": "341700",
      "area_name": "池州市",
      "level": "2",
      "city_code": "0566",
      "center": "117.491592,30.664779",
      "parent_id": "1021"
    },
    {
      "area_id": "1131",
      "area_code": "341702",
      "area_name": "贵池区",
      "level": "3",
      "city_code": "0566",
      "center": "117.567264,30.687219",
      "parent_id": "1130"
    },
    {
      "area_id": "1132",
      "area_code": "341721",
      "area_name": "东至县",
      "level": "3",
      "city_code": "0566",
      "center": "117.027618,30.111163",
      "parent_id": "1130"
    },
    {
      "area_id": "1133",
      "area_code": "341722",
      "area_name": "石台县",
      "level": "3",
      "city_code": "0566",
      "center": "117.486306,30.210313",
      "parent_id": "1130"
    },
    {
      "area_id": "1134",
      "area_code": "341723",
      "area_name": "青阳县",
      "level": "3",
      "city_code": "0566",
      "center": "117.84743,30.63923",
      "parent_id": "1130"
    },
    {
      "area_id": "1135",
      "area_code": "341800",
      "area_name": "宣城市",
      "level": "2",
      "city_code": "0563",
      "center": "118.75868,30.940195",
      "parent_id": "1021"
    },
    {
      "area_id": "1136",
      "area_code": "341802",
      "area_name": "宣州区",
      "level": "3",
      "city_code": "0563",
      "center": "118.785561,30.944076",
      "parent_id": "1135"
    },
    {
      "area_id": "1137",
      "area_code": "341821",
      "area_name": "郎溪县",
      "level": "3",
      "city_code": "0563",
      "center": "119.179656,31.126412",
      "parent_id": "1135"
    },
    {
      "area_id": "1138",
      "area_code": "341822",
      "area_name": "广德县",
      "level": "3",
      "city_code": "0563",
      "center": "119.420935,30.877555",
      "parent_id": "1135"
    },
    {
      "area_id": "1139",
      "area_code": "341823",
      "area_name": "泾县",
      "level": "3",
      "city_code": "0563",
      "center": "118.419859,30.688634",
      "parent_id": "1135"
    },
    {
      "area_id": "1140",
      "area_code": "341824",
      "area_name": "绩溪县",
      "level": "3",
      "city_code": "0563",
      "center": "118.578519,30.067533",
      "parent_id": "1135"
    },
    {
      "area_id": "1141",
      "area_code": "341825",
      "area_name": "旌德县",
      "level": "3",
      "city_code": "0563",
      "center": "118.549861,30.298142",
      "parent_id": "1135"
    },
    {
      "area_id": "1142",
      "area_code": "341881",
      "area_name": "宁国市",
      "level": "3",
      "city_code": "0563",
      "center": "118.983171,30.633882",
      "parent_id": "1135"
    },
    {
      "area_id": "1143",
      "area_code": "350000",
      "area_name": "福建省",
      "level": "1",
      "city_code": "[]",
      "center": "119.295143,26.100779",
      "parent_id": "0"
    },
    {
      "area_id": "1144",
      "area_code": "350100",
      "area_name": "福州市",
      "level": "2",
      "city_code": "0591",
      "center": "119.296389,26.074268",
      "parent_id": "1143"
    },
    {
      "area_id": "1145",
      "area_code": "350102",
      "area_name": "鼓楼区",
      "level": "3",
      "city_code": "0591",
      "center": "119.303917,26.081983",
      "parent_id": "1144"
    },
    {
      "area_id": "1146",
      "area_code": "350103",
      "area_name": "台江区",
      "level": "3",
      "city_code": "0591",
      "center": "119.314041,26.052843",
      "parent_id": "1144"
    },
    {
      "area_id": "1147",
      "area_code": "350104",
      "area_name": "仓山区",
      "level": "3",
      "city_code": "0591",
      "center": "119.273545,26.046743",
      "parent_id": "1144"
    },
    {
      "area_id": "1148",
      "area_code": "350105",
      "area_name": "马尾区",
      "level": "3",
      "city_code": "0591",
      "center": "119.455588,25.9895",
      "parent_id": "1144"
    },
    {
      "area_id": "1149",
      "area_code": "350111",
      "area_name": "晋安区",
      "level": "3",
      "city_code": "0591",
      "center": "119.328521,26.082107",
      "parent_id": "1144"
    },
    {
      "area_id": "1150",
      "area_code": "350121",
      "area_name": "闽侯县",
      "level": "3",
      "city_code": "0591",
      "center": "119.131724,26.150047",
      "parent_id": "1144"
    },
    {
      "area_id": "1151",
      "area_code": "350122",
      "area_name": "连江县",
      "level": "3",
      "city_code": "0591",
      "center": "119.539704,26.197364",
      "parent_id": "1144"
    },
    {
      "area_id": "1152",
      "area_code": "350123",
      "area_name": "罗源县",
      "level": "3",
      "city_code": "0591",
      "center": "119.549776,26.489558",
      "parent_id": "1144"
    },
    {
      "area_id": "1153",
      "area_code": "350124",
      "area_name": "闽清县",
      "level": "3",
      "city_code": "0591",
      "center": "118.863361,26.221197",
      "parent_id": "1144"
    },
    {
      "area_id": "1154",
      "area_code": "350125",
      "area_name": "永泰县",
      "level": "3",
      "city_code": "0591",
      "center": "118.932592,25.866694",
      "parent_id": "1144"
    },
    {
      "area_id": "1155",
      "area_code": "350128",
      "area_name": "平潭县",
      "level": "3",
      "city_code": "0591",
      "center": "119.790168,25.49872",
      "parent_id": "1144"
    },
    {
      "area_id": "1156",
      "area_code": "350181",
      "area_name": "福清市",
      "level": "3",
      "city_code": "0591",
      "center": "119.384201,25.72071",
      "parent_id": "1144"
    },
    {
      "area_id": "1157",
      "area_code": "350182",
      "area_name": "长乐市",
      "level": "3",
      "city_code": "0591",
      "center": "119.523266,25.962888",
      "parent_id": "1144"
    },
    {
      "area_id": "1158",
      "area_code": "350200",
      "area_name": "厦门市",
      "level": "2",
      "city_code": "0592",
      "center": "118.089204,24.479664",
      "parent_id": "1143"
    },
    {
      "area_id": "1159",
      "area_code": "350203",
      "area_name": "思明区",
      "level": "3",
      "city_code": "0592",
      "center": "118.082649,24.445484",
      "parent_id": "1158"
    },
    {
      "area_id": "1160",
      "area_code": "350205",
      "area_name": "海沧区",
      "level": "3",
      "city_code": "0592",
      "center": "118.032984,24.484685",
      "parent_id": "1158"
    },
    {
      "area_id": "1161",
      "area_code": "350206",
      "area_name": "湖里区",
      "level": "3",
      "city_code": "0592",
      "center": "118.146768,24.512904",
      "parent_id": "1158"
    },
    {
      "area_id": "1162",
      "area_code": "350211",
      "area_name": "集美区",
      "level": "3",
      "city_code": "0592",
      "center": "118.097337,24.575969",
      "parent_id": "1158"
    },
    {
      "area_id": "1163",
      "area_code": "350212",
      "area_name": "同安区",
      "level": "3",
      "city_code": "0592",
      "center": "118.152041,24.723234",
      "parent_id": "1158"
    },
    {
      "area_id": "1164",
      "area_code": "350213",
      "area_name": "翔安区",
      "level": "3",
      "city_code": "0592",
      "center": "118.248034,24.618543",
      "parent_id": "1158"
    },
    {
      "area_id": "1165",
      "area_code": "350300",
      "area_name": "莆田市",
      "level": "2",
      "city_code": "0594",
      "center": "119.007777,25.454084",
      "parent_id": "1143"
    },
    {
      "area_id": "1166",
      "area_code": "350302",
      "area_name": "城厢区",
      "level": "3",
      "city_code": "0594",
      "center": "118.993884,25.419319",
      "parent_id": "1165"
    },
    {
      "area_id": "1167",
      "area_code": "350303",
      "area_name": "涵江区",
      "level": "3",
      "city_code": "0594",
      "center": "119.116289,25.45872",
      "parent_id": "1165"
    },
    {
      "area_id": "1168",
      "area_code": "350304",
      "area_name": "荔城区",
      "level": "3",
      "city_code": "0594",
      "center": "119.015061,25.431941",
      "parent_id": "1165"
    },
    {
      "area_id": "1169",
      "area_code": "350305",
      "area_name": "秀屿区",
      "level": "3",
      "city_code": "0594",
      "center": "119.105494,25.31836",
      "parent_id": "1165"
    },
    {
      "area_id": "1170",
      "area_code": "350322",
      "area_name": "仙游县",
      "level": "3",
      "city_code": "0594",
      "center": "118.691637,25.362093",
      "parent_id": "1165"
    },
    {
      "area_id": "1171",
      "area_code": "350400",
      "area_name": "三明市",
      "level": "2",
      "city_code": "0598",
      "center": "117.638678,26.263406",
      "parent_id": "1143"
    },
    {
      "area_id": "1172",
      "area_code": "350402",
      "area_name": "梅列区",
      "level": "3",
      "city_code": "0598",
      "center": "117.645855,26.271711",
      "parent_id": "1171"
    },
    {
      "area_id": "1173",
      "area_code": "350403",
      "area_name": "三元区",
      "level": "3",
      "city_code": "0598",
      "center": "117.608044,26.234019",
      "parent_id": "1171"
    },
    {
      "area_id": "1174",
      "area_code": "350421",
      "area_name": "明溪县",
      "level": "3",
      "city_code": "0598",
      "center": "117.202226,26.355856",
      "parent_id": "1171"
    },
    {
      "area_id": "1175",
      "area_code": "350423",
      "area_name": "清流县",
      "level": "3",
      "city_code": "0598",
      "center": "116.816909,26.177796",
      "parent_id": "1171"
    },
    {
      "area_id": "1176",
      "area_code": "350424",
      "area_name": "宁化县",
      "level": "3",
      "city_code": "0598",
      "center": "116.654365,26.261754",
      "parent_id": "1171"
    },
    {
      "area_id": "1177",
      "area_code": "350425",
      "area_name": "大田县",
      "level": "3",
      "city_code": "0598",
      "center": "117.847115,25.692699",
      "parent_id": "1171"
    },
    {
      "area_id": "1178",
      "area_code": "350426",
      "area_name": "尤溪县",
      "level": "3",
      "city_code": "0598",
      "center": "118.190467,26.170171",
      "parent_id": "1171"
    },
    {
      "area_id": "1179",
      "area_code": "350427",
      "area_name": "沙县",
      "level": "3",
      "city_code": "0598",
      "center": "117.792396,26.397199",
      "parent_id": "1171"
    },
    {
      "area_id": "1180",
      "area_code": "350428",
      "area_name": "将乐县",
      "level": "3",
      "city_code": "0598",
      "center": "117.471372,26.728952",
      "parent_id": "1171"
    },
    {
      "area_id": "1181",
      "area_code": "350429",
      "area_name": "泰宁县",
      "level": "3",
      "city_code": "0598",
      "center": "117.17574,26.900259",
      "parent_id": "1171"
    },
    {
      "area_id": "1182",
      "area_code": "350430",
      "area_name": "建宁县",
      "level": "3",
      "city_code": "0598",
      "center": "116.848443,26.833588",
      "parent_id": "1171"
    },
    {
      "area_id": "1183",
      "area_code": "350481",
      "area_name": "永安市",
      "level": "3",
      "city_code": "0598",
      "center": "117.365052,25.941937",
      "parent_id": "1171"
    },
    {
      "area_id": "1184",
      "area_code": "350500",
      "area_name": "泉州市",
      "level": "2",
      "city_code": "0595",
      "center": "118.675676,24.874132",
      "parent_id": "1143"
    },
    {
      "area_id": "1185",
      "area_code": "350502",
      "area_name": "鲤城区",
      "level": "3",
      "city_code": "0595",
      "center": "118.587097,24.907424",
      "parent_id": "1184"
    },
    {
      "area_id": "1186",
      "area_code": "350503",
      "area_name": "丰泽区",
      "level": "3",
      "city_code": "0595",
      "center": "118.613172,24.891173",
      "parent_id": "1184"
    },
    {
      "area_id": "1187",
      "area_code": "350504",
      "area_name": "洛江区",
      "level": "3",
      "city_code": "0595",
      "center": "118.671193,24.939796",
      "parent_id": "1184"
    },
    {
      "area_id": "1188",
      "area_code": "350505",
      "area_name": "泉港区",
      "level": "3",
      "city_code": "0595",
      "center": "118.916309,25.119815",
      "parent_id": "1184"
    },
    {
      "area_id": "1189",
      "area_code": "350521",
      "area_name": "惠安县",
      "level": "3",
      "city_code": "0595",
      "center": "118.796607,25.030801",
      "parent_id": "1184"
    },
    {
      "area_id": "1190",
      "area_code": "350524",
      "area_name": "安溪县",
      "level": "3",
      "city_code": "0595",
      "center": "118.186288,25.055954",
      "parent_id": "1184"
    },
    {
      "area_id": "1191",
      "area_code": "350525",
      "area_name": "永春县",
      "level": "3",
      "city_code": "0595",
      "center": "118.294048,25.321565",
      "parent_id": "1184"
    },
    {
      "area_id": "1192",
      "area_code": "350526",
      "area_name": "德化县",
      "level": "3",
      "city_code": "0595",
      "center": "118.241094,25.491493",
      "parent_id": "1184"
    },
    {
      "area_id": "1193",
      "area_code": "350527",
      "area_name": "金门县",
      "level": "3",
      "city_code": "0595",
      "center": "118.323221,24.436417",
      "parent_id": "1184"
    },
    {
      "area_id": "1194",
      "area_code": "350581",
      "area_name": "石狮市",
      "level": "3",
      "city_code": "0595",
      "center": "118.648066,24.732204",
      "parent_id": "1184"
    },
    {
      "area_id": "1195",
      "area_code": "350582",
      "area_name": "晋江市",
      "level": "3",
      "city_code": "0595",
      "center": "118.551682,24.781636",
      "parent_id": "1184"
    },
    {
      "area_id": "1196",
      "area_code": "350583",
      "area_name": "南安市",
      "level": "3",
      "city_code": "0595",
      "center": "118.386279,24.960385",
      "parent_id": "1184"
    },
    {
      "area_id": "1197",
      "area_code": "350600",
      "area_name": "漳州市",
      "level": "2",
      "city_code": "0596",
      "center": "117.647093,24.513025",
      "parent_id": "1143"
    },
    {
      "area_id": "1198",
      "area_code": "350602",
      "area_name": "芗城区",
      "level": "3",
      "city_code": "0596",
      "center": "117.653968,24.510787",
      "parent_id": "1197"
    },
    {
      "area_id": "1199",
      "area_code": "350603",
      "area_name": "龙文区",
      "level": "3",
      "city_code": "0596",
      "center": "117.709754,24.503113",
      "parent_id": "1197"
    },
    {
      "area_id": "1200",
      "area_code": "350622",
      "area_name": "云霄县",
      "level": "3",
      "city_code": "0596",
      "center": "117.339573,23.957936",
      "parent_id": "1197"
    },
    {
      "area_id": "1201",
      "area_code": "350623",
      "area_name": "漳浦县",
      "level": "3",
      "city_code": "0596",
      "center": "117.613808,24.117102",
      "parent_id": "1197"
    },
    {
      "area_id": "1202",
      "area_code": "350624",
      "area_name": "诏安县",
      "level": "3",
      "city_code": "0596",
      "center": "117.175184,23.711579",
      "parent_id": "1197"
    },
    {
      "area_id": "1203",
      "area_code": "350625",
      "area_name": "长泰县",
      "level": "3",
      "city_code": "0596",
      "center": "117.759153,24.625449",
      "parent_id": "1197"
    },
    {
      "area_id": "1204",
      "area_code": "350626",
      "area_name": "东山县",
      "level": "3",
      "city_code": "0596",
      "center": "117.430061,23.701262",
      "parent_id": "1197"
    },
    {
      "area_id": "1205",
      "area_code": "350627",
      "area_name": "南靖县",
      "level": "3",
      "city_code": "0596",
      "center": "117.35732,24.514654",
      "parent_id": "1197"
    },
    {
      "area_id": "1206",
      "area_code": "350628",
      "area_name": "平和县",
      "level": "3",
      "city_code": "0596",
      "center": "117.315017,24.363508",
      "parent_id": "1197"
    },
    {
      "area_id": "1207",
      "area_code": "350629",
      "area_name": "华安县",
      "level": "3",
      "city_code": "0596",
      "center": "117.534103,25.004425",
      "parent_id": "1197"
    },
    {
      "area_id": "1208",
      "area_code": "350681",
      "area_name": "龙海市",
      "level": "3",
      "city_code": "0596",
      "center": "117.818197,24.446706",
      "parent_id": "1197"
    },
    {
      "area_id": "1209",
      "area_code": "350700",
      "area_name": "南平市",
      "level": "2",
      "city_code": "0599",
      "center": "118.17771,26.641774",
      "parent_id": "1143"
    },
    {
      "area_id": "1210",
      "area_code": "350702",
      "area_name": "延平区",
      "level": "3",
      "city_code": "0599",
      "center": "118.182036,26.637438",
      "parent_id": "1209"
    },
    {
      "area_id": "1211",
      "area_code": "350703",
      "area_name": "建阳区",
      "level": "3",
      "city_code": "0599",
      "center": "118.120464,27.331876",
      "parent_id": "1209"
    },
    {
      "area_id": "1212",
      "area_code": "350721",
      "area_name": "顺昌县",
      "level": "3",
      "city_code": "0599",
      "center": "117.810357,26.793288",
      "parent_id": "1209"
    },
    {
      "area_id": "1213",
      "area_code": "350722",
      "area_name": "浦城县",
      "level": "3",
      "city_code": "0599",
      "center": "118.541256,27.917263",
      "parent_id": "1209"
    },
    {
      "area_id": "1214",
      "area_code": "350723",
      "area_name": "光泽县",
      "level": "3",
      "city_code": "0599",
      "center": "117.334106,27.540987",
      "parent_id": "1209"
    },
    {
      "area_id": "1215",
      "area_code": "350724",
      "area_name": "松溪县",
      "level": "3",
      "city_code": "0599",
      "center": "118.785468,27.526232",
      "parent_id": "1209"
    },
    {
      "area_id": "1216",
      "area_code": "350725",
      "area_name": "政和县",
      "level": "3",
      "city_code": "0599",
      "center": "118.857642,27.366104",
      "parent_id": "1209"
    },
    {
      "area_id": "1217",
      "area_code": "350781",
      "area_name": "邵武市",
      "level": "3",
      "city_code": "0599",
      "center": "117.492533,27.340326",
      "parent_id": "1209"
    },
    {
      "area_id": "1218",
      "area_code": "350782",
      "area_name": "武夷山市",
      "level": "3",
      "city_code": "0599",
      "center": "118.035309,27.756647",
      "parent_id": "1209"
    },
    {
      "area_id": "1219",
      "area_code": "350783",
      "area_name": "建瓯市",
      "level": "3",
      "city_code": "0599",
      "center": "118.304966,27.022774",
      "parent_id": "1209"
    },
    {
      "area_id": "1220",
      "area_code": "350800",
      "area_name": "龙岩市",
      "level": "2",
      "city_code": "0597",
      "center": "117.017295,25.075119",
      "parent_id": "1143"
    },
    {
      "area_id": "1221",
      "area_code": "350802",
      "area_name": "新罗区",
      "level": "3",
      "city_code": "0597",
      "center": "117.037155,25.098312",
      "parent_id": "1220"
    },
    {
      "area_id": "1222",
      "area_code": "350803",
      "area_name": "永定区",
      "level": "3",
      "city_code": "0597",
      "center": "116.732091,24.723961",
      "parent_id": "1220"
    },
    {
      "area_id": "1223",
      "area_code": "350821",
      "area_name": "长汀县",
      "level": "3",
      "city_code": "0597",
      "center": "116.357581,25.833531",
      "parent_id": "1220"
    },
    {
      "area_id": "1224",
      "area_code": "350823",
      "area_name": "上杭县",
      "level": "3",
      "city_code": "0597",
      "center": "116.420098,25.049518",
      "parent_id": "1220"
    },
    {
      "area_id": "1225",
      "area_code": "350824",
      "area_name": "武平县",
      "level": "3",
      "city_code": "0597",
      "center": "116.100414,25.095386",
      "parent_id": "1220"
    },
    {
      "area_id": "1226",
      "area_code": "350825",
      "area_name": "连城县",
      "level": "3",
      "city_code": "0597",
      "center": "116.754472,25.710538",
      "parent_id": "1220"
    },
    {
      "area_id": "1227",
      "area_code": "350881",
      "area_name": "漳平市",
      "level": "3",
      "city_code": "0597",
      "center": "117.419998,25.290184",
      "parent_id": "1220"
    },
    {
      "area_id": "1228",
      "area_code": "350900",
      "area_name": "宁德市",
      "level": "2",
      "city_code": "0593",
      "center": "119.547932,26.665617",
      "parent_id": "1143"
    },
    {
      "area_id": "1229",
      "area_code": "350902",
      "area_name": "蕉城区",
      "level": "3",
      "city_code": "0593",
      "center": "119.526299,26.66061",
      "parent_id": "1228"
    },
    {
      "area_id": "1230",
      "area_code": "350921",
      "area_name": "霞浦县",
      "level": "3",
      "city_code": "0593",
      "center": "120.005146,26.885703",
      "parent_id": "1228"
    },
    {
      "area_id": "1231",
      "area_code": "350922",
      "area_name": "古田县",
      "level": "3",
      "city_code": "0593",
      "center": "118.746284,26.577837",
      "parent_id": "1228"
    },
    {
      "area_id": "1232",
      "area_code": "350923",
      "area_name": "屏南县",
      "level": "3",
      "city_code": "0593",
      "center": "118.985895,26.908276",
      "parent_id": "1228"
    },
    {
      "area_id": "1233",
      "area_code": "350924",
      "area_name": "寿宁县",
      "level": "3",
      "city_code": "0593",
      "center": "119.514986,27.454479",
      "parent_id": "1228"
    },
    {
      "area_id": "1234",
      "area_code": "350925",
      "area_name": "周宁县",
      "level": "3",
      "city_code": "0593",
      "center": "119.339025,27.104591",
      "parent_id": "1228"
    },
    {
      "area_id": "1235",
      "area_code": "350926",
      "area_name": "柘荣县",
      "level": "3",
      "city_code": "0593",
      "center": "119.900609,27.233933",
      "parent_id": "1228"
    },
    {
      "area_id": "1236",
      "area_code": "350981",
      "area_name": "福安市",
      "level": "3",
      "city_code": "0593",
      "center": "119.64785,27.08834",
      "parent_id": "1228"
    },
    {
      "area_id": "1237",
      "area_code": "350982",
      "area_name": "福鼎市",
      "level": "3",
      "city_code": "0593",
      "center": "120.216977,27.324479",
      "parent_id": "1228"
    },
    {
      "area_id": "1238",
      "area_code": "360000",
      "area_name": "江西省",
      "level": "1",
      "city_code": "[]",
      "center": "115.81635,28.63666",
      "parent_id": "0"
    },
    {
      "area_id": "1239",
      "area_code": "360100",
      "area_name": "南昌市",
      "level": "2",
      "city_code": "0791",
      "center": "115.858198,28.682892",
      "parent_id": "1238"
    },
    {
      "area_id": "1240",
      "area_code": "360102",
      "area_name": "东湖区",
      "level": "3",
      "city_code": "0791",
      "center": "115.903526,28.698731",
      "parent_id": "1239"
    },
    {
      "area_id": "1241",
      "area_code": "360103",
      "area_name": "西湖区",
      "level": "3",
      "city_code": "0791",
      "center": "115.877233,28.657595",
      "parent_id": "1239"
    },
    {
      "area_id": "1242",
      "area_code": "360104",
      "area_name": "青云谱区",
      "level": "3",
      "city_code": "0791",
      "center": "115.925749,28.621169",
      "parent_id": "1239"
    },
    {
      "area_id": "1243",
      "area_code": "360105",
      "area_name": "湾里区",
      "level": "3",
      "city_code": "0791",
      "center": "115.730847,28.714796",
      "parent_id": "1239"
    },
    {
      "area_id": "1244",
      "area_code": "360111",
      "area_name": "青山湖区",
      "level": "3",
      "city_code": "0791",
      "center": "115.962144,28.682984",
      "parent_id": "1239"
    },
    {
      "area_id": "1245",
      "area_code": "360112",
      "area_name": "新建区",
      "level": "3",
      "city_code": "0791",
      "center": "115.815277,28.692864",
      "parent_id": "1239"
    },
    {
      "area_id": "1246",
      "area_code": "360121",
      "area_name": "南昌县",
      "level": "3",
      "city_code": "0791",
      "center": "115.933742,28.558296",
      "parent_id": "1239"
    },
    {
      "area_id": "1247",
      "area_code": "360123",
      "area_name": "安义县",
      "level": "3",
      "city_code": "0791",
      "center": "115.548658,28.846",
      "parent_id": "1239"
    },
    {
      "area_id": "1248",
      "area_code": "360124",
      "area_name": "进贤县",
      "level": "3",
      "city_code": "0791",
      "center": "116.241288,28.377343",
      "parent_id": "1239"
    },
    {
      "area_id": "1249",
      "area_code": "360200",
      "area_name": "景德镇市",
      "level": "2",
      "city_code": "0798",
      "center": "117.178222,29.268945",
      "parent_id": "1238"
    },
    {
      "area_id": "1250",
      "area_code": "360202",
      "area_name": "昌江区",
      "level": "3",
      "city_code": "0798",
      "center": "117.18363,29.273565",
      "parent_id": "1249"
    },
    {
      "area_id": "1251",
      "area_code": "360203",
      "area_name": "珠山区",
      "level": "3",
      "city_code": "0798",
      "center": "117.202919,29.299938",
      "parent_id": "1249"
    },
    {
      "area_id": "1252",
      "area_code": "360222",
      "area_name": "浮梁县",
      "level": "3",
      "city_code": "0798",
      "center": "117.215066,29.352253",
      "parent_id": "1249"
    },
    {
      "area_id": "1253",
      "area_code": "360281",
      "area_name": "乐平市",
      "level": "3",
      "city_code": "0798",
      "center": "117.151796,28.97844",
      "parent_id": "1249"
    },
    {
      "area_id": "1254",
      "area_code": "360300",
      "area_name": "萍乡市",
      "level": "2",
      "city_code": "0799",
      "center": "113.887083,27.658373",
      "parent_id": "1238"
    },
    {
      "area_id": "1255",
      "area_code": "360302",
      "area_name": "安源区",
      "level": "3",
      "city_code": "0799",
      "center": "113.870704,27.61511",
      "parent_id": "1254"
    },
    {
      "area_id": "1256",
      "area_code": "360313",
      "area_name": "湘东区",
      "level": "3",
      "city_code": "0799",
      "center": "113.733047,27.640075",
      "parent_id": "1254"
    },
    {
      "area_id": "1257",
      "area_code": "360321",
      "area_name": "莲花县",
      "level": "3",
      "city_code": "0799",
      "center": "113.961488,27.127664",
      "parent_id": "1254"
    },
    {
      "area_id": "1258",
      "area_code": "360322",
      "area_name": "上栗县",
      "level": "3",
      "city_code": "0799",
      "center": "113.795311,27.880301",
      "parent_id": "1254"
    },
    {
      "area_id": "1259",
      "area_code": "360323",
      "area_name": "芦溪县",
      "level": "3",
      "city_code": "0799",
      "center": "114.029827,27.630806",
      "parent_id": "1254"
    },
    {
      "area_id": "1260",
      "area_code": "360400",
      "area_name": "九江市",
      "level": "2",
      "city_code": "0792",
      "center": "115.952914,29.662117",
      "parent_id": "1238"
    },
    {
      "area_id": "1261",
      "area_code": "360402",
      "area_name": "濂溪区",
      "level": "3",
      "city_code": "0792",
      "center": "115.992842,29.668064",
      "parent_id": "1260"
    },
    {
      "area_id": "1262",
      "area_code": "360403",
      "area_name": "浔阳区",
      "level": "3",
      "city_code": "0792",
      "center": "115.990301,29.727593",
      "parent_id": "1260"
    },
    {
      "area_id": "1263",
      "area_code": "360421",
      "area_name": "九江县",
      "level": "3",
      "city_code": "0792",
      "center": "115.911323,29.608431",
      "parent_id": "1260"
    },
    {
      "area_id": "1264",
      "area_code": "360423",
      "area_name": "武宁县",
      "level": "3",
      "city_code": "0792",
      "center": "115.092757,29.246591",
      "parent_id": "1260"
    },
    {
      "area_id": "1265",
      "area_code": "360424",
      "area_name": "修水县",
      "level": "3",
      "city_code": "0792",
      "center": "114.546836,29.025726",
      "parent_id": "1260"
    },
    {
      "area_id": "1266",
      "area_code": "360425",
      "area_name": "永修县",
      "level": "3",
      "city_code": "0792",
      "center": "115.831956,29.011871",
      "parent_id": "1260"
    },
    {
      "area_id": "1267",
      "area_code": "360426",
      "area_name": "德安县",
      "level": "3",
      "city_code": "0792",
      "center": "115.767447,29.298696",
      "parent_id": "1260"
    },
    {
      "area_id": "1268",
      "area_code": "360483",
      "area_name": "庐山市",
      "level": "3",
      "city_code": "0792",
      "center": "116.04506,29.448128",
      "parent_id": "1260"
    },
    {
      "area_id": "1269",
      "area_code": "360428",
      "area_name": "都昌县",
      "level": "3",
      "city_code": "0792",
      "center": "116.203979,29.273239",
      "parent_id": "1260"
    },
    {
      "area_id": "1270",
      "area_code": "360429",
      "area_name": "湖口县",
      "level": "3",
      "city_code": "0792",
      "center": "116.251947,29.731101",
      "parent_id": "1260"
    },
    {
      "area_id": "1271",
      "area_code": "360430",
      "area_name": "彭泽县",
      "level": "3",
      "city_code": "0792",
      "center": "116.56438,29.876991",
      "parent_id": "1260"
    },
    {
      "area_id": "1272",
      "area_code": "360481",
      "area_name": "瑞昌市",
      "level": "3",
      "city_code": "0792",
      "center": "115.681335,29.675834",
      "parent_id": "1260"
    },
    {
      "area_id": "1273",
      "area_code": "360482",
      "area_name": "共青城市",
      "level": "3",
      "city_code": "0792",
      "center": "115.808844,29.248316",
      "parent_id": "1260"
    },
    {
      "area_id": "1274",
      "area_code": "360500",
      "area_name": "新余市",
      "level": "2",
      "city_code": "0790",
      "center": "114.917346,27.817808",
      "parent_id": "1238"
    },
    {
      "area_id": "1275",
      "area_code": "360502",
      "area_name": "渝水区",
      "level": "3",
      "city_code": "0790",
      "center": "114.944549,27.800148",
      "parent_id": "1274"
    },
    {
      "area_id": "1276",
      "area_code": "360521",
      "area_name": "分宜县",
      "level": "3",
      "city_code": "0790",
      "center": "114.692049,27.814757",
      "parent_id": "1274"
    },
    {
      "area_id": "1277",
      "area_code": "360600",
      "area_name": "鹰潭市",
      "level": "2",
      "city_code": "0701",
      "center": "117.042173,28.272537",
      "parent_id": "1238"
    },
    {
      "area_id": "1278",
      "area_code": "360602",
      "area_name": "月湖区",
      "level": "3",
      "city_code": "0701",
      "center": "117.102475,28.267018",
      "parent_id": "1277"
    },
    {
      "area_id": "1279",
      "area_code": "360622",
      "area_name": "余江县",
      "level": "3",
      "city_code": "0701",
      "center": "116.85926,28.198652",
      "parent_id": "1277"
    },
    {
      "area_id": "1280",
      "area_code": "360681",
      "area_name": "贵溪市",
      "level": "3",
      "city_code": "0701",
      "center": "117.245497,28.292519",
      "parent_id": "1277"
    },
    {
      "area_id": "1281",
      "area_code": "360700",
      "area_name": "赣州市",
      "level": "2",
      "city_code": "0797",
      "center": "114.933546,25.830694",
      "parent_id": "1238"
    },
    {
      "area_id": "1282",
      "area_code": "360702",
      "area_name": "章贡区",
      "level": "3",
      "city_code": "0797",
      "center": "114.921171,25.817816",
      "parent_id": "1281"
    },
    {
      "area_id": "1283",
      "area_code": "360703",
      "area_name": "南康区",
      "level": "3",
      "city_code": "0797",
      "center": "114.765412,25.66145",
      "parent_id": "1281"
    },
    {
      "area_id": "1284",
      "area_code": "360704",
      "area_name": "赣县区",
      "level": "3",
      "city_code": "0797",
      "center": "115.011561,25.86069",
      "parent_id": "1281"
    },
    {
      "area_id": "1285",
      "area_code": "360722",
      "area_name": "信丰县",
      "level": "3",
      "city_code": "0797",
      "center": "114.922922,25.386379",
      "parent_id": "1281"
    },
    {
      "area_id": "1286",
      "area_code": "360723",
      "area_name": "大余县",
      "level": "3",
      "city_code": "0797",
      "center": "114.362112,25.401313",
      "parent_id": "1281"
    },
    {
      "area_id": "1287",
      "area_code": "360724",
      "area_name": "上犹县",
      "level": "3",
      "city_code": "0797",
      "center": "114.551138,25.785172",
      "parent_id": "1281"
    },
    {
      "area_id": "1288",
      "area_code": "360725",
      "area_name": "崇义县",
      "level": "3",
      "city_code": "0797",
      "center": "114.308267,25.681784",
      "parent_id": "1281"
    },
    {
      "area_id": "1289",
      "area_code": "360726",
      "area_name": "安远县",
      "level": "3",
      "city_code": "0797",
      "center": "115.393922,25.136927",
      "parent_id": "1281"
    },
    {
      "area_id": "1290",
      "area_code": "360727",
      "area_name": "龙南县",
      "level": "3",
      "city_code": "0797",
      "center": "114.789873,24.911069",
      "parent_id": "1281"
    },
    {
      "area_id": "1291",
      "area_code": "360728",
      "area_name": "定南县",
      "level": "3",
      "city_code": "0797",
      "center": "115.027845,24.78441",
      "parent_id": "1281"
    },
    {
      "area_id": "1292",
      "area_code": "360729",
      "area_name": "全南县",
      "level": "3",
      "city_code": "0797",
      "center": "114.530125,24.742403",
      "parent_id": "1281"
    },
    {
      "area_id": "1293",
      "area_code": "360730",
      "area_name": "宁都县",
      "level": "3",
      "city_code": "0797",
      "center": "116.009472,26.470116",
      "parent_id": "1281"
    },
    {
      "area_id": "1294",
      "area_code": "360731",
      "area_name": "于都县",
      "level": "3",
      "city_code": "0797",
      "center": "115.415508,25.952068",
      "parent_id": "1281"
    },
    {
      "area_id": "1295",
      "area_code": "360732",
      "area_name": "兴国县",
      "level": "3",
      "city_code": "0797",
      "center": "115.363189,26.337937",
      "parent_id": "1281"
    },
    {
      "area_id": "1296",
      "area_code": "360733",
      "area_name": "会昌县",
      "level": "3",
      "city_code": "0797",
      "center": "115.786056,25.600272",
      "parent_id": "1281"
    },
    {
      "area_id": "1297",
      "area_code": "360734",
      "area_name": "寻乌县",
      "level": "3",
      "city_code": "0797",
      "center": "115.637933,24.969167",
      "parent_id": "1281"
    },
    {
      "area_id": "1298",
      "area_code": "360735",
      "area_name": "石城县",
      "level": "3",
      "city_code": "0797",
      "center": "116.346995,26.314775",
      "parent_id": "1281"
    },
    {
      "area_id": "1299",
      "area_code": "360781",
      "area_name": "瑞金市",
      "level": "3",
      "city_code": "0797",
      "center": "116.027134,25.885555",
      "parent_id": "1281"
    },
    {
      "area_id": "1300",
      "area_code": "360800",
      "area_name": "吉安市",
      "level": "2",
      "city_code": "0796",
      "center": "114.966567,27.090763",
      "parent_id": "1238"
    },
    {
      "area_id": "1301",
      "area_code": "360802",
      "area_name": "吉州区",
      "level": "3",
      "city_code": "0796",
      "center": "114.994763,27.143801",
      "parent_id": "1300"
    },
    {
      "area_id": "1302",
      "area_code": "360803",
      "area_name": "青原区",
      "level": "3",
      "city_code": "0796",
      "center": "115.014811,27.081977",
      "parent_id": "1300"
    },
    {
      "area_id": "1303",
      "area_code": "360821",
      "area_name": "吉安县",
      "level": "3",
      "city_code": "0796",
      "center": "114.907875,27.039787",
      "parent_id": "1300"
    },
    {
      "area_id": "1304",
      "area_code": "360822",
      "area_name": "吉水县",
      "level": "3",
      "city_code": "0796",
      "center": "115.135507,27.229632",
      "parent_id": "1300"
    },
    {
      "area_id": "1305",
      "area_code": "360823",
      "area_name": "峡江县",
      "level": "3",
      "city_code": "0796",
      "center": "115.316566,27.582901",
      "parent_id": "1300"
    },
    {
      "area_id": "1306",
      "area_code": "360824",
      "area_name": "新干县",
      "level": "3",
      "city_code": "0796",
      "center": "115.387052,27.740191",
      "parent_id": "1300"
    },
    {
      "area_id": "1307",
      "area_code": "360825",
      "area_name": "永丰县",
      "level": "3",
      "city_code": "0796",
      "center": "115.421344,27.316939",
      "parent_id": "1300"
    },
    {
      "area_id": "1308",
      "area_code": "360826",
      "area_name": "泰和县",
      "level": "3",
      "city_code": "0796",
      "center": "114.92299,26.801628",
      "parent_id": "1300"
    },
    {
      "area_id": "1309",
      "area_code": "360827",
      "area_name": "遂川县",
      "level": "3",
      "city_code": "0796",
      "center": "114.520537,26.313737",
      "parent_id": "1300"
    },
    {
      "area_id": "1310",
      "area_code": "360828",
      "area_name": "万安县",
      "level": "3",
      "city_code": "0796",
      "center": "114.759364,26.456553",
      "parent_id": "1300"
    },
    {
      "area_id": "1311",
      "area_code": "360829",
      "area_name": "安福县",
      "level": "3",
      "city_code": "0796",
      "center": "114.619893,27.392873",
      "parent_id": "1300"
    },
    {
      "area_id": "1312",
      "area_code": "360830",
      "area_name": "永新县",
      "level": "3",
      "city_code": "0796",
      "center": "114.243072,26.944962",
      "parent_id": "1300"
    },
    {
      "area_id": "1313",
      "area_code": "360881",
      "area_name": "井冈山市",
      "level": "3",
      "city_code": "0796",
      "center": "114.289228,26.748081",
      "parent_id": "1300"
    },
    {
      "area_id": "1314",
      "area_code": "360900",
      "area_name": "宜春市",
      "level": "2",
      "city_code": "0795",
      "center": "114.416785,27.815743",
      "parent_id": "1238"
    },
    {
      "area_id": "1315",
      "area_code": "360902",
      "area_name": "袁州区",
      "level": "3",
      "city_code": "0795",
      "center": "114.427858,27.797091",
      "parent_id": "1314"
    },
    {
      "area_id": "1316",
      "area_code": "360921",
      "area_name": "奉新县",
      "level": "3",
      "city_code": "0795",
      "center": "115.400491,28.688423",
      "parent_id": "1314"
    },
    {
      "area_id": "1317",
      "area_code": "360922",
      "area_name": "万载县",
      "level": "3",
      "city_code": "0795",
      "center": "114.444854,28.105689",
      "parent_id": "1314"
    },
    {
      "area_id": "1318",
      "area_code": "360923",
      "area_name": "上高县",
      "level": "3",
      "city_code": "0795",
      "center": "114.947683,28.238061",
      "parent_id": "1314"
    },
    {
      "area_id": "1319",
      "area_code": "360924",
      "area_name": "宜丰县",
      "level": "3",
      "city_code": "0795",
      "center": "114.802852,28.394565",
      "parent_id": "1314"
    },
    {
      "area_id": "1320",
      "area_code": "360925",
      "area_name": "靖安县",
      "level": "3",
      "city_code": "0795",
      "center": "115.362628,28.861478",
      "parent_id": "1314"
    },
    {
      "area_id": "1321",
      "area_code": "360926",
      "area_name": "铜鼓县",
      "level": "3",
      "city_code": "0795",
      "center": "114.371172,28.520769",
      "parent_id": "1314"
    },
    {
      "area_id": "1322",
      "area_code": "360981",
      "area_name": "丰城市",
      "level": "3",
      "city_code": "0795",
      "center": "115.771093,28.159141",
      "parent_id": "1314"
    },
    {
      "area_id": "1323",
      "area_code": "360982",
      "area_name": "樟树市",
      "level": "3",
      "city_code": "0795",
      "center": "115.546152,28.055853",
      "parent_id": "1314"
    },
    {
      "area_id": "1324",
      "area_code": "360983",
      "area_name": "高安市",
      "level": "3",
      "city_code": "0795",
      "center": "115.360619,28.441152",
      "parent_id": "1314"
    },
    {
      "area_id": "1325",
      "area_code": "361000",
      "area_name": "抚州市",
      "level": "2",
      "city_code": "0794",
      "center": "116.358181,27.949217",
      "parent_id": "1238"
    },
    {
      "area_id": "1326",
      "area_code": "361002",
      "area_name": "临川区",
      "level": "3",
      "city_code": "0794",
      "center": "116.312166,27.934572",
      "parent_id": "1325"
    },
    {
      "area_id": "1327",
      "area_code": "361021",
      "area_name": "南城县",
      "level": "3",
      "city_code": "0794",
      "center": "116.63704,27.569678",
      "parent_id": "1325"
    },
    {
      "area_id": "1328",
      "area_code": "361022",
      "area_name": "黎川县",
      "level": "3",
      "city_code": "0794",
      "center": "116.907681,27.282333",
      "parent_id": "1325"
    },
    {
      "area_id": "1329",
      "area_code": "361023",
      "area_name": "南丰县",
      "level": "3",
      "city_code": "0794",
      "center": "116.525725,27.218444",
      "parent_id": "1325"
    },
    {
      "area_id": "1330",
      "area_code": "361024",
      "area_name": "崇仁县",
      "level": "3",
      "city_code": "0794",
      "center": "116.07626,27.754466",
      "parent_id": "1325"
    },
    {
      "area_id": "1331",
      "area_code": "361025",
      "area_name": "乐安县",
      "level": "3",
      "city_code": "0794",
      "center": "115.83048,27.428765",
      "parent_id": "1325"
    },
    {
      "area_id": "1332",
      "area_code": "361026",
      "area_name": "宜黄县",
      "level": "3",
      "city_code": "0794",
      "center": "116.236201,27.554886",
      "parent_id": "1325"
    },
    {
      "area_id": "1333",
      "area_code": "361027",
      "area_name": "金溪县",
      "level": "3",
      "city_code": "0794",
      "center": "116.755058,27.918959",
      "parent_id": "1325"
    },
    {
      "area_id": "1334",
      "area_code": "361028",
      "area_name": "资溪县",
      "level": "3",
      "city_code": "0794",
      "center": "117.060263,27.706101",
      "parent_id": "1325"
    },
    {
      "area_id": "1335",
      "area_code": "361003",
      "area_name": "东乡区",
      "level": "3",
      "city_code": "0794",
      "center": "116.603559,28.247696",
      "parent_id": "1325"
    },
    {
      "area_id": "1336",
      "area_code": "361030",
      "area_name": "广昌县",
      "level": "3",
      "city_code": "0794",
      "center": "116.335686,26.843684",
      "parent_id": "1325"
    },
    {
      "area_id": "1337",
      "area_code": "361100",
      "area_name": "上饶市",
      "level": "2",
      "city_code": "0793",
      "center": "117.943433,28.454863",
      "parent_id": "1238"
    },
    {
      "area_id": "1338",
      "area_code": "361102",
      "area_name": "信州区",
      "level": "3",
      "city_code": "0793",
      "center": "117.966268,28.431006",
      "parent_id": "1337"
    },
    {
      "area_id": "1339",
      "area_code": "361103",
      "area_name": "广丰区",
      "level": "3",
      "city_code": "0793",
      "center": "118.19124,28.436285",
      "parent_id": "1337"
    },
    {
      "area_id": "1340",
      "area_code": "361121",
      "area_name": "上饶县",
      "level": "3",
      "city_code": "0793",
      "center": "117.907849,28.448982",
      "parent_id": "1337"
    },
    {
      "area_id": "1341",
      "area_code": "361123",
      "area_name": "玉山县",
      "level": "3",
      "city_code": "0793",
      "center": "118.244769,28.682309",
      "parent_id": "1337"
    },
    {
      "area_id": "1342",
      "area_code": "361124",
      "area_name": "铅山县",
      "level": "3",
      "city_code": "0793",
      "center": "117.709659,28.315664",
      "parent_id": "1337"
    },
    {
      "area_id": "1343",
      "area_code": "361125",
      "area_name": "横峰县",
      "level": "3",
      "city_code": "0793",
      "center": "117.596452,28.407117",
      "parent_id": "1337"
    },
    {
      "area_id": "1344",
      "area_code": "361126",
      "area_name": "弋阳县",
      "level": "3",
      "city_code": "0793",
      "center": "117.449588,28.378044",
      "parent_id": "1337"
    },
    {
      "area_id": "1345",
      "area_code": "361127",
      "area_name": "余干县",
      "level": "3",
      "city_code": "0793",
      "center": "116.695646,28.702302",
      "parent_id": "1337"
    },
    {
      "area_id": "1346",
      "area_code": "361128",
      "area_name": "鄱阳县",
      "level": "3",
      "city_code": "0793",
      "center": "116.70359,29.004847",
      "parent_id": "1337"
    },
    {
      "area_id": "1347",
      "area_code": "361129",
      "area_name": "万年县",
      "level": "3",
      "city_code": "0793",
      "center": "117.058445,28.694582",
      "parent_id": "1337"
    },
    {
      "area_id": "1348",
      "area_code": "361130",
      "area_name": "婺源县",
      "level": "3",
      "city_code": "0793",
      "center": "117.861797,29.248085",
      "parent_id": "1337"
    },
    {
      "area_id": "1349",
      "area_code": "361181",
      "area_name": "德兴市",
      "level": "3",
      "city_code": "0793",
      "center": "117.578713,28.946464",
      "parent_id": "1337"
    },
    {
      "area_id": "1350",
      "area_code": "370000",
      "area_name": "山东省",
      "level": "1",
      "city_code": "[]",
      "center": "117.019915,36.671156",
      "parent_id": "0"
    },
    {
      "area_id": "1351",
      "area_code": "370100",
      "area_name": "济南市",
      "level": "2",
      "city_code": "0531",
      "center": "117.120098,36.6512",
      "parent_id": "1350"
    },
    {
      "area_id": "1352",
      "area_code": "370102",
      "area_name": "历下区",
      "level": "3",
      "city_code": "0531",
      "center": "117.076441,36.666465",
      "parent_id": "1351"
    },
    {
      "area_id": "1353",
      "area_code": "370103",
      "area_name": "市中区",
      "level": "3",
      "city_code": "0531",
      "center": "116.997845,36.651335",
      "parent_id": "1351"
    },
    {
      "area_id": "1354",
      "area_code": "370104",
      "area_name": "槐荫区",
      "level": "3",
      "city_code": "0531",
      "center": "116.901224,36.651441",
      "parent_id": "1351"
    },
    {
      "area_id": "1355",
      "area_code": "370105",
      "area_name": "天桥区",
      "level": "3",
      "city_code": "0531",
      "center": "116.987153,36.678589",
      "parent_id": "1351"
    },
    {
      "area_id": "1356",
      "area_code": "370112",
      "area_name": "历城区",
      "level": "3",
      "city_code": "0531",
      "center": "117.06523,36.680259",
      "parent_id": "1351"
    },
    {
      "area_id": "1357",
      "area_code": "370113",
      "area_name": "长清区",
      "level": "3",
      "city_code": "0531",
      "center": "116.751843,36.55371",
      "parent_id": "1351"
    },
    {
      "area_id": "1358",
      "area_code": "370124",
      "area_name": "平阴县",
      "level": "3",
      "city_code": "0531",
      "center": "116.456006,36.289251",
      "parent_id": "1351"
    },
    {
      "area_id": "1359",
      "area_code": "370125",
      "area_name": "济阳县",
      "level": "3",
      "city_code": "0531",
      "center": "117.173524,36.978537",
      "parent_id": "1351"
    },
    {
      "area_id": "1360",
      "area_code": "370126",
      "area_name": "商河县",
      "level": "3",
      "city_code": "0531",
      "center": "117.157232,37.309041",
      "parent_id": "1351"
    },
    {
      "area_id": "1361",
      "area_code": "370114",
      "area_name": "章丘区",
      "level": "3",
      "city_code": "0531",
      "center": "117.526228,36.681258",
      "parent_id": "1351"
    },
    {
      "area_id": "1362",
      "area_code": "370200",
      "area_name": "青岛市",
      "level": "2",
      "city_code": "0532",
      "center": "120.382621,36.067131",
      "parent_id": "1350"
    },
    {
      "area_id": "1363",
      "area_code": "370202",
      "area_name": "市南区",
      "level": "3",
      "city_code": "0532",
      "center": "120.412392,36.075651",
      "parent_id": "1362"
    },
    {
      "area_id": "1364",
      "area_code": "370203",
      "area_name": "市北区",
      "level": "3",
      "city_code": "0532",
      "center": "120.374701,36.0876",
      "parent_id": "1362"
    },
    {
      "area_id": "1365",
      "area_code": "370211",
      "area_name": "黄岛区",
      "level": "3",
      "city_code": "0532",
      "center": "120.198055,35.960933",
      "parent_id": "1362"
    },
    {
      "area_id": "1366",
      "area_code": "370212",
      "area_name": "崂山区",
      "level": "3",
      "city_code": "0532",
      "center": "120.468956,36.107538",
      "parent_id": "1362"
    },
    {
      "area_id": "1367",
      "area_code": "370213",
      "area_name": "李沧区",
      "level": "3",
      "city_code": "0532",
      "center": "120.432922,36.145519",
      "parent_id": "1362"
    },
    {
      "area_id": "1368",
      "area_code": "370214",
      "area_name": "城阳区",
      "level": "3",
      "city_code": "0532",
      "center": "120.396256,36.307559",
      "parent_id": "1362"
    },
    {
      "area_id": "1369",
      "area_code": "370281",
      "area_name": "胶州市",
      "level": "3",
      "city_code": "0532",
      "center": "120.033382,36.26468",
      "parent_id": "1362"
    },
    {
      "area_id": "1370",
      "area_code": "370282",
      "area_name": "即墨市",
      "level": "3",
      "city_code": "0532",
      "center": "120.447158,36.389408",
      "parent_id": "1362"
    },
    {
      "area_id": "1371",
      "area_code": "370283",
      "area_name": "平度市",
      "level": "3",
      "city_code": "0532",
      "center": "119.98842,36.776357",
      "parent_id": "1362"
    },
    {
      "area_id": "1372",
      "area_code": "370285",
      "area_name": "莱西市",
      "level": "3",
      "city_code": "0532",
      "center": "120.51769,36.889084",
      "parent_id": "1362"
    },
    {
      "area_id": "1373",
      "area_code": "370300",
      "area_name": "淄博市",
      "level": "2",
      "city_code": "0533",
      "center": "118.055019,36.813546",
      "parent_id": "1350"
    },
    {
      "area_id": "1374",
      "area_code": "370302",
      "area_name": "淄川区",
      "level": "3",
      "city_code": "0533",
      "center": "117.966723,36.643452",
      "parent_id": "1373"
    },
    {
      "area_id": "1375",
      "area_code": "370303",
      "area_name": "张店区",
      "level": "3",
      "city_code": "0533",
      "center": "118.017938,36.806669",
      "parent_id": "1373"
    },
    {
      "area_id": "1376",
      "area_code": "370304",
      "area_name": "博山区",
      "level": "3",
      "city_code": "0533",
      "center": "117.861851,36.494701",
      "parent_id": "1373"
    },
    {
      "area_id": "1377",
      "area_code": "370305",
      "area_name": "临淄区",
      "level": "3",
      "city_code": "0533",
      "center": "118.309118,36.826981",
      "parent_id": "1373"
    },
    {
      "area_id": "1378",
      "area_code": "370306",
      "area_name": "周村区",
      "level": "3",
      "city_code": "0533",
      "center": "117.869886,36.803072",
      "parent_id": "1373"
    },
    {
      "area_id": "1379",
      "area_code": "370321",
      "area_name": "桓台县",
      "level": "3",
      "city_code": "0533",
      "center": "118.097922,36.959804",
      "parent_id": "1373"
    },
    {
      "area_id": "1380",
      "area_code": "370322",
      "area_name": "高青县",
      "level": "3",
      "city_code": "0533",
      "center": "117.826924,37.170979",
      "parent_id": "1373"
    },
    {
      "area_id": "1381",
      "area_code": "370323",
      "area_name": "沂源县",
      "level": "3",
      "city_code": "0533",
      "center": "118.170855,36.185038",
      "parent_id": "1373"
    },
    {
      "area_id": "1382",
      "area_code": "370400",
      "area_name": "枣庄市",
      "level": "2",
      "city_code": "0632",
      "center": "117.323725,34.810488",
      "parent_id": "1350"
    },
    {
      "area_id": "1383",
      "area_code": "370402",
      "area_name": "市中区",
      "level": "3",
      "city_code": "0632",
      "center": "117.556139,34.863554",
      "parent_id": "1382"
    },
    {
      "area_id": "1384",
      "area_code": "370403",
      "area_name": "薛城区",
      "level": "3",
      "city_code": "0632",
      "center": "117.263164,34.795062",
      "parent_id": "1382"
    },
    {
      "area_id": "1385",
      "area_code": "370404",
      "area_name": "峄城区",
      "level": "3",
      "city_code": "0632",
      "center": "117.590816,34.773263",
      "parent_id": "1382"
    },
    {
      "area_id": "1386",
      "area_code": "370405",
      "area_name": "台儿庄区",
      "level": "3",
      "city_code": "0632",
      "center": "117.734414,34.56244",
      "parent_id": "1382"
    },
    {
      "area_id": "1387",
      "area_code": "370406",
      "area_name": "山亭区",
      "level": "3",
      "city_code": "0632",
      "center": "117.461517,35.099528",
      "parent_id": "1382"
    },
    {
      "area_id": "1388",
      "area_code": "370481",
      "area_name": "滕州市",
      "level": "3",
      "city_code": "0632",
      "center": "117.165824,35.114155",
      "parent_id": "1382"
    },
    {
      "area_id": "1389",
      "area_code": "370500",
      "area_name": "东营市",
      "level": "2",
      "city_code": "0546",
      "center": "118.674614,37.433963",
      "parent_id": "1350"
    },
    {
      "area_id": "1390",
      "area_code": "370502",
      "area_name": "东营区",
      "level": "3",
      "city_code": "0546",
      "center": "118.582184,37.448964",
      "parent_id": "1389"
    },
    {
      "area_id": "1391",
      "area_code": "370503",
      "area_name": "河口区",
      "level": "3",
      "city_code": "0546",
      "center": "118.525543,37.886162",
      "parent_id": "1389"
    },
    {
      "area_id": "1392",
      "area_code": "370505",
      "area_name": "垦利区",
      "level": "3",
      "city_code": "0546",
      "center": "118.575228,37.573054",
      "parent_id": "1389"
    },
    {
      "area_id": "1393",
      "area_code": "370522",
      "area_name": "利津县",
      "level": "3",
      "city_code": "0546",
      "center": "118.255287,37.490328",
      "parent_id": "1389"
    },
    {
      "area_id": "1394",
      "area_code": "370523",
      "area_name": "广饶县",
      "level": "3",
      "city_code": "0546",
      "center": "118.407107,37.053555",
      "parent_id": "1389"
    },
    {
      "area_id": "1395",
      "area_code": "370600",
      "area_name": "烟台市",
      "level": "2",
      "city_code": "0535",
      "center": "121.447852,37.464539",
      "parent_id": "1350"
    },
    {
      "area_id": "1396",
      "area_code": "370602",
      "area_name": "芝罘区",
      "level": "3",
      "city_code": "0535",
      "center": "121.400445,37.541475",
      "parent_id": "1395"
    },
    {
      "area_id": "1397",
      "area_code": "370611",
      "area_name": "福山区",
      "level": "3",
      "city_code": "0535",
      "center": "121.267741,37.498246",
      "parent_id": "1395"
    },
    {
      "area_id": "1398",
      "area_code": "370612",
      "area_name": "牟平区",
      "level": "3",
      "city_code": "0535",
      "center": "121.600455,37.387061",
      "parent_id": "1395"
    },
    {
      "area_id": "1399",
      "area_code": "370613",
      "area_name": "莱山区",
      "level": "3",
      "city_code": "0535",
      "center": "121.445301,37.511291",
      "parent_id": "1395"
    },
    {
      "area_id": "1400",
      "area_code": "370634",
      "area_name": "长岛县",
      "level": "3",
      "city_code": "0535",
      "center": "120.73658,37.921368",
      "parent_id": "1395"
    },
    {
      "area_id": "1401",
      "area_code": "370681",
      "area_name": "龙口市",
      "level": "3",
      "city_code": "0535",
      "center": "120.477813,37.646107",
      "parent_id": "1395"
    },
    {
      "area_id": "1402",
      "area_code": "370682",
      "area_name": "莱阳市",
      "level": "3",
      "city_code": "0535",
      "center": "120.711672,36.978941",
      "parent_id": "1395"
    },
    {
      "area_id": "1403",
      "area_code": "370683",
      "area_name": "莱州市",
      "level": "3",
      "city_code": "0535",
      "center": "119.942274,37.177129",
      "parent_id": "1395"
    },
    {
      "area_id": "1404",
      "area_code": "370684",
      "area_name": "蓬莱市",
      "level": "3",
      "city_code": "0535",
      "center": "120.758848,37.810661",
      "parent_id": "1395"
    },
    {
      "area_id": "1405",
      "area_code": "370685",
      "area_name": "招远市",
      "level": "3",
      "city_code": "0535",
      "center": "120.434071,37.355469",
      "parent_id": "1395"
    },
    {
      "area_id": "1406",
      "area_code": "370686",
      "area_name": "栖霞市",
      "level": "3",
      "city_code": "0535",
      "center": "120.849675,37.335123",
      "parent_id": "1395"
    },
    {
      "area_id": "1407",
      "area_code": "370687",
      "area_name": "海阳市",
      "level": "3",
      "city_code": "0535",
      "center": "121.173793,36.688",
      "parent_id": "1395"
    },
    {
      "area_id": "1408",
      "area_code": "370700",
      "area_name": "潍坊市",
      "level": "2",
      "city_code": "0536",
      "center": "119.161748,36.706962",
      "parent_id": "1350"
    },
    {
      "area_id": "1409",
      "area_code": "370702",
      "area_name": "潍城区",
      "level": "3",
      "city_code": "0536",
      "center": "119.024835,36.7281",
      "parent_id": "1408"
    },
    {
      "area_id": "1410",
      "area_code": "370703",
      "area_name": "寒亭区",
      "level": "3",
      "city_code": "0536",
      "center": "119.211157,36.755623",
      "parent_id": "1408"
    },
    {
      "area_id": "1411",
      "area_code": "370704",
      "area_name": "坊子区",
      "level": "3",
      "city_code": "0536",
      "center": "119.166485,36.654448",
      "parent_id": "1408"
    },
    {
      "area_id": "1412",
      "area_code": "370705",
      "area_name": "奎文区",
      "level": "3",
      "city_code": "0536",
      "center": "119.132482,36.70759",
      "parent_id": "1408"
    },
    {
      "area_id": "1413",
      "area_code": "370724",
      "area_name": "临朐县",
      "level": "3",
      "city_code": "0536",
      "center": "118.542982,36.512506",
      "parent_id": "1408"
    },
    {
      "area_id": "1414",
      "area_code": "370725",
      "area_name": "昌乐县",
      "level": "3",
      "city_code": "0536",
      "center": "118.829992,36.706964",
      "parent_id": "1408"
    },
    {
      "area_id": "1415",
      "area_code": "370781",
      "area_name": "青州市",
      "level": "3",
      "city_code": "0536",
      "center": "118.479654,36.684789",
      "parent_id": "1408"
    },
    {
      "area_id": "1416",
      "area_code": "370782",
      "area_name": "诸城市",
      "level": "3",
      "city_code": "0536",
      "center": "119.410103,35.995654",
      "parent_id": "1408"
    },
    {
      "area_id": "1417",
      "area_code": "370783",
      "area_name": "寿光市",
      "level": "3",
      "city_code": "0536",
      "center": "118.790739,36.85576",
      "parent_id": "1408"
    },
    {
      "area_id": "1418",
      "area_code": "370784",
      "area_name": "安丘市",
      "level": "3",
      "city_code": "0536",
      "center": "119.218978,36.478493",
      "parent_id": "1408"
    },
    {
      "area_id": "1419",
      "area_code": "370785",
      "area_name": "高密市",
      "level": "3",
      "city_code": "0536",
      "center": "119.755597,36.382594",
      "parent_id": "1408"
    },
    {
      "area_id": "1420",
      "area_code": "370786",
      "area_name": "昌邑市",
      "level": "3",
      "city_code": "0536",
      "center": "119.403069,36.843319",
      "parent_id": "1408"
    },
    {
      "area_id": "1421",
      "area_code": "370800",
      "area_name": "济宁市",
      "level": "2",
      "city_code": "0537",
      "center": "116.587282,35.414982",
      "parent_id": "1350"
    },
    {
      "area_id": "1422",
      "area_code": "370811",
      "area_name": "任城区",
      "level": "3",
      "city_code": "0537",
      "center": "116.606103,35.444028",
      "parent_id": "1421"
    },
    {
      "area_id": "1423",
      "area_code": "370812",
      "area_name": "兖州区",
      "level": "3",
      "city_code": "0537",
      "center": "116.783833,35.553144",
      "parent_id": "1421"
    },
    {
      "area_id": "1424",
      "area_code": "370826",
      "area_name": "微山县",
      "level": "3",
      "city_code": "0537",
      "center": "117.128827,34.806554",
      "parent_id": "1421"
    },
    {
      "area_id": "1425",
      "area_code": "370827",
      "area_name": "鱼台县",
      "level": "3",
      "city_code": "0537",
      "center": "116.650608,35.012749",
      "parent_id": "1421"
    },
    {
      "area_id": "1426",
      "area_code": "370828",
      "area_name": "金乡县",
      "level": "3",
      "city_code": "0537",
      "center": "116.311532,35.066619",
      "parent_id": "1421"
    },
    {
      "area_id": "1427",
      "area_code": "370829",
      "area_name": "嘉祥县",
      "level": "3",
      "city_code": "0537",
      "center": "116.342449,35.408824",
      "parent_id": "1421"
    },
    {
      "area_id": "1428",
      "area_code": "370830",
      "area_name": "汶上县",
      "level": "3",
      "city_code": "0537",
      "center": "116.49708,35.712298",
      "parent_id": "1421"
    },
    {
      "area_id": "1429",
      "area_code": "370831",
      "area_name": "泗水县",
      "level": "3",
      "city_code": "0537",
      "center": "117.251195,35.664323",
      "parent_id": "1421"
    },
    {
      "area_id": "1430",
      "area_code": "370832",
      "area_name": "梁山县",
      "level": "3",
      "city_code": "0537",
      "center": "116.096044,35.802306",
      "parent_id": "1421"
    },
    {
      "area_id": "1431",
      "area_code": "370881",
      "area_name": "曲阜市",
      "level": "3",
      "city_code": "0537",
      "center": "116.986526,35.581108",
      "parent_id": "1421"
    },
    {
      "area_id": "1432",
      "area_code": "370883",
      "area_name": "邹城市",
      "level": "3",
      "city_code": "0537",
      "center": "117.007453,35.40268",
      "parent_id": "1421"
    },
    {
      "area_id": "1433",
      "area_code": "370900",
      "area_name": "泰安市",
      "level": "2",
      "city_code": "0538",
      "center": "117.087614,36.200252",
      "parent_id": "1350"
    },
    {
      "area_id": "1434",
      "area_code": "370902",
      "area_name": "泰山区",
      "level": "3",
      "city_code": "0538",
      "center": "117.135354,36.192083",
      "parent_id": "1433"
    },
    {
      "area_id": "1435",
      "area_code": "370911",
      "area_name": "岱岳区",
      "level": "3",
      "city_code": "0538",
      "center": "117.041581,36.187989",
      "parent_id": "1433"
    },
    {
      "area_id": "1436",
      "area_code": "370921",
      "area_name": "宁阳县",
      "level": "3",
      "city_code": "0538",
      "center": "116.805796,35.758786",
      "parent_id": "1433"
    },
    {
      "area_id": "1437",
      "area_code": "370923",
      "area_name": "东平县",
      "level": "3",
      "city_code": "0538",
      "center": "116.470304,35.937102",
      "parent_id": "1433"
    },
    {
      "area_id": "1438",
      "area_code": "370982",
      "area_name": "新泰市",
      "level": "3",
      "city_code": "0538",
      "center": "117.767952,35.909032",
      "parent_id": "1433"
    },
    {
      "area_id": "1439",
      "area_code": "370983",
      "area_name": "肥城市",
      "level": "3",
      "city_code": "0538",
      "center": "116.768358,36.182571",
      "parent_id": "1433"
    },
    {
      "area_id": "1440",
      "area_code": "371000",
      "area_name": "威海市",
      "level": "2",
      "city_code": "0631",
      "center": "122.120282,37.513412",
      "parent_id": "1350"
    },
    {
      "area_id": "1441",
      "area_code": "371002",
      "area_name": "环翠区",
      "level": "3",
      "city_code": "0631",
      "center": "122.123443,37.50199",
      "parent_id": "1440"
    },
    {
      "area_id": "1442",
      "area_code": "371003",
      "area_name": "文登区",
      "level": "3",
      "city_code": "0631",
      "center": "122.05767,37.193735",
      "parent_id": "1440"
    },
    {
      "area_id": "1443",
      "area_code": "371082",
      "area_name": "荣成市",
      "level": "3",
      "city_code": "0631",
      "center": "122.486657,37.16516",
      "parent_id": "1440"
    },
    {
      "area_id": "1444",
      "area_code": "371083",
      "area_name": "乳山市",
      "level": "3",
      "city_code": "0631",
      "center": "121.539764,36.919816",
      "parent_id": "1440"
    },
    {
      "area_id": "1445",
      "area_code": "371100",
      "area_name": "日照市",
      "level": "2",
      "city_code": "0633",
      "center": "119.526925,35.416734",
      "parent_id": "1350"
    },
    {
      "area_id": "1446",
      "area_code": "371102",
      "area_name": "东港区",
      "level": "3",
      "city_code": "0633",
      "center": "119.462267,35.42548",
      "parent_id": "1445"
    },
    {
      "area_id": "1447",
      "area_code": "371103",
      "area_name": "岚山区",
      "level": "3",
      "city_code": "0633",
      "center": "119.318928,35.121884",
      "parent_id": "1445"
    },
    {
      "area_id": "1448",
      "area_code": "371121",
      "area_name": "五莲县",
      "level": "3",
      "city_code": "0633",
      "center": "119.213619,35.760228",
      "parent_id": "1445"
    },
    {
      "area_id": "1449",
      "area_code": "371122",
      "area_name": "莒县",
      "level": "3",
      "city_code": "0633",
      "center": "118.837063,35.579868",
      "parent_id": "1445"
    },
    {
      "area_id": "1450",
      "area_code": "371200",
      "area_name": "莱芜市",
      "level": "2",
      "city_code": "0634",
      "center": "117.676723,36.213813",
      "parent_id": "1350"
    },
    {
      "area_id": "1451",
      "area_code": "371202",
      "area_name": "莱城区",
      "level": "3",
      "city_code": "0634",
      "center": "117.659884,36.203179",
      "parent_id": "1450"
    },
    {
      "area_id": "1452",
      "area_code": "371203",
      "area_name": "钢城区",
      "level": "3",
      "city_code": "0634",
      "center": "117.811354,36.058572",
      "parent_id": "1450"
    },
    {
      "area_id": "1453",
      "area_code": "371300",
      "area_name": "临沂市",
      "level": "2",
      "city_code": "0539",
      "center": "118.356414,35.104673",
      "parent_id": "1350"
    },
    {
      "area_id": "1454",
      "area_code": "371302",
      "area_name": "兰山区",
      "level": "3",
      "city_code": "0539",
      "center": "118.347842,35.051804",
      "parent_id": "1453"
    },
    {
      "area_id": "1455",
      "area_code": "371311",
      "area_name": "罗庄区",
      "level": "3",
      "city_code": "0539",
      "center": "118.284786,34.996741",
      "parent_id": "1453"
    },
    {
      "area_id": "1456",
      "area_code": "371312",
      "area_name": "河东区",
      "level": "3",
      "city_code": "0539",
      "center": "118.402893,35.089916",
      "parent_id": "1453"
    },
    {
      "area_id": "1457",
      "area_code": "371321",
      "area_name": "沂南县",
      "level": "3",
      "city_code": "0539",
      "center": "118.465221,35.550217",
      "parent_id": "1453"
    },
    {
      "area_id": "1458",
      "area_code": "371322",
      "area_name": "郯城县",
      "level": "3",
      "city_code": "0539",
      "center": "118.367215,34.613586",
      "parent_id": "1453"
    },
    {
      "area_id": "1459",
      "area_code": "371323",
      "area_name": "沂水县",
      "level": "3",
      "city_code": "0539",
      "center": "118.627917,35.79045",
      "parent_id": "1453"
    },
    {
      "area_id": "1460",
      "area_code": "371324",
      "area_name": "兰陵县",
      "level": "3",
      "city_code": "0539",
      "center": "118.07065,34.857149",
      "parent_id": "1453"
    },
    {
      "area_id": "1461",
      "area_code": "371325",
      "area_name": "费县",
      "level": "3",
      "city_code": "0539",
      "center": "117.977325,35.26596",
      "parent_id": "1453"
    },
    {
      "area_id": "1462",
      "area_code": "371326",
      "area_name": "平邑县",
      "level": "3",
      "city_code": "0539",
      "center": "117.640352,35.505943",
      "parent_id": "1453"
    },
    {
      "area_id": "1463",
      "area_code": "371327",
      "area_name": "莒南县",
      "level": "3",
      "city_code": "0539",
      "center": "118.835163,35.174846",
      "parent_id": "1453"
    },
    {
      "area_id": "1464",
      "area_code": "371328",
      "area_name": "蒙阴县",
      "level": "3",
      "city_code": "0539",
      "center": "117.953621,35.719396",
      "parent_id": "1453"
    },
    {
      "area_id": "1465",
      "area_code": "371329",
      "area_name": "临沭县",
      "level": "3",
      "city_code": "0539",
      "center": "118.650781,34.919851",
      "parent_id": "1453"
    },
    {
      "area_id": "1466",
      "area_code": "371400",
      "area_name": "德州市",
      "level": "2",
      "city_code": "0534",
      "center": "116.359381,37.436657",
      "parent_id": "1350"
    },
    {
      "area_id": "1467",
      "area_code": "371402",
      "area_name": "德城区",
      "level": "3",
      "city_code": "0534",
      "center": "116.29947,37.450804",
      "parent_id": "1466"
    },
    {
      "area_id": "1468",
      "area_code": "371403",
      "area_name": "陵城区",
      "level": "3",
      "city_code": "0534",
      "center": "116.576092,37.335794",
      "parent_id": "1466"
    },
    {
      "area_id": "1469",
      "area_code": "371422",
      "area_name": "宁津县",
      "level": "3",
      "city_code": "0534",
      "center": "116.800306,37.652189",
      "parent_id": "1466"
    },
    {
      "area_id": "1470",
      "area_code": "371423",
      "area_name": "庆云县",
      "level": "3",
      "city_code": "0534",
      "center": "117.385256,37.775349",
      "parent_id": "1466"
    },
    {
      "area_id": "1471",
      "area_code": "371424",
      "area_name": "临邑县",
      "level": "3",
      "city_code": "0534",
      "center": "116.866799,37.189797",
      "parent_id": "1466"
    },
    {
      "area_id": "1472",
      "area_code": "371425",
      "area_name": "齐河县",
      "level": "3",
      "city_code": "0534",
      "center": "116.762893,36.784158",
      "parent_id": "1466"
    },
    {
      "area_id": "1473",
      "area_code": "371426",
      "area_name": "平原县",
      "level": "3",
      "city_code": "0534",
      "center": "116.434032,37.165323",
      "parent_id": "1466"
    },
    {
      "area_id": "1474",
      "area_code": "371427",
      "area_name": "夏津县",
      "level": "3",
      "city_code": "0534",
      "center": "116.001726,36.948371",
      "parent_id": "1466"
    },
    {
      "area_id": "1475",
      "area_code": "371428",
      "area_name": "武城县",
      "level": "3",
      "city_code": "0534",
      "center": "116.069302,37.213311",
      "parent_id": "1466"
    },
    {
      "area_id": "1476",
      "area_code": "371481",
      "area_name": "乐陵市",
      "level": "3",
      "city_code": "0534",
      "center": "117.231934,37.729907",
      "parent_id": "1466"
    },
    {
      "area_id": "1477",
      "area_code": "371482",
      "area_name": "禹城市",
      "level": "3",
      "city_code": "0534",
      "center": "116.638327,36.933812",
      "parent_id": "1466"
    },
    {
      "area_id": "1478",
      "area_code": "371500",
      "area_name": "聊城市",
      "level": "2",
      "city_code": "0635",
      "center": "115.985389,36.456684",
      "parent_id": "1350"
    },
    {
      "area_id": "1479",
      "area_code": "371502",
      "area_name": "东昌府区",
      "level": "3",
      "city_code": "0635",
      "center": "115.988349,36.434669",
      "parent_id": "1478"
    },
    {
      "area_id": "1480",
      "area_code": "371521",
      "area_name": "阳谷县",
      "level": "3",
      "city_code": "0635",
      "center": "115.79182,36.114392",
      "parent_id": "1478"
    },
    {
      "area_id": "1481",
      "area_code": "371522",
      "area_name": "莘县",
      "level": "3",
      "city_code": "0635",
      "center": "115.671191,36.233598",
      "parent_id": "1478"
    },
    {
      "area_id": "1482",
      "area_code": "371523",
      "area_name": "茌平县",
      "level": "3",
      "city_code": "0635",
      "center": "116.25527,36.580688",
      "parent_id": "1478"
    },
    {
      "area_id": "1483",
      "area_code": "371524",
      "area_name": "东阿县",
      "level": "3",
      "city_code": "0635",
      "center": "116.247579,36.334917",
      "parent_id": "1478"
    },
    {
      "area_id": "1484",
      "area_code": "371525",
      "area_name": "冠县",
      "level": "3",
      "city_code": "0635",
      "center": "115.442739,36.484009",
      "parent_id": "1478"
    },
    {
      "area_id": "1485",
      "area_code": "371526",
      "area_name": "高唐县",
      "level": "3",
      "city_code": "0635",
      "center": "116.23016,36.846762",
      "parent_id": "1478"
    },
    {
      "area_id": "1486",
      "area_code": "371581",
      "area_name": "临清市",
      "level": "3",
      "city_code": "0635",
      "center": "115.704881,36.838277",
      "parent_id": "1478"
    },
    {
      "area_id": "1487",
      "area_code": "371600",
      "area_name": "滨州市",
      "level": "2",
      "city_code": "0543",
      "center": "117.970699,37.38198",
      "parent_id": "1350"
    },
    {
      "area_id": "1488",
      "area_code": "371602",
      "area_name": "滨城区",
      "level": "3",
      "city_code": "0543",
      "center": "118.019326,37.430724",
      "parent_id": "1487"
    },
    {
      "area_id": "1489",
      "area_code": "371603",
      "area_name": "沾化区",
      "level": "3",
      "city_code": "0543",
      "center": "118.098902,37.69926",
      "parent_id": "1487"
    },
    {
      "area_id": "1490",
      "area_code": "371621",
      "area_name": "惠民县",
      "level": "3",
      "city_code": "0543",
      "center": "117.509921,37.489877",
      "parent_id": "1487"
    },
    {
      "area_id": "1491",
      "area_code": "371622",
      "area_name": "阳信县",
      "level": "3",
      "city_code": "0543",
      "center": "117.603339,37.632433",
      "parent_id": "1487"
    },
    {
      "area_id": "1492",
      "area_code": "371623",
      "area_name": "无棣县",
      "level": "3",
      "city_code": "0543",
      "center": "117.625696,37.77026",
      "parent_id": "1487"
    },
    {
      "area_id": "1493",
      "area_code": "371625",
      "area_name": "博兴县",
      "level": "3",
      "city_code": "0543",
      "center": "118.110709,37.15457",
      "parent_id": "1487"
    },
    {
      "area_id": "1494",
      "area_code": "371626",
      "area_name": "邹平县",
      "level": "3",
      "city_code": "0543",
      "center": "117.743109,36.862989",
      "parent_id": "1487"
    },
    {
      "area_id": "1495",
      "area_code": "371700",
      "area_name": "菏泽市",
      "level": "2",
      "city_code": "0530",
      "center": "115.480656,35.23375",
      "parent_id": "1350"
    },
    {
      "area_id": "1496",
      "area_code": "371702",
      "area_name": "牡丹区",
      "level": "3",
      "city_code": "0530",
      "center": "115.417826,35.252512",
      "parent_id": "1495"
    },
    {
      "area_id": "1497",
      "area_code": "371703",
      "area_name": "定陶区",
      "level": "3",
      "city_code": "0530",
      "center": "115.57302,35.070995",
      "parent_id": "1495"
    },
    {
      "area_id": "1498",
      "area_code": "371721",
      "area_name": "曹县",
      "level": "3",
      "city_code": "0530",
      "center": "115.542328,34.825508",
      "parent_id": "1495"
    },
    {
      "area_id": "1499",
      "area_code": "371722",
      "area_name": "单县",
      "level": "3",
      "city_code": "0530",
      "center": "116.107428,34.778808",
      "parent_id": "1495"
    },
    {
      "area_id": "1500",
      "area_code": "371723",
      "area_name": "成武县",
      "level": "3",
      "city_code": "0530",
      "center": "115.889764,34.952459",
      "parent_id": "1495"
    },
    {
      "area_id": "1501",
      "area_code": "371724",
      "area_name": "巨野县",
      "level": "3",
      "city_code": "0530",
      "center": "116.062394,35.388925",
      "parent_id": "1495"
    },
    {
      "area_id": "1502",
      "area_code": "371725",
      "area_name": "郓城县",
      "level": "3",
      "city_code": "0530",
      "center": "115.9389,35.575135",
      "parent_id": "1495"
    },
    {
      "area_id": "1503",
      "area_code": "371726",
      "area_name": "鄄城县",
      "level": "3",
      "city_code": "0530",
      "center": "115.510192,35.563408",
      "parent_id": "1495"
    },
    {
      "area_id": "1504",
      "area_code": "371728",
      "area_name": "东明县",
      "level": "3",
      "city_code": "0530",
      "center": "115.107404,35.276162",
      "parent_id": "1495"
    },
    {
      "area_id": "1505",
      "area_code": "410000",
      "area_name": "河南省",
      "level": "1",
      "city_code": "[]",
      "center": "113.753394,34.765869",
      "parent_id": "0"
    },
    {
      "area_id": "1506",
      "area_code": "410100",
      "area_name": "郑州市",
      "level": "2",
      "city_code": "0371",
      "center": "113.625328,34.746611",
      "parent_id": "1505"
    },
    {
      "area_id": "1507",
      "area_code": "410102",
      "area_name": "中原区",
      "level": "3",
      "city_code": "0371",
      "center": "113.613337,34.748256",
      "parent_id": "1506"
    },
    {
      "area_id": "1508",
      "area_code": "410103",
      "area_name": "二七区",
      "level": "3",
      "city_code": "0371",
      "center": "113.640211,34.724114",
      "parent_id": "1506"
    },
    {
      "area_id": "1509",
      "area_code": "410104",
      "area_name": "管城回族区",
      "level": "3",
      "city_code": "0371",
      "center": "113.6775,34.75429",
      "parent_id": "1506"
    },
    {
      "area_id": "1510",
      "area_code": "410105",
      "area_name": "金水区",
      "level": "3",
      "city_code": "0371",
      "center": "113.660617,34.800004",
      "parent_id": "1506"
    },
    {
      "area_id": "1511",
      "area_code": "410106",
      "area_name": "上街区",
      "level": "3",
      "city_code": "0371",
      "center": "113.30893,34.802752",
      "parent_id": "1506"
    },
    {
      "area_id": "1512",
      "area_code": "410108",
      "area_name": "惠济区",
      "level": "3",
      "city_code": "0371",
      "center": "113.6169,34.867457",
      "parent_id": "1506"
    },
    {
      "area_id": "1513",
      "area_code": "410122",
      "area_name": "中牟县",
      "level": "3",
      "city_code": "0371",
      "center": "113.976253,34.718936",
      "parent_id": "1506"
    },
    {
      "area_id": "1514",
      "area_code": "410181",
      "area_name": "巩义市",
      "level": "3",
      "city_code": "0371",
      "center": "113.022406,34.7481",
      "parent_id": "1506"
    },
    {
      "area_id": "1515",
      "area_code": "410182",
      "area_name": "荥阳市",
      "level": "3",
      "city_code": "0371",
      "center": "113.38324,34.786948",
      "parent_id": "1506"
    },
    {
      "area_id": "1516",
      "area_code": "410183",
      "area_name": "新密市",
      "level": "3",
      "city_code": "0371",
      "center": "113.391087,34.539376",
      "parent_id": "1506"
    },
    {
      "area_id": "1517",
      "area_code": "410184",
      "area_name": "新郑市",
      "level": "3",
      "city_code": "0371",
      "center": "113.740662,34.395949",
      "parent_id": "1506"
    },
    {
      "area_id": "1518",
      "area_code": "410185",
      "area_name": "登封市",
      "level": "3",
      "city_code": "0371",
      "center": "113.050581,34.454443",
      "parent_id": "1506"
    },
    {
      "area_id": "1519",
      "area_code": "410200",
      "area_name": "开封市",
      "level": "2",
      "city_code": "0378",
      "center": "114.307677,34.797966",
      "parent_id": "1505"
    },
    {
      "area_id": "1520",
      "area_code": "410202",
      "area_name": "龙亭区",
      "level": "3",
      "city_code": "0378",
      "center": "114.356076,34.815565",
      "parent_id": "1519"
    },
    {
      "area_id": "1521",
      "area_code": "410203",
      "area_name": "顺河回族区",
      "level": "3",
      "city_code": "0378",
      "center": "114.364875,34.800458",
      "parent_id": "1519"
    },
    {
      "area_id": "1522",
      "area_code": "410204",
      "area_name": "鼓楼区",
      "level": "3",
      "city_code": "0378",
      "center": "114.348306,34.78856",
      "parent_id": "1519"
    },
    {
      "area_id": "1523",
      "area_code": "410205",
      "area_name": "禹王台区",
      "level": "3",
      "city_code": "0378",
      "center": "114.34817,34.777104",
      "parent_id": "1519"
    },
    {
      "area_id": "1524",
      "area_code": "410212",
      "area_name": "祥符区",
      "level": "3",
      "city_code": "0378",
      "center": "114.441285,34.756916",
      "parent_id": "1519"
    },
    {
      "area_id": "1525",
      "area_code": "410221",
      "area_name": "杞县",
      "level": "3",
      "city_code": "0378",
      "center": "114.783139,34.549174",
      "parent_id": "1519"
    },
    {
      "area_id": "1526",
      "area_code": "410222",
      "area_name": "通许县",
      "level": "3",
      "city_code": "0378",
      "center": "114.467467,34.480433",
      "parent_id": "1519"
    },
    {
      "area_id": "1527",
      "area_code": "410223",
      "area_name": "尉氏县",
      "level": "3",
      "city_code": "0378",
      "center": "114.193081,34.411494",
      "parent_id": "1519"
    },
    {
      "area_id": "1528",
      "area_code": "410225",
      "area_name": "兰考县",
      "level": "3",
      "city_code": "0378",
      "center": "114.821348,34.822211",
      "parent_id": "1519"
    },
    {
      "area_id": "1529",
      "area_code": "410300",
      "area_name": "洛阳市",
      "level": "2",
      "city_code": "0379",
      "center": "112.453926,34.620202",
      "parent_id": "1505"
    },
    {
      "area_id": "1530",
      "area_code": "410302",
      "area_name": "老城区",
      "level": "3",
      "city_code": "0379",
      "center": "112.469766,34.6842",
      "parent_id": "1529"
    },
    {
      "area_id": "1531",
      "area_code": "410303",
      "area_name": "西工区",
      "level": "3",
      "city_code": "0379",
      "center": "112.427914,34.660378",
      "parent_id": "1529"
    },
    {
      "area_id": "1532",
      "area_code": "410304",
      "area_name": "瀍河回族区",
      "level": "3",
      "city_code": "0379",
      "center": "112.500131,34.679773",
      "parent_id": "1529"
    },
    {
      "area_id": "1533",
      "area_code": "410305",
      "area_name": "涧西区",
      "level": "3",
      "city_code": "0379",
      "center": "112.395756,34.658033",
      "parent_id": "1529"
    },
    {
      "area_id": "1534",
      "area_code": "410306",
      "area_name": "吉利区",
      "level": "3",
      "city_code": "0379",
      "center": "112.589112,34.900467",
      "parent_id": "1529"
    },
    {
      "area_id": "1535",
      "area_code": "410311",
      "area_name": "洛龙区",
      "level": "3",
      "city_code": "0379",
      "center": "112.463833,34.619711",
      "parent_id": "1529"
    },
    {
      "area_id": "1536",
      "area_code": "410322",
      "area_name": "孟津县",
      "level": "3",
      "city_code": "0379",
      "center": "112.445354,34.825638",
      "parent_id": "1529"
    },
    {
      "area_id": "1537",
      "area_code": "410323",
      "area_name": "新安县",
      "level": "3",
      "city_code": "0379",
      "center": "112.13244,34.728284",
      "parent_id": "1529"
    },
    {
      "area_id": "1538",
      "area_code": "410324",
      "area_name": "栾川县",
      "level": "3",
      "city_code": "0379",
      "center": "111.615768,33.785698",
      "parent_id": "1529"
    },
    {
      "area_id": "1539",
      "area_code": "410325",
      "area_name": "嵩县",
      "level": "3",
      "city_code": "0379",
      "center": "112.085634,34.134516",
      "parent_id": "1529"
    },
    {
      "area_id": "1540",
      "area_code": "410326",
      "area_name": "汝阳县",
      "level": "3",
      "city_code": "0379",
      "center": "112.473139,34.153939",
      "parent_id": "1529"
    },
    {
      "area_id": "1541",
      "area_code": "410327",
      "area_name": "宜阳县",
      "level": "3",
      "city_code": "0379",
      "center": "112.179238,34.514644",
      "parent_id": "1529"
    },
    {
      "area_id": "1542",
      "area_code": "410328",
      "area_name": "洛宁县",
      "level": "3",
      "city_code": "0379",
      "center": "111.653111,34.389197",
      "parent_id": "1529"
    },
    {
      "area_id": "1543",
      "area_code": "410329",
      "area_name": "伊川县",
      "level": "3",
      "city_code": "0379",
      "center": "112.425676,34.421323",
      "parent_id": "1529"
    },
    {
      "area_id": "1544",
      "area_code": "410381",
      "area_name": "偃师市",
      "level": "3",
      "city_code": "0379",
      "center": "112.789534,34.72722",
      "parent_id": "1529"
    },
    {
      "area_id": "1545",
      "area_code": "410400",
      "area_name": "平顶山市",
      "level": "2",
      "city_code": "0375",
      "center": "113.192661,33.766169",
      "parent_id": "1505"
    },
    {
      "area_id": "1546",
      "area_code": "410402",
      "area_name": "新华区",
      "level": "3",
      "city_code": "0375",
      "center": "113.293977,33.737251",
      "parent_id": "1545"
    },
    {
      "area_id": "1547",
      "area_code": "410403",
      "area_name": "卫东区",
      "level": "3",
      "city_code": "0375",
      "center": "113.335192,33.734706",
      "parent_id": "1545"
    },
    {
      "area_id": "1548",
      "area_code": "410404",
      "area_name": "石龙区",
      "level": "3",
      "city_code": "0375",
      "center": "112.898818,33.898713",
      "parent_id": "1545"
    },
    {
      "area_id": "1549",
      "area_code": "410411",
      "area_name": "湛河区",
      "level": "3",
      "city_code": "0375",
      "center": "113.320873,33.725681",
      "parent_id": "1545"
    },
    {
      "area_id": "1550",
      "area_code": "410421",
      "area_name": "宝丰县",
      "level": "3",
      "city_code": "0375",
      "center": "113.054801,33.868434",
      "parent_id": "1545"
    },
    {
      "area_id": "1551",
      "area_code": "410422",
      "area_name": "叶县",
      "level": "3",
      "city_code": "0375",
      "center": "113.357239,33.626731",
      "parent_id": "1545"
    },
    {
      "area_id": "1552",
      "area_code": "410423",
      "area_name": "鲁山县",
      "level": "3",
      "city_code": "0375",
      "center": "112.908202,33.738293",
      "parent_id": "1545"
    },
    {
      "area_id": "1553",
      "area_code": "410425",
      "area_name": "郏县",
      "level": "3",
      "city_code": "0375",
      "center": "113.212609,33.971787",
      "parent_id": "1545"
    },
    {
      "area_id": "1554",
      "area_code": "410481",
      "area_name": "舞钢市",
      "level": "3",
      "city_code": "0375",
      "center": "113.516343,33.314033",
      "parent_id": "1545"
    },
    {
      "area_id": "1555",
      "area_code": "410482",
      "area_name": "汝州市",
      "level": "3",
      "city_code": "0375",
      "center": "112.844517,34.167029",
      "parent_id": "1545"
    },
    {
      "area_id": "1556",
      "area_code": "410500",
      "area_name": "安阳市",
      "level": "2",
      "city_code": "0372",
      "center": "114.392392,36.097577",
      "parent_id": "1505"
    },
    {
      "area_id": "1557",
      "area_code": "410502",
      "area_name": "文峰区",
      "level": "3",
      "city_code": "0372",
      "center": "114.357082,36.090468",
      "parent_id": "1556"
    },
    {
      "area_id": "1558",
      "area_code": "410503",
      "area_name": "北关区",
      "level": "3",
      "city_code": "0372",
      "center": "114.355742,36.10766",
      "parent_id": "1556"
    },
    {
      "area_id": "1559",
      "area_code": "410505",
      "area_name": "殷都区",
      "level": "3",
      "city_code": "0372",
      "center": "114.303553,36.10989",
      "parent_id": "1556"
    },
    {
      "area_id": "1560",
      "area_code": "410506",
      "area_name": "龙安区",
      "level": "3",
      "city_code": "0372",
      "center": "114.301331,36.076225",
      "parent_id": "1556"
    },
    {
      "area_id": "1561",
      "area_code": "410522",
      "area_name": "安阳县",
      "level": "3",
      "city_code": "0372",
      "center": "114.130207,36.130584",
      "parent_id": "1556"
    },
    {
      "area_id": "1562",
      "area_code": "410523",
      "area_name": "汤阴县",
      "level": "3",
      "city_code": "0372",
      "center": "114.357763,35.924514",
      "parent_id": "1556"
    },
    {
      "area_id": "1563",
      "area_code": "410526",
      "area_name": "滑县",
      "level": "3",
      "city_code": "0372",
      "center": "114.519311,35.575417",
      "parent_id": "1556"
    },
    {
      "area_id": "1564",
      "area_code": "410527",
      "area_name": "内黄县",
      "level": "3",
      "city_code": "0372",
      "center": "114.901452,35.971704",
      "parent_id": "1556"
    },
    {
      "area_id": "1565",
      "area_code": "410581",
      "area_name": "林州市",
      "level": "3",
      "city_code": "0372",
      "center": "113.820129,36.083046",
      "parent_id": "1556"
    },
    {
      "area_id": "1566",
      "area_code": "410600",
      "area_name": "鹤壁市",
      "level": "2",
      "city_code": "0392",
      "center": "114.297309,35.748325",
      "parent_id": "1505"
    },
    {
      "area_id": "1567",
      "area_code": "410602",
      "area_name": "鹤山区",
      "level": "3",
      "city_code": "0392",
      "center": "114.163258,35.954611",
      "parent_id": "1566"
    },
    {
      "area_id": "1568",
      "area_code": "410603",
      "area_name": "山城区",
      "level": "3",
      "city_code": "0392",
      "center": "114.184318,35.898033",
      "parent_id": "1566"
    },
    {
      "area_id": "1569",
      "area_code": "410611",
      "area_name": "淇滨区",
      "level": "3",
      "city_code": "0392",
      "center": "114.298789,35.741592",
      "parent_id": "1566"
    },
    {
      "area_id": "1570",
      "area_code": "410621",
      "area_name": "浚县",
      "level": "3",
      "city_code": "0392",
      "center": "114.55091,35.67636",
      "parent_id": "1566"
    },
    {
      "area_id": "1571",
      "area_code": "410622",
      "area_name": "淇县",
      "level": "3",
      "city_code": "0392",
      "center": "114.208828,35.622507",
      "parent_id": "1566"
    },
    {
      "area_id": "1572",
      "area_code": "410700",
      "area_name": "新乡市",
      "level": "2",
      "city_code": "0373",
      "center": "113.926763,35.303704",
      "parent_id": "1505"
    },
    {
      "area_id": "1573",
      "area_code": "410702",
      "area_name": "红旗区",
      "level": "3",
      "city_code": "0373",
      "center": "113.875245,35.30385",
      "parent_id": "1572"
    },
    {
      "area_id": "1574",
      "area_code": "410703",
      "area_name": "卫滨区",
      "level": "3",
      "city_code": "0373",
      "center": "113.865663,35.301992",
      "parent_id": "1572"
    },
    {
      "area_id": "1575",
      "area_code": "410704",
      "area_name": "凤泉区",
      "level": "3",
      "city_code": "0373",
      "center": "113.915184,35.383978",
      "parent_id": "1572"
    },
    {
      "area_id": "1576",
      "area_code": "410711",
      "area_name": "牧野区",
      "level": "3",
      "city_code": "0373",
      "center": "113.908772,35.315039",
      "parent_id": "1572"
    },
    {
      "area_id": "1577",
      "area_code": "410721",
      "area_name": "新乡县",
      "level": "3",
      "city_code": "0373",
      "center": "113.805205,35.190836",
      "parent_id": "1572"
    },
    {
      "area_id": "1578",
      "area_code": "410724",
      "area_name": "获嘉县",
      "level": "3",
      "city_code": "0373",
      "center": "113.657433,35.259808",
      "parent_id": "1572"
    },
    {
      "area_id": "1579",
      "area_code": "410725",
      "area_name": "原阳县",
      "level": "3",
      "city_code": "0373",
      "center": "113.940046,35.065587",
      "parent_id": "1572"
    },
    {
      "area_id": "1580",
      "area_code": "410726",
      "area_name": "延津县",
      "level": "3",
      "city_code": "0373",
      "center": "114.20509,35.141889",
      "parent_id": "1572"
    },
    {
      "area_id": "1581",
      "area_code": "410727",
      "area_name": "封丘县",
      "level": "3",
      "city_code": "0373",
      "center": "114.418882,35.041198",
      "parent_id": "1572"
    },
    {
      "area_id": "1582",
      "area_code": "410728",
      "area_name": "长垣县",
      "level": "3",
      "city_code": "0373",
      "center": "114.668936,35.201548",
      "parent_id": "1572"
    },
    {
      "area_id": "1583",
      "area_code": "410781",
      "area_name": "卫辉市",
      "level": "3",
      "city_code": "0373",
      "center": "114.064907,35.398494",
      "parent_id": "1572"
    },
    {
      "area_id": "1584",
      "area_code": "410782",
      "area_name": "辉县市",
      "level": "3",
      "city_code": "0373",
      "center": "113.805468,35.462312",
      "parent_id": "1572"
    },
    {
      "area_id": "1585",
      "area_code": "410800",
      "area_name": "焦作市",
      "level": "2",
      "city_code": "0391",
      "center": "113.241823,35.215893",
      "parent_id": "1505"
    },
    {
      "area_id": "1586",
      "area_code": "410802",
      "area_name": "解放区",
      "level": "3",
      "city_code": "0391",
      "center": "113.230816,35.240282",
      "parent_id": "1585"
    },
    {
      "area_id": "1587",
      "area_code": "410803",
      "area_name": "中站区",
      "level": "3",
      "city_code": "0391",
      "center": "113.182946,35.236819",
      "parent_id": "1585"
    },
    {
      "area_id": "1588",
      "area_code": "410804",
      "area_name": "马村区",
      "level": "3",
      "city_code": "0391",
      "center": "113.322332,35.256108",
      "parent_id": "1585"
    },
    {
      "area_id": "1589",
      "area_code": "410811",
      "area_name": "山阳区",
      "level": "3",
      "city_code": "0391",
      "center": "113.254881,35.214507",
      "parent_id": "1585"
    },
    {
      "area_id": "1590",
      "area_code": "410821",
      "area_name": "修武县",
      "level": "3",
      "city_code": "0391",
      "center": "113.447755,35.223514",
      "parent_id": "1585"
    },
    {
      "area_id": "1591",
      "area_code": "410822",
      "area_name": "博爱县",
      "level": "3",
      "city_code": "0391",
      "center": "113.064379,35.171045",
      "parent_id": "1585"
    },
    {
      "area_id": "1592",
      "area_code": "410823",
      "area_name": "武陟县",
      "level": "3",
      "city_code": "0391",
      "center": "113.401679,35.099378",
      "parent_id": "1585"
    },
    {
      "area_id": "1593",
      "area_code": "410825",
      "area_name": "温县",
      "level": "3",
      "city_code": "0391",
      "center": "113.08053,34.940189",
      "parent_id": "1585"
    },
    {
      "area_id": "1594",
      "area_code": "410882",
      "area_name": "沁阳市",
      "level": "3",
      "city_code": "0391",
      "center": "112.950716,35.087539",
      "parent_id": "1585"
    },
    {
      "area_id": "1595",
      "area_code": "410883",
      "area_name": "孟州市",
      "level": "3",
      "city_code": "0391",
      "center": "112.791401,34.907315",
      "parent_id": "1585"
    },
    {
      "area_id": "1596",
      "area_code": "410900",
      "area_name": "濮阳市",
      "level": "2",
      "city_code": "0393",
      "center": "115.029216,35.761829",
      "parent_id": "1505"
    },
    {
      "area_id": "1597",
      "area_code": "410902",
      "area_name": "华龙区",
      "level": "3",
      "city_code": "0393",
      "center": "115.074151,35.777346",
      "parent_id": "1596"
    },
    {
      "area_id": "1598",
      "area_code": "410922",
      "area_name": "清丰县",
      "level": "3",
      "city_code": "0393",
      "center": "115.104389,35.88518",
      "parent_id": "1596"
    },
    {
      "area_id": "1599",
      "area_code": "410923",
      "area_name": "南乐县",
      "level": "3",
      "city_code": "0393",
      "center": "115.204675,36.069476",
      "parent_id": "1596"
    },
    {
      "area_id": "1600",
      "area_code": "410926",
      "area_name": "范县",
      "level": "3",
      "city_code": "0393",
      "center": "115.504201,35.851906",
      "parent_id": "1596"
    },
    {
      "area_id": "1601",
      "area_code": "410927",
      "area_name": "台前县",
      "level": "3",
      "city_code": "0393",
      "center": "115.871906,35.96939",
      "parent_id": "1596"
    },
    {
      "area_id": "1602",
      "area_code": "410928",
      "area_name": "濮阳县",
      "level": "3",
      "city_code": "0393",
      "center": "115.029078,35.712193",
      "parent_id": "1596"
    },
    {
      "area_id": "1603",
      "area_code": "411000",
      "area_name": "许昌市",
      "level": "2",
      "city_code": "0374",
      "center": "113.852454,34.035771",
      "parent_id": "1505"
    },
    {
      "area_id": "1604",
      "area_code": "411002",
      "area_name": "魏都区",
      "level": "3",
      "city_code": "0374",
      "center": "113.822647,34.025341",
      "parent_id": "1603"
    },
    {
      "area_id": "1605",
      "area_code": "411003",
      "area_name": "建安区",
      "level": "3",
      "city_code": "0374",
      "center": "113.822983,34.12466",
      "parent_id": "1603"
    },
    {
      "area_id": "1606",
      "area_code": "411024",
      "area_name": "鄢陵县",
      "level": "3",
      "city_code": "0374",
      "center": "114.177399,34.102332",
      "parent_id": "1603"
    },
    {
      "area_id": "1607",
      "area_code": "411025",
      "area_name": "襄城县",
      "level": "3",
      "city_code": "0374",
      "center": "113.505874,33.851459",
      "parent_id": "1603"
    },
    {
      "area_id": "1608",
      "area_code": "411081",
      "area_name": "禹州市",
      "level": "3",
      "city_code": "0374",
      "center": "113.488478,34.140701",
      "parent_id": "1603"
    },
    {
      "area_id": "1609",
      "area_code": "411082",
      "area_name": "长葛市",
      "level": "3",
      "city_code": "0374",
      "center": "113.813714,34.19592",
      "parent_id": "1603"
    },
    {
      "area_id": "1610",
      "area_code": "411100",
      "area_name": "漯河市",
      "level": "2",
      "city_code": "0395",
      "center": "114.016536,33.580873",
      "parent_id": "1505"
    },
    {
      "area_id": "1611",
      "area_code": "411102",
      "area_name": "源汇区",
      "level": "3",
      "city_code": "0395",
      "center": "114.017948,33.565441",
      "parent_id": "1610"
    },
    {
      "area_id": "1612",
      "area_code": "411103",
      "area_name": "郾城区",
      "level": "3",
      "city_code": "0395",
      "center": "114.006943,33.587409",
      "parent_id": "1610"
    },
    {
      "area_id": "1613",
      "area_code": "411104",
      "area_name": "召陵区",
      "level": "3",
      "city_code": "0395",
      "center": "114.093902,33.586565",
      "parent_id": "1610"
    },
    {
      "area_id": "1614",
      "area_code": "411121",
      "area_name": "舞阳县",
      "level": "3",
      "city_code": "0395",
      "center": "113.609286,33.437876",
      "parent_id": "1610"
    },
    {
      "area_id": "1615",
      "area_code": "411122",
      "area_name": "临颍县",
      "level": "3",
      "city_code": "0395",
      "center": "113.931261,33.828042",
      "parent_id": "1610"
    },
    {
      "area_id": "1616",
      "area_code": "411200",
      "area_name": "三门峡市",
      "level": "2",
      "city_code": "0398",
      "center": "111.200367,34.772792",
      "parent_id": "1505"
    },
    {
      "area_id": "1617",
      "area_code": "411202",
      "area_name": "湖滨区",
      "level": "3",
      "city_code": "0398",
      "center": "111.188397,34.770886",
      "parent_id": "1616"
    },
    {
      "area_id": "1618",
      "area_code": "411203",
      "area_name": "陕州区",
      "level": "3",
      "city_code": "0398",
      "center": "111.103563,34.720547",
      "parent_id": "1616"
    },
    {
      "area_id": "1619",
      "area_code": "411221",
      "area_name": "渑池县",
      "level": "3",
      "city_code": "0398",
      "center": "111.761797,34.767951",
      "parent_id": "1616"
    },
    {
      "area_id": "1620",
      "area_code": "411224",
      "area_name": "卢氏县",
      "level": "3",
      "city_code": "0398",
      "center": "111.047858,34.054324",
      "parent_id": "1616"
    },
    {
      "area_id": "1621",
      "area_code": "411281",
      "area_name": "义马市",
      "level": "3",
      "city_code": "0398",
      "center": "111.87448,34.7474",
      "parent_id": "1616"
    },
    {
      "area_id": "1622",
      "area_code": "411282",
      "area_name": "灵宝市",
      "level": "3",
      "city_code": "0398",
      "center": "110.89422,34.516828",
      "parent_id": "1616"
    },
    {
      "area_id": "1623",
      "area_code": "411300",
      "area_name": "南阳市",
      "level": "2",
      "city_code": "0377",
      "center": "112.528308,32.990664",
      "parent_id": "1505"
    },
    {
      "area_id": "1624",
      "area_code": "411302",
      "area_name": "宛城区",
      "level": "3",
      "city_code": "0377",
      "center": "112.539558,33.003784",
      "parent_id": "1623"
    },
    {
      "area_id": "1625",
      "area_code": "411303",
      "area_name": "卧龙区",
      "level": "3",
      "city_code": "0377",
      "center": "112.528789,32.989877",
      "parent_id": "1623"
    },
    {
      "area_id": "1626",
      "area_code": "411321",
      "area_name": "南召县",
      "level": "3",
      "city_code": "0377",
      "center": "112.429133,33.489877",
      "parent_id": "1623"
    },
    {
      "area_id": "1627",
      "area_code": "411322",
      "area_name": "方城县",
      "level": "3",
      "city_code": "0377",
      "center": "113.012494,33.254391",
      "parent_id": "1623"
    },
    {
      "area_id": "1628",
      "area_code": "411323",
      "area_name": "西峡县",
      "level": "3",
      "city_code": "0377",
      "center": "111.47353,33.307294",
      "parent_id": "1623"
    },
    {
      "area_id": "1629",
      "area_code": "411324",
      "area_name": "镇平县",
      "level": "3",
      "city_code": "0377",
      "center": "112.234697,33.03411",
      "parent_id": "1623"
    },
    {
      "area_id": "1630",
      "area_code": "411325",
      "area_name": "内乡县",
      "level": "3",
      "city_code": "0377",
      "center": "111.849392,33.044864",
      "parent_id": "1623"
    },
    {
      "area_id": "1631",
      "area_code": "411326",
      "area_name": "淅川县",
      "level": "3",
      "city_code": "0377",
      "center": "111.490964,33.13782",
      "parent_id": "1623"
    },
    {
      "area_id": "1632",
      "area_code": "411327",
      "area_name": "社旗县",
      "level": "3",
      "city_code": "0377",
      "center": "112.948245,33.056109",
      "parent_id": "1623"
    },
    {
      "area_id": "1633",
      "area_code": "411328",
      "area_name": "唐河县",
      "level": "3",
      "city_code": "0377",
      "center": "112.807636,32.681335",
      "parent_id": "1623"
    },
    {
      "area_id": "1634",
      "area_code": "411329",
      "area_name": "新野县",
      "level": "3",
      "city_code": "0377",
      "center": "112.360026,32.520805",
      "parent_id": "1623"
    },
    {
      "area_id": "1635",
      "area_code": "411330",
      "area_name": "桐柏县",
      "level": "3",
      "city_code": "0377",
      "center": "113.428287,32.380073",
      "parent_id": "1623"
    },
    {
      "area_id": "1636",
      "area_code": "411381",
      "area_name": "邓州市",
      "level": "3",
      "city_code": "0377",
      "center": "112.087493,32.68758",
      "parent_id": "1623"
    },
    {
      "area_id": "1637",
      "area_code": "411400",
      "area_name": "商丘市",
      "level": "2",
      "city_code": "0370",
      "center": "115.656339,34.414961",
      "parent_id": "1505"
    },
    {
      "area_id": "1638",
      "area_code": "411402",
      "area_name": "梁园区",
      "level": "3",
      "city_code": "0370",
      "center": "115.613965,34.443893",
      "parent_id": "1637"
    },
    {
      "area_id": "1639",
      "area_code": "411403",
      "area_name": "睢阳区",
      "level": "3",
      "city_code": "0370",
      "center": "115.653301,34.388389",
      "parent_id": "1637"
    },
    {
      "area_id": "1640",
      "area_code": "411421",
      "area_name": "民权县",
      "level": "3",
      "city_code": "0370",
      "center": "115.173971,34.648191",
      "parent_id": "1637"
    },
    {
      "area_id": "1641",
      "area_code": "411422",
      "area_name": "睢县",
      "level": "3",
      "city_code": "0370",
      "center": "115.071879,34.445655",
      "parent_id": "1637"
    },
    {
      "area_id": "1642",
      "area_code": "411423",
      "area_name": "宁陵县",
      "level": "3",
      "city_code": "0370",
      "center": "115.313743,34.460399",
      "parent_id": "1637"
    },
    {
      "area_id": "1643",
      "area_code": "411424",
      "area_name": "柘城县",
      "level": "3",
      "city_code": "0370",
      "center": "115.305708,34.091082",
      "parent_id": "1637"
    },
    {
      "area_id": "1644",
      "area_code": "411425",
      "area_name": "虞城县",
      "level": "3",
      "city_code": "0370",
      "center": "115.828319,34.400835",
      "parent_id": "1637"
    },
    {
      "area_id": "1645",
      "area_code": "411426",
      "area_name": "夏邑县",
      "level": "3",
      "city_code": "0370",
      "center": "116.131447,34.237553",
      "parent_id": "1637"
    },
    {
      "area_id": "1646",
      "area_code": "411481",
      "area_name": "永城市",
      "level": "3",
      "city_code": "0370",
      "center": "116.4495,33.929291",
      "parent_id": "1637"
    },
    {
      "area_id": "1647",
      "area_code": "411500",
      "area_name": "信阳市",
      "level": "2",
      "city_code": "0376",
      "center": "114.091193,32.147679",
      "parent_id": "1505"
    },
    {
      "area_id": "1648",
      "area_code": "411502",
      "area_name": "浉河区",
      "level": "3",
      "city_code": "0376",
      "center": "114.058713,32.116803",
      "parent_id": "1647"
    },
    {
      "area_id": "1649",
      "area_code": "411503",
      "area_name": "平桥区",
      "level": "3",
      "city_code": "0376",
      "center": "114.125656,32.101031",
      "parent_id": "1647"
    },
    {
      "area_id": "1650",
      "area_code": "411521",
      "area_name": "罗山县",
      "level": "3",
      "city_code": "0376",
      "center": "114.512872,32.203883",
      "parent_id": "1647"
    },
    {
      "area_id": "1651",
      "area_code": "411522",
      "area_name": "光山县",
      "level": "3",
      "city_code": "0376",
      "center": "114.919152,32.010002",
      "parent_id": "1647"
    },
    {
      "area_id": "1652",
      "area_code": "411523",
      "area_name": "新县",
      "level": "3",
      "city_code": "0376",
      "center": "114.879239,31.643918",
      "parent_id": "1647"
    },
    {
      "area_id": "1653",
      "area_code": "411524",
      "area_name": "商城县",
      "level": "3",
      "city_code": "0376",
      "center": "115.406862,31.798377",
      "parent_id": "1647"
    },
    {
      "area_id": "1654",
      "area_code": "411525",
      "area_name": "固始县",
      "level": "3",
      "city_code": "0376",
      "center": "115.654481,32.168137",
      "parent_id": "1647"
    },
    {
      "area_id": "1655",
      "area_code": "411526",
      "area_name": "潢川县",
      "level": "3",
      "city_code": "0376",
      "center": "115.051908,32.131522",
      "parent_id": "1647"
    },
    {
      "area_id": "1656",
      "area_code": "411527",
      "area_name": "淮滨县",
      "level": "3",
      "city_code": "0376",
      "center": "115.419537,32.473258",
      "parent_id": "1647"
    },
    {
      "area_id": "1657",
      "area_code": "411528",
      "area_name": "息县",
      "level": "3",
      "city_code": "0376",
      "center": "114.740456,32.342792",
      "parent_id": "1647"
    },
    {
      "area_id": "1658",
      "area_code": "411600",
      "area_name": "周口市",
      "level": "2",
      "city_code": "0394",
      "center": "114.69695,33.626149",
      "parent_id": "1505"
    },
    {
      "area_id": "1659",
      "area_code": "411602",
      "area_name": "川汇区",
      "level": "3",
      "city_code": "0394",
      "center": "114.650628,33.647598",
      "parent_id": "1658"
    },
    {
      "area_id": "1660",
      "area_code": "411621",
      "area_name": "扶沟县",
      "level": "3",
      "city_code": "0394",
      "center": "114.394821,34.059968",
      "parent_id": "1658"
    },
    {
      "area_id": "1661",
      "area_code": "411622",
      "area_name": "西华县",
      "level": "3",
      "city_code": "0394",
      "center": "114.529756,33.767407",
      "parent_id": "1658"
    },
    {
      "area_id": "1662",
      "area_code": "411623",
      "area_name": "商水县",
      "level": "3",
      "city_code": "0394",
      "center": "114.611651,33.542138",
      "parent_id": "1658"
    },
    {
      "area_id": "1663",
      "area_code": "411624",
      "area_name": "沈丘县",
      "level": "3",
      "city_code": "0394",
      "center": "115.098583,33.409369",
      "parent_id": "1658"
    },
    {
      "area_id": "1664",
      "area_code": "411625",
      "area_name": "郸城县",
      "level": "3",
      "city_code": "0394",
      "center": "115.177188,33.644743",
      "parent_id": "1658"
    },
    {
      "area_id": "1665",
      "area_code": "411626",
      "area_name": "淮阳县",
      "level": "3",
      "city_code": "0394",
      "center": "114.886153,33.731561",
      "parent_id": "1658"
    },
    {
      "area_id": "1666",
      "area_code": "411627",
      "area_name": "太康县",
      "level": "3",
      "city_code": "0394",
      "center": "114.837888,34.064463",
      "parent_id": "1658"
    },
    {
      "area_id": "1667",
      "area_code": "411628",
      "area_name": "鹿邑县",
      "level": "3",
      "city_code": "0394",
      "center": "115.484454,33.86",
      "parent_id": "1658"
    },
    {
      "area_id": "1668",
      "area_code": "411681",
      "area_name": "项城市",
      "level": "3",
      "city_code": "0394",
      "center": "114.875333,33.465838",
      "parent_id": "1658"
    },
    {
      "area_id": "1669",
      "area_code": "411700",
      "area_name": "驻马店市",
      "level": "2",
      "city_code": "0396",
      "center": "114.022247,33.012885",
      "parent_id": "1505"
    },
    {
      "area_id": "1670",
      "area_code": "411702",
      "area_name": "驿城区",
      "level": "3",
      "city_code": "0396",
      "center": "113.993914,32.973054",
      "parent_id": "1669"
    },
    {
      "area_id": "1671",
      "area_code": "411721",
      "area_name": "西平县",
      "level": "3",
      "city_code": "0396",
      "center": "114.021538,33.387684",
      "parent_id": "1669"
    },
    {
      "area_id": "1672",
      "area_code": "411722",
      "area_name": "上蔡县",
      "level": "3",
      "city_code": "0396",
      "center": "114.264381,33.262439",
      "parent_id": "1669"
    },
    {
      "area_id": "1673",
      "area_code": "411723",
      "area_name": "平舆县",
      "level": "3",
      "city_code": "0396",
      "center": "114.619159,32.96271",
      "parent_id": "1669"
    },
    {
      "area_id": "1674",
      "area_code": "411724",
      "area_name": "正阳县",
      "level": "3",
      "city_code": "0396",
      "center": "114.392773,32.605697",
      "parent_id": "1669"
    },
    {
      "area_id": "1675",
      "area_code": "411725",
      "area_name": "确山县",
      "level": "3",
      "city_code": "0396",
      "center": "114.026429,32.802064",
      "parent_id": "1669"
    },
    {
      "area_id": "1676",
      "area_code": "411726",
      "area_name": "泌阳县",
      "level": "3",
      "city_code": "0396",
      "center": "113.327144,32.723975",
      "parent_id": "1669"
    },
    {
      "area_id": "1677",
      "area_code": "411727",
      "area_name": "汝南县",
      "level": "3",
      "city_code": "0396",
      "center": "114.362379,33.006729",
      "parent_id": "1669"
    },
    {
      "area_id": "1678",
      "area_code": "411728",
      "area_name": "遂平县",
      "level": "3",
      "city_code": "0396",
      "center": "114.013182,33.145649",
      "parent_id": "1669"
    },
    {
      "area_id": "1679",
      "area_code": "411729",
      "area_name": "新蔡县",
      "level": "3",
      "city_code": "0396",
      "center": "114.96547,32.744896",
      "parent_id": "1669"
    },
    {
      "area_id": "1680",
      "area_code": "419001",
      "area_name": "济源市",
      "level": "2",
      "city_code": "1391",
      "center": "112.602256,35.067199",
      "parent_id": "1505"
    },
    {
      "area_id": "1681",
      "area_code": "420000",
      "area_name": "湖北省",
      "level": "1",
      "city_code": "[]",
      "center": "114.341745,30.546557",
      "parent_id": "0"
    },
    {
      "area_id": "1682",
      "area_code": "420100",
      "area_name": "武汉市",
      "level": "2",
      "city_code": "027",
      "center": "114.305469,30.593175",
      "parent_id": "1681"
    },
    {
      "area_id": "1683",
      "area_code": "420102",
      "area_name": "江岸区",
      "level": "3",
      "city_code": "027",
      "center": "114.30911,30.600052",
      "parent_id": "1682"
    },
    {
      "area_id": "1684",
      "area_code": "420103",
      "area_name": "江汉区",
      "level": "3",
      "city_code": "027",
      "center": "114.270867,30.601475",
      "parent_id": "1682"
    },
    {
      "area_id": "1685",
      "area_code": "420104",
      "area_name": "硚口区",
      "level": "3",
      "city_code": "027",
      "center": "114.21492,30.582202",
      "parent_id": "1682"
    },
    {
      "area_id": "1686",
      "area_code": "420105",
      "area_name": "汉阳区",
      "level": "3",
      "city_code": "027",
      "center": "114.21861,30.553983",
      "parent_id": "1682"
    },
    {
      "area_id": "1687",
      "area_code": "420106",
      "area_name": "武昌区",
      "level": "3",
      "city_code": "027",
      "center": "114.31665,30.554408",
      "parent_id": "1682"
    },
    {
      "area_id": "1688",
      "area_code": "420107",
      "area_name": "青山区",
      "level": "3",
      "city_code": "027",
      "center": "114.384968,30.640191",
      "parent_id": "1682"
    },
    {
      "area_id": "1689",
      "area_code": "420111",
      "area_name": "洪山区",
      "level": "3",
      "city_code": "027",
      "center": "114.343796,30.500247",
      "parent_id": "1682"
    },
    {
      "area_id": "1690",
      "area_code": "420112",
      "area_name": "东西湖区",
      "level": "3",
      "city_code": "027",
      "center": "114.137116,30.619917",
      "parent_id": "1682"
    },
    {
      "area_id": "1691",
      "area_code": "420113",
      "area_name": "汉南区",
      "level": "3",
      "city_code": "027",
      "center": "114.084597,30.308829",
      "parent_id": "1682"
    },
    {
      "area_id": "1692",
      "area_code": "420114",
      "area_name": "蔡甸区",
      "level": "3",
      "city_code": "027",
      "center": "114.087285,30.536454",
      "parent_id": "1682"
    },
    {
      "area_id": "1693",
      "area_code": "420115",
      "area_name": "江夏区",
      "level": "3",
      "city_code": "027",
      "center": "114.319097,30.376308",
      "parent_id": "1682"
    },
    {
      "area_id": "1694",
      "area_code": "420116",
      "area_name": "黄陂区",
      "level": "3",
      "city_code": "027",
      "center": "114.375725,30.882174",
      "parent_id": "1682"
    },
    {
      "area_id": "1695",
      "area_code": "420117",
      "area_name": "新洲区",
      "level": "3",
      "city_code": "027",
      "center": "114.801096,30.841425",
      "parent_id": "1682"
    },
    {
      "area_id": "1696",
      "area_code": "420200",
      "area_name": "黄石市",
      "level": "2",
      "city_code": "0714",
      "center": "115.038962,30.201038",
      "parent_id": "1681"
    },
    {
      "area_id": "1697",
      "area_code": "420202",
      "area_name": "黄石港区",
      "level": "3",
      "city_code": "0714",
      "center": "115.065849,30.222938",
      "parent_id": "1696"
    },
    {
      "area_id": "1698",
      "area_code": "420203",
      "area_name": "西塞山区",
      "level": "3",
      "city_code": "0714",
      "center": "115.109955,30.204924",
      "parent_id": "1696"
    },
    {
      "area_id": "1699",
      "area_code": "420204",
      "area_name": "下陆区",
      "level": "3",
      "city_code": "0714",
      "center": "114.961327,30.173912",
      "parent_id": "1696"
    },
    {
      "area_id": "1700",
      "area_code": "420205",
      "area_name": "铁山区",
      "level": "3",
      "city_code": "0714",
      "center": "114.891605,30.203118",
      "parent_id": "1696"
    },
    {
      "area_id": "1701",
      "area_code": "420222",
      "area_name": "阳新县",
      "level": "3",
      "city_code": "0714",
      "center": "115.215227,29.830257",
      "parent_id": "1696"
    },
    {
      "area_id": "1702",
      "area_code": "420281",
      "area_name": "大冶市",
      "level": "3",
      "city_code": "0714",
      "center": "114.980424,30.096147",
      "parent_id": "1696"
    },
    {
      "area_id": "1703",
      "area_code": "420300",
      "area_name": "十堰市",
      "level": "2",
      "city_code": "0719",
      "center": "110.799291,32.629462",
      "parent_id": "1681"
    },
    {
      "area_id": "1704",
      "area_code": "420302",
      "area_name": "茅箭区",
      "level": "3",
      "city_code": "0719",
      "center": "110.813719,32.591904",
      "parent_id": "1703"
    },
    {
      "area_id": "1705",
      "area_code": "420303",
      "area_name": "张湾区",
      "level": "3",
      "city_code": "0719",
      "center": "110.769132,32.652297",
      "parent_id": "1703"
    },
    {
      "area_id": "1706",
      "area_code": "420304",
      "area_name": "郧阳区",
      "level": "3",
      "city_code": "0719",
      "center": "110.81205,32.834775",
      "parent_id": "1703"
    },
    {
      "area_id": "1707",
      "area_code": "420322",
      "area_name": "郧西县",
      "level": "3",
      "city_code": "0719",
      "center": "110.425983,32.993182",
      "parent_id": "1703"
    },
    {
      "area_id": "1708",
      "area_code": "420323",
      "area_name": "竹山县",
      "level": "3",
      "city_code": "0719",
      "center": "110.228747,32.224808",
      "parent_id": "1703"
    },
    {
      "area_id": "1709",
      "area_code": "420324",
      "area_name": "竹溪县",
      "level": "3",
      "city_code": "0719",
      "center": "109.715304,32.318255",
      "parent_id": "1703"
    },
    {
      "area_id": "1710",
      "area_code": "420325",
      "area_name": "房县",
      "level": "3",
      "city_code": "0719",
      "center": "110.733181,32.050378",
      "parent_id": "1703"
    },
    {
      "area_id": "1711",
      "area_code": "420381",
      "area_name": "丹江口市",
      "level": "3",
      "city_code": "0719",
      "center": "111.513127,32.540157",
      "parent_id": "1703"
    },
    {
      "area_id": "1712",
      "area_code": "420500",
      "area_name": "宜昌市",
      "level": "2",
      "city_code": "0717",
      "center": "111.286445,30.691865",
      "parent_id": "1681"
    },
    {
      "area_id": "1713",
      "area_code": "420502",
      "area_name": "西陵区",
      "level": "3",
      "city_code": "0717",
      "center": "111.285646,30.710781",
      "parent_id": "1712"
    },
    {
      "area_id": "1714",
      "area_code": "420503",
      "area_name": "伍家岗区",
      "level": "3",
      "city_code": "0717",
      "center": "111.361037,30.644334",
      "parent_id": "1712"
    },
    {
      "area_id": "1715",
      "area_code": "420504",
      "area_name": "点军区",
      "level": "3",
      "city_code": "0717",
      "center": "111.268119,30.693247",
      "parent_id": "1712"
    },
    {
      "area_id": "1716",
      "area_code": "420505",
      "area_name": "猇亭区",
      "level": "3",
      "city_code": "0717",
      "center": "111.43462,30.530903",
      "parent_id": "1712"
    },
    {
      "area_id": "1717",
      "area_code": "420506",
      "area_name": "夷陵区",
      "level": "3",
      "city_code": "0717",
      "center": "111.32638,30.770006",
      "parent_id": "1712"
    },
    {
      "area_id": "1718",
      "area_code": "420525",
      "area_name": "远安县",
      "level": "3",
      "city_code": "0717",
      "center": "111.640508,31.060869",
      "parent_id": "1712"
    },
    {
      "area_id": "1719",
      "area_code": "420526",
      "area_name": "兴山县",
      "level": "3",
      "city_code": "0717",
      "center": "110.746804,31.348196",
      "parent_id": "1712"
    },
    {
      "area_id": "1720",
      "area_code": "420527",
      "area_name": "秭归县",
      "level": "3",
      "city_code": "0717",
      "center": "110.977711,30.825897",
      "parent_id": "1712"
    },
    {
      "area_id": "1721",
      "area_code": "420528",
      "area_name": "长阳土家族自治县",
      "level": "3",
      "city_code": "0717",
      "center": "111.207242,30.472763",
      "parent_id": "1712"
    },
    {
      "area_id": "1722",
      "area_code": "420529",
      "area_name": "五峰土家族自治县",
      "level": "3",
      "city_code": "0717",
      "center": "111.07374,30.156741",
      "parent_id": "1712"
    },
    {
      "area_id": "1723",
      "area_code": "420581",
      "area_name": "宜都市",
      "level": "3",
      "city_code": "0717",
      "center": "111.450096,30.378299",
      "parent_id": "1712"
    },
    {
      "area_id": "1724",
      "area_code": "420582",
      "area_name": "当阳市",
      "level": "3",
      "city_code": "0717",
      "center": "111.788312,30.821266",
      "parent_id": "1712"
    },
    {
      "area_id": "1725",
      "area_code": "420583",
      "area_name": "枝江市",
      "level": "3",
      "city_code": "0717",
      "center": "111.76053,30.42594",
      "parent_id": "1712"
    },
    {
      "area_id": "1726",
      "area_code": "420600",
      "area_name": "襄阳市",
      "level": "2",
      "city_code": "0710",
      "center": "112.122426,32.009016",
      "parent_id": "1681"
    },
    {
      "area_id": "1727",
      "area_code": "420602",
      "area_name": "襄城区",
      "level": "3",
      "city_code": "0710",
      "center": "112.134052,32.010366",
      "parent_id": "1726"
    },
    {
      "area_id": "1728",
      "area_code": "420606",
      "area_name": "樊城区",
      "level": "3",
      "city_code": "0710",
      "center": "112.135684,32.044832",
      "parent_id": "1726"
    },
    {
      "area_id": "1729",
      "area_code": "420607",
      "area_name": "襄州区",
      "level": "3",
      "city_code": "0710",
      "center": "112.211982,32.087127",
      "parent_id": "1726"
    },
    {
      "area_id": "1730",
      "area_code": "420624",
      "area_name": "南漳县",
      "level": "3",
      "city_code": "0710",
      "center": "111.838905,31.774636",
      "parent_id": "1726"
    },
    {
      "area_id": "1731",
      "area_code": "420625",
      "area_name": "谷城县",
      "level": "3",
      "city_code": "0710",
      "center": "111.652982,32.263849",
      "parent_id": "1726"
    },
    {
      "area_id": "1732",
      "area_code": "420626",
      "area_name": "保康县",
      "level": "3",
      "city_code": "0710",
      "center": "111.261308,31.87831",
      "parent_id": "1726"
    },
    {
      "area_id": "1733",
      "area_code": "420682",
      "area_name": "老河口市",
      "level": "3",
      "city_code": "0710",
      "center": "111.683861,32.359068",
      "parent_id": "1726"
    },
    {
      "area_id": "1734",
      "area_code": "420683",
      "area_name": "枣阳市",
      "level": "3",
      "city_code": "0710",
      "center": "112.771959,32.128818",
      "parent_id": "1726"
    },
    {
      "area_id": "1735",
      "area_code": "420684",
      "area_name": "宜城市",
      "level": "3",
      "city_code": "0710",
      "center": "112.257788,31.719806",
      "parent_id": "1726"
    },
    {
      "area_id": "1736",
      "area_code": "420700",
      "area_name": "鄂州市",
      "level": "2",
      "city_code": "0711",
      "center": "114.894935,30.391141",
      "parent_id": "1681"
    },
    {
      "area_id": "1737",
      "area_code": "420702",
      "area_name": "梁子湖区",
      "level": "3",
      "city_code": "0711",
      "center": "114.684731,30.100141",
      "parent_id": "1736"
    },
    {
      "area_id": "1738",
      "area_code": "420703",
      "area_name": "华容区",
      "level": "3",
      "city_code": "0711",
      "center": "114.729878,30.534309",
      "parent_id": "1736"
    },
    {
      "area_id": "1739",
      "area_code": "420704",
      "area_name": "鄂城区",
      "level": "3",
      "city_code": "0711",
      "center": "114.891586,30.400651",
      "parent_id": "1736"
    },
    {
      "area_id": "1740",
      "area_code": "420800",
      "area_name": "荆门市",
      "level": "2",
      "city_code": "0724",
      "center": "112.199427,31.035395",
      "parent_id": "1681"
    },
    {
      "area_id": "1741",
      "area_code": "420802",
      "area_name": "东宝区",
      "level": "3",
      "city_code": "0724",
      "center": "112.201493,31.051852",
      "parent_id": "1740"
    },
    {
      "area_id": "1742",
      "area_code": "420804",
      "area_name": "掇刀区",
      "level": "3",
      "city_code": "0724",
      "center": "112.207962,30.973451",
      "parent_id": "1740"
    },
    {
      "area_id": "1743",
      "area_code": "420821",
      "area_name": "京山县",
      "level": "3",
      "city_code": "0724",
      "center": "113.119566,31.018457",
      "parent_id": "1740"
    },
    {
      "area_id": "1744",
      "area_code": "420822",
      "area_name": "沙洋县",
      "level": "3",
      "city_code": "0724",
      "center": "112.588581,30.709221",
      "parent_id": "1740"
    },
    {
      "area_id": "1745",
      "area_code": "420881",
      "area_name": "钟祥市",
      "level": "3",
      "city_code": "0724",
      "center": "112.58812,31.167819",
      "parent_id": "1740"
    },
    {
      "area_id": "1746",
      "area_code": "420900",
      "area_name": "孝感市",
      "level": "2",
      "city_code": "0712",
      "center": "113.957037,30.917766",
      "parent_id": "1681"
    },
    {
      "area_id": "1747",
      "area_code": "420902",
      "area_name": "孝南区",
      "level": "3",
      "city_code": "0712",
      "center": "113.910705,30.916812",
      "parent_id": "1746"
    },
    {
      "area_id": "1748",
      "area_code": "420921",
      "area_name": "孝昌县",
      "level": "3",
      "city_code": "0712",
      "center": "113.998009,31.258159",
      "parent_id": "1746"
    },
    {
      "area_id": "1749",
      "area_code": "420922",
      "area_name": "大悟县",
      "level": "3",
      "city_code": "0712",
      "center": "114.127022,31.561164",
      "parent_id": "1746"
    },
    {
      "area_id": "1750",
      "area_code": "420923",
      "area_name": "云梦县",
      "level": "3",
      "city_code": "0712",
      "center": "113.753554,31.020983",
      "parent_id": "1746"
    },
    {
      "area_id": "1751",
      "area_code": "420981",
      "area_name": "应城市",
      "level": "3",
      "city_code": "0712",
      "center": "113.572707,30.92837",
      "parent_id": "1746"
    },
    {
      "area_id": "1752",
      "area_code": "420982",
      "area_name": "安陆市",
      "level": "3",
      "city_code": "0712",
      "center": "113.688941,31.25561",
      "parent_id": "1746"
    },
    {
      "area_id": "1753",
      "area_code": "420984",
      "area_name": "汉川市",
      "level": "3",
      "city_code": "0712",
      "center": "113.839149,30.661243",
      "parent_id": "1746"
    },
    {
      "area_id": "1754",
      "area_code": "421000",
      "area_name": "荆州市",
      "level": "2",
      "city_code": "0716",
      "center": "112.239746,30.335184",
      "parent_id": "1681"
    },
    {
      "area_id": "1755",
      "area_code": "421002",
      "area_name": "沙市区",
      "level": "3",
      "city_code": "0716",
      "center": "112.25193,30.326009",
      "parent_id": "1754"
    },
    {
      "area_id": "1756",
      "area_code": "421003",
      "area_name": "荆州区",
      "level": "3",
      "city_code": "0716",
      "center": "112.190185,30.352853",
      "parent_id": "1754"
    },
    {
      "area_id": "1757",
      "area_code": "421022",
      "area_name": "公安县",
      "level": "3",
      "city_code": "0716",
      "center": "112.229648,30.058336",
      "parent_id": "1754"
    },
    {
      "area_id": "1758",
      "area_code": "421023",
      "area_name": "监利县",
      "level": "3",
      "city_code": "0716",
      "center": "112.904788,29.840179",
      "parent_id": "1754"
    },
    {
      "area_id": "1759",
      "area_code": "421024",
      "area_name": "江陵县",
      "level": "3",
      "city_code": "0716",
      "center": "112.424664,30.041822",
      "parent_id": "1754"
    },
    {
      "area_id": "1760",
      "area_code": "421081",
      "area_name": "石首市",
      "level": "3",
      "city_code": "0716",
      "center": "112.425454,29.720938",
      "parent_id": "1754"
    },
    {
      "area_id": "1761",
      "area_code": "421083",
      "area_name": "洪湖市",
      "level": "3",
      "city_code": "0716",
      "center": "113.475801,29.826916",
      "parent_id": "1754"
    },
    {
      "area_id": "1762",
      "area_code": "421087",
      "area_name": "松滋市",
      "level": "3",
      "city_code": "0716",
      "center": "111.756781,30.174529",
      "parent_id": "1754"
    },
    {
      "area_id": "1763",
      "area_code": "421100",
      "area_name": "黄冈市",
      "level": "2",
      "city_code": "0713",
      "center": "114.872199,30.453667",
      "parent_id": "1681"
    },
    {
      "area_id": "1764",
      "area_code": "421102",
      "area_name": "黄州区",
      "level": "3",
      "city_code": "0713",
      "center": "114.880104,30.434354",
      "parent_id": "1763"
    },
    {
      "area_id": "1765",
      "area_code": "421121",
      "area_name": "团风县",
      "level": "3",
      "city_code": "0713",
      "center": "114.872191,30.643569",
      "parent_id": "1763"
    },
    {
      "area_id": "1766",
      "area_code": "421122",
      "area_name": "红安县",
      "level": "3",
      "city_code": "0713",
      "center": "114.618236,31.288153",
      "parent_id": "1763"
    },
    {
      "area_id": "1767",
      "area_code": "421123",
      "area_name": "罗田县",
      "level": "3",
      "city_code": "0713",
      "center": "115.399222,30.78429",
      "parent_id": "1763"
    },
    {
      "area_id": "1768",
      "area_code": "421124",
      "area_name": "英山县",
      "level": "3",
      "city_code": "0713",
      "center": "115.681359,30.735157",
      "parent_id": "1763"
    },
    {
      "area_id": "1769",
      "area_code": "421125",
      "area_name": "浠水县",
      "level": "3",
      "city_code": "0713",
      "center": "115.265355,30.452115",
      "parent_id": "1763"
    },
    {
      "area_id": "1770",
      "area_code": "421126",
      "area_name": "蕲春县",
      "level": "3",
      "city_code": "0713",
      "center": "115.437007,30.225964",
      "parent_id": "1763"
    },
    {
      "area_id": "1771",
      "area_code": "421127",
      "area_name": "黄梅县",
      "level": "3",
      "city_code": "0713",
      "center": "115.944219,30.070453",
      "parent_id": "1763"
    },
    {
      "area_id": "1772",
      "area_code": "421181",
      "area_name": "麻城市",
      "level": "3",
      "city_code": "0713",
      "center": "115.008163,31.172739",
      "parent_id": "1763"
    },
    {
      "area_id": "1773",
      "area_code": "421182",
      "area_name": "武穴市",
      "level": "3",
      "city_code": "0713",
      "center": "115.561217,29.844107",
      "parent_id": "1763"
    },
    {
      "area_id": "1774",
      "area_code": "421200",
      "area_name": "咸宁市",
      "level": "2",
      "city_code": "0715",
      "center": "114.322616,29.841362",
      "parent_id": "1681"
    },
    {
      "area_id": "1775",
      "area_code": "421202",
      "area_name": "咸安区",
      "level": "3",
      "city_code": "0715",
      "center": "114.298711,29.852891",
      "parent_id": "1774"
    },
    {
      "area_id": "1776",
      "area_code": "421221",
      "area_name": "嘉鱼县",
      "level": "3",
      "city_code": "0715",
      "center": "113.939271,29.970676",
      "parent_id": "1774"
    },
    {
      "area_id": "1777",
      "area_code": "421222",
      "area_name": "通城县",
      "level": "3",
      "city_code": "0715",
      "center": "113.816966,29.245269",
      "parent_id": "1774"
    },
    {
      "area_id": "1778",
      "area_code": "421223",
      "area_name": "崇阳县",
      "level": "3",
      "city_code": "0715",
      "center": "114.039523,29.556688",
      "parent_id": "1774"
    },
    {
      "area_id": "1779",
      "area_code": "421224",
      "area_name": "通山县",
      "level": "3",
      "city_code": "0715",
      "center": "114.482622,29.606372",
      "parent_id": "1774"
    },
    {
      "area_id": "1780",
      "area_code": "421281",
      "area_name": "赤壁市",
      "level": "3",
      "city_code": "0715",
      "center": "113.90038,29.725184",
      "parent_id": "1774"
    },
    {
      "area_id": "1781",
      "area_code": "421300",
      "area_name": "随州市",
      "level": "2",
      "city_code": "0722",
      "center": "113.382515,31.690191",
      "parent_id": "1681"
    },
    {
      "area_id": "1782",
      "area_code": "421303",
      "area_name": "曾都区",
      "level": "3",
      "city_code": "0722",
      "center": "113.37112,31.71628",
      "parent_id": "1781"
    },
    {
      "area_id": "1783",
      "area_code": "421321",
      "area_name": "随县",
      "level": "3",
      "city_code": "0722",
      "center": "113.290634,31.883739",
      "parent_id": "1781"
    },
    {
      "area_id": "1784",
      "area_code": "421381",
      "area_name": "广水市",
      "level": "3",
      "city_code": "0722",
      "center": "113.825889,31.616853",
      "parent_id": "1781"
    },
    {
      "area_id": "1785",
      "area_code": "422800",
      "area_name": "恩施土家族苗族自治州",
      "level": "2",
      "city_code": "0718",
      "center": "109.488172,30.272156",
      "parent_id": "1681"
    },
    {
      "area_id": "1786",
      "area_code": "422801",
      "area_name": "恩施市",
      "level": "3",
      "city_code": "0718",
      "center": "109.479664,30.29468",
      "parent_id": "1785"
    },
    {
      "area_id": "1787",
      "area_code": "422802",
      "area_name": "利川市",
      "level": "3",
      "city_code": "0718",
      "center": "108.936452,30.29098",
      "parent_id": "1785"
    },
    {
      "area_id": "1788",
      "area_code": "422822",
      "area_name": "建始县",
      "level": "3",
      "city_code": "0718",
      "center": "109.722109,30.602129",
      "parent_id": "1785"
    },
    {
      "area_id": "1789",
      "area_code": "422823",
      "area_name": "巴东县",
      "level": "3",
      "city_code": "0718",
      "center": "110.340756,31.042324",
      "parent_id": "1785"
    },
    {
      "area_id": "1790",
      "area_code": "422825",
      "area_name": "宣恩县",
      "level": "3",
      "city_code": "0718",
      "center": "109.489926,29.98692",
      "parent_id": "1785"
    },
    {
      "area_id": "1791",
      "area_code": "422826",
      "area_name": "咸丰县",
      "level": "3",
      "city_code": "0718",
      "center": "109.139726,29.665202",
      "parent_id": "1785"
    },
    {
      "area_id": "1792",
      "area_code": "422827",
      "area_name": "来凤县",
      "level": "3",
      "city_code": "0718",
      "center": "109.407828,29.493484",
      "parent_id": "1785"
    },
    {
      "area_id": "1793",
      "area_code": "422828",
      "area_name": "鹤峰县",
      "level": "3",
      "city_code": "0718",
      "center": "110.033662,29.890171",
      "parent_id": "1785"
    },
    {
      "area_id": "1794",
      "area_code": "429005",
      "area_name": "潜江市",
      "level": "2",
      "city_code": "2728",
      "center": "112.899762,30.402167",
      "parent_id": "1681"
    },
    {
      "area_id": "1795",
      "area_code": "429021",
      "area_name": "神农架林区",
      "level": "2",
      "city_code": "1719",
      "center": "110.675743,31.744915",
      "parent_id": "1681"
    },
    {
      "area_id": "1796",
      "area_code": "429006",
      "area_name": "天门市",
      "level": "2",
      "city_code": "1728",
      "center": "113.166078,30.663337",
      "parent_id": "1681"
    },
    {
      "area_id": "1797",
      "area_code": "429004",
      "area_name": "仙桃市",
      "level": "2",
      "city_code": "0728",
      "center": "113.423583,30.361438",
      "parent_id": "1681"
    },
    {
      "area_id": "1798",
      "area_code": "430000",
      "area_name": "湖南省",
      "level": "1",
      "city_code": "[]",
      "center": "112.9836,28.112743",
      "parent_id": "0"
    },
    {
      "area_id": "1799",
      "area_code": "430100",
      "area_name": "长沙市",
      "level": "2",
      "city_code": "0731",
      "center": "112.938884,28.22808",
      "parent_id": "1798"
    },
    {
      "area_id": "1800",
      "area_code": "430102",
      "area_name": "芙蓉区",
      "level": "3",
      "city_code": "0731",
      "center": "113.032539,28.185389",
      "parent_id": "1799"
    },
    {
      "area_id": "1801",
      "area_code": "430103",
      "area_name": "天心区",
      "level": "3",
      "city_code": "0731",
      "center": "112.989897,28.114526",
      "parent_id": "1799"
    },
    {
      "area_id": "1802",
      "area_code": "430104",
      "area_name": "岳麓区",
      "level": "3",
      "city_code": "0731",
      "center": "112.93132,28.234538",
      "parent_id": "1799"
    },
    {
      "area_id": "1803",
      "area_code": "430105",
      "area_name": "开福区",
      "level": "3",
      "city_code": "0731",
      "center": "112.985884,28.256298",
      "parent_id": "1799"
    },
    {
      "area_id": "1804",
      "area_code": "430111",
      "area_name": "雨花区",
      "level": "3",
      "city_code": "0731",
      "center": "113.03826,28.135722",
      "parent_id": "1799"
    },
    {
      "area_id": "1805",
      "area_code": "430112",
      "area_name": "望城区",
      "level": "3",
      "city_code": "0731",
      "center": "112.831176,28.353434",
      "parent_id": "1799"
    },
    {
      "area_id": "1806",
      "area_code": "430121",
      "area_name": "长沙县",
      "level": "3",
      "city_code": "0731",
      "center": "113.081097,28.246918",
      "parent_id": "1799"
    },
    {
      "area_id": "1807",
      "area_code": "430124",
      "area_name": "宁乡市",
      "level": "3",
      "city_code": "0731",
      "center": "112.551885,28.277483",
      "parent_id": "1799"
    },
    {
      "area_id": "1808",
      "area_code": "430181",
      "area_name": "浏阳市",
      "level": "3",
      "city_code": "0731",
      "center": "113.643076,28.162833",
      "parent_id": "1799"
    },
    {
      "area_id": "1809",
      "area_code": "430200",
      "area_name": "株洲市",
      "level": "2",
      "city_code": "0733",
      "center": "113.133853,27.827986",
      "parent_id": "1798"
    },
    {
      "area_id": "1810",
      "area_code": "430202",
      "area_name": "荷塘区",
      "level": "3",
      "city_code": "0733",
      "center": "113.173487,27.855928",
      "parent_id": "1809"
    },
    {
      "area_id": "1811",
      "area_code": "430203",
      "area_name": "芦淞区",
      "level": "3",
      "city_code": "0733",
      "center": "113.152724,27.78507",
      "parent_id": "1809"
    },
    {
      "area_id": "1812",
      "area_code": "430204",
      "area_name": "石峰区",
      "level": "3",
      "city_code": "0733",
      "center": "113.117731,27.875445",
      "parent_id": "1809"
    },
    {
      "area_id": "1813",
      "area_code": "430211",
      "area_name": "天元区",
      "level": "3",
      "city_code": "0733",
      "center": "113.082216,27.826866",
      "parent_id": "1809"
    },
    {
      "area_id": "1814",
      "area_code": "430221",
      "area_name": "株洲县",
      "level": "3",
      "city_code": "0733",
      "center": "113.144109,27.699232",
      "parent_id": "1809"
    },
    {
      "area_id": "1815",
      "area_code": "430223",
      "area_name": "攸县",
      "level": "3",
      "city_code": "0733",
      "center": "113.396385,27.014583",
      "parent_id": "1809"
    },
    {
      "area_id": "1816",
      "area_code": "430224",
      "area_name": "茶陵县",
      "level": "3",
      "city_code": "0733",
      "center": "113.539094,26.777521",
      "parent_id": "1809"
    },
    {
      "area_id": "1817",
      "area_code": "430225",
      "area_name": "炎陵县",
      "level": "3",
      "city_code": "0733",
      "center": "113.772655,26.489902",
      "parent_id": "1809"
    },
    {
      "area_id": "1818",
      "area_code": "430281",
      "area_name": "醴陵市",
      "level": "3",
      "city_code": "0733",
      "center": "113.496999,27.646096",
      "parent_id": "1809"
    },
    {
      "area_id": "1819",
      "area_code": "430300",
      "area_name": "湘潭市",
      "level": "2",
      "city_code": "0732",
      "center": "112.944026,27.829795",
      "parent_id": "1798"
    },
    {
      "area_id": "1820",
      "area_code": "430302",
      "area_name": "雨湖区",
      "level": "3",
      "city_code": "0732",
      "center": "112.907162,27.856325",
      "parent_id": "1819"
    },
    {
      "area_id": "1821",
      "area_code": "430304",
      "area_name": "岳塘区",
      "level": "3",
      "city_code": "0732",
      "center": "112.969479,27.872028",
      "parent_id": "1819"
    },
    {
      "area_id": "1822",
      "area_code": "430321",
      "area_name": "湘潭县",
      "level": "3",
      "city_code": "0732",
      "center": "112.950831,27.778958",
      "parent_id": "1819"
    },
    {
      "area_id": "1823",
      "area_code": "430381",
      "area_name": "湘乡市",
      "level": "3",
      "city_code": "0732",
      "center": "112.550205,27.718549",
      "parent_id": "1819"
    },
    {
      "area_id": "1824",
      "area_code": "430382",
      "area_name": "韶山市",
      "level": "3",
      "city_code": "0732",
      "center": "112.52667,27.915008",
      "parent_id": "1819"
    },
    {
      "area_id": "1825",
      "area_code": "430400",
      "area_name": "衡阳市",
      "level": "2",
      "city_code": "0734",
      "center": "112.572018,26.893368",
      "parent_id": "1798"
    },
    {
      "area_id": "1826",
      "area_code": "430405",
      "area_name": "珠晖区",
      "level": "3",
      "city_code": "0734",
      "center": "112.620209,26.894765",
      "parent_id": "1825"
    },
    {
      "area_id": "1827",
      "area_code": "430406",
      "area_name": "雁峰区",
      "level": "3",
      "city_code": "0734",
      "center": "112.6154,26.840602",
      "parent_id": "1825"
    },
    {
      "area_id": "1828",
      "area_code": "430407",
      "area_name": "石鼓区",
      "level": "3",
      "city_code": "0734",
      "center": "112.597992,26.943755",
      "parent_id": "1825"
    },
    {
      "area_id": "1829",
      "area_code": "430408",
      "area_name": "蒸湘区",
      "level": "3",
      "city_code": "0734",
      "center": "112.567107,26.911854",
      "parent_id": "1825"
    },
    {
      "area_id": "1830",
      "area_code": "430412",
      "area_name": "南岳区",
      "level": "3",
      "city_code": "0734",
      "center": "112.738604,27.232443",
      "parent_id": "1825"
    },
    {
      "area_id": "1831",
      "area_code": "430421",
      "area_name": "衡阳县",
      "level": "3",
      "city_code": "0734",
      "center": "112.370546,26.969577",
      "parent_id": "1825"
    },
    {
      "area_id": "1832",
      "area_code": "430422",
      "area_name": "衡南县",
      "level": "3",
      "city_code": "0734",
      "center": "112.677877,26.738247",
      "parent_id": "1825"
    },
    {
      "area_id": "1833",
      "area_code": "430423",
      "area_name": "衡山县",
      "level": "3",
      "city_code": "0734",
      "center": "112.868268,27.23029",
      "parent_id": "1825"
    },
    {
      "area_id": "1834",
      "area_code": "430424",
      "area_name": "衡东县",
      "level": "3",
      "city_code": "0734",
      "center": "112.953168,27.08117",
      "parent_id": "1825"
    },
    {
      "area_id": "1835",
      "area_code": "430426",
      "area_name": "祁东县",
      "level": "3",
      "city_code": "0734",
      "center": "112.090356,26.799896",
      "parent_id": "1825"
    },
    {
      "area_id": "1836",
      "area_code": "430481",
      "area_name": "耒阳市",
      "level": "3",
      "city_code": "0734",
      "center": "112.859759,26.422277",
      "parent_id": "1825"
    },
    {
      "area_id": "1837",
      "area_code": "430482",
      "area_name": "常宁市",
      "level": "3",
      "city_code": "0734",
      "center": "112.399878,26.421956",
      "parent_id": "1825"
    },
    {
      "area_id": "1838",
      "area_code": "430500",
      "area_name": "邵阳市",
      "level": "2",
      "city_code": "0739",
      "center": "111.467674,27.23895",
      "parent_id": "1798"
    },
    {
      "area_id": "1839",
      "area_code": "430502",
      "area_name": "双清区",
      "level": "3",
      "city_code": "0739",
      "center": "111.496341,27.232708",
      "parent_id": "1838"
    },
    {
      "area_id": "1840",
      "area_code": "430503",
      "area_name": "大祥区",
      "level": "3",
      "city_code": "0739",
      "center": "111.439091,27.221452",
      "parent_id": "1838"
    },
    {
      "area_id": "1841",
      "area_code": "430511",
      "area_name": "北塔区",
      "level": "3",
      "city_code": "0739",
      "center": "111.452196,27.246489",
      "parent_id": "1838"
    },
    {
      "area_id": "1842",
      "area_code": "430521",
      "area_name": "邵东县",
      "level": "3",
      "city_code": "0739",
      "center": "111.74427,27.258987",
      "parent_id": "1838"
    },
    {
      "area_id": "1843",
      "area_code": "430522",
      "area_name": "新邵县",
      "level": "3",
      "city_code": "0739",
      "center": "111.458656,27.320917",
      "parent_id": "1838"
    },
    {
      "area_id": "1844",
      "area_code": "430523",
      "area_name": "邵阳县",
      "level": "3",
      "city_code": "0739",
      "center": "111.273805,26.990637",
      "parent_id": "1838"
    },
    {
      "area_id": "1845",
      "area_code": "430524",
      "area_name": "隆回县",
      "level": "3",
      "city_code": "0739",
      "center": "111.032437,27.113978",
      "parent_id": "1838"
    },
    {
      "area_id": "1846",
      "area_code": "430525",
      "area_name": "洞口县",
      "level": "3",
      "city_code": "0739",
      "center": "110.575846,27.06032",
      "parent_id": "1838"
    },
    {
      "area_id": "1847",
      "area_code": "430527",
      "area_name": "绥宁县",
      "level": "3",
      "city_code": "0739",
      "center": "110.155655,26.581954",
      "parent_id": "1838"
    },
    {
      "area_id": "1848",
      "area_code": "430528",
      "area_name": "新宁县",
      "level": "3",
      "city_code": "0739",
      "center": "110.856988,26.433367",
      "parent_id": "1838"
    },
    {
      "area_id": "1849",
      "area_code": "430529",
      "area_name": "城步苗族自治县",
      "level": "3",
      "city_code": "0739",
      "center": "110.322239,26.390598",
      "parent_id": "1838"
    },
    {
      "area_id": "1850",
      "area_code": "430581",
      "area_name": "武冈市",
      "level": "3",
      "city_code": "0739",
      "center": "110.631884,26.726599",
      "parent_id": "1838"
    },
    {
      "area_id": "1851",
      "area_code": "430600",
      "area_name": "岳阳市",
      "level": "2",
      "city_code": "0730",
      "center": "113.12873,29.356803",
      "parent_id": "1798"
    },
    {
      "area_id": "1852",
      "area_code": "430602",
      "area_name": "岳阳楼区",
      "level": "3",
      "city_code": "0730",
      "center": "113.129684,29.371814",
      "parent_id": "1851"
    },
    {
      "area_id": "1853",
      "area_code": "430603",
      "area_name": "云溪区",
      "level": "3",
      "city_code": "0730",
      "center": "113.272312,29.472745",
      "parent_id": "1851"
    },
    {
      "area_id": "1854",
      "area_code": "430611",
      "area_name": "君山区",
      "level": "3",
      "city_code": "0730",
      "center": "113.006435,29.461106",
      "parent_id": "1851"
    },
    {
      "area_id": "1855",
      "area_code": "430621",
      "area_name": "岳阳县",
      "level": "3",
      "city_code": "0730",
      "center": "113.116418,29.144066",
      "parent_id": "1851"
    },
    {
      "area_id": "1856",
      "area_code": "430623",
      "area_name": "华容县",
      "level": "3",
      "city_code": "0730",
      "center": "112.540463,29.531057",
      "parent_id": "1851"
    },
    {
      "area_id": "1857",
      "area_code": "430624",
      "area_name": "湘阴县",
      "level": "3",
      "city_code": "0730",
      "center": "112.909426,28.689104",
      "parent_id": "1851"
    },
    {
      "area_id": "1858",
      "area_code": "430626",
      "area_name": "平江县",
      "level": "3",
      "city_code": "0730",
      "center": "113.581234,28.701868",
      "parent_id": "1851"
    },
    {
      "area_id": "1859",
      "area_code": "430681",
      "area_name": "汨罗市",
      "level": "3",
      "city_code": "0730",
      "center": "113.067251,28.806881",
      "parent_id": "1851"
    },
    {
      "area_id": "1860",
      "area_code": "430682",
      "area_name": "临湘市",
      "level": "3",
      "city_code": "0730",
      "center": "113.450423,29.476849",
      "parent_id": "1851"
    },
    {
      "area_id": "1861",
      "area_code": "430700",
      "area_name": "常德市",
      "level": "2",
      "city_code": "0736",
      "center": "111.698784,29.031654",
      "parent_id": "1798"
    },
    {
      "area_id": "1862",
      "area_code": "430702",
      "area_name": "武陵区",
      "level": "3",
      "city_code": "0736",
      "center": "111.683153,29.055163",
      "parent_id": "1861"
    },
    {
      "area_id": "1863",
      "area_code": "430703",
      "area_name": "鼎城区",
      "level": "3",
      "city_code": "0736",
      "center": "111.680783,29.018593",
      "parent_id": "1861"
    },
    {
      "area_id": "1864",
      "area_code": "430721",
      "area_name": "安乡县",
      "level": "3",
      "city_code": "0736",
      "center": "112.171131,29.411309",
      "parent_id": "1861"
    },
    {
      "area_id": "1865",
      "area_code": "430722",
      "area_name": "汉寿县",
      "level": "3",
      "city_code": "0736",
      "center": "111.970514,28.906106",
      "parent_id": "1861"
    },
    {
      "area_id": "1866",
      "area_code": "430723",
      "area_name": "澧县",
      "level": "3",
      "city_code": "0736",
      "center": "111.758702,29.633236",
      "parent_id": "1861"
    },
    {
      "area_id": "1867",
      "area_code": "430724",
      "area_name": "临澧县",
      "level": "3",
      "city_code": "0736",
      "center": "111.647517,29.440793",
      "parent_id": "1861"
    },
    {
      "area_id": "1868",
      "area_code": "430725",
      "area_name": "桃源县",
      "level": "3",
      "city_code": "0736",
      "center": "111.488925,28.902503",
      "parent_id": "1861"
    },
    {
      "area_id": "1869",
      "area_code": "430726",
      "area_name": "石门县",
      "level": "3",
      "city_code": "0736",
      "center": "111.380014,29.584292",
      "parent_id": "1861"
    },
    {
      "area_id": "1870",
      "area_code": "430781",
      "area_name": "津市市",
      "level": "3",
      "city_code": "0736",
      "center": "111.877499,29.60548",
      "parent_id": "1861"
    },
    {
      "area_id": "1871",
      "area_code": "430800",
      "area_name": "张家界市",
      "level": "2",
      "city_code": "0744",
      "center": "110.479148,29.117013",
      "parent_id": "1798"
    },
    {
      "area_id": "1872",
      "area_code": "430802",
      "area_name": "永定区",
      "level": "3",
      "city_code": "0744",
      "center": "110.537138,29.119855",
      "parent_id": "1871"
    },
    {
      "area_id": "1873",
      "area_code": "430811",
      "area_name": "武陵源区",
      "level": "3",
      "city_code": "0744",
      "center": "110.550433,29.34573",
      "parent_id": "1871"
    },
    {
      "area_id": "1874",
      "area_code": "430821",
      "area_name": "慈利县",
      "level": "3",
      "city_code": "0744",
      "center": "111.139775,29.429999",
      "parent_id": "1871"
    },
    {
      "area_id": "1875",
      "area_code": "430822",
      "area_name": "桑植县",
      "level": "3",
      "city_code": "0744",
      "center": "110.204652,29.414111",
      "parent_id": "1871"
    },
    {
      "area_id": "1876",
      "area_code": "430900",
      "area_name": "益阳市",
      "level": "2",
      "city_code": "0737",
      "center": "112.355129,28.554349",
      "parent_id": "1798"
    },
    {
      "area_id": "1877",
      "area_code": "430902",
      "area_name": "资阳区",
      "level": "3",
      "city_code": "0737",
      "center": "112.324272,28.59111",
      "parent_id": "1876"
    },
    {
      "area_id": "1878",
      "area_code": "430903",
      "area_name": "赫山区",
      "level": "3",
      "city_code": "0737",
      "center": "112.374145,28.579494",
      "parent_id": "1876"
    },
    {
      "area_id": "1879",
      "area_code": "430921",
      "area_name": "南县",
      "level": "3",
      "city_code": "0737",
      "center": "112.396337,29.362275",
      "parent_id": "1876"
    },
    {
      "area_id": "1880",
      "area_code": "430922",
      "area_name": "桃江县",
      "level": "3",
      "city_code": "0737",
      "center": "112.155822,28.518084",
      "parent_id": "1876"
    },
    {
      "area_id": "1881",
      "area_code": "430923",
      "area_name": "安化县",
      "level": "3",
      "city_code": "0737",
      "center": "111.212846,28.374107",
      "parent_id": "1876"
    },
    {
      "area_id": "1882",
      "area_code": "430981",
      "area_name": "沅江市",
      "level": "3",
      "city_code": "0737",
      "center": "112.355954,28.847045",
      "parent_id": "1876"
    },
    {
      "area_id": "1883",
      "area_code": "431000",
      "area_name": "郴州市",
      "level": "2",
      "city_code": "0735",
      "center": "113.014984,25.770532",
      "parent_id": "1798"
    },
    {
      "area_id": "1884",
      "area_code": "431002",
      "area_name": "北湖区",
      "level": "3",
      "city_code": "0735",
      "center": "113.011035,25.784054",
      "parent_id": "1883"
    },
    {
      "area_id": "1885",
      "area_code": "431003",
      "area_name": "苏仙区",
      "level": "3",
      "city_code": "0735",
      "center": "113.112105,25.797013",
      "parent_id": "1883"
    },
    {
      "area_id": "1886",
      "area_code": "431021",
      "area_name": "桂阳县",
      "level": "3",
      "city_code": "0735",
      "center": "112.734173,25.754172",
      "parent_id": "1883"
    },
    {
      "area_id": "1887",
      "area_code": "431022",
      "area_name": "宜章县",
      "level": "3",
      "city_code": "0735",
      "center": "112.948712,25.399938",
      "parent_id": "1883"
    },
    {
      "area_id": "1888",
      "area_code": "431023",
      "area_name": "永兴县",
      "level": "3",
      "city_code": "0735",
      "center": "113.116527,26.12715",
      "parent_id": "1883"
    },
    {
      "area_id": "1889",
      "area_code": "431024",
      "area_name": "嘉禾县",
      "level": "3",
      "city_code": "0735",
      "center": "112.36902,25.587519",
      "parent_id": "1883"
    },
    {
      "area_id": "1890",
      "area_code": "431025",
      "area_name": "临武县",
      "level": "3",
      "city_code": "0735",
      "center": "112.563456,25.27556",
      "parent_id": "1883"
    },
    {
      "area_id": "1891",
      "area_code": "431026",
      "area_name": "汝城县",
      "level": "3",
      "city_code": "0735",
      "center": "113.684727,25.532816",
      "parent_id": "1883"
    },
    {
      "area_id": "1892",
      "area_code": "431027",
      "area_name": "桂东县",
      "level": "3",
      "city_code": "0735",
      "center": "113.944614,26.077616",
      "parent_id": "1883"
    },
    {
      "area_id": "1893",
      "area_code": "431028",
      "area_name": "安仁县",
      "level": "3",
      "city_code": "0735",
      "center": "113.26932,26.709061",
      "parent_id": "1883"
    },
    {
      "area_id": "1894",
      "area_code": "431081",
      "area_name": "资兴市",
      "level": "3",
      "city_code": "0735",
      "center": "113.236146,25.976243",
      "parent_id": "1883"
    },
    {
      "area_id": "1895",
      "area_code": "431100",
      "area_name": "永州市",
      "level": "2",
      "city_code": "0746",
      "center": "111.613418,26.419641",
      "parent_id": "1798"
    },
    {
      "area_id": "1896",
      "area_code": "431102",
      "area_name": "零陵区",
      "level": "3",
      "city_code": "0746",
      "center": "111.631109,26.221936",
      "parent_id": "1895"
    },
    {
      "area_id": "1897",
      "area_code": "431103",
      "area_name": "冷水滩区",
      "level": "3",
      "city_code": "0746",
      "center": "111.592343,26.46128",
      "parent_id": "1895"
    },
    {
      "area_id": "1898",
      "area_code": "431121",
      "area_name": "祁阳县",
      "level": "3",
      "city_code": "0746",
      "center": "111.840657,26.58012",
      "parent_id": "1895"
    },
    {
      "area_id": "1899",
      "area_code": "431122",
      "area_name": "东安县",
      "level": "3",
      "city_code": "0746",
      "center": "111.316464,26.392183",
      "parent_id": "1895"
    },
    {
      "area_id": "1900",
      "area_code": "431123",
      "area_name": "双牌县",
      "level": "3",
      "city_code": "0746",
      "center": "111.659967,25.961909",
      "parent_id": "1895"
    },
    {
      "area_id": "1901",
      "area_code": "431124",
      "area_name": "道县",
      "level": "3",
      "city_code": "0746",
      "center": "111.600795,25.526437",
      "parent_id": "1895"
    },
    {
      "area_id": "1902",
      "area_code": "431125",
      "area_name": "江永县",
      "level": "3",
      "city_code": "0746",
      "center": "111.343911,25.273539",
      "parent_id": "1895"
    },
    {
      "area_id": "1903",
      "area_code": "431126",
      "area_name": "宁远县",
      "level": "3",
      "city_code": "0746",
      "center": "111.945844,25.570888",
      "parent_id": "1895"
    },
    {
      "area_id": "1904",
      "area_code": "431127",
      "area_name": "蓝山县",
      "level": "3",
      "city_code": "0746",
      "center": "112.196567,25.369725",
      "parent_id": "1895"
    },
    {
      "area_id": "1905",
      "area_code": "431128",
      "area_name": "新田县",
      "level": "3",
      "city_code": "0746",
      "center": "112.203287,25.904305",
      "parent_id": "1895"
    },
    {
      "area_id": "1906",
      "area_code": "431129",
      "area_name": "江华瑶族自治县",
      "level": "3",
      "city_code": "0746",
      "center": "111.579535,25.185809",
      "parent_id": "1895"
    },
    {
      "area_id": "1907",
      "area_code": "431200",
      "area_name": "怀化市",
      "level": "2",
      "city_code": "0745",
      "center": "110.001923,27.569517",
      "parent_id": "1798"
    },
    {
      "area_id": "1908",
      "area_code": "431202",
      "area_name": "鹤城区",
      "level": "3",
      "city_code": "0745",
      "center": "110.040315,27.578926",
      "parent_id": "1907"
    },
    {
      "area_id": "1909",
      "area_code": "431221",
      "area_name": "中方县",
      "level": "3",
      "city_code": "0745",
      "center": "109.944711,27.440138",
      "parent_id": "1907"
    },
    {
      "area_id": "1910",
      "area_code": "431222",
      "area_name": "沅陵县",
      "level": "3",
      "city_code": "0745",
      "center": "110.393844,28.452686",
      "parent_id": "1907"
    },
    {
      "area_id": "1911",
      "area_code": "431223",
      "area_name": "辰溪县",
      "level": "3",
      "city_code": "0745",
      "center": "110.183917,28.006336",
      "parent_id": "1907"
    },
    {
      "area_id": "1912",
      "area_code": "431224",
      "area_name": "溆浦县",
      "level": "3",
      "city_code": "0745",
      "center": "110.594879,27.908267",
      "parent_id": "1907"
    },
    {
      "area_id": "1913",
      "area_code": "431225",
      "area_name": "会同县",
      "level": "3",
      "city_code": "0745",
      "center": "109.735661,26.887238",
      "parent_id": "1907"
    },
    {
      "area_id": "1914",
      "area_code": "431226",
      "area_name": "麻阳苗族自治县",
      "level": "3",
      "city_code": "0745",
      "center": "109.81701,27.857569",
      "parent_id": "1907"
    },
    {
      "area_id": "1915",
      "area_code": "431227",
      "area_name": "新晃侗族自治县",
      "level": "3",
      "city_code": "0745",
      "center": "109.174932,27.352673",
      "parent_id": "1907"
    },
    {
      "area_id": "1916",
      "area_code": "431228",
      "area_name": "芷江侗族自治县",
      "level": "3",
      "city_code": "0745",
      "center": "109.684629,27.443499",
      "parent_id": "1907"
    },
    {
      "area_id": "1917",
      "area_code": "431229",
      "area_name": "靖州苗族侗族自治县",
      "level": "3",
      "city_code": "0745",
      "center": "109.696273,26.575107",
      "parent_id": "1907"
    },
    {
      "area_id": "1918",
      "area_code": "431230",
      "area_name": "通道侗族自治县",
      "level": "3",
      "city_code": "0745",
      "center": "109.784412,26.158054",
      "parent_id": "1907"
    },
    {
      "area_id": "1919",
      "area_code": "431281",
      "area_name": "洪江市",
      "level": "3",
      "city_code": "0745",
      "center": "109.836669,27.208609",
      "parent_id": "1907"
    },
    {
      "area_id": "1920",
      "area_code": "431300",
      "area_name": "娄底市",
      "level": "2",
      "city_code": "0738",
      "center": "111.994482,27.70027",
      "parent_id": "1798"
    },
    {
      "area_id": "1921",
      "area_code": "431302",
      "area_name": "娄星区",
      "level": "3",
      "city_code": "0738",
      "center": "112.001914,27.729863",
      "parent_id": "1920"
    },
    {
      "area_id": "1922",
      "area_code": "431321",
      "area_name": "双峰县",
      "level": "3",
      "city_code": "0738",
      "center": "112.175163,27.457172",
      "parent_id": "1920"
    },
    {
      "area_id": "1923",
      "area_code": "431322",
      "area_name": "新化县",
      "level": "3",
      "city_code": "0738",
      "center": "111.327412,27.726514",
      "parent_id": "1920"
    },
    {
      "area_id": "1924",
      "area_code": "431381",
      "area_name": "冷水江市",
      "level": "3",
      "city_code": "0738",
      "center": "111.434984,27.686251",
      "parent_id": "1920"
    },
    {
      "area_id": "1925",
      "area_code": "431382",
      "area_name": "涟源市",
      "level": "3",
      "city_code": "0738",
      "center": "111.664329,27.692577",
      "parent_id": "1920"
    },
    {
      "area_id": "1926",
      "area_code": "433100",
      "area_name": "湘西土家族苗族自治州",
      "level": "2",
      "city_code": "0743",
      "center": "109.738906,28.31195",
      "parent_id": "1798"
    },
    {
      "area_id": "1927",
      "area_code": "433101",
      "area_name": "吉首市",
      "level": "3",
      "city_code": "0743",
      "center": "109.698015,28.262376",
      "parent_id": "1926"
    },
    {
      "area_id": "1928",
      "area_code": "433122",
      "area_name": "泸溪县",
      "level": "3",
      "city_code": "0743",
      "center": "110.21961,28.216641",
      "parent_id": "1926"
    },
    {
      "area_id": "1929",
      "area_code": "433123",
      "area_name": "凤凰县",
      "level": "3",
      "city_code": "0743",
      "center": "109.581083,27.958081",
      "parent_id": "1926"
    },
    {
      "area_id": "1930",
      "area_code": "433124",
      "area_name": "花垣县",
      "level": "3",
      "city_code": "0743",
      "center": "109.482078,28.572029",
      "parent_id": "1926"
    },
    {
      "area_id": "1931",
      "area_code": "433125",
      "area_name": "保靖县",
      "level": "3",
      "city_code": "0743",
      "center": "109.660559,28.699878",
      "parent_id": "1926"
    },
    {
      "area_id": "1932",
      "area_code": "433126",
      "area_name": "古丈县",
      "level": "3",
      "city_code": "0743",
      "center": "109.950728,28.616935",
      "parent_id": "1926"
    },
    {
      "area_id": "1933",
      "area_code": "433127",
      "area_name": "永顺县",
      "level": "3",
      "city_code": "0743",
      "center": "109.856933,28.979955",
      "parent_id": "1926"
    },
    {
      "area_id": "1934",
      "area_code": "433130",
      "area_name": "龙山县",
      "level": "3",
      "city_code": "0743",
      "center": "109.443938,29.457663",
      "parent_id": "1926"
    },
    {
      "area_id": "1935",
      "area_code": "440000",
      "area_name": "广东省",
      "level": "1",
      "city_code": "[]",
      "center": "113.26641,23.132324",
      "parent_id": "0"
    },
    {
      "area_id": "1936",
      "area_code": "440100",
      "area_name": "广州市",
      "level": "2",
      "city_code": "020",
      "center": "113.264385,23.12911",
      "parent_id": "1935"
    },
    {
      "area_id": "1937",
      "area_code": "440103",
      "area_name": "荔湾区",
      "level": "3",
      "city_code": "020",
      "center": "113.244258,23.125863",
      "parent_id": "1936"
    },
    {
      "area_id": "1938",
      "area_code": "440104",
      "area_name": "越秀区",
      "level": "3",
      "city_code": "020",
      "center": "113.266835,23.128537",
      "parent_id": "1936"
    },
    {
      "area_id": "1939",
      "area_code": "440105",
      "area_name": "海珠区",
      "level": "3",
      "city_code": "020",
      "center": "113.317443,23.083788",
      "parent_id": "1936"
    },
    {
      "area_id": "1940",
      "area_code": "440106",
      "area_name": "天河区",
      "level": "3",
      "city_code": "020",
      "center": "113.361575,23.124807",
      "parent_id": "1936"
    },
    {
      "area_id": "1941",
      "area_code": "440111",
      "area_name": "白云区",
      "level": "3",
      "city_code": "020",
      "center": "113.273238,23.157367",
      "parent_id": "1936"
    },
    {
      "area_id": "1942",
      "area_code": "440112",
      "area_name": "黄埔区",
      "level": "3",
      "city_code": "020",
      "center": "113.480541,23.181706",
      "parent_id": "1936"
    },
    {
      "area_id": "1943",
      "area_code": "440113",
      "area_name": "番禺区",
      "level": "3",
      "city_code": "020",
      "center": "113.384152,22.937556",
      "parent_id": "1936"
    },
    {
      "area_id": "1944",
      "area_code": "440114",
      "area_name": "花都区",
      "level": "3",
      "city_code": "020",
      "center": "113.220463,23.403744",
      "parent_id": "1936"
    },
    {
      "area_id": "1945",
      "area_code": "440115",
      "area_name": "南沙区",
      "level": "3",
      "city_code": "020",
      "center": "113.525165,22.801624",
      "parent_id": "1936"
    },
    {
      "area_id": "1946",
      "area_code": "440117",
      "area_name": "从化区",
      "level": "3",
      "city_code": "020",
      "center": "113.586679,23.548748",
      "parent_id": "1936"
    },
    {
      "area_id": "1947",
      "area_code": "440118",
      "area_name": "增城区",
      "level": "3",
      "city_code": "020",
      "center": "113.810627,23.261465",
      "parent_id": "1936"
    },
    {
      "area_id": "1948",
      "area_code": "440200",
      "area_name": "韶关市",
      "level": "2",
      "city_code": "0751",
      "center": "113.59762,24.810879",
      "parent_id": "1935"
    },
    {
      "area_id": "1949",
      "area_code": "440203",
      "area_name": "武江区",
      "level": "3",
      "city_code": "0751",
      "center": "113.587756,24.792926",
      "parent_id": "1948"
    },
    {
      "area_id": "1950",
      "area_code": "440204",
      "area_name": "浈江区",
      "level": "3",
      "city_code": "0751",
      "center": "113.611098,24.804381",
      "parent_id": "1948"
    },
    {
      "area_id": "1951",
      "area_code": "440205",
      "area_name": "曲江区",
      "level": "3",
      "city_code": "0751",
      "center": "113.604535,24.682501",
      "parent_id": "1948"
    },
    {
      "area_id": "1952",
      "area_code": "440222",
      "area_name": "始兴县",
      "level": "3",
      "city_code": "0751",
      "center": "114.061789,24.952976",
      "parent_id": "1948"
    },
    {
      "area_id": "1953",
      "area_code": "440224",
      "area_name": "仁化县",
      "level": "3",
      "city_code": "0751",
      "center": "113.749027,25.085621",
      "parent_id": "1948"
    },
    {
      "area_id": "1954",
      "area_code": "440229",
      "area_name": "翁源县",
      "level": "3",
      "city_code": "0751",
      "center": "114.130342,24.350346",
      "parent_id": "1948"
    },
    {
      "area_id": "1955",
      "area_code": "440232",
      "area_name": "乳源瑶族自治县",
      "level": "3",
      "city_code": "0751",
      "center": "113.275883,24.776078",
      "parent_id": "1948"
    },
    {
      "area_id": "1956",
      "area_code": "440233",
      "area_name": "新丰县",
      "level": "3",
      "city_code": "0751",
      "center": "114.206867,24.05976",
      "parent_id": "1948"
    },
    {
      "area_id": "1957",
      "area_code": "440281",
      "area_name": "乐昌市",
      "level": "3",
      "city_code": "0751",
      "center": "113.347545,25.130602",
      "parent_id": "1948"
    },
    {
      "area_id": "1958",
      "area_code": "440282",
      "area_name": "南雄市",
      "level": "3",
      "city_code": "0751",
      "center": "114.311982,25.117753",
      "parent_id": "1948"
    },
    {
      "area_id": "1959",
      "area_code": "440300",
      "area_name": "深圳市",
      "level": "2",
      "city_code": "0755",
      "center": "114.057939,22.543527",
      "parent_id": "1935"
    },
    {
      "area_id": "1960",
      "area_code": "440303",
      "area_name": "罗湖区",
      "level": "3",
      "city_code": "0755",
      "center": "114.131459,22.548389",
      "parent_id": "1959"
    },
    {
      "area_id": "1961",
      "area_code": "440304",
      "area_name": "福田区",
      "level": "3",
      "city_code": "0755",
      "center": "114.055072,22.521521",
      "parent_id": "1959"
    },
    {
      "area_id": "1962",
      "area_code": "440305",
      "area_name": "南山区",
      "level": "3",
      "city_code": "0755",
      "center": "113.930413,22.533287",
      "parent_id": "1959"
    },
    {
      "area_id": "1963",
      "area_code": "440306",
      "area_name": "宝安区",
      "level": "3",
      "city_code": "0755",
      "center": "113.883802,22.554996",
      "parent_id": "1959"
    },
    {
      "area_id": "1964",
      "area_code": "440307",
      "area_name": "龙岗区",
      "level": "3",
      "city_code": "0755",
      "center": "114.246899,22.720974",
      "parent_id": "1959"
    },
    {
      "area_id": "1965",
      "area_code": "440308",
      "area_name": "盐田区",
      "level": "3",
      "city_code": "0755",
      "center": "114.236739,22.557001",
      "parent_id": "1959"
    },
    {
      "area_id": "1966",
      "area_code": "440309",
      "area_name": "龙华区",
      "level": "3",
      "city_code": "0755",
      "center": "114.045422,22.696667",
      "parent_id": "1959"
    },
    {
      "area_id": "1967",
      "area_code": "440310",
      "area_name": "坪山区",
      "level": "3",
      "city_code": "0755",
      "center": "114.350584,22.708881",
      "parent_id": "1959"
    },
    {
      "area_id": "1968",
      "area_code": "440400",
      "area_name": "珠海市",
      "level": "2",
      "city_code": "0756",
      "center": "113.576677,22.270978",
      "parent_id": "1935"
    },
    {
      "area_id": "1969",
      "area_code": "440402",
      "area_name": "香洲区",
      "level": "3",
      "city_code": "0756",
      "center": "113.543784,22.265811",
      "parent_id": "1968"
    },
    {
      "area_id": "1970",
      "area_code": "440403",
      "area_name": "斗门区",
      "level": "3",
      "city_code": "0756",
      "center": "113.296467,22.2092",
      "parent_id": "1968"
    },
    {
      "area_id": "1971",
      "area_code": "440404",
      "area_name": "金湾区",
      "level": "3",
      "city_code": "0756",
      "center": "113.362656,22.147471",
      "parent_id": "1968"
    },
    {
      "area_id": "1972",
      "area_code": "440500",
      "area_name": "汕头市",
      "level": "2",
      "city_code": "0754",
      "center": "116.681972,23.354091",
      "parent_id": "1935"
    },
    {
      "area_id": "1973",
      "area_code": "440507",
      "area_name": "龙湖区",
      "level": "3",
      "city_code": "0754",
      "center": "116.716446,23.372254",
      "parent_id": "1972"
    },
    {
      "area_id": "1974",
      "area_code": "440511",
      "area_name": "金平区",
      "level": "3",
      "city_code": "0754",
      "center": "116.70345,23.365556",
      "parent_id": "1972"
    },
    {
      "area_id": "1975",
      "area_code": "440512",
      "area_name": "濠江区",
      "level": "3",
      "city_code": "0754",
      "center": "116.726973,23.286079",
      "parent_id": "1972"
    },
    {
      "area_id": "1976",
      "area_code": "440513",
      "area_name": "潮阳区",
      "level": "3",
      "city_code": "0754",
      "center": "116.601509,23.265356",
      "parent_id": "1972"
    },
    {
      "area_id": "1977",
      "area_code": "440514",
      "area_name": "潮南区",
      "level": "3",
      "city_code": "0754",
      "center": "116.439178,23.23865",
      "parent_id": "1972"
    },
    {
      "area_id": "1978",
      "area_code": "440515",
      "area_name": "澄海区",
      "level": "3",
      "city_code": "0754",
      "center": "116.755992,23.466709",
      "parent_id": "1972"
    },
    {
      "area_id": "1979",
      "area_code": "440523",
      "area_name": "南澳县",
      "level": "3",
      "city_code": "0754",
      "center": "117.023374,23.421724",
      "parent_id": "1972"
    },
    {
      "area_id": "1980",
      "area_code": "440600",
      "area_name": "佛山市",
      "level": "2",
      "city_code": "0757",
      "center": "113.121435,23.021478",
      "parent_id": "1935"
    },
    {
      "area_id": "1981",
      "area_code": "440604",
      "area_name": "禅城区",
      "level": "3",
      "city_code": "0757",
      "center": "113.122421,23.009551",
      "parent_id": "1980"
    },
    {
      "area_id": "1982",
      "area_code": "440605",
      "area_name": "南海区",
      "level": "3",
      "city_code": "0757",
      "center": "113.143441,23.028956",
      "parent_id": "1980"
    },
    {
      "area_id": "1983",
      "area_code": "440606",
      "area_name": "顺德区",
      "level": "3",
      "city_code": "0757",
      "center": "113.293359,22.80524",
      "parent_id": "1980"
    },
    {
      "area_id": "1984",
      "area_code": "440607",
      "area_name": "三水区",
      "level": "3",
      "city_code": "0757",
      "center": "112.896685,23.155931",
      "parent_id": "1980"
    },
    {
      "area_id": "1985",
      "area_code": "440608",
      "area_name": "高明区",
      "level": "3",
      "city_code": "0757",
      "center": "112.892585,22.900139",
      "parent_id": "1980"
    },
    {
      "area_id": "1986",
      "area_code": "440700",
      "area_name": "江门市",
      "level": "2",
      "city_code": "0750",
      "center": "113.081542,22.57899",
      "parent_id": "1935"
    },
    {
      "area_id": "1987",
      "area_code": "440703",
      "area_name": "蓬江区",
      "level": "3",
      "city_code": "0750",
      "center": "113.078521,22.595149",
      "parent_id": "1986"
    },
    {
      "area_id": "1988",
      "area_code": "440704",
      "area_name": "江海区",
      "level": "3",
      "city_code": "0750",
      "center": "113.111612,22.560473",
      "parent_id": "1986"
    },
    {
      "area_id": "1989",
      "area_code": "440705",
      "area_name": "新会区",
      "level": "3",
      "city_code": "0750",
      "center": "113.034187,22.4583",
      "parent_id": "1986"
    },
    {
      "area_id": "1990",
      "area_code": "440781",
      "area_name": "台山市",
      "level": "3",
      "city_code": "0750",
      "center": "112.794065,22.251924",
      "parent_id": "1986"
    },
    {
      "area_id": "1991",
      "area_code": "440783",
      "area_name": "开平市",
      "level": "3",
      "city_code": "0750",
      "center": "112.698545,22.376395",
      "parent_id": "1986"
    },
    {
      "area_id": "1992",
      "area_code": "440784",
      "area_name": "鹤山市",
      "level": "3",
      "city_code": "0750",
      "center": "112.964252,22.76545",
      "parent_id": "1986"
    },
    {
      "area_id": "1993",
      "area_code": "440785",
      "area_name": "恩平市",
      "level": "3",
      "city_code": "0750",
      "center": "112.305145,22.183206",
      "parent_id": "1986"
    },
    {
      "area_id": "1994",
      "area_code": "440800",
      "area_name": "湛江市",
      "level": "2",
      "city_code": "0759",
      "center": "110.356639,21.270145",
      "parent_id": "1935"
    },
    {
      "area_id": "1995",
      "area_code": "440802",
      "area_name": "赤坎区",
      "level": "3",
      "city_code": "0759",
      "center": "110.365899,21.266119",
      "parent_id": "1994"
    },
    {
      "area_id": "1996",
      "area_code": "440803",
      "area_name": "霞山区",
      "level": "3",
      "city_code": "0759",
      "center": "110.397656,21.192457",
      "parent_id": "1994"
    },
    {
      "area_id": "1997",
      "area_code": "440804",
      "area_name": "坡头区",
      "level": "3",
      "city_code": "0759",
      "center": "110.455332,21.244721",
      "parent_id": "1994"
    },
    {
      "area_id": "1998",
      "area_code": "440811",
      "area_name": "麻章区",
      "level": "3",
      "city_code": "0759",
      "center": "110.334387,21.263442",
      "parent_id": "1994"
    },
    {
      "area_id": "1999",
      "area_code": "440823",
      "area_name": "遂溪县",
      "level": "3",
      "city_code": "0759",
      "center": "110.250123,21.377246",
      "parent_id": "1994"
    },
    {
      "area_id": "2000",
      "area_code": "440825",
      "area_name": "徐闻县",
      "level": "3",
      "city_code": "0759",
      "center": "110.176749,20.325489",
      "parent_id": "1994"
    },
    {
      "area_id": "2001",
      "area_code": "440881",
      "area_name": "廉江市",
      "level": "3",
      "city_code": "0759",
      "center": "110.286208,21.6097",
      "parent_id": "1994"
    },
    {
      "area_id": "2002",
      "area_code": "440882",
      "area_name": "雷州市",
      "level": "3",
      "city_code": "0759",
      "center": "110.096586,20.914178",
      "parent_id": "1994"
    },
    {
      "area_id": "2003",
      "area_code": "440883",
      "area_name": "吴川市",
      "level": "3",
      "city_code": "0759",
      "center": "110.778411,21.441808",
      "parent_id": "1994"
    },
    {
      "area_id": "2004",
      "area_code": "440900",
      "area_name": "茂名市",
      "level": "2",
      "city_code": "0668",
      "center": "110.925439,21.662991",
      "parent_id": "1935"
    },
    {
      "area_id": "2005",
      "area_code": "440902",
      "area_name": "茂南区",
      "level": "3",
      "city_code": "0668",
      "center": "110.918026,21.641337",
      "parent_id": "2004"
    },
    {
      "area_id": "2006",
      "area_code": "440904",
      "area_name": "电白区",
      "level": "3",
      "city_code": "0668",
      "center": "111.013556,21.514163",
      "parent_id": "2004"
    },
    {
      "area_id": "2007",
      "area_code": "440981",
      "area_name": "高州市",
      "level": "3",
      "city_code": "0668",
      "center": "110.853299,21.918203",
      "parent_id": "2004"
    },
    {
      "area_id": "2008",
      "area_code": "440982",
      "area_name": "化州市",
      "level": "3",
      "city_code": "0668",
      "center": "110.639565,21.66463",
      "parent_id": "2004"
    },
    {
      "area_id": "2009",
      "area_code": "440983",
      "area_name": "信宜市",
      "level": "3",
      "city_code": "0668",
      "center": "110.947043,22.354385",
      "parent_id": "2004"
    },
    {
      "area_id": "2010",
      "area_code": "441200",
      "area_name": "肇庆市",
      "level": "2",
      "city_code": "0758",
      "center": "112.465091,23.047191",
      "parent_id": "1935"
    },
    {
      "area_id": "2011",
      "area_code": "441202",
      "area_name": "端州区",
      "level": "3",
      "city_code": "0758",
      "center": "112.484848,23.052101",
      "parent_id": "2010"
    },
    {
      "area_id": "2012",
      "area_code": "441203",
      "area_name": "鼎湖区",
      "level": "3",
      "city_code": "0758",
      "center": "112.567588,23.158447",
      "parent_id": "2010"
    },
    {
      "area_id": "2013",
      "area_code": "441204",
      "area_name": "高要区",
      "level": "3",
      "city_code": "0758",
      "center": "112.457981,23.025305",
      "parent_id": "2010"
    },
    {
      "area_id": "2014",
      "area_code": "441223",
      "area_name": "广宁县",
      "level": "3",
      "city_code": "0758",
      "center": "112.44069,23.634675",
      "parent_id": "2010"
    },
    {
      "area_id": "2015",
      "area_code": "441224",
      "area_name": "怀集县",
      "level": "3",
      "city_code": "0758",
      "center": "112.167742,23.92035",
      "parent_id": "2010"
    },
    {
      "area_id": "2016",
      "area_code": "441225",
      "area_name": "封开县",
      "level": "3",
      "city_code": "0758",
      "center": "111.512343,23.424033",
      "parent_id": "2010"
    },
    {
      "area_id": "2017",
      "area_code": "441226",
      "area_name": "德庆县",
      "level": "3",
      "city_code": "0758",
      "center": "111.785937,23.143722",
      "parent_id": "2010"
    },
    {
      "area_id": "2018",
      "area_code": "441284",
      "area_name": "四会市",
      "level": "3",
      "city_code": "0758",
      "center": "112.734103,23.327001",
      "parent_id": "2010"
    },
    {
      "area_id": "2019",
      "area_code": "441300",
      "area_name": "惠州市",
      "level": "2",
      "city_code": "0752",
      "center": "114.415612,23.112381",
      "parent_id": "1935"
    },
    {
      "area_id": "2020",
      "area_code": "441302",
      "area_name": "惠城区",
      "level": "3",
      "city_code": "0752",
      "center": "114.382474,23.084137",
      "parent_id": "2019"
    },
    {
      "area_id": "2021",
      "area_code": "441303",
      "area_name": "惠阳区",
      "level": "3",
      "city_code": "0752",
      "center": "114.456176,22.789788",
      "parent_id": "2019"
    },
    {
      "area_id": "2022",
      "area_code": "441322",
      "area_name": "博罗县",
      "level": "3",
      "city_code": "0752",
      "center": "114.289528,23.172771",
      "parent_id": "2019"
    },
    {
      "area_id": "2023",
      "area_code": "441323",
      "area_name": "惠东县",
      "level": "3",
      "city_code": "0752",
      "center": "114.719988,22.985014",
      "parent_id": "2019"
    },
    {
      "area_id": "2024",
      "area_code": "441324",
      "area_name": "龙门县",
      "level": "3",
      "city_code": "0752",
      "center": "114.254863,23.727737",
      "parent_id": "2019"
    },
    {
      "area_id": "2025",
      "area_code": "441400",
      "area_name": "梅州市",
      "level": "2",
      "city_code": "0753",
      "center": "116.122523,24.288578",
      "parent_id": "1935"
    },
    {
      "area_id": "2026",
      "area_code": "441402",
      "area_name": "梅江区",
      "level": "3",
      "city_code": "0753",
      "center": "116.116695,24.31049",
      "parent_id": "2025"
    },
    {
      "area_id": "2027",
      "area_code": "441403",
      "area_name": "梅县区",
      "level": "3",
      "city_code": "0753",
      "center": "116.081656,24.265926",
      "parent_id": "2025"
    },
    {
      "area_id": "2028",
      "area_code": "441422",
      "area_name": "大埔县",
      "level": "3",
      "city_code": "0753",
      "center": "116.695195,24.347782",
      "parent_id": "2025"
    },
    {
      "area_id": "2029",
      "area_code": "441423",
      "area_name": "丰顺县",
      "level": "3",
      "city_code": "0753",
      "center": "116.181691,23.739343",
      "parent_id": "2025"
    },
    {
      "area_id": "2030",
      "area_code": "441424",
      "area_name": "五华县",
      "level": "3",
      "city_code": "0753",
      "center": "115.775788,23.932409",
      "parent_id": "2025"
    },
    {
      "area_id": "2031",
      "area_code": "441426",
      "area_name": "平远县",
      "level": "3",
      "city_code": "0753",
      "center": "115.891638,24.567261",
      "parent_id": "2025"
    },
    {
      "area_id": "2032",
      "area_code": "441427",
      "area_name": "蕉岭县",
      "level": "3",
      "city_code": "0753",
      "center": "116.171355,24.658699",
      "parent_id": "2025"
    },
    {
      "area_id": "2033",
      "area_code": "441481",
      "area_name": "兴宁市",
      "level": "3",
      "city_code": "0753",
      "center": "115.731167,24.136708",
      "parent_id": "2025"
    },
    {
      "area_id": "2034",
      "area_code": "441500",
      "area_name": "汕尾市",
      "level": "2",
      "city_code": "0660",
      "center": "115.375431,22.78705",
      "parent_id": "1935"
    },
    {
      "area_id": "2035",
      "area_code": "441502",
      "area_name": "城区",
      "level": "3",
      "city_code": "0660",
      "center": "115.365058,22.779207",
      "parent_id": "2034"
    },
    {
      "area_id": "2036",
      "area_code": "441521",
      "area_name": "海丰县",
      "level": "3",
      "city_code": "0660",
      "center": "115.323436,22.966585",
      "parent_id": "2034"
    },
    {
      "area_id": "2037",
      "area_code": "441523",
      "area_name": "陆河县",
      "level": "3",
      "city_code": "0660",
      "center": "115.660143,23.301616",
      "parent_id": "2034"
    },
    {
      "area_id": "2038",
      "area_code": "441581",
      "area_name": "陆丰市",
      "level": "3",
      "city_code": "0660",
      "center": "115.652151,22.919228",
      "parent_id": "2034"
    },
    {
      "area_id": "2039",
      "area_code": "441600",
      "area_name": "河源市",
      "level": "2",
      "city_code": "0762",
      "center": "114.700961,23.743686",
      "parent_id": "1935"
    },
    {
      "area_id": "2040",
      "area_code": "441602",
      "area_name": "源城区",
      "level": "3",
      "city_code": "0762",
      "center": "114.702517,23.733969",
      "parent_id": "2039"
    },
    {
      "area_id": "2041",
      "area_code": "441621",
      "area_name": "紫金县",
      "level": "3",
      "city_code": "0762",
      "center": "115.184107,23.635745",
      "parent_id": "2039"
    },
    {
      "area_id": "2042",
      "area_code": "441622",
      "area_name": "龙川县",
      "level": "3",
      "city_code": "0762",
      "center": "115.259871,24.100066",
      "parent_id": "2039"
    },
    {
      "area_id": "2043",
      "area_code": "441623",
      "area_name": "连平县",
      "level": "3",
      "city_code": "0762",
      "center": "114.488556,24.369583",
      "parent_id": "2039"
    },
    {
      "area_id": "2044",
      "area_code": "441624",
      "area_name": "和平县",
      "level": "3",
      "city_code": "0762",
      "center": "114.938684,24.44218",
      "parent_id": "2039"
    },
    {
      "area_id": "2045",
      "area_code": "441625",
      "area_name": "东源县",
      "level": "3",
      "city_code": "0762",
      "center": "114.746344,23.788189",
      "parent_id": "2039"
    },
    {
      "area_id": "2046",
      "area_code": "441700",
      "area_name": "阳江市",
      "level": "2",
      "city_code": "0662",
      "center": "111.982589,21.857887",
      "parent_id": "1935"
    },
    {
      "area_id": "2047",
      "area_code": "441702",
      "area_name": "江城区",
      "level": "3",
      "city_code": "0662",
      "center": "111.955058,21.861786",
      "parent_id": "2046"
    },
    {
      "area_id": "2048",
      "area_code": "441704",
      "area_name": "阳东区",
      "level": "3",
      "city_code": "0662",
      "center": "112.006363,21.868337",
      "parent_id": "2046"
    },
    {
      "area_id": "2049",
      "area_code": "441721",
      "area_name": "阳西县",
      "level": "3",
      "city_code": "0662",
      "center": "111.61766,21.752771",
      "parent_id": "2046"
    },
    {
      "area_id": "2050",
      "area_code": "441781",
      "area_name": "阳春市",
      "level": "3",
      "city_code": "0662",
      "center": "111.791587,22.17041",
      "parent_id": "2046"
    },
    {
      "area_id": "2051",
      "area_code": "441800",
      "area_name": "清远市",
      "level": "2",
      "city_code": "0763",
      "center": "113.056042,23.681774",
      "parent_id": "1935"
    },
    {
      "area_id": "2052",
      "area_code": "441802",
      "area_name": "清城区",
      "level": "3",
      "city_code": "0763",
      "center": "113.062692,23.697899",
      "parent_id": "2051"
    },
    {
      "area_id": "2053",
      "area_code": "441803",
      "area_name": "清新区",
      "level": "3",
      "city_code": "0763",
      "center": "113.017747,23.734677",
      "parent_id": "2051"
    },
    {
      "area_id": "2054",
      "area_code": "441821",
      "area_name": "佛冈县",
      "level": "3",
      "city_code": "0763",
      "center": "113.531607,23.879192",
      "parent_id": "2051"
    },
    {
      "area_id": "2055",
      "area_code": "441823",
      "area_name": "阳山县",
      "level": "3",
      "city_code": "0763",
      "center": "112.641363,24.465359",
      "parent_id": "2051"
    },
    {
      "area_id": "2056",
      "area_code": "441825",
      "area_name": "连山壮族瑶族自治县",
      "level": "3",
      "city_code": "0763",
      "center": "112.093617,24.570491",
      "parent_id": "2051"
    },
    {
      "area_id": "2057",
      "area_code": "441826",
      "area_name": "连南瑶族自治县",
      "level": "3",
      "city_code": "0763",
      "center": "112.287012,24.726017",
      "parent_id": "2051"
    },
    {
      "area_id": "2058",
      "area_code": "441881",
      "area_name": "英德市",
      "level": "3",
      "city_code": "0763",
      "center": "113.401701,24.206986",
      "parent_id": "2051"
    },
    {
      "area_id": "2059",
      "area_code": "441882",
      "area_name": "连州市",
      "level": "3",
      "city_code": "0763",
      "center": "112.377361,24.780966",
      "parent_id": "2051"
    },
    {
      "area_id": "2060",
      "area_code": "441900",
      "area_name": "东莞市",
      "level": "2",
      "city_code": "0769",
      "center": "113.751799,23.020673",
      "parent_id": "1935"
    },
    {
      "area_id": "2061",
      "area_code": "442000",
      "area_name": "中山市",
      "level": "2",
      "city_code": "0760",
      "center": "113.39277,22.517585",
      "parent_id": "1935"
    },
    {
      "area_id": "2062",
      "area_code": "445100",
      "area_name": "潮州市",
      "level": "2",
      "city_code": "0768",
      "center": "116.622444,23.657262",
      "parent_id": "1935"
    },
    {
      "area_id": "2063",
      "area_code": "445102",
      "area_name": "湘桥区",
      "level": "3",
      "city_code": "0768",
      "center": "116.628627,23.674387",
      "parent_id": "2062"
    },
    {
      "area_id": "2064",
      "area_code": "445103",
      "area_name": "潮安区",
      "level": "3",
      "city_code": "0768",
      "center": "116.678203,23.462613",
      "parent_id": "2062"
    },
    {
      "area_id": "2065",
      "area_code": "445122",
      "area_name": "饶平县",
      "level": "3",
      "city_code": "0768",
      "center": "117.0039,23.663824",
      "parent_id": "2062"
    },
    {
      "area_id": "2066",
      "area_code": "445200",
      "area_name": "揭阳市",
      "level": "2",
      "city_code": "0663",
      "center": "116.372708,23.549701",
      "parent_id": "1935"
    },
    {
      "area_id": "2067",
      "area_code": "445202",
      "area_name": "榕城区",
      "level": "3",
      "city_code": "0663",
      "center": "116.367012,23.525382",
      "parent_id": "2066"
    },
    {
      "area_id": "2068",
      "area_code": "445203",
      "area_name": "揭东区",
      "level": "3",
      "city_code": "0663",
      "center": "116.412015,23.566126",
      "parent_id": "2066"
    },
    {
      "area_id": "2069",
      "area_code": "445222",
      "area_name": "揭西县",
      "level": "3",
      "city_code": "0663",
      "center": "115.841837,23.431294",
      "parent_id": "2066"
    },
    {
      "area_id": "2070",
      "area_code": "445224",
      "area_name": "惠来县",
      "level": "3",
      "city_code": "0663",
      "center": "116.29515,23.033266",
      "parent_id": "2066"
    },
    {
      "area_id": "2071",
      "area_code": "445281",
      "area_name": "普宁市",
      "level": "3",
      "city_code": "0663",
      "center": "116.165777,23.297493",
      "parent_id": "2066"
    },
    {
      "area_id": "2072",
      "area_code": "445300",
      "area_name": "云浮市",
      "level": "2",
      "city_code": "0766",
      "center": "112.044491,22.915094",
      "parent_id": "1935"
    },
    {
      "area_id": "2073",
      "area_code": "445302",
      "area_name": "云城区",
      "level": "3",
      "city_code": "0766",
      "center": "112.043945,22.92815",
      "parent_id": "2072"
    },
    {
      "area_id": "2074",
      "area_code": "445303",
      "area_name": "云安区",
      "level": "3",
      "city_code": "0766",
      "center": "112.003208,23.071019",
      "parent_id": "2072"
    },
    {
      "area_id": "2075",
      "area_code": "445321",
      "area_name": "新兴县",
      "level": "3",
      "city_code": "0766",
      "center": "112.225334,22.69569",
      "parent_id": "2072"
    },
    {
      "area_id": "2076",
      "area_code": "445322",
      "area_name": "郁南县",
      "level": "3",
      "city_code": "0766",
      "center": "111.535285,23.23456",
      "parent_id": "2072"
    },
    {
      "area_id": "2077",
      "area_code": "445381",
      "area_name": "罗定市",
      "level": "3",
      "city_code": "0766",
      "center": "111.569892,22.768285",
      "parent_id": "2072"
    },
    {
      "area_id": "2078",
      "area_code": "442100",
      "area_name": "东沙群岛",
      "level": "2",
      "city_code": "[]",
      "center": "116.887613,20.617825",
      "parent_id": "1935"
    },
    {
      "area_id": "2079",
      "area_code": "450000",
      "area_name": "广西壮族自治区",
      "level": "1",
      "city_code": "[]",
      "center": "108.327546,22.815478",
      "parent_id": "0"
    },
    {
      "area_id": "2080",
      "area_code": "450100",
      "area_name": "南宁市",
      "level": "2",
      "city_code": "0771",
      "center": "108.366543,22.817002",
      "parent_id": "2079"
    },
    {
      "area_id": "2081",
      "area_code": "450102",
      "area_name": "兴宁区",
      "level": "3",
      "city_code": "0771",
      "center": "108.368871,22.854021",
      "parent_id": "2080"
    },
    {
      "area_id": "2082",
      "area_code": "450103",
      "area_name": "青秀区",
      "level": "3",
      "city_code": "0771",
      "center": "108.494024,22.785879",
      "parent_id": "2080"
    },
    {
      "area_id": "2083",
      "area_code": "450105",
      "area_name": "江南区",
      "level": "3",
      "city_code": "0771",
      "center": "108.273133,22.78136",
      "parent_id": "2080"
    },
    {
      "area_id": "2084",
      "area_code": "450107",
      "area_name": "西乡塘区",
      "level": "3",
      "city_code": "0771",
      "center": "108.313494,22.833928",
      "parent_id": "2080"
    },
    {
      "area_id": "2085",
      "area_code": "450108",
      "area_name": "良庆区",
      "level": "3",
      "city_code": "0771",
      "center": "108.39301,22.752997",
      "parent_id": "2080"
    },
    {
      "area_id": "2086",
      "area_code": "450109",
      "area_name": "邕宁区",
      "level": "3",
      "city_code": "0771",
      "center": "108.487368,22.75839",
      "parent_id": "2080"
    },
    {
      "area_id": "2087",
      "area_code": "450110",
      "area_name": "武鸣区",
      "level": "3",
      "city_code": "0771",
      "center": "108.27467,23.158595",
      "parent_id": "2080"
    },
    {
      "area_id": "2088",
      "area_code": "450123",
      "area_name": "隆安县",
      "level": "3",
      "city_code": "0771",
      "center": "107.696153,23.166028",
      "parent_id": "2080"
    },
    {
      "area_id": "2089",
      "area_code": "450124",
      "area_name": "马山县",
      "level": "3",
      "city_code": "0771",
      "center": "108.177019,23.708321",
      "parent_id": "2080"
    },
    {
      "area_id": "2090",
      "area_code": "450125",
      "area_name": "上林县",
      "level": "3",
      "city_code": "0771",
      "center": "108.602846,23.431908",
      "parent_id": "2080"
    },
    {
      "area_id": "2091",
      "area_code": "450126",
      "area_name": "宾阳县",
      "level": "3",
      "city_code": "0771",
      "center": "108.810326,23.217786",
      "parent_id": "2080"
    },
    {
      "area_id": "2092",
      "area_code": "450127",
      "area_name": "横县",
      "level": "3",
      "city_code": "0771",
      "center": "109.261384,22.679931",
      "parent_id": "2080"
    },
    {
      "area_id": "2093",
      "area_code": "450200",
      "area_name": "柳州市",
      "level": "2",
      "city_code": "0772",
      "center": "109.428608,24.326291",
      "parent_id": "2079"
    },
    {
      "area_id": "2094",
      "area_code": "450202",
      "area_name": "城中区",
      "level": "3",
      "city_code": "0772",
      "center": "109.4273,24.366",
      "parent_id": "2093"
    },
    {
      "area_id": "2095",
      "area_code": "450203",
      "area_name": "鱼峰区",
      "level": "3",
      "city_code": "0772",
      "center": "109.452442,24.318516",
      "parent_id": "2093"
    },
    {
      "area_id": "2096",
      "area_code": "450204",
      "area_name": "柳南区",
      "level": "3",
      "city_code": "0772",
      "center": "109.385518,24.336229",
      "parent_id": "2093"
    },
    {
      "area_id": "2097",
      "area_code": "450205",
      "area_name": "柳北区",
      "level": "3",
      "city_code": "0772",
      "center": "109.402049,24.362691",
      "parent_id": "2093"
    },
    {
      "area_id": "2098",
      "area_code": "450206",
      "area_name": "柳江区",
      "level": "3",
      "city_code": "0772",
      "center": "109.32638,24.254891",
      "parent_id": "2093"
    },
    {
      "area_id": "2099",
      "area_code": "450222",
      "area_name": "柳城县",
      "level": "3",
      "city_code": "0772",
      "center": "109.24473,24.651518",
      "parent_id": "2093"
    },
    {
      "area_id": "2100",
      "area_code": "450223",
      "area_name": "鹿寨县",
      "level": "3",
      "city_code": "0772",
      "center": "109.750638,24.472897",
      "parent_id": "2093"
    },
    {
      "area_id": "2101",
      "area_code": "450224",
      "area_name": "融安县",
      "level": "3",
      "city_code": "0772",
      "center": "109.397538,25.224549",
      "parent_id": "2093"
    },
    {
      "area_id": "2102",
      "area_code": "450225",
      "area_name": "融水苗族自治县",
      "level": "3",
      "city_code": "0772",
      "center": "109.256334,25.065934",
      "parent_id": "2093"
    },
    {
      "area_id": "2103",
      "area_code": "450226",
      "area_name": "三江侗族自治县",
      "level": "3",
      "city_code": "0772",
      "center": "109.607675,25.783198",
      "parent_id": "2093"
    },
    {
      "area_id": "2104",
      "area_code": "450300",
      "area_name": "桂林市",
      "level": "2",
      "city_code": "0773",
      "center": "110.179953,25.234479",
      "parent_id": "2079"
    },
    {
      "area_id": "2105",
      "area_code": "450302",
      "area_name": "秀峰区",
      "level": "3",
      "city_code": "0773",
      "center": "110.264183,25.273625",
      "parent_id": "2104"
    },
    {
      "area_id": "2106",
      "area_code": "450303",
      "area_name": "叠彩区",
      "level": "3",
      "city_code": "0773",
      "center": "110.301723,25.314",
      "parent_id": "2104"
    },
    {
      "area_id": "2107",
      "area_code": "450304",
      "area_name": "象山区",
      "level": "3",
      "city_code": "0773",
      "center": "110.281082,25.261686",
      "parent_id": "2104"
    },
    {
      "area_id": "2108",
      "area_code": "450305",
      "area_name": "七星区",
      "level": "3",
      "city_code": "0773",
      "center": "110.317826,25.252701",
      "parent_id": "2104"
    },
    {
      "area_id": "2109",
      "area_code": "450311",
      "area_name": "雁山区",
      "level": "3",
      "city_code": "0773",
      "center": "110.28669,25.101934",
      "parent_id": "2104"
    },
    {
      "area_id": "2110",
      "area_code": "450312",
      "area_name": "临桂区",
      "level": "3",
      "city_code": "0773",
      "center": "110.212463,25.238628",
      "parent_id": "2104"
    },
    {
      "area_id": "2111",
      "area_code": "450321",
      "area_name": "阳朔县",
      "level": "3",
      "city_code": "0773",
      "center": "110.496593,24.77848",
      "parent_id": "2104"
    },
    {
      "area_id": "2112",
      "area_code": "450323",
      "area_name": "灵川县",
      "level": "3",
      "city_code": "0773",
      "center": "110.319897,25.394781",
      "parent_id": "2104"
    },
    {
      "area_id": "2113",
      "area_code": "450324",
      "area_name": "全州县",
      "level": "3",
      "city_code": "0773",
      "center": "111.072946,25.928387",
      "parent_id": "2104"
    },
    {
      "area_id": "2114",
      "area_code": "450325",
      "area_name": "兴安县",
      "level": "3",
      "city_code": "0773",
      "center": "110.67167,25.611704",
      "parent_id": "2104"
    },
    {
      "area_id": "2115",
      "area_code": "450326",
      "area_name": "永福县",
      "level": "3",
      "city_code": "0773",
      "center": "109.983076,24.979855",
      "parent_id": "2104"
    },
    {
      "area_id": "2116",
      "area_code": "450327",
      "area_name": "灌阳县",
      "level": "3",
      "city_code": "0773",
      "center": "111.160851,25.489383",
      "parent_id": "2104"
    },
    {
      "area_id": "2117",
      "area_code": "450328",
      "area_name": "龙胜各族自治县",
      "level": "3",
      "city_code": "0773",
      "center": "110.011238,25.797931",
      "parent_id": "2104"
    },
    {
      "area_id": "2118",
      "area_code": "450329",
      "area_name": "资源县",
      "level": "3",
      "city_code": "0773",
      "center": "110.6527,26.042443",
      "parent_id": "2104"
    },
    {
      "area_id": "2119",
      "area_code": "450330",
      "area_name": "平乐县",
      "level": "3",
      "city_code": "0773",
      "center": "110.643305,24.633362",
      "parent_id": "2104"
    },
    {
      "area_id": "2120",
      "area_code": "450331",
      "area_name": "荔浦县",
      "level": "3",
      "city_code": "0773",
      "center": "110.395104,24.488342",
      "parent_id": "2104"
    },
    {
      "area_id": "2121",
      "area_code": "450332",
      "area_name": "恭城瑶族自治县",
      "level": "3",
      "city_code": "0773",
      "center": "110.828409,24.831682",
      "parent_id": "2104"
    },
    {
      "area_id": "2122",
      "area_code": "450400",
      "area_name": "梧州市",
      "level": "2",
      "city_code": "0774",
      "center": "111.279115,23.476962",
      "parent_id": "2079"
    },
    {
      "area_id": "2123",
      "area_code": "450403",
      "area_name": "万秀区",
      "level": "3",
      "city_code": "0774",
      "center": "111.320518,23.472991",
      "parent_id": "2122"
    },
    {
      "area_id": "2124",
      "area_code": "450405",
      "area_name": "长洲区",
      "level": "3",
      "city_code": "0774",
      "center": "111.274673,23.485944",
      "parent_id": "2122"
    },
    {
      "area_id": "2125",
      "area_code": "450406",
      "area_name": "龙圩区",
      "level": "3",
      "city_code": "0774",
      "center": "111.246606,23.404772",
      "parent_id": "2122"
    },
    {
      "area_id": "2126",
      "area_code": "450421",
      "area_name": "苍梧县",
      "level": "3",
      "city_code": "0774",
      "center": "111.544007,23.845097",
      "parent_id": "2122"
    },
    {
      "area_id": "2127",
      "area_code": "450422",
      "area_name": "藤县",
      "level": "3",
      "city_code": "0774",
      "center": "110.914849,23.374983",
      "parent_id": "2122"
    },
    {
      "area_id": "2128",
      "area_code": "450423",
      "area_name": "蒙山县",
      "level": "3",
      "city_code": "0774",
      "center": "110.525003,24.19357",
      "parent_id": "2122"
    },
    {
      "area_id": "2129",
      "area_code": "450481",
      "area_name": "岑溪市",
      "level": "3",
      "city_code": "0774",
      "center": "110.994913,22.91835",
      "parent_id": "2122"
    },
    {
      "area_id": "2130",
      "area_code": "450500",
      "area_name": "北海市",
      "level": "2",
      "city_code": "0779",
      "center": "109.120161,21.481291",
      "parent_id": "2079"
    },
    {
      "area_id": "2131",
      "area_code": "450502",
      "area_name": "海城区",
      "level": "3",
      "city_code": "0779",
      "center": "109.117209,21.475004",
      "parent_id": "2130"
    },
    {
      "area_id": "2132",
      "area_code": "450503",
      "area_name": "银海区",
      "level": "3",
      "city_code": "0779",
      "center": "109.139862,21.449308",
      "parent_id": "2130"
    },
    {
      "area_id": "2133",
      "area_code": "450512",
      "area_name": "铁山港区",
      "level": "3",
      "city_code": "0779",
      "center": "109.42158,21.529127",
      "parent_id": "2130"
    },
    {
      "area_id": "2134",
      "area_code": "450521",
      "area_name": "合浦县",
      "level": "3",
      "city_code": "0779",
      "center": "109.207335,21.660935",
      "parent_id": "2130"
    },
    {
      "area_id": "2135",
      "area_code": "450600",
      "area_name": "防城港市",
      "level": "2",
      "city_code": "0770",
      "center": "108.353846,21.68686",
      "parent_id": "2079"
    },
    {
      "area_id": "2136",
      "area_code": "450602",
      "area_name": "港口区",
      "level": "3",
      "city_code": "0770",
      "center": "108.380143,21.643383",
      "parent_id": "2135"
    },
    {
      "area_id": "2137",
      "area_code": "450603",
      "area_name": "防城区",
      "level": "3",
      "city_code": "0770",
      "center": "108.353499,21.769211",
      "parent_id": "2135"
    },
    {
      "area_id": "2138",
      "area_code": "450621",
      "area_name": "上思县",
      "level": "3",
      "city_code": "0770",
      "center": "107.983627,22.153671",
      "parent_id": "2135"
    },
    {
      "area_id": "2139",
      "area_code": "450681",
      "area_name": "东兴市",
      "level": "3",
      "city_code": "0770",
      "center": "107.971828,21.547821",
      "parent_id": "2135"
    },
    {
      "area_id": "2140",
      "area_code": "450700",
      "area_name": "钦州市",
      "level": "2",
      "city_code": "0777",
      "center": "108.654146,21.979933",
      "parent_id": "2079"
    },
    {
      "area_id": "2141",
      "area_code": "450702",
      "area_name": "钦南区",
      "level": "3",
      "city_code": "0777",
      "center": "108.657209,21.938859",
      "parent_id": "2140"
    },
    {
      "area_id": "2142",
      "area_code": "450703",
      "area_name": "钦北区",
      "level": "3",
      "city_code": "0777",
      "center": "108.44911,22.132761",
      "parent_id": "2140"
    },
    {
      "area_id": "2143",
      "area_code": "450721",
      "area_name": "灵山县",
      "level": "3",
      "city_code": "0777",
      "center": "109.291006,22.416536",
      "parent_id": "2140"
    },
    {
      "area_id": "2144",
      "area_code": "450722",
      "area_name": "浦北县",
      "level": "3",
      "city_code": "0777",
      "center": "109.556953,22.271651",
      "parent_id": "2140"
    },
    {
      "area_id": "2145",
      "area_code": "450800",
      "area_name": "贵港市",
      "level": "2",
      "city_code": "1755",
      "center": "109.598926,23.11153",
      "parent_id": "2079"
    },
    {
      "area_id": "2146",
      "area_code": "450802",
      "area_name": "港北区",
      "level": "3",
      "city_code": "1755",
      "center": "109.57224,23.11153",
      "parent_id": "2145"
    },
    {
      "area_id": "2147",
      "area_code": "450803",
      "area_name": "港南区",
      "level": "3",
      "city_code": "1755",
      "center": "109.599556,23.075573",
      "parent_id": "2145"
    },
    {
      "area_id": "2148",
      "area_code": "450804",
      "area_name": "覃塘区",
      "level": "3",
      "city_code": "1755",
      "center": "109.452662,23.127149",
      "parent_id": "2145"
    },
    {
      "area_id": "2149",
      "area_code": "450821",
      "area_name": "平南县",
      "level": "3",
      "city_code": "1755",
      "center": "110.392311,23.539264",
      "parent_id": "2145"
    },
    {
      "area_id": "2150",
      "area_code": "450881",
      "area_name": "桂平市",
      "level": "3",
      "city_code": "1755",
      "center": "110.079379,23.394325",
      "parent_id": "2145"
    },
    {
      "area_id": "2151",
      "area_code": "450900",
      "area_name": "玉林市",
      "level": "2",
      "city_code": "0775",
      "center": "110.18122,22.654032",
      "parent_id": "2079"
    },
    {
      "area_id": "2152",
      "area_code": "450902",
      "area_name": "玉州区",
      "level": "3",
      "city_code": "0775",
      "center": "110.151153,22.628087",
      "parent_id": "2151"
    },
    {
      "area_id": "2153",
      "area_code": "450903",
      "area_name": "福绵区",
      "level": "3",
      "city_code": "0775",
      "center": "110.059439,22.585556",
      "parent_id": "2151"
    },
    {
      "area_id": "2154",
      "area_code": "450921",
      "area_name": "容县",
      "level": "3",
      "city_code": "0775",
      "center": "110.558074,22.857839",
      "parent_id": "2151"
    },
    {
      "area_id": "2155",
      "area_code": "450922",
      "area_name": "陆川县",
      "level": "3",
      "city_code": "0775",
      "center": "110.264052,22.321048",
      "parent_id": "2151"
    },
    {
      "area_id": "2156",
      "area_code": "450923",
      "area_name": "博白县",
      "level": "3",
      "city_code": "0775",
      "center": "109.975985,22.273048",
      "parent_id": "2151"
    },
    {
      "area_id": "2157",
      "area_code": "450924",
      "area_name": "兴业县",
      "level": "3",
      "city_code": "0775",
      "center": "109.875304,22.736421",
      "parent_id": "2151"
    },
    {
      "area_id": "2158",
      "area_code": "450981",
      "area_name": "北流市",
      "level": "3",
      "city_code": "0775",
      "center": "110.354214,22.70831",
      "parent_id": "2151"
    },
    {
      "area_id": "2159",
      "area_code": "451000",
      "area_name": "百色市",
      "level": "2",
      "city_code": "0776",
      "center": "106.618202,23.90233",
      "parent_id": "2079"
    },
    {
      "area_id": "2160",
      "area_code": "451002",
      "area_name": "右江区",
      "level": "3",
      "city_code": "0776",
      "center": "106.618225,23.90097",
      "parent_id": "2159"
    },
    {
      "area_id": "2161",
      "area_code": "451021",
      "area_name": "田阳县",
      "level": "3",
      "city_code": "0776",
      "center": "106.915496,23.735692",
      "parent_id": "2159"
    },
    {
      "area_id": "2162",
      "area_code": "451022",
      "area_name": "田东县",
      "level": "3",
      "city_code": "0776",
      "center": "107.12608,23.597194",
      "parent_id": "2159"
    },
    {
      "area_id": "2163",
      "area_code": "451023",
      "area_name": "平果县",
      "level": "3",
      "city_code": "0776",
      "center": "107.589809,23.329376",
      "parent_id": "2159"
    },
    {
      "area_id": "2164",
      "area_code": "451024",
      "area_name": "德保县",
      "level": "3",
      "city_code": "0776",
      "center": "106.615373,23.32345",
      "parent_id": "2159"
    },
    {
      "area_id": "2165",
      "area_code": "451026",
      "area_name": "那坡县",
      "level": "3",
      "city_code": "0776",
      "center": "105.83253,23.387441",
      "parent_id": "2159"
    },
    {
      "area_id": "2166",
      "area_code": "451027",
      "area_name": "凌云县",
      "level": "3",
      "city_code": "0776",
      "center": "106.56131,24.347557",
      "parent_id": "2159"
    },
    {
      "area_id": "2167",
      "area_code": "451028",
      "area_name": "乐业县",
      "level": "3",
      "city_code": "0776",
      "center": "106.556519,24.776827",
      "parent_id": "2159"
    },
    {
      "area_id": "2168",
      "area_code": "451029",
      "area_name": "田林县",
      "level": "3",
      "city_code": "0776",
      "center": "106.228538,24.294487",
      "parent_id": "2159"
    },
    {
      "area_id": "2169",
      "area_code": "451030",
      "area_name": "西林县",
      "level": "3",
      "city_code": "0776",
      "center": "105.093825,24.489823",
      "parent_id": "2159"
    },
    {
      "area_id": "2170",
      "area_code": "451031",
      "area_name": "隆林各族自治县",
      "level": "3",
      "city_code": "0776",
      "center": "105.34404,24.770896",
      "parent_id": "2159"
    },
    {
      "area_id": "2171",
      "area_code": "451081",
      "area_name": "靖西市",
      "level": "3",
      "city_code": "0776",
      "center": "106.417805,23.134117",
      "parent_id": "2159"
    },
    {
      "area_id": "2172",
      "area_code": "451100",
      "area_name": "贺州市",
      "level": "2",
      "city_code": "1774",
      "center": "111.566871,24.403528",
      "parent_id": "2079"
    },
    {
      "area_id": "2173",
      "area_code": "451102",
      "area_name": "八步区",
      "level": "3",
      "city_code": "1774",
      "center": "111.552095,24.411805",
      "parent_id": "2172"
    },
    {
      "area_id": "2174",
      "area_code": "451103",
      "area_name": "平桂区",
      "level": "3",
      "city_code": "1774",
      "center": "111.479923,24.453845",
      "parent_id": "2172"
    },
    {
      "area_id": "2175",
      "area_code": "451121",
      "area_name": "昭平县",
      "level": "3",
      "city_code": "1774",
      "center": "110.811325,24.169385",
      "parent_id": "2172"
    },
    {
      "area_id": "2176",
      "area_code": "451122",
      "area_name": "钟山县",
      "level": "3",
      "city_code": "1774",
      "center": "111.303009,24.525957",
      "parent_id": "2172"
    },
    {
      "area_id": "2177",
      "area_code": "451123",
      "area_name": "富川瑶族自治县",
      "level": "3",
      "city_code": "1774",
      "center": "111.27745,24.814443",
      "parent_id": "2172"
    },
    {
      "area_id": "2178",
      "area_code": "451200",
      "area_name": "河池市",
      "level": "2",
      "city_code": "0778",
      "center": "108.085261,24.692931",
      "parent_id": "2079"
    },
    {
      "area_id": "2179",
      "area_code": "451202",
      "area_name": "金城江区",
      "level": "3",
      "city_code": "0778",
      "center": "108.037276,24.689703",
      "parent_id": "2178"
    },
    {
      "area_id": "2180",
      "area_code": "451221",
      "area_name": "南丹县",
      "level": "3",
      "city_code": "0778",
      "center": "107.541244,24.975631",
      "parent_id": "2178"
    },
    {
      "area_id": "2181",
      "area_code": "451222",
      "area_name": "天峨县",
      "level": "3",
      "city_code": "0778",
      "center": "107.173802,24.999108",
      "parent_id": "2178"
    },
    {
      "area_id": "2182",
      "area_code": "451223",
      "area_name": "凤山县",
      "level": "3",
      "city_code": "0778",
      "center": "107.04219,24.546876",
      "parent_id": "2178"
    },
    {
      "area_id": "2183",
      "area_code": "451224",
      "area_name": "东兰县",
      "level": "3",
      "city_code": "0778",
      "center": "107.374293,24.510842",
      "parent_id": "2178"
    },
    {
      "area_id": "2184",
      "area_code": "451225",
      "area_name": "罗城仫佬族自治县",
      "level": "3",
      "city_code": "0778",
      "center": "108.904706,24.777411",
      "parent_id": "2178"
    },
    {
      "area_id": "2185",
      "area_code": "451226",
      "area_name": "环江毛南族自治县",
      "level": "3",
      "city_code": "0778",
      "center": "108.258028,24.825664",
      "parent_id": "2178"
    },
    {
      "area_id": "2186",
      "area_code": "451227",
      "area_name": "巴马瑶族自治县",
      "level": "3",
      "city_code": "0778",
      "center": "107.258588,24.142298",
      "parent_id": "2178"
    },
    {
      "area_id": "2187",
      "area_code": "451228",
      "area_name": "都安瑶族自治县",
      "level": "3",
      "city_code": "0778",
      "center": "108.105311,23.932675",
      "parent_id": "2178"
    },
    {
      "area_id": "2188",
      "area_code": "451229",
      "area_name": "大化瑶族自治县",
      "level": "3",
      "city_code": "0778",
      "center": "107.998149,23.736457",
      "parent_id": "2178"
    },
    {
      "area_id": "2189",
      "area_code": "451203",
      "area_name": "宜州区",
      "level": "3",
      "city_code": "0778",
      "center": "108.636414,24.485214",
      "parent_id": "2178"
    },
    {
      "area_id": "2190",
      "area_code": "451300",
      "area_name": "来宾市",
      "level": "2",
      "city_code": "1772",
      "center": "109.221465,23.750306",
      "parent_id": "2079"
    },
    {
      "area_id": "2191",
      "area_code": "451302",
      "area_name": "兴宾区",
      "level": "3",
      "city_code": "1772",
      "center": "109.183333,23.72892",
      "parent_id": "2190"
    },
    {
      "area_id": "2192",
      "area_code": "451321",
      "area_name": "忻城县",
      "level": "3",
      "city_code": "1772",
      "center": "108.665666,24.066234",
      "parent_id": "2190"
    },
    {
      "area_id": "2193",
      "area_code": "451322",
      "area_name": "象州县",
      "level": "3",
      "city_code": "1772",
      "center": "109.705065,23.973793",
      "parent_id": "2190"
    },
    {
      "area_id": "2194",
      "area_code": "451323",
      "area_name": "武宣县",
      "level": "3",
      "city_code": "1772",
      "center": "109.663206,23.59411",
      "parent_id": "2190"
    },
    {
      "area_id": "2195",
      "area_code": "451324",
      "area_name": "金秀瑶族自治县",
      "level": "3",
      "city_code": "1772",
      "center": "110.189462,24.130374",
      "parent_id": "2190"
    },
    {
      "area_id": "2196",
      "area_code": "451381",
      "area_name": "合山市",
      "level": "3",
      "city_code": "1772",
      "center": "108.886082,23.806535",
      "parent_id": "2190"
    },
    {
      "area_id": "2197",
      "area_code": "451400",
      "area_name": "崇左市",
      "level": "2",
      "city_code": "1771",
      "center": "107.365094,22.377253",
      "parent_id": "2079"
    },
    {
      "area_id": "2198",
      "area_code": "451402",
      "area_name": "江州区",
      "level": "3",
      "city_code": "1771",
      "center": "107.353437,22.405325",
      "parent_id": "2197"
    },
    {
      "area_id": "2199",
      "area_code": "451421",
      "area_name": "扶绥县",
      "level": "3",
      "city_code": "1771",
      "center": "107.904186,22.635012",
      "parent_id": "2197"
    },
    {
      "area_id": "2200",
      "area_code": "451422",
      "area_name": "宁明县",
      "level": "3",
      "city_code": "1771",
      "center": "107.076456,22.140192",
      "parent_id": "2197"
    },
    {
      "area_id": "2201",
      "area_code": "451423",
      "area_name": "龙州县",
      "level": "3",
      "city_code": "1771",
      "center": "106.854482,22.342778",
      "parent_id": "2197"
    },
    {
      "area_id": "2202",
      "area_code": "451424",
      "area_name": "大新县",
      "level": "3",
      "city_code": "1771",
      "center": "107.200654,22.829287",
      "parent_id": "2197"
    },
    {
      "area_id": "2203",
      "area_code": "451425",
      "area_name": "天等县",
      "level": "3",
      "city_code": "1771",
      "center": "107.143432,23.081394",
      "parent_id": "2197"
    },
    {
      "area_id": "2204",
      "area_code": "451481",
      "area_name": "凭祥市",
      "level": "3",
      "city_code": "1771",
      "center": "106.766293,22.094484",
      "parent_id": "2197"
    },
    {
      "area_id": "2205",
      "area_code": "460000",
      "area_name": "海南省",
      "level": "1",
      "city_code": "[]",
      "center": "110.349228,20.017377",
      "parent_id": "0"
    },
    {
      "area_id": "2206",
      "area_code": "469025",
      "area_name": "白沙黎族自治县",
      "level": "2",
      "city_code": "0802",
      "center": "109.451484,19.224823",
      "parent_id": "2205"
    },
    {
      "area_id": "2207",
      "area_code": "469029",
      "area_name": "保亭黎族苗族自治县",
      "level": "2",
      "city_code": "0801",
      "center": "109.70259,18.63913",
      "parent_id": "2205"
    },
    {
      "area_id": "2208",
      "area_code": "469026",
      "area_name": "昌江黎族自治县",
      "level": "2",
      "city_code": "0803",
      "center": "109.055739,19.298184",
      "parent_id": "2205"
    },
    {
      "area_id": "2209",
      "area_code": "469023",
      "area_name": "澄迈县",
      "level": "2",
      "city_code": "0804",
      "center": "110.006754,19.738521",
      "parent_id": "2205"
    },
    {
      "area_id": "2210",
      "area_code": "460100",
      "area_name": "海口市",
      "level": "2",
      "city_code": "0898",
      "center": "110.198286,20.044412",
      "parent_id": "2205"
    },
    {
      "area_id": "2211",
      "area_code": "460105",
      "area_name": "秀英区",
      "level": "3",
      "city_code": "0898",
      "center": "110.293603,20.007494",
      "parent_id": "2210"
    },
    {
      "area_id": "2212",
      "area_code": "460106",
      "area_name": "龙华区",
      "level": "3",
      "city_code": "0898",
      "center": "110.328492,20.031006",
      "parent_id": "2210"
    },
    {
      "area_id": "2213",
      "area_code": "460107",
      "area_name": "琼山区",
      "level": "3",
      "city_code": "0898",
      "center": "110.353972,20.003169",
      "parent_id": "2210"
    },
    {
      "area_id": "2214",
      "area_code": "460108",
      "area_name": "美兰区",
      "level": "3",
      "city_code": "0898",
      "center": "110.366358,20.029083",
      "parent_id": "2210"
    },
    {
      "area_id": "2215",
      "area_code": "460200",
      "area_name": "三亚市",
      "level": "2",
      "city_code": "0899",
      "center": "109.511772,18.253135",
      "parent_id": "2205"
    },
    {
      "area_id": "2216",
      "area_code": "460202",
      "area_name": "海棠区",
      "level": "3",
      "city_code": "0899",
      "center": "109.752569,18.400106",
      "parent_id": "2215"
    },
    {
      "area_id": "2217",
      "area_code": "460203",
      "area_name": "吉阳区",
      "level": "3",
      "city_code": "0899",
      "center": "109.578336,18.281406",
      "parent_id": "2215"
    },
    {
      "area_id": "2218",
      "area_code": "460204",
      "area_name": "天涯区",
      "level": "3",
      "city_code": "0899",
      "center": "109.452378,18.298156",
      "parent_id": "2215"
    },
    {
      "area_id": "2219",
      "area_code": "460205",
      "area_name": "崖州区",
      "level": "3",
      "city_code": "0899",
      "center": "109.171841,18.357291",
      "parent_id": "2215"
    },
    {
      "area_id": "2220",
      "area_code": "460300",
      "area_name": "三沙市",
      "level": "2",
      "city_code": "2898",
      "center": "112.338695,16.831839",
      "parent_id": "2205"
    },
    {
      "area_id": "2221",
      "area_code": "460321",
      "area_name": "西沙群岛",
      "level": "3",
      "city_code": "1895",
      "center": "111.792944,16.204546",
      "parent_id": "2220"
    },
    {
      "area_id": "2222",
      "area_code": "460322",
      "area_name": "南沙群岛",
      "level": "3",
      "city_code": "1891",
      "center": "116.749997,11.471888",
      "parent_id": "2220"
    },
    {
      "area_id": "2223",
      "area_code": "460323",
      "area_name": "中沙群岛的岛礁及其海域",
      "level": "3",
      "city_code": "2801",
      "center": "117.740071,15.112855",
      "parent_id": "2220"
    },
    {
      "area_id": "2224",
      "area_code": "460400",
      "area_name": "儋州市",
      "level": "2",
      "city_code": "0805",
      "center": "109.580811,19.521134",
      "parent_id": "2205"
    },
    {
      "area_id": "2225",
      "area_code": "469021",
      "area_name": "定安县",
      "level": "2",
      "city_code": "0806",
      "center": "110.359339,19.681404",
      "parent_id": "2205"
    },
    {
      "area_id": "2226",
      "area_code": "469007",
      "area_name": "东方市",
      "level": "2",
      "city_code": "0807",
      "center": "108.651815,19.095351",
      "parent_id": "2205"
    },
    {
      "area_id": "2227",
      "area_code": "469027",
      "area_name": "乐东黎族自治县",
      "level": "2",
      "city_code": "2802",
      "center": "109.173054,18.750259",
      "parent_id": "2205"
    },
    {
      "area_id": "2228",
      "area_code": "469024",
      "area_name": "临高县",
      "level": "2",
      "city_code": "1896",
      "center": "109.690508,19.912025",
      "parent_id": "2205"
    },
    {
      "area_id": "2229",
      "area_code": "469028",
      "area_name": "陵水黎族自治县",
      "level": "2",
      "city_code": "0809",
      "center": "110.037503,18.506048",
      "parent_id": "2205"
    },
    {
      "area_id": "2230",
      "area_code": "469002",
      "area_name": "琼海市",
      "level": "2",
      "city_code": "1894",
      "center": "110.474497,19.259134",
      "parent_id": "2205"
    },
    {
      "area_id": "2231",
      "area_code": "469030",
      "area_name": "琼中黎族苗族自治县",
      "level": "2",
      "city_code": "1899",
      "center": "109.838389,19.033369",
      "parent_id": "2205"
    },
    {
      "area_id": "2232",
      "area_code": "469022",
      "area_name": "屯昌县",
      "level": "2",
      "city_code": "1892",
      "center": "110.103415,19.351765",
      "parent_id": "2205"
    },
    {
      "area_id": "2233",
      "area_code": "469006",
      "area_name": "万宁市",
      "level": "2",
      "city_code": "1898",
      "center": "110.391073,18.795143",
      "parent_id": "2205"
    },
    {
      "area_id": "2234",
      "area_code": "469005",
      "area_name": "文昌市",
      "level": "2",
      "city_code": "1893",
      "center": "110.797717,19.543422",
      "parent_id": "2205"
    },
    {
      "area_id": "2235",
      "area_code": "469001",
      "area_name": "五指山市",
      "level": "2",
      "city_code": "1897",
      "center": "109.516925,18.775146",
      "parent_id": "2205"
    },
    {
      "area_id": "2236",
      "area_code": "500000",
      "area_name": "重庆市",
      "level": "1",
      "city_code": "023",
      "center": "106.551643,29.562849",
      "parent_id": "0"
    },
    {
      "area_id": "2237",
      "area_code": "500100",
      "area_name": "重庆城区",
      "level": "2",
      "city_code": "023",
      "center": "106.551643,29.562849",
      "parent_id": "2236"
    },
    {
      "area_id": "2238",
      "area_code": "500101",
      "area_name": "万州区",
      "level": "3",
      "city_code": "023",
      "center": "108.408661,30.807667",
      "parent_id": "2237"
    },
    {
      "area_id": "2239",
      "area_code": "500102",
      "area_name": "涪陵区",
      "level": "3",
      "city_code": "023",
      "center": "107.38977,29.703022",
      "parent_id": "2237"
    },
    {
      "area_id": "2240",
      "area_code": "500103",
      "area_name": "渝中区",
      "level": "3",
      "city_code": "023",
      "center": "106.568896,29.552736",
      "parent_id": "2237"
    },
    {
      "area_id": "2241",
      "area_code": "500104",
      "area_name": "大渡口区",
      "level": "3",
      "city_code": "023",
      "center": "106.482346,29.484527",
      "parent_id": "2237"
    },
    {
      "area_id": "2242",
      "area_code": "500105",
      "area_name": "江北区",
      "level": "3",
      "city_code": "023",
      "center": "106.574271,29.606703",
      "parent_id": "2237"
    },
    {
      "area_id": "2243",
      "area_code": "500106",
      "area_name": "沙坪坝区",
      "level": "3",
      "city_code": "023",
      "center": "106.456878,29.541144",
      "parent_id": "2237"
    },
    {
      "area_id": "2244",
      "area_code": "500107",
      "area_name": "九龙坡区",
      "level": "3",
      "city_code": "023",
      "center": "106.510676,29.502272",
      "parent_id": "2237"
    },
    {
      "area_id": "2245",
      "area_code": "500108",
      "area_name": "南岸区",
      "level": "3",
      "city_code": "023",
      "center": "106.644447,29.50126",
      "parent_id": "2237"
    },
    {
      "area_id": "2246",
      "area_code": "500109",
      "area_name": "北碚区",
      "level": "3",
      "city_code": "023",
      "center": "106.395612,29.805107",
      "parent_id": "2237"
    },
    {
      "area_id": "2247",
      "area_code": "500110",
      "area_name": "綦江区",
      "level": "3",
      "city_code": "023",
      "center": "106.651361,29.028066",
      "parent_id": "2237"
    },
    {
      "area_id": "2248",
      "area_code": "500111",
      "area_name": "大足区",
      "level": "3",
      "city_code": "023",
      "center": "105.721733,29.707032",
      "parent_id": "2237"
    },
    {
      "area_id": "2249",
      "area_code": "500112",
      "area_name": "渝北区",
      "level": "3",
      "city_code": "023",
      "center": "106.631187,29.718142",
      "parent_id": "2237"
    },
    {
      "area_id": "2250",
      "area_code": "500113",
      "area_name": "巴南区",
      "level": "3",
      "city_code": "023",
      "center": "106.540256,29.402408",
      "parent_id": "2237"
    },
    {
      "area_id": "2251",
      "area_code": "500114",
      "area_name": "黔江区",
      "level": "3",
      "city_code": "023",
      "center": "108.770677,29.533609",
      "parent_id": "2237"
    },
    {
      "area_id": "2252",
      "area_code": "500115",
      "area_name": "长寿区",
      "level": "3",
      "city_code": "023",
      "center": "107.080734,29.857912",
      "parent_id": "2237"
    },
    {
      "area_id": "2253",
      "area_code": "500116",
      "area_name": "江津区",
      "level": "3",
      "city_code": "023",
      "center": "106.259281,29.290069",
      "parent_id": "2237"
    },
    {
      "area_id": "2254",
      "area_code": "500117",
      "area_name": "合川区",
      "level": "3",
      "city_code": "023",
      "center": "106.27613,29.972084",
      "parent_id": "2237"
    },
    {
      "area_id": "2255",
      "area_code": "500118",
      "area_name": "永川区",
      "level": "3",
      "city_code": "023",
      "center": "105.927001,29.356311",
      "parent_id": "2237"
    },
    {
      "area_id": "2256",
      "area_code": "500119",
      "area_name": "南川区",
      "level": "3",
      "city_code": "023",
      "center": "107.099266,29.15789",
      "parent_id": "2237"
    },
    {
      "area_id": "2257",
      "area_code": "500120",
      "area_name": "璧山区",
      "level": "3",
      "city_code": "023",
      "center": "106.227305,29.592024",
      "parent_id": "2237"
    },
    {
      "area_id": "2258",
      "area_code": "500151",
      "area_name": "铜梁区",
      "level": "3",
      "city_code": "023",
      "center": "106.056404,29.844811",
      "parent_id": "2237"
    },
    {
      "area_id": "2259",
      "area_code": "500152",
      "area_name": "潼南区",
      "level": "3",
      "city_code": "023",
      "center": "105.840431,30.190992",
      "parent_id": "2237"
    },
    {
      "area_id": "2260",
      "area_code": "500153",
      "area_name": "荣昌区",
      "level": "3",
      "city_code": "023",
      "center": "105.594623,29.405002",
      "parent_id": "2237"
    },
    {
      "area_id": "2261",
      "area_code": "500154",
      "area_name": "开州区",
      "level": "3",
      "city_code": "023",
      "center": "108.393135,31.160711",
      "parent_id": "2237"
    },
    {
      "area_id": "2262",
      "area_code": "500200",
      "area_name": "重庆郊县",
      "level": "2",
      "city_code": "023",
      "center": "108.165537,29.293902",
      "parent_id": "2236"
    },
    {
      "area_id": "2263",
      "area_code": "500155",
      "area_name": "梁平区",
      "level": "3",
      "city_code": "023",
      "center": "107.769568,30.654233",
      "parent_id": "2262"
    },
    {
      "area_id": "2264",
      "area_code": "500229",
      "area_name": "城口县",
      "level": "3",
      "city_code": "023",
      "center": "108.664214,31.947633",
      "parent_id": "2262"
    },
    {
      "area_id": "2265",
      "area_code": "500230",
      "area_name": "丰都县",
      "level": "3",
      "city_code": "023",
      "center": "107.730894,29.8635",
      "parent_id": "2262"
    },
    {
      "area_id": "2266",
      "area_code": "500231",
      "area_name": "垫江县",
      "level": "3",
      "city_code": "023",
      "center": "107.33339,30.327716",
      "parent_id": "2262"
    },
    {
      "area_id": "2267",
      "area_code": "500156",
      "area_name": "武隆区",
      "level": "3",
      "city_code": "023",
      "center": "107.760025,29.325601",
      "parent_id": "2262"
    },
    {
      "area_id": "2268",
      "area_code": "500233",
      "area_name": "忠县",
      "level": "3",
      "city_code": "023",
      "center": "108.039002,30.299559",
      "parent_id": "2262"
    },
    {
      "area_id": "2269",
      "area_code": "500235",
      "area_name": "云阳县",
      "level": "3",
      "city_code": "023",
      "center": "108.697324,30.930612",
      "parent_id": "2262"
    },
    {
      "area_id": "2270",
      "area_code": "500236",
      "area_name": "奉节县",
      "level": "3",
      "city_code": "023",
      "center": "109.400403,31.018363",
      "parent_id": "2262"
    },
    {
      "area_id": "2271",
      "area_code": "500237",
      "area_name": "巫山县",
      "level": "3",
      "city_code": "023",
      "center": "109.879153,31.074834",
      "parent_id": "2262"
    },
    {
      "area_id": "2272",
      "area_code": "500238",
      "area_name": "巫溪县",
      "level": "3",
      "city_code": "023",
      "center": "109.570062,31.398604",
      "parent_id": "2262"
    },
    {
      "area_id": "2273",
      "area_code": "500240",
      "area_name": "石柱土家族自治县",
      "level": "3",
      "city_code": "023",
      "center": "108.114069,29.999285",
      "parent_id": "2262"
    },
    {
      "area_id": "2274",
      "area_code": "500241",
      "area_name": "秀山土家族苗族自治县",
      "level": "3",
      "city_code": "023",
      "center": "109.007094,28.447997",
      "parent_id": "2262"
    },
    {
      "area_id": "2275",
      "area_code": "500242",
      "area_name": "酉阳土家族苗族自治县",
      "level": "3",
      "city_code": "023",
      "center": "108.767747,28.841244",
      "parent_id": "2262"
    },
    {
      "area_id": "2276",
      "area_code": "500243",
      "area_name": "彭水苗族土家族自治县",
      "level": "3",
      "city_code": "023",
      "center": "108.165537,29.293902",
      "parent_id": "2262"
    },
    {
      "area_id": "2277",
      "area_code": "510000",
      "area_name": "四川省",
      "level": "1",
      "city_code": "[]",
      "center": "104.075809,30.651239",
      "parent_id": "0"
    },
    {
      "area_id": "2278",
      "area_code": "510100",
      "area_name": "成都市",
      "level": "2",
      "city_code": "028",
      "center": "104.066794,30.572893",
      "parent_id": "2277"
    },
    {
      "area_id": "2279",
      "area_code": "510104",
      "area_name": "锦江区",
      "level": "3",
      "city_code": "028",
      "center": "104.117022,30.598158",
      "parent_id": "2278"
    },
    {
      "area_id": "2280",
      "area_code": "510105",
      "area_name": "青羊区",
      "level": "3",
      "city_code": "028",
      "center": "104.061442,30.673914",
      "parent_id": "2278"
    },
    {
      "area_id": "2281",
      "area_code": "510106",
      "area_name": "金牛区",
      "level": "3",
      "city_code": "028",
      "center": "104.052236,30.691359",
      "parent_id": "2278"
    },
    {
      "area_id": "2282",
      "area_code": "510107",
      "area_name": "武侯区",
      "level": "3",
      "city_code": "028",
      "center": "104.043235,30.641907",
      "parent_id": "2278"
    },
    {
      "area_id": "2283",
      "area_code": "510108",
      "area_name": "成华区",
      "level": "3",
      "city_code": "028",
      "center": "104.101515,30.659966",
      "parent_id": "2278"
    },
    {
      "area_id": "2284",
      "area_code": "510112",
      "area_name": "龙泉驿区",
      "level": "3",
      "city_code": "028",
      "center": "104.274632,30.556506",
      "parent_id": "2278"
    },
    {
      "area_id": "2285",
      "area_code": "510113",
      "area_name": "青白江区",
      "level": "3",
      "city_code": "028",
      "center": "104.250945,30.878629",
      "parent_id": "2278"
    },
    {
      "area_id": "2286",
      "area_code": "510114",
      "area_name": "新都区",
      "level": "3",
      "city_code": "028",
      "center": "104.158705,30.823498",
      "parent_id": "2278"
    },
    {
      "area_id": "2287",
      "area_code": "510115",
      "area_name": "温江区",
      "level": "3",
      "city_code": "028",
      "center": "103.856646,30.682203",
      "parent_id": "2278"
    },
    {
      "area_id": "2288",
      "area_code": "510116",
      "area_name": "双流区",
      "level": "3",
      "city_code": "028",
      "center": "103.923566,30.574449",
      "parent_id": "2278"
    },
    {
      "area_id": "2289",
      "area_code": "510121",
      "area_name": "金堂县",
      "level": "3",
      "city_code": "028",
      "center": "104.411976,30.861979",
      "parent_id": "2278"
    },
    {
      "area_id": "2290",
      "area_code": "510117",
      "area_name": "郫都区",
      "level": "3",
      "city_code": "028",
      "center": "103.901091,30.795854",
      "parent_id": "2278"
    },
    {
      "area_id": "2291",
      "area_code": "510129",
      "area_name": "大邑县",
      "level": "3",
      "city_code": "028",
      "center": "103.511865,30.572268",
      "parent_id": "2278"
    },
    {
      "area_id": "2292",
      "area_code": "510131",
      "area_name": "蒲江县",
      "level": "3",
      "city_code": "028",
      "center": "103.506498,30.196788",
      "parent_id": "2278"
    },
    {
      "area_id": "2293",
      "area_code": "510132",
      "area_name": "新津县",
      "level": "3",
      "city_code": "028",
      "center": "103.811286,30.410346",
      "parent_id": "2278"
    },
    {
      "area_id": "2294",
      "area_code": "510185",
      "area_name": "简阳市",
      "level": "3",
      "city_code": "028",
      "center": "104.54722,30.411264",
      "parent_id": "2278"
    },
    {
      "area_id": "2295",
      "area_code": "510181",
      "area_name": "都江堰市",
      "level": "3",
      "city_code": "028",
      "center": "103.647153,30.988767",
      "parent_id": "2278"
    },
    {
      "area_id": "2296",
      "area_code": "510182",
      "area_name": "彭州市",
      "level": "3",
      "city_code": "028",
      "center": "103.957983,30.990212",
      "parent_id": "2278"
    },
    {
      "area_id": "2297",
      "area_code": "510183",
      "area_name": "邛崃市",
      "level": "3",
      "city_code": "028",
      "center": "103.464207,30.410324",
      "parent_id": "2278"
    },
    {
      "area_id": "2298",
      "area_code": "510184",
      "area_name": "崇州市",
      "level": "3",
      "city_code": "028",
      "center": "103.673001,30.630122",
      "parent_id": "2278"
    },
    {
      "area_id": "2299",
      "area_code": "510300",
      "area_name": "自贡市",
      "level": "2",
      "city_code": "0813",
      "center": "104.778442,29.33903",
      "parent_id": "2277"
    },
    {
      "area_id": "2300",
      "area_code": "510302",
      "area_name": "自流井区",
      "level": "3",
      "city_code": "0813",
      "center": "104.777191,29.337429",
      "parent_id": "2299"
    },
    {
      "area_id": "2301",
      "area_code": "510303",
      "area_name": "贡井区",
      "level": "3",
      "city_code": "0813",
      "center": "104.715288,29.345313",
      "parent_id": "2299"
    },
    {
      "area_id": "2302",
      "area_code": "510304",
      "area_name": "大安区",
      "level": "3",
      "city_code": "0813",
      "center": "104.773994,29.363702",
      "parent_id": "2299"
    },
    {
      "area_id": "2303",
      "area_code": "510311",
      "area_name": "沿滩区",
      "level": "3",
      "city_code": "0813",
      "center": "104.874079,29.272586",
      "parent_id": "2299"
    },
    {
      "area_id": "2304",
      "area_code": "510321",
      "area_name": "荣县",
      "level": "3",
      "city_code": "0813",
      "center": "104.417493,29.445479",
      "parent_id": "2299"
    },
    {
      "area_id": "2305",
      "area_code": "510322",
      "area_name": "富顺县",
      "level": "3",
      "city_code": "0813",
      "center": "104.975048,29.181429",
      "parent_id": "2299"
    },
    {
      "area_id": "2306",
      "area_code": "510400",
      "area_name": "攀枝花市",
      "level": "2",
      "city_code": "0812",
      "center": "101.718637,26.582347",
      "parent_id": "2277"
    },
    {
      "area_id": "2307",
      "area_code": "510402",
      "area_name": "东区",
      "level": "3",
      "city_code": "0812",
      "center": "101.704109,26.546491",
      "parent_id": "2306"
    },
    {
      "area_id": "2308",
      "area_code": "510403",
      "area_name": "西区",
      "level": "3",
      "city_code": "0812",
      "center": "101.630619,26.597781",
      "parent_id": "2306"
    },
    {
      "area_id": "2309",
      "area_code": "510411",
      "area_name": "仁和区",
      "level": "3",
      "city_code": "0812",
      "center": "101.738528,26.497765",
      "parent_id": "2306"
    },
    {
      "area_id": "2310",
      "area_code": "510421",
      "area_name": "米易县",
      "level": "3",
      "city_code": "0812",
      "center": "102.112895,26.897694",
      "parent_id": "2306"
    },
    {
      "area_id": "2311",
      "area_code": "510422",
      "area_name": "盐边县",
      "level": "3",
      "city_code": "0812",
      "center": "101.855071,26.683213",
      "parent_id": "2306"
    },
    {
      "area_id": "2312",
      "area_code": "510500",
      "area_name": "泸州市",
      "level": "2",
      "city_code": "0830",
      "center": "105.442285,28.871805",
      "parent_id": "2277"
    },
    {
      "area_id": "2313",
      "area_code": "510502",
      "area_name": "江阳区",
      "level": "3",
      "city_code": "0830",
      "center": "105.434982,28.87881",
      "parent_id": "2312"
    },
    {
      "area_id": "2314",
      "area_code": "510503",
      "area_name": "纳溪区",
      "level": "3",
      "city_code": "0830",
      "center": "105.371505,28.773134",
      "parent_id": "2312"
    },
    {
      "area_id": "2315",
      "area_code": "510504",
      "area_name": "龙马潭区",
      "level": "3",
      "city_code": "0830",
      "center": "105.437751,28.913257",
      "parent_id": "2312"
    },
    {
      "area_id": "2316",
      "area_code": "510521",
      "area_name": "泸县",
      "level": "3",
      "city_code": "0830",
      "center": "105.381893,29.151534",
      "parent_id": "2312"
    },
    {
      "area_id": "2317",
      "area_code": "510522",
      "area_name": "合江县",
      "level": "3",
      "city_code": "0830",
      "center": "105.830986,28.811164",
      "parent_id": "2312"
    },
    {
      "area_id": "2318",
      "area_code": "510524",
      "area_name": "叙永县",
      "level": "3",
      "city_code": "0830",
      "center": "105.444765,28.155801",
      "parent_id": "2312"
    },
    {
      "area_id": "2319",
      "area_code": "510525",
      "area_name": "古蔺县",
      "level": "3",
      "city_code": "0830",
      "center": "105.812601,28.038801",
      "parent_id": "2312"
    },
    {
      "area_id": "2320",
      "area_code": "510600",
      "area_name": "德阳市",
      "level": "2",
      "city_code": "0838",
      "center": "104.397894,31.126855",
      "parent_id": "2277"
    },
    {
      "area_id": "2321",
      "area_code": "510603",
      "area_name": "旌阳区",
      "level": "3",
      "city_code": "0838",
      "center": "104.416966,31.142633",
      "parent_id": "2320"
    },
    {
      "area_id": "2322",
      "area_code": "510623",
      "area_name": "中江县",
      "level": "3",
      "city_code": "0838",
      "center": "104.678751,31.03307",
      "parent_id": "2320"
    },
    {
      "area_id": "2323",
      "area_code": "510626",
      "area_name": "罗江县",
      "level": "3",
      "city_code": "0838",
      "center": "104.510249,31.317045",
      "parent_id": "2320"
    },
    {
      "area_id": "2324",
      "area_code": "510681",
      "area_name": "广汉市",
      "level": "3",
      "city_code": "0838",
      "center": "104.282429,30.977119",
      "parent_id": "2320"
    },
    {
      "area_id": "2325",
      "area_code": "510682",
      "area_name": "什邡市",
      "level": "3",
      "city_code": "0838",
      "center": "104.167501,31.12678",
      "parent_id": "2320"
    },
    {
      "area_id": "2326",
      "area_code": "510683",
      "area_name": "绵竹市",
      "level": "3",
      "city_code": "0838",
      "center": "104.22075,31.338077",
      "parent_id": "2320"
    },
    {
      "area_id": "2327",
      "area_code": "510700",
      "area_name": "绵阳市",
      "level": "2",
      "city_code": "0816",
      "center": "104.679004,31.467459",
      "parent_id": "2277"
    },
    {
      "area_id": "2328",
      "area_code": "510703",
      "area_name": "涪城区",
      "level": "3",
      "city_code": "0816",
      "center": "104.756944,31.455101",
      "parent_id": "2327"
    },
    {
      "area_id": "2329",
      "area_code": "510704",
      "area_name": "游仙区",
      "level": "3",
      "city_code": "0816",
      "center": "104.766392,31.473779",
      "parent_id": "2327"
    },
    {
      "area_id": "2330",
      "area_code": "510705",
      "area_name": "安州区",
      "level": "3",
      "city_code": "0816",
      "center": "104.567187,31.534886",
      "parent_id": "2327"
    },
    {
      "area_id": "2331",
      "area_code": "510722",
      "area_name": "三台县",
      "level": "3",
      "city_code": "0816",
      "center": "105.094586,31.095979",
      "parent_id": "2327"
    },
    {
      "area_id": "2332",
      "area_code": "510723",
      "area_name": "盐亭县",
      "level": "3",
      "city_code": "0816",
      "center": "105.389453,31.208362",
      "parent_id": "2327"
    },
    {
      "area_id": "2333",
      "area_code": "510725",
      "area_name": "梓潼县",
      "level": "3",
      "city_code": "0816",
      "center": "105.170845,31.642718",
      "parent_id": "2327"
    },
    {
      "area_id": "2334",
      "area_code": "510726",
      "area_name": "北川羌族自治县",
      "level": "3",
      "city_code": "0816",
      "center": "104.46797,31.617203",
      "parent_id": "2327"
    },
    {
      "area_id": "2335",
      "area_code": "510727",
      "area_name": "平武县",
      "level": "3",
      "city_code": "0816",
      "center": "104.555583,32.409675",
      "parent_id": "2327"
    },
    {
      "area_id": "2336",
      "area_code": "510781",
      "area_name": "江油市",
      "level": "3",
      "city_code": "0816",
      "center": "104.745915,31.778026",
      "parent_id": "2327"
    },
    {
      "area_id": "2337",
      "area_code": "510800",
      "area_name": "广元市",
      "level": "2",
      "city_code": "0839",
      "center": "105.843357,32.435435",
      "parent_id": "2277"
    },
    {
      "area_id": "2338",
      "area_code": "510802",
      "area_name": "利州区",
      "level": "3",
      "city_code": "0839",
      "center": "105.845307,32.433756",
      "parent_id": "2337"
    },
    {
      "area_id": "2339",
      "area_code": "510811",
      "area_name": "昭化区",
      "level": "3",
      "city_code": "0839",
      "center": "105.962819,32.323256",
      "parent_id": "2337"
    },
    {
      "area_id": "2340",
      "area_code": "510812",
      "area_name": "朝天区",
      "level": "3",
      "city_code": "0839",
      "center": "105.882642,32.651336",
      "parent_id": "2337"
    },
    {
      "area_id": "2341",
      "area_code": "510821",
      "area_name": "旺苍县",
      "level": "3",
      "city_code": "0839",
      "center": "106.289983,32.229058",
      "parent_id": "2337"
    },
    {
      "area_id": "2342",
      "area_code": "510822",
      "area_name": "青川县",
      "level": "3",
      "city_code": "0839",
      "center": "105.238842,32.575484",
      "parent_id": "2337"
    },
    {
      "area_id": "2343",
      "area_code": "510823",
      "area_name": "剑阁县",
      "level": "3",
      "city_code": "0839",
      "center": "105.524766,32.287722",
      "parent_id": "2337"
    },
    {
      "area_id": "2344",
      "area_code": "510824",
      "area_name": "苍溪县",
      "level": "3",
      "city_code": "0839",
      "center": "105.934756,31.731709",
      "parent_id": "2337"
    },
    {
      "area_id": "2345",
      "area_code": "510900",
      "area_name": "遂宁市",
      "level": "2",
      "city_code": "0825",
      "center": "105.592803,30.53292",
      "parent_id": "2277"
    },
    {
      "area_id": "2346",
      "area_code": "510903",
      "area_name": "船山区",
      "level": "3",
      "city_code": "0825",
      "center": "105.568297,30.525475",
      "parent_id": "2345"
    },
    {
      "area_id": "2347",
      "area_code": "510904",
      "area_name": "安居区",
      "level": "3",
      "city_code": "0825",
      "center": "105.456342,30.355379",
      "parent_id": "2345"
    },
    {
      "area_id": "2348",
      "area_code": "510921",
      "area_name": "蓬溪县",
      "level": "3",
      "city_code": "0825",
      "center": "105.70757,30.757575",
      "parent_id": "2345"
    },
    {
      "area_id": "2349",
      "area_code": "510922",
      "area_name": "射洪县",
      "level": "3",
      "city_code": "0825",
      "center": "105.388412,30.871131",
      "parent_id": "2345"
    },
    {
      "area_id": "2350",
      "area_code": "510923",
      "area_name": "大英县",
      "level": "3",
      "city_code": "0825",
      "center": "105.236923,30.594409",
      "parent_id": "2345"
    },
    {
      "area_id": "2351",
      "area_code": "511000",
      "area_name": "内江市",
      "level": "2",
      "city_code": "1832",
      "center": "105.058432,29.580228",
      "parent_id": "2277"
    },
    {
      "area_id": "2352",
      "area_code": "511002",
      "area_name": "市中区",
      "level": "3",
      "city_code": "1832",
      "center": "105.067597,29.587053",
      "parent_id": "2351"
    },
    {
      "area_id": "2353",
      "area_code": "511011",
      "area_name": "东兴区",
      "level": "3",
      "city_code": "1832",
      "center": "105.075489,29.592756",
      "parent_id": "2351"
    },
    {
      "area_id": "2354",
      "area_code": "511024",
      "area_name": "威远县",
      "level": "3",
      "city_code": "1832",
      "center": "104.668879,29.52744",
      "parent_id": "2351"
    },
    {
      "area_id": "2355",
      "area_code": "511025",
      "area_name": "资中县",
      "level": "3",
      "city_code": "1832",
      "center": "104.851944,29.764059",
      "parent_id": "2351"
    },
    {
      "area_id": "2356",
      "area_code": "511028",
      "area_name": "隆昌市",
      "level": "3",
      "city_code": "1832",
      "center": "105.287612,29.339476",
      "parent_id": "2351"
    },
    {
      "area_id": "2357",
      "area_code": "511100",
      "area_name": "乐山市",
      "level": "2",
      "city_code": "0833",
      "center": "103.765678,29.552115",
      "parent_id": "2277"
    },
    {
      "area_id": "2358",
      "area_code": "511102",
      "area_name": "市中区",
      "level": "3",
      "city_code": "0833",
      "center": "103.761329,29.555374",
      "parent_id": "2357"
    },
    {
      "area_id": "2359",
      "area_code": "511111",
      "area_name": "沙湾区",
      "level": "3",
      "city_code": "0833",
      "center": "103.549991,29.413091",
      "parent_id": "2357"
    },
    {
      "area_id": "2360",
      "area_code": "511112",
      "area_name": "五通桥区",
      "level": "3",
      "city_code": "0833",
      "center": "103.818014,29.406945",
      "parent_id": "2357"
    },
    {
      "area_id": "2361",
      "area_code": "511113",
      "area_name": "金口河区",
      "level": "3",
      "city_code": "0833",
      "center": "103.07862,29.244345",
      "parent_id": "2357"
    },
    {
      "area_id": "2362",
      "area_code": "511123",
      "area_name": "犍为县",
      "level": "3",
      "city_code": "0833",
      "center": "103.949326,29.20817",
      "parent_id": "2357"
    },
    {
      "area_id": "2363",
      "area_code": "511124",
      "area_name": "井研县",
      "level": "3",
      "city_code": "0833",
      "center": "104.069726,29.651287",
      "parent_id": "2357"
    },
    {
      "area_id": "2364",
      "area_code": "511126",
      "area_name": "夹江县",
      "level": "3",
      "city_code": "0833",
      "center": "103.571656,29.73763",
      "parent_id": "2357"
    },
    {
      "area_id": "2365",
      "area_code": "511129",
      "area_name": "沐川县",
      "level": "3",
      "city_code": "0833",
      "center": "103.902334,28.956647",
      "parent_id": "2357"
    },
    {
      "area_id": "2366",
      "area_code": "511132",
      "area_name": "峨边彝族自治县",
      "level": "3",
      "city_code": "0833",
      "center": "103.262048,29.230425",
      "parent_id": "2357"
    },
    {
      "area_id": "2367",
      "area_code": "511133",
      "area_name": "马边彝族自治县",
      "level": "3",
      "city_code": "0833",
      "center": "103.546347,28.83552",
      "parent_id": "2357"
    },
    {
      "area_id": "2368",
      "area_code": "511181",
      "area_name": "峨眉山市",
      "level": "3",
      "city_code": "0833",
      "center": "103.484503,29.601198",
      "parent_id": "2357"
    },
    {
      "area_id": "2369",
      "area_code": "511300",
      "area_name": "南充市",
      "level": "2",
      "city_code": "0817",
      "center": "106.110698,30.837793",
      "parent_id": "2277"
    },
    {
      "area_id": "2370",
      "area_code": "511302",
      "area_name": "顺庆区",
      "level": "3",
      "city_code": "0817",
      "center": "106.09245,30.796803",
      "parent_id": "2369"
    },
    {
      "area_id": "2371",
      "area_code": "511303",
      "area_name": "高坪区",
      "level": "3",
      "city_code": "0817",
      "center": "106.118808,30.781623",
      "parent_id": "2369"
    },
    {
      "area_id": "2372",
      "area_code": "511304",
      "area_name": "嘉陵区",
      "level": "3",
      "city_code": "0817",
      "center": "106.071876,30.758823",
      "parent_id": "2369"
    },
    {
      "area_id": "2373",
      "area_code": "511321",
      "area_name": "南部县",
      "level": "3",
      "city_code": "0817",
      "center": "106.036584,31.347467",
      "parent_id": "2369"
    },
    {
      "area_id": "2374",
      "area_code": "511322",
      "area_name": "营山县",
      "level": "3",
      "city_code": "0817",
      "center": "106.565519,31.076579",
      "parent_id": "2369"
    },
    {
      "area_id": "2375",
      "area_code": "511323",
      "area_name": "蓬安县",
      "level": "3",
      "city_code": "0817",
      "center": "106.412136,31.029091",
      "parent_id": "2369"
    },
    {
      "area_id": "2376",
      "area_code": "511324",
      "area_name": "仪陇县",
      "level": "3",
      "city_code": "0817",
      "center": "106.303042,31.271561",
      "parent_id": "2369"
    },
    {
      "area_id": "2377",
      "area_code": "511325",
      "area_name": "西充县",
      "level": "3",
      "city_code": "0817",
      "center": "105.90087,30.995683",
      "parent_id": "2369"
    },
    {
      "area_id": "2378",
      "area_code": "511381",
      "area_name": "阆中市",
      "level": "3",
      "city_code": "0817",
      "center": "106.005046,31.558356",
      "parent_id": "2369"
    },
    {
      "area_id": "2379",
      "area_code": "511400",
      "area_name": "眉山市",
      "level": "2",
      "city_code": "1833",
      "center": "103.848403,30.076994",
      "parent_id": "2277"
    },
    {
      "area_id": "2380",
      "area_code": "511402",
      "area_name": "东坡区",
      "level": "3",
      "city_code": "1833",
      "center": "103.831863,30.042308",
      "parent_id": "2379"
    },
    {
      "area_id": "2381",
      "area_code": "511403",
      "area_name": "彭山区",
      "level": "3",
      "city_code": "1833",
      "center": "103.872949,30.193056",
      "parent_id": "2379"
    },
    {
      "area_id": "2382",
      "area_code": "511421",
      "area_name": "仁寿县",
      "level": "3",
      "city_code": "1833",
      "center": "104.133995,29.995635",
      "parent_id": "2379"
    },
    {
      "area_id": "2383",
      "area_code": "511423",
      "area_name": "洪雅县",
      "level": "3",
      "city_code": "1833",
      "center": "103.372863,29.90489",
      "parent_id": "2379"
    },
    {
      "area_id": "2384",
      "area_code": "511424",
      "area_name": "丹棱县",
      "level": "3",
      "city_code": "1833",
      "center": "103.512783,30.01521",
      "parent_id": "2379"
    },
    {
      "area_id": "2385",
      "area_code": "511425",
      "area_name": "青神县",
      "level": "3",
      "city_code": "1833",
      "center": "103.846688,29.831357",
      "parent_id": "2379"
    },
    {
      "area_id": "2386",
      "area_code": "511500",
      "area_name": "宜宾市",
      "level": "2",
      "city_code": "0831",
      "center": "104.642845,28.752134",
      "parent_id": "2277"
    },
    {
      "area_id": "2387",
      "area_code": "511502",
      "area_name": "翠屏区",
      "level": "3",
      "city_code": "0831",
      "center": "104.620009,28.765689",
      "parent_id": "2386"
    },
    {
      "area_id": "2388",
      "area_code": "511503",
      "area_name": "南溪区",
      "level": "3",
      "city_code": "0831",
      "center": "104.969152,28.846382",
      "parent_id": "2386"
    },
    {
      "area_id": "2389",
      "area_code": "511521",
      "area_name": "宜宾县",
      "level": "3",
      "city_code": "0831",
      "center": "104.533212,28.690045",
      "parent_id": "2386"
    },
    {
      "area_id": "2390",
      "area_code": "511523",
      "area_name": "江安县",
      "level": "3",
      "city_code": "0831",
      "center": "105.066879,28.723855",
      "parent_id": "2386"
    },
    {
      "area_id": "2391",
      "area_code": "511524",
      "area_name": "长宁县",
      "level": "3",
      "city_code": "0831",
      "center": "104.921174,28.582169",
      "parent_id": "2386"
    },
    {
      "area_id": "2392",
      "area_code": "511525",
      "area_name": "高县",
      "level": "3",
      "city_code": "0831",
      "center": "104.517748,28.436166",
      "parent_id": "2386"
    },
    {
      "area_id": "2393",
      "area_code": "511526",
      "area_name": "珙县",
      "level": "3",
      "city_code": "0831",
      "center": "104.709202,28.43863",
      "parent_id": "2386"
    },
    {
      "area_id": "2394",
      "area_code": "511527",
      "area_name": "筠连县",
      "level": "3",
      "city_code": "0831",
      "center": "104.512025,28.167831",
      "parent_id": "2386"
    },
    {
      "area_id": "2395",
      "area_code": "511528",
      "area_name": "兴文县",
      "level": "3",
      "city_code": "0831",
      "center": "105.236325,28.303614",
      "parent_id": "2386"
    },
    {
      "area_id": "2396",
      "area_code": "511529",
      "area_name": "屏山县",
      "level": "3",
      "city_code": "0831",
      "center": "104.345974,28.828482",
      "parent_id": "2386"
    },
    {
      "area_id": "2397",
      "area_code": "511600",
      "area_name": "广安市",
      "level": "2",
      "city_code": "0826",
      "center": "106.633088,30.456224",
      "parent_id": "2277"
    },
    {
      "area_id": "2398",
      "area_code": "511602",
      "area_name": "广安区",
      "level": "3",
      "city_code": "0826",
      "center": "106.641662,30.473913",
      "parent_id": "2397"
    },
    {
      "area_id": "2399",
      "area_code": "511603",
      "area_name": "前锋区",
      "level": "3",
      "city_code": "0826",
      "center": "106.886143,30.495804",
      "parent_id": "2397"
    },
    {
      "area_id": "2400",
      "area_code": "511621",
      "area_name": "岳池县",
      "level": "3",
      "city_code": "0826",
      "center": "106.440114,30.537863",
      "parent_id": "2397"
    },
    {
      "area_id": "2401",
      "area_code": "511622",
      "area_name": "武胜县",
      "level": "3",
      "city_code": "0826",
      "center": "106.295764,30.348772",
      "parent_id": "2397"
    },
    {
      "area_id": "2402",
      "area_code": "511623",
      "area_name": "邻水县",
      "level": "3",
      "city_code": "0826",
      "center": "106.93038,30.334768",
      "parent_id": "2397"
    },
    {
      "area_id": "2403",
      "area_code": "511681",
      "area_name": "华蓥市",
      "level": "3",
      "city_code": "0826",
      "center": "106.7831,30.390188",
      "parent_id": "2397"
    },
    {
      "area_id": "2404",
      "area_code": "511700",
      "area_name": "达州市",
      "level": "2",
      "city_code": "0818",
      "center": "107.467758,31.209121",
      "parent_id": "2277"
    },
    {
      "area_id": "2405",
      "area_code": "511702",
      "area_name": "通川区",
      "level": "3",
      "city_code": "0818",
      "center": "107.504928,31.214715",
      "parent_id": "2404"
    },
    {
      "area_id": "2406",
      "area_code": "511703",
      "area_name": "达川区",
      "level": "3",
      "city_code": "0818",
      "center": "107.511749,31.196157",
      "parent_id": "2404"
    },
    {
      "area_id": "2407",
      "area_code": "511722",
      "area_name": "宣汉县",
      "level": "3",
      "city_code": "0818",
      "center": "107.72719,31.353835",
      "parent_id": "2404"
    },
    {
      "area_id": "2408",
      "area_code": "511723",
      "area_name": "开江县",
      "level": "3",
      "city_code": "0818",
      "center": "107.868736,31.082986",
      "parent_id": "2404"
    },
    {
      "area_id": "2409",
      "area_code": "511724",
      "area_name": "大竹县",
      "level": "3",
      "city_code": "0818",
      "center": "107.204795,30.73641",
      "parent_id": "2404"
    },
    {
      "area_id": "2410",
      "area_code": "511725",
      "area_name": "渠县",
      "level": "3",
      "city_code": "0818",
      "center": "106.97303,30.836618",
      "parent_id": "2404"
    },
    {
      "area_id": "2411",
      "area_code": "511781",
      "area_name": "万源市",
      "level": "3",
      "city_code": "0818",
      "center": "108.034657,32.081631",
      "parent_id": "2404"
    },
    {
      "area_id": "2412",
      "area_code": "511800",
      "area_name": "雅安市",
      "level": "2",
      "city_code": "0835",
      "center": "103.042375,30.010602",
      "parent_id": "2277"
    },
    {
      "area_id": "2413",
      "area_code": "511802",
      "area_name": "雨城区",
      "level": "3",
      "city_code": "0835",
      "center": "103.033026,30.005461",
      "parent_id": "2412"
    },
    {
      "area_id": "2414",
      "area_code": "511803",
      "area_name": "名山区",
      "level": "3",
      "city_code": "0835",
      "center": "103.109184,30.069954",
      "parent_id": "2412"
    },
    {
      "area_id": "2415",
      "area_code": "511822",
      "area_name": "荥经县",
      "level": "3",
      "city_code": "0835",
      "center": "102.846737,29.792931",
      "parent_id": "2412"
    },
    {
      "area_id": "2416",
      "area_code": "511823",
      "area_name": "汉源县",
      "level": "3",
      "city_code": "0835",
      "center": "102.645467,29.347192",
      "parent_id": "2412"
    },
    {
      "area_id": "2417",
      "area_code": "511824",
      "area_name": "石棉县",
      "level": "3",
      "city_code": "0835",
      "center": "102.359462,29.227874",
      "parent_id": "2412"
    },
    {
      "area_id": "2418",
      "area_code": "511825",
      "area_name": "天全县",
      "level": "3",
      "city_code": "0835",
      "center": "102.758317,30.066712",
      "parent_id": "2412"
    },
    {
      "area_id": "2419",
      "area_code": "511826",
      "area_name": "芦山县",
      "level": "3",
      "city_code": "0835",
      "center": "102.932385,30.142307",
      "parent_id": "2412"
    },
    {
      "area_id": "2420",
      "area_code": "511827",
      "area_name": "宝兴县",
      "level": "3",
      "city_code": "0835",
      "center": "102.815403,30.37641",
      "parent_id": "2412"
    },
    {
      "area_id": "2421",
      "area_code": "511900",
      "area_name": "巴中市",
      "level": "2",
      "city_code": "0827",
      "center": "106.747477,31.867903",
      "parent_id": "2277"
    },
    {
      "area_id": "2422",
      "area_code": "511902",
      "area_name": "巴州区",
      "level": "3",
      "city_code": "0827",
      "center": "106.768878,31.851478",
      "parent_id": "2421"
    },
    {
      "area_id": "2423",
      "area_code": "511903",
      "area_name": "恩阳区",
      "level": "3",
      "city_code": "0827",
      "center": "106.654386,31.787186",
      "parent_id": "2421"
    },
    {
      "area_id": "2424",
      "area_code": "511921",
      "area_name": "通江县",
      "level": "3",
      "city_code": "0827",
      "center": "107.245033,31.911705",
      "parent_id": "2421"
    },
    {
      "area_id": "2425",
      "area_code": "511922",
      "area_name": "南江县",
      "level": "3",
      "city_code": "0827",
      "center": "106.828697,32.346589",
      "parent_id": "2421"
    },
    {
      "area_id": "2426",
      "area_code": "511923",
      "area_name": "平昌县",
      "level": "3",
      "city_code": "0827",
      "center": "107.104008,31.560874",
      "parent_id": "2421"
    },
    {
      "area_id": "2427",
      "area_code": "512000",
      "area_name": "资阳市",
      "level": "2",
      "city_code": "0832",
      "center": "104.627636,30.128901",
      "parent_id": "2277"
    },
    {
      "area_id": "2428",
      "area_code": "512002",
      "area_name": "雁江区",
      "level": "3",
      "city_code": "0832",
      "center": "104.677091,30.108216",
      "parent_id": "2427"
    },
    {
      "area_id": "2429",
      "area_code": "512021",
      "area_name": "安岳县",
      "level": "3",
      "city_code": "0832",
      "center": "105.35534,30.103107",
      "parent_id": "2427"
    },
    {
      "area_id": "2430",
      "area_code": "512022",
      "area_name": "乐至县",
      "level": "3",
      "city_code": "0832",
      "center": "105.02019,30.276121",
      "parent_id": "2427"
    },
    {
      "area_id": "2431",
      "area_code": "513200",
      "area_name": "阿坝藏族羌族自治州",
      "level": "2",
      "city_code": "0837",
      "center": "102.224653,31.899413",
      "parent_id": "2277"
    },
    {
      "area_id": "2432",
      "area_code": "513201",
      "area_name": "马尔康市",
      "level": "3",
      "city_code": "0837",
      "center": "102.20652,31.905693",
      "parent_id": "2431"
    },
    {
      "area_id": "2433",
      "area_code": "513221",
      "area_name": "汶川县",
      "level": "3",
      "city_code": "0837",
      "center": "103.590179,31.476854",
      "parent_id": "2431"
    },
    {
      "area_id": "2434",
      "area_code": "513222",
      "area_name": "理县",
      "level": "3",
      "city_code": "0837",
      "center": "103.164661,31.435174",
      "parent_id": "2431"
    },
    {
      "area_id": "2435",
      "area_code": "513223",
      "area_name": "茂县",
      "level": "3",
      "city_code": "0837",
      "center": "103.853363,31.681547",
      "parent_id": "2431"
    },
    {
      "area_id": "2436",
      "area_code": "513224",
      "area_name": "松潘县",
      "level": "3",
      "city_code": "0837",
      "center": "103.604698,32.655325",
      "parent_id": "2431"
    },
    {
      "area_id": "2437",
      "area_code": "513225",
      "area_name": "九寨沟县",
      "level": "3",
      "city_code": "0837",
      "center": "104.243841,33.252056",
      "parent_id": "2431"
    },
    {
      "area_id": "2438",
      "area_code": "513226",
      "area_name": "金川县",
      "level": "3",
      "city_code": "0837",
      "center": "102.063829,31.476277",
      "parent_id": "2431"
    },
    {
      "area_id": "2439",
      "area_code": "513227",
      "area_name": "小金县",
      "level": "3",
      "city_code": "0837",
      "center": "102.362984,30.995823",
      "parent_id": "2431"
    },
    {
      "area_id": "2440",
      "area_code": "513228",
      "area_name": "黑水县",
      "level": "3",
      "city_code": "0837",
      "center": "102.990108,32.061895",
      "parent_id": "2431"
    },
    {
      "area_id": "2441",
      "area_code": "513230",
      "area_name": "壤塘县",
      "level": "3",
      "city_code": "0837",
      "center": "100.978526,32.265796",
      "parent_id": "2431"
    },
    {
      "area_id": "2442",
      "area_code": "513231",
      "area_name": "阿坝县",
      "level": "3",
      "city_code": "0837",
      "center": "101.706655,32.902459",
      "parent_id": "2431"
    },
    {
      "area_id": "2443",
      "area_code": "513232",
      "area_name": "若尔盖县",
      "level": "3",
      "city_code": "0837",
      "center": "102.967826,33.578159",
      "parent_id": "2431"
    },
    {
      "area_id": "2444",
      "area_code": "513233",
      "area_name": "红原县",
      "level": "3",
      "city_code": "0837",
      "center": "102.544405,32.790891",
      "parent_id": "2431"
    },
    {
      "area_id": "2445",
      "area_code": "513300",
      "area_name": "甘孜藏族自治州",
      "level": "2",
      "city_code": "0836",
      "center": "101.96231,30.04952",
      "parent_id": "2277"
    },
    {
      "area_id": "2446",
      "area_code": "513301",
      "area_name": "康定市",
      "level": "3",
      "city_code": "0836",
      "center": "101.957146,29.998435",
      "parent_id": "2445"
    },
    {
      "area_id": "2447",
      "area_code": "513322",
      "area_name": "泸定县",
      "level": "3",
      "city_code": "0836",
      "center": "102.234617,29.91416",
      "parent_id": "2445"
    },
    {
      "area_id": "2448",
      "area_code": "513323",
      "area_name": "丹巴县",
      "level": "3",
      "city_code": "0836",
      "center": "101.890358,30.878577",
      "parent_id": "2445"
    },
    {
      "area_id": "2449",
      "area_code": "513324",
      "area_name": "九龙县",
      "level": "3",
      "city_code": "0836",
      "center": "101.507294,29.000347",
      "parent_id": "2445"
    },
    {
      "area_id": "2450",
      "area_code": "513325",
      "area_name": "雅江县",
      "level": "3",
      "city_code": "0836",
      "center": "101.014425,30.031533",
      "parent_id": "2445"
    },
    {
      "area_id": "2451",
      "area_code": "513326",
      "area_name": "道孚县",
      "level": "3",
      "city_code": "0836",
      "center": "101.125237,30.979545",
      "parent_id": "2445"
    },
    {
      "area_id": "2452",
      "area_code": "513327",
      "area_name": "炉霍县",
      "level": "3",
      "city_code": "0836",
      "center": "100.676372,31.39179",
      "parent_id": "2445"
    },
    {
      "area_id": "2453",
      "area_code": "513328",
      "area_name": "甘孜县",
      "level": "3",
      "city_code": "0836",
      "center": "99.99267,31.622933",
      "parent_id": "2445"
    },
    {
      "area_id": "2454",
      "area_code": "513329",
      "area_name": "新龙县",
      "level": "3",
      "city_code": "0836",
      "center": "100.311368,30.939169",
      "parent_id": "2445"
    },
    {
      "area_id": "2455",
      "area_code": "513330",
      "area_name": "德格县",
      "level": "3",
      "city_code": "0836",
      "center": "98.580914,31.806118",
      "parent_id": "2445"
    },
    {
      "area_id": "2456",
      "area_code": "513331",
      "area_name": "白玉县",
      "level": "3",
      "city_code": "0836",
      "center": "98.824182,31.209913",
      "parent_id": "2445"
    },
    {
      "area_id": "2457",
      "area_code": "513332",
      "area_name": "石渠县",
      "level": "3",
      "city_code": "0836",
      "center": "98.102914,32.97896",
      "parent_id": "2445"
    },
    {
      "area_id": "2458",
      "area_code": "513333",
      "area_name": "色达县",
      "level": "3",
      "city_code": "0836",
      "center": "100.332743,32.268129",
      "parent_id": "2445"
    },
    {
      "area_id": "2459",
      "area_code": "513334",
      "area_name": "理塘县",
      "level": "3",
      "city_code": "0836",
      "center": "100.269817,29.996049",
      "parent_id": "2445"
    },
    {
      "area_id": "2460",
      "area_code": "513335",
      "area_name": "巴塘县",
      "level": "3",
      "city_code": "0836",
      "center": "99.110712,30.004677",
      "parent_id": "2445"
    },
    {
      "area_id": "2461",
      "area_code": "513336",
      "area_name": "乡城县",
      "level": "3",
      "city_code": "0836",
      "center": "99.798435,28.931172",
      "parent_id": "2445"
    },
    {
      "area_id": "2462",
      "area_code": "513337",
      "area_name": "稻城县",
      "level": "3",
      "city_code": "0836",
      "center": "100.298403,29.037007",
      "parent_id": "2445"
    },
    {
      "area_id": "2463",
      "area_code": "513338",
      "area_name": "得荣县",
      "level": "3",
      "city_code": "0836",
      "center": "99.286335,28.713036",
      "parent_id": "2445"
    },
    {
      "area_id": "2464",
      "area_code": "513400",
      "area_name": "凉山彝族自治州",
      "level": "2",
      "city_code": "0834",
      "center": "102.267712,27.88157",
      "parent_id": "2277"
    },
    {
      "area_id": "2465",
      "area_code": "513401",
      "area_name": "西昌市",
      "level": "3",
      "city_code": "0834",
      "center": "102.264449,27.894504",
      "parent_id": "2464"
    },
    {
      "area_id": "2466",
      "area_code": "513422",
      "area_name": "木里藏族自治县",
      "level": "3",
      "city_code": "0834",
      "center": "101.280205,27.928835",
      "parent_id": "2464"
    },
    {
      "area_id": "2467",
      "area_code": "513423",
      "area_name": "盐源县",
      "level": "3",
      "city_code": "0834",
      "center": "101.509188,27.422645",
      "parent_id": "2464"
    },
    {
      "area_id": "2468",
      "area_code": "513424",
      "area_name": "德昌县",
      "level": "3",
      "city_code": "0834",
      "center": "102.17567,27.402839",
      "parent_id": "2464"
    },
    {
      "area_id": "2469",
      "area_code": "513425",
      "area_name": "会理县",
      "level": "3",
      "city_code": "0834",
      "center": "102.244683,26.655026",
      "parent_id": "2464"
    },
    {
      "area_id": "2470",
      "area_code": "513426",
      "area_name": "会东县",
      "level": "3",
      "city_code": "0834",
      "center": "102.57796,26.634669",
      "parent_id": "2464"
    },
    {
      "area_id": "2471",
      "area_code": "513427",
      "area_name": "宁南县",
      "level": "3",
      "city_code": "0834",
      "center": "102.751745,27.061189",
      "parent_id": "2464"
    },
    {
      "area_id": "2472",
      "area_code": "513428",
      "area_name": "普格县",
      "level": "3",
      "city_code": "0834",
      "center": "102.540901,27.376413",
      "parent_id": "2464"
    },
    {
      "area_id": "2473",
      "area_code": "513429",
      "area_name": "布拖县",
      "level": "3",
      "city_code": "0834",
      "center": "102.812061,27.706061",
      "parent_id": "2464"
    },
    {
      "area_id": "2474",
      "area_code": "513430",
      "area_name": "金阳县",
      "level": "3",
      "city_code": "0834",
      "center": "103.248772,27.69686",
      "parent_id": "2464"
    },
    {
      "area_id": "2475",
      "area_code": "513431",
      "area_name": "昭觉县",
      "level": "3",
      "city_code": "0834",
      "center": "102.840264,28.015333",
      "parent_id": "2464"
    },
    {
      "area_id": "2476",
      "area_code": "513432",
      "area_name": "喜德县",
      "level": "3",
      "city_code": "0834",
      "center": "102.412518,28.306726",
      "parent_id": "2464"
    },
    {
      "area_id": "2477",
      "area_code": "513433",
      "area_name": "冕宁县",
      "level": "3",
      "city_code": "0834",
      "center": "102.17701,28.549656",
      "parent_id": "2464"
    },
    {
      "area_id": "2478",
      "area_code": "513434",
      "area_name": "越西县",
      "level": "3",
      "city_code": "0834",
      "center": "102.50768,28.639801",
      "parent_id": "2464"
    },
    {
      "area_id": "2479",
      "area_code": "513435",
      "area_name": "甘洛县",
      "level": "3",
      "city_code": "0834",
      "center": "102.771504,28.959157",
      "parent_id": "2464"
    },
    {
      "area_id": "2480",
      "area_code": "513436",
      "area_name": "美姑县",
      "level": "3",
      "city_code": "0834",
      "center": "103.132179,28.32864",
      "parent_id": "2464"
    },
    {
      "area_id": "2481",
      "area_code": "513437",
      "area_name": "雷波县",
      "level": "3",
      "city_code": "0834",
      "center": "103.571696,28.262682",
      "parent_id": "2464"
    },
    {
      "area_id": "2482",
      "area_code": "520000",
      "area_name": "贵州省",
      "level": "1",
      "city_code": "[]",
      "center": "106.70546,26.600055",
      "parent_id": "0"
    },
    {
      "area_id": "2483",
      "area_code": "520100",
      "area_name": "贵阳市",
      "level": "2",
      "city_code": "0851",
      "center": "106.630153,26.647661",
      "parent_id": "2482"
    },
    {
      "area_id": "2484",
      "area_code": "520102",
      "area_name": "南明区",
      "level": "3",
      "city_code": "0851",
      "center": "106.714374,26.567944",
      "parent_id": "2483"
    },
    {
      "area_id": "2485",
      "area_code": "520103",
      "area_name": "云岩区",
      "level": "3",
      "city_code": "0851",
      "center": "106.724494,26.604688",
      "parent_id": "2483"
    },
    {
      "area_id": "2486",
      "area_code": "520111",
      "area_name": "花溪区",
      "level": "3",
      "city_code": "0851",
      "center": "106.67026,26.409817",
      "parent_id": "2483"
    },
    {
      "area_id": "2487",
      "area_code": "520112",
      "area_name": "乌当区",
      "level": "3",
      "city_code": "0851",
      "center": "106.750625,26.630845",
      "parent_id": "2483"
    },
    {
      "area_id": "2488",
      "area_code": "520113",
      "area_name": "白云区",
      "level": "3",
      "city_code": "0851",
      "center": "106.623007,26.678561",
      "parent_id": "2483"
    },
    {
      "area_id": "2489",
      "area_code": "520115",
      "area_name": "观山湖区",
      "level": "3",
      "city_code": "0851",
      "center": "106.622453,26.60145",
      "parent_id": "2483"
    },
    {
      "area_id": "2490",
      "area_code": "520121",
      "area_name": "开阳县",
      "level": "3",
      "city_code": "0851",
      "center": "106.965089,27.057764",
      "parent_id": "2483"
    },
    {
      "area_id": "2491",
      "area_code": "520122",
      "area_name": "息烽县",
      "level": "3",
      "city_code": "0851",
      "center": "106.740407,27.090479",
      "parent_id": "2483"
    },
    {
      "area_id": "2492",
      "area_code": "520123",
      "area_name": "修文县",
      "level": "3",
      "city_code": "0851",
      "center": "106.592108,26.838926",
      "parent_id": "2483"
    },
    {
      "area_id": "2493",
      "area_code": "520181",
      "area_name": "清镇市",
      "level": "3",
      "city_code": "0851",
      "center": "106.470714,26.556079",
      "parent_id": "2483"
    },
    {
      "area_id": "2494",
      "area_code": "520200",
      "area_name": "六盘水市",
      "level": "2",
      "city_code": "0858",
      "center": "104.830458,26.592707",
      "parent_id": "2482"
    },
    {
      "area_id": "2495",
      "area_code": "520201",
      "area_name": "钟山区",
      "level": "3",
      "city_code": "0858",
      "center": "104.843555,26.574979",
      "parent_id": "2494"
    },
    {
      "area_id": "2496",
      "area_code": "520203",
      "area_name": "六枝特区",
      "level": "3",
      "city_code": "0858",
      "center": "105.476608,26.213108",
      "parent_id": "2494"
    },
    {
      "area_id": "2497",
      "area_code": "520221",
      "area_name": "水城县",
      "level": "3",
      "city_code": "0858",
      "center": "104.95783,26.547904",
      "parent_id": "2494"
    },
    {
      "area_id": "2498",
      "area_code": "520222",
      "area_name": "盘州市",
      "level": "3",
      "city_code": "0858",
      "center": "104.471375,25.709852",
      "parent_id": "2494"
    },
    {
      "area_id": "2499",
      "area_code": "520300",
      "area_name": "遵义市",
      "level": "2",
      "city_code": "0852",
      "center": "106.927389,27.725654",
      "parent_id": "2482"
    },
    {
      "area_id": "2500",
      "area_code": "520302",
      "area_name": "红花岗区",
      "level": "3",
      "city_code": "0852",
      "center": "106.8937,27.644754",
      "parent_id": "2499"
    },
    {
      "area_id": "2501",
      "area_code": "520303",
      "area_name": "汇川区",
      "level": "3",
      "city_code": "0852",
      "center": "106.93427,27.750125",
      "parent_id": "2499"
    },
    {
      "area_id": "2502",
      "area_code": "520304",
      "area_name": "播州区",
      "level": "3",
      "city_code": "0852",
      "center": "106.829574,27.536298",
      "parent_id": "2499"
    },
    {
      "area_id": "2503",
      "area_code": "520322",
      "area_name": "桐梓县",
      "level": "3",
      "city_code": "0852",
      "center": "106.825198,28.133311",
      "parent_id": "2499"
    },
    {
      "area_id": "2504",
      "area_code": "520323",
      "area_name": "绥阳县",
      "level": "3",
      "city_code": "0852",
      "center": "107.191222,27.946222",
      "parent_id": "2499"
    },
    {
      "area_id": "2505",
      "area_code": "520324",
      "area_name": "正安县",
      "level": "3",
      "city_code": "0852",
      "center": "107.453945,28.553285",
      "parent_id": "2499"
    },
    {
      "area_id": "2506",
      "area_code": "520325",
      "area_name": "道真仡佬族苗族自治县",
      "level": "3",
      "city_code": "0852",
      "center": "107.613133,28.862425",
      "parent_id": "2499"
    },
    {
      "area_id": "2507",
      "area_code": "520326",
      "area_name": "务川仡佬族苗族自治县",
      "level": "3",
      "city_code": "0852",
      "center": "107.898956,28.563086",
      "parent_id": "2499"
    },
    {
      "area_id": "2508",
      "area_code": "520327",
      "area_name": "凤冈县",
      "level": "3",
      "city_code": "0852",
      "center": "107.716355,27.954695",
      "parent_id": "2499"
    },
    {
      "area_id": "2509",
      "area_code": "520328",
      "area_name": "湄潭县",
      "level": "3",
      "city_code": "0852",
      "center": "107.465407,27.749055",
      "parent_id": "2499"
    },
    {
      "area_id": "2510",
      "area_code": "520329",
      "area_name": "余庆县",
      "level": "3",
      "city_code": "0852",
      "center": "107.905197,27.215491",
      "parent_id": "2499"
    },
    {
      "area_id": "2511",
      "area_code": "520330",
      "area_name": "习水县",
      "level": "3",
      "city_code": "0852",
      "center": "106.197137,28.33127",
      "parent_id": "2499"
    },
    {
      "area_id": "2512",
      "area_code": "520381",
      "area_name": "赤水市",
      "level": "3",
      "city_code": "0852",
      "center": "105.697472,28.590337",
      "parent_id": "2499"
    },
    {
      "area_id": "2513",
      "area_code": "520382",
      "area_name": "仁怀市",
      "level": "3",
      "city_code": "0852",
      "center": "106.40109,27.792514",
      "parent_id": "2499"
    },
    {
      "area_id": "2514",
      "area_code": "520400",
      "area_name": "安顺市",
      "level": "2",
      "city_code": "0853",
      "center": "105.947594,26.253088",
      "parent_id": "2482"
    },
    {
      "area_id": "2515",
      "area_code": "520402",
      "area_name": "西秀区",
      "level": "3",
      "city_code": "0853",
      "center": "105.965116,26.245315",
      "parent_id": "2514"
    },
    {
      "area_id": "2516",
      "area_code": "520403",
      "area_name": "平坝区",
      "level": "3",
      "city_code": "0853",
      "center": "106.256412,26.405715",
      "parent_id": "2514"
    },
    {
      "area_id": "2517",
      "area_code": "520422",
      "area_name": "普定县",
      "level": "3",
      "city_code": "0853",
      "center": "105.743277,26.301565",
      "parent_id": "2514"
    },
    {
      "area_id": "2518",
      "area_code": "520423",
      "area_name": "镇宁布依族苗族自治县",
      "level": "3",
      "city_code": "0853",
      "center": "105.770283,26.058086",
      "parent_id": "2514"
    },
    {
      "area_id": "2519",
      "area_code": "520424",
      "area_name": "关岭布依族苗族自治县",
      "level": "3",
      "city_code": "0853",
      "center": "105.61933,25.94361",
      "parent_id": "2514"
    },
    {
      "area_id": "2520",
      "area_code": "520425",
      "area_name": "紫云苗族布依族自治县",
      "level": "3",
      "city_code": "0853",
      "center": "106.084441,25.751047",
      "parent_id": "2514"
    },
    {
      "area_id": "2521",
      "area_code": "520500",
      "area_name": "毕节市",
      "level": "2",
      "city_code": "0857",
      "center": "105.291702,27.283908",
      "parent_id": "2482"
    },
    {
      "area_id": "2522",
      "area_code": "520502",
      "area_name": "七星关区",
      "level": "3",
      "city_code": "0857",
      "center": "105.30474,27.298458",
      "parent_id": "2521"
    },
    {
      "area_id": "2523",
      "area_code": "520521",
      "area_name": "大方县",
      "level": "3",
      "city_code": "0857",
      "center": "105.613037,27.141735",
      "parent_id": "2521"
    },
    {
      "area_id": "2524",
      "area_code": "520522",
      "area_name": "黔西县",
      "level": "3",
      "city_code": "0857",
      "center": "106.033544,27.007713",
      "parent_id": "2521"
    },
    {
      "area_id": "2525",
      "area_code": "520523",
      "area_name": "金沙县",
      "level": "3",
      "city_code": "0857",
      "center": "106.220227,27.459214",
      "parent_id": "2521"
    },
    {
      "area_id": "2526",
      "area_code": "520524",
      "area_name": "织金县",
      "level": "3",
      "city_code": "0857",
      "center": "105.770542,26.663449",
      "parent_id": "2521"
    },
    {
      "area_id": "2527",
      "area_code": "520525",
      "area_name": "纳雍县",
      "level": "3",
      "city_code": "0857",
      "center": "105.382714,26.777645",
      "parent_id": "2521"
    },
    {
      "area_id": "2528",
      "area_code": "520526",
      "area_name": "威宁彝族回族苗族自治县",
      "level": "3",
      "city_code": "0857",
      "center": "104.253071,26.873806",
      "parent_id": "2521"
    },
    {
      "area_id": "2529",
      "area_code": "520527",
      "area_name": "赫章县",
      "level": "3",
      "city_code": "0857",
      "center": "104.727418,27.123078",
      "parent_id": "2521"
    },
    {
      "area_id": "2530",
      "area_code": "520600",
      "area_name": "铜仁市",
      "level": "2",
      "city_code": "0856",
      "center": "109.189598,27.731514",
      "parent_id": "2482"
    },
    {
      "area_id": "2531",
      "area_code": "520602",
      "area_name": "碧江区",
      "level": "3",
      "city_code": "0856",
      "center": "109.263998,27.815927",
      "parent_id": "2530"
    },
    {
      "area_id": "2532",
      "area_code": "520603",
      "area_name": "万山区",
      "level": "3",
      "city_code": "0856",
      "center": "109.213644,27.517896",
      "parent_id": "2530"
    },
    {
      "area_id": "2533",
      "area_code": "520621",
      "area_name": "江口县",
      "level": "3",
      "city_code": "0856",
      "center": "108.839557,27.69965",
      "parent_id": "2530"
    },
    {
      "area_id": "2534",
      "area_code": "520622",
      "area_name": "玉屏侗族自治县",
      "level": "3",
      "city_code": "0856",
      "center": "108.906411,27.235813",
      "parent_id": "2530"
    },
    {
      "area_id": "2535",
      "area_code": "520623",
      "area_name": "石阡县",
      "level": "3",
      "city_code": "0856",
      "center": "108.223612,27.513829",
      "parent_id": "2530"
    },
    {
      "area_id": "2536",
      "area_code": "520624",
      "area_name": "思南县",
      "level": "3",
      "city_code": "0856",
      "center": "108.253882,27.93755",
      "parent_id": "2530"
    },
    {
      "area_id": "2537",
      "area_code": "520625",
      "area_name": "印江土家族苗族自治县",
      "level": "3",
      "city_code": "0856",
      "center": "108.409751,27.994246",
      "parent_id": "2530"
    },
    {
      "area_id": "2538",
      "area_code": "520626",
      "area_name": "德江县",
      "level": "3",
      "city_code": "0856",
      "center": "108.119807,28.263963",
      "parent_id": "2530"
    },
    {
      "area_id": "2539",
      "area_code": "520627",
      "area_name": "沿河土家族自治县",
      "level": "3",
      "city_code": "0856",
      "center": "108.50387,28.563927",
      "parent_id": "2530"
    },
    {
      "area_id": "2540",
      "area_code": "520628",
      "area_name": "松桃苗族自治县",
      "level": "3",
      "city_code": "0856",
      "center": "109.202886,28.154071",
      "parent_id": "2530"
    },
    {
      "area_id": "2541",
      "area_code": "522300",
      "area_name": "黔西南布依族苗族自治州",
      "level": "2",
      "city_code": "0859",
      "center": "104.906397,25.087856",
      "parent_id": "2482"
    },
    {
      "area_id": "2542",
      "area_code": "522301",
      "area_name": "兴义市",
      "level": "3",
      "city_code": "0859",
      "center": "104.895467,25.09204",
      "parent_id": "2541"
    },
    {
      "area_id": "2543",
      "area_code": "522322",
      "area_name": "兴仁县",
      "level": "3",
      "city_code": "0859",
      "center": "105.186237,25.435183",
      "parent_id": "2541"
    },
    {
      "area_id": "2544",
      "area_code": "522323",
      "area_name": "普安县",
      "level": "3",
      "city_code": "0859",
      "center": "104.953062,25.784135",
      "parent_id": "2541"
    },
    {
      "area_id": "2545",
      "area_code": "522324",
      "area_name": "晴隆县",
      "level": "3",
      "city_code": "0859",
      "center": "105.218991,25.834783",
      "parent_id": "2541"
    },
    {
      "area_id": "2546",
      "area_code": "522325",
      "area_name": "贞丰县",
      "level": "3",
      "city_code": "0859",
      "center": "105.649864,25.38576",
      "parent_id": "2541"
    },
    {
      "area_id": "2547",
      "area_code": "522326",
      "area_name": "望谟县",
      "level": "3",
      "city_code": "0859",
      "center": "106.099617,25.178421",
      "parent_id": "2541"
    },
    {
      "area_id": "2548",
      "area_code": "522327",
      "area_name": "册亨县",
      "level": "3",
      "city_code": "0859",
      "center": "105.811592,24.983663",
      "parent_id": "2541"
    },
    {
      "area_id": "2549",
      "area_code": "522328",
      "area_name": "安龙县",
      "level": "3",
      "city_code": "0859",
      "center": "105.442701,25.099014",
      "parent_id": "2541"
    },
    {
      "area_id": "2550",
      "area_code": "522600",
      "area_name": "黔东南苗族侗族自治州",
      "level": "2",
      "city_code": "0855",
      "center": "107.982874,26.583457",
      "parent_id": "2482"
    },
    {
      "area_id": "2551",
      "area_code": "522601",
      "area_name": "凯里市",
      "level": "3",
      "city_code": "0855",
      "center": "107.97754,26.582963",
      "parent_id": "2550"
    },
    {
      "area_id": "2552",
      "area_code": "522622",
      "area_name": "黄平县",
      "level": "3",
      "city_code": "0855",
      "center": "107.916411,26.905396",
      "parent_id": "2550"
    },
    {
      "area_id": "2553",
      "area_code": "522623",
      "area_name": "施秉县",
      "level": "3",
      "city_code": "0855",
      "center": "108.124379,27.03292",
      "parent_id": "2550"
    },
    {
      "area_id": "2554",
      "area_code": "522624",
      "area_name": "三穗县",
      "level": "3",
      "city_code": "0855",
      "center": "108.675267,26.952967",
      "parent_id": "2550"
    },
    {
      "area_id": "2555",
      "area_code": "522625",
      "area_name": "镇远县",
      "level": "3",
      "city_code": "0855",
      "center": "108.429534,27.049497",
      "parent_id": "2550"
    },
    {
      "area_id": "2556",
      "area_code": "522626",
      "area_name": "岑巩县",
      "level": "3",
      "city_code": "0855",
      "center": "108.81606,27.173887",
      "parent_id": "2550"
    },
    {
      "area_id": "2557",
      "area_code": "522627",
      "area_name": "天柱县",
      "level": "3",
      "city_code": "0855",
      "center": "109.207751,26.909639",
      "parent_id": "2550"
    },
    {
      "area_id": "2558",
      "area_code": "522628",
      "area_name": "锦屏县",
      "level": "3",
      "city_code": "0855",
      "center": "109.200534,26.676233",
      "parent_id": "2550"
    },
    {
      "area_id": "2559",
      "area_code": "522629",
      "area_name": "剑河县",
      "level": "3",
      "city_code": "0855",
      "center": "108.441501,26.728274",
      "parent_id": "2550"
    },
    {
      "area_id": "2560",
      "area_code": "522630",
      "area_name": "台江县",
      "level": "3",
      "city_code": "0855",
      "center": "108.321245,26.667525",
      "parent_id": "2550"
    },
    {
      "area_id": "2561",
      "area_code": "522631",
      "area_name": "黎平县",
      "level": "3",
      "city_code": "0855",
      "center": "109.136932,26.230706",
      "parent_id": "2550"
    },
    {
      "area_id": "2562",
      "area_code": "522632",
      "area_name": "榕江县",
      "level": "3",
      "city_code": "0855",
      "center": "108.52188,25.931893",
      "parent_id": "2550"
    },
    {
      "area_id": "2563",
      "area_code": "522633",
      "area_name": "从江县",
      "level": "3",
      "city_code": "0855",
      "center": "108.905329,25.753009",
      "parent_id": "2550"
    },
    {
      "area_id": "2564",
      "area_code": "522634",
      "area_name": "雷山县",
      "level": "3",
      "city_code": "0855",
      "center": "108.07754,26.378442",
      "parent_id": "2550"
    },
    {
      "area_id": "2565",
      "area_code": "522635",
      "area_name": "麻江县",
      "level": "3",
      "city_code": "0855",
      "center": "107.589359,26.491105",
      "parent_id": "2550"
    },
    {
      "area_id": "2566",
      "area_code": "522636",
      "area_name": "丹寨县",
      "level": "3",
      "city_code": "0855",
      "center": "107.788727,26.19832",
      "parent_id": "2550"
    },
    {
      "area_id": "2567",
      "area_code": "522700",
      "area_name": "黔南布依族苗族自治州",
      "level": "2",
      "city_code": "0854",
      "center": "107.522171,26.253275",
      "parent_id": "2482"
    },
    {
      "area_id": "2568",
      "area_code": "522701",
      "area_name": "都匀市",
      "level": "3",
      "city_code": "0854",
      "center": "107.518847,26.259427",
      "parent_id": "2567"
    },
    {
      "area_id": "2569",
      "area_code": "522702",
      "area_name": "福泉市",
      "level": "3",
      "city_code": "0854",
      "center": "107.520386,26.686335",
      "parent_id": "2567"
    },
    {
      "area_id": "2570",
      "area_code": "522722",
      "area_name": "荔波县",
      "level": "3",
      "city_code": "0854",
      "center": "107.898882,25.423895",
      "parent_id": "2567"
    },
    {
      "area_id": "2571",
      "area_code": "522723",
      "area_name": "贵定县",
      "level": "3",
      "city_code": "0854",
      "center": "107.232793,26.557089",
      "parent_id": "2567"
    },
    {
      "area_id": "2572",
      "area_code": "522725",
      "area_name": "瓮安县",
      "level": "3",
      "city_code": "0854",
      "center": "107.470942,27.078441",
      "parent_id": "2567"
    },
    {
      "area_id": "2573",
      "area_code": "522726",
      "area_name": "独山县",
      "level": "3",
      "city_code": "0854",
      "center": "107.545048,25.822132",
      "parent_id": "2567"
    },
    {
      "area_id": "2574",
      "area_code": "522727",
      "area_name": "平塘县",
      "level": "3",
      "city_code": "0854",
      "center": "107.322323,25.822349",
      "parent_id": "2567"
    },
    {
      "area_id": "2575",
      "area_code": "522728",
      "area_name": "罗甸县",
      "level": "3",
      "city_code": "0854",
      "center": "106.751589,25.426173",
      "parent_id": "2567"
    },
    {
      "area_id": "2576",
      "area_code": "522729",
      "area_name": "长顺县",
      "level": "3",
      "city_code": "0854",
      "center": "106.441805,26.025626",
      "parent_id": "2567"
    },
    {
      "area_id": "2577",
      "area_code": "522730",
      "area_name": "龙里县",
      "level": "3",
      "city_code": "0854",
      "center": "106.979524,26.453154",
      "parent_id": "2567"
    },
    {
      "area_id": "2578",
      "area_code": "522731",
      "area_name": "惠水县",
      "level": "3",
      "city_code": "0854",
      "center": "106.656442,26.13278",
      "parent_id": "2567"
    },
    {
      "area_id": "2579",
      "area_code": "522732",
      "area_name": "三都水族自治县",
      "level": "3",
      "city_code": "0854",
      "center": "107.869749,25.983202",
      "parent_id": "2567"
    },
    {
      "area_id": "2580",
      "area_code": "530000",
      "area_name": "云南省",
      "level": "1",
      "city_code": "[]",
      "center": "102.710002,25.045806",
      "parent_id": "0"
    },
    {
      "area_id": "2581",
      "area_code": "530100",
      "area_name": "昆明市",
      "level": "2",
      "city_code": "0871",
      "center": "102.832891,24.880095",
      "parent_id": "2580"
    },
    {
      "area_id": "2582",
      "area_code": "530102",
      "area_name": "五华区",
      "level": "3",
      "city_code": "0871",
      "center": "102.707262,25.043635",
      "parent_id": "2581"
    },
    {
      "area_id": "2583",
      "area_code": "530103",
      "area_name": "盘龙区",
      "level": "3",
      "city_code": "0871",
      "center": "102.751941,25.116465",
      "parent_id": "2581"
    },
    {
      "area_id": "2584",
      "area_code": "530111",
      "area_name": "官渡区",
      "level": "3",
      "city_code": "0871",
      "center": "102.749026,24.950231",
      "parent_id": "2581"
    },
    {
      "area_id": "2585",
      "area_code": "530112",
      "area_name": "西山区",
      "level": "3",
      "city_code": "0871",
      "center": "102.664382,25.038604",
      "parent_id": "2581"
    },
    {
      "area_id": "2586",
      "area_code": "530113",
      "area_name": "东川区",
      "level": "3",
      "city_code": "0871",
      "center": "103.187824,26.082873",
      "parent_id": "2581"
    },
    {
      "area_id": "2587",
      "area_code": "530114",
      "area_name": "呈贡区",
      "level": "3",
      "city_code": "0871",
      "center": "102.821675,24.885587",
      "parent_id": "2581"
    },
    {
      "area_id": "2588",
      "area_code": "530115",
      "area_name": "晋宁区",
      "level": "3",
      "city_code": "0871",
      "center": "102.595412,24.66974",
      "parent_id": "2581"
    },
    {
      "area_id": "2589",
      "area_code": "530124",
      "area_name": "富民县",
      "level": "3",
      "city_code": "0871",
      "center": "102.4976,25.221935",
      "parent_id": "2581"
    },
    {
      "area_id": "2590",
      "area_code": "530125",
      "area_name": "宜良县",
      "level": "3",
      "city_code": "0871",
      "center": "103.141603,24.919839",
      "parent_id": "2581"
    },
    {
      "area_id": "2591",
      "area_code": "530126",
      "area_name": "石林彝族自治县",
      "level": "3",
      "city_code": "0871",
      "center": "103.290536,24.771761",
      "parent_id": "2581"
    },
    {
      "area_id": "2592",
      "area_code": "530127",
      "area_name": "嵩明县",
      "level": "3",
      "city_code": "0871",
      "center": "103.036908,25.338643",
      "parent_id": "2581"
    },
    {
      "area_id": "2593",
      "area_code": "530128",
      "area_name": "禄劝彝族苗族自治县",
      "level": "3",
      "city_code": "0871",
      "center": "102.471518,25.551332",
      "parent_id": "2581"
    },
    {
      "area_id": "2594",
      "area_code": "530129",
      "area_name": "寻甸回族彝族自治县",
      "level": "3",
      "city_code": "0871",
      "center": "103.256615,25.558201",
      "parent_id": "2581"
    },
    {
      "area_id": "2595",
      "area_code": "530181",
      "area_name": "安宁市",
      "level": "3",
      "city_code": "0871",
      "center": "102.478494,24.919493",
      "parent_id": "2581"
    },
    {
      "area_id": "2596",
      "area_code": "530300",
      "area_name": "曲靖市",
      "level": "2",
      "city_code": "0874",
      "center": "103.796167,25.489999",
      "parent_id": "2580"
    },
    {
      "area_id": "2597",
      "area_code": "530302",
      "area_name": "麒麟区",
      "level": "3",
      "city_code": "0874",
      "center": "103.80474,25.495326",
      "parent_id": "2596"
    },
    {
      "area_id": "2598",
      "area_code": "530303",
      "area_name": "沾益区",
      "level": "3",
      "city_code": "0874",
      "center": "103.822324,25.600507",
      "parent_id": "2596"
    },
    {
      "area_id": "2599",
      "area_code": "530321",
      "area_name": "马龙县",
      "level": "3",
      "city_code": "0874",
      "center": "103.578478,25.42805",
      "parent_id": "2596"
    },
    {
      "area_id": "2600",
      "area_code": "530322",
      "area_name": "陆良县",
      "level": "3",
      "city_code": "0874",
      "center": "103.666663,25.030051",
      "parent_id": "2596"
    },
    {
      "area_id": "2601",
      "area_code": "530323",
      "area_name": "师宗县",
      "level": "3",
      "city_code": "0874",
      "center": "103.985321,24.822233",
      "parent_id": "2596"
    },
    {
      "area_id": "2602",
      "area_code": "530324",
      "area_name": "罗平县",
      "level": "3",
      "city_code": "0874",
      "center": "104.308675,24.884626",
      "parent_id": "2596"
    },
    {
      "area_id": "2603",
      "area_code": "530325",
      "area_name": "富源县",
      "level": "3",
      "city_code": "0874",
      "center": "104.255014,25.674238",
      "parent_id": "2596"
    },
    {
      "area_id": "2604",
      "area_code": "530326",
      "area_name": "会泽县",
      "level": "3",
      "city_code": "0874",
      "center": "103.297386,26.417345",
      "parent_id": "2596"
    },
    {
      "area_id": "2605",
      "area_code": "530381",
      "area_name": "宣威市",
      "level": "3",
      "city_code": "0874",
      "center": "104.10455,26.219735",
      "parent_id": "2596"
    },
    {
      "area_id": "2606",
      "area_code": "530400",
      "area_name": "玉溪市",
      "level": "2",
      "city_code": "0877",
      "center": "102.527197,24.347324",
      "parent_id": "2580"
    },
    {
      "area_id": "2607",
      "area_code": "530402",
      "area_name": "红塔区",
      "level": "3",
      "city_code": "0877",
      "center": "102.540122,24.341215",
      "parent_id": "2606"
    },
    {
      "area_id": "2608",
      "area_code": "530403",
      "area_name": "江川区",
      "level": "3",
      "city_code": "0877",
      "center": "102.75344,24.287485",
      "parent_id": "2606"
    },
    {
      "area_id": "2609",
      "area_code": "530422",
      "area_name": "澄江县",
      "level": "3",
      "city_code": "0877",
      "center": "102.904629,24.675689",
      "parent_id": "2606"
    },
    {
      "area_id": "2610",
      "area_code": "530423",
      "area_name": "通海县",
      "level": "3",
      "city_code": "0877",
      "center": "102.725452,24.111048",
      "parent_id": "2606"
    },
    {
      "area_id": "2611",
      "area_code": "530424",
      "area_name": "华宁县",
      "level": "3",
      "city_code": "0877",
      "center": "102.928835,24.19276",
      "parent_id": "2606"
    },
    {
      "area_id": "2612",
      "area_code": "530425",
      "area_name": "易门县",
      "level": "3",
      "city_code": "0877",
      "center": "102.162531,24.671651",
      "parent_id": "2606"
    },
    {
      "area_id": "2613",
      "area_code": "530426",
      "area_name": "峨山彝族自治县",
      "level": "3",
      "city_code": "0877",
      "center": "102.405819,24.168957",
      "parent_id": "2606"
    },
    {
      "area_id": "2614",
      "area_code": "530427",
      "area_name": "新平彝族傣族自治县",
      "level": "3",
      "city_code": "0877",
      "center": "101.990157,24.07005",
      "parent_id": "2606"
    },
    {
      "area_id": "2615",
      "area_code": "530428",
      "area_name": "元江哈尼族彝族傣族自治县",
      "level": "3",
      "city_code": "0877",
      "center": "101.998103,23.596503",
      "parent_id": "2606"
    },
    {
      "area_id": "2616",
      "area_code": "530500",
      "area_name": "保山市",
      "level": "2",
      "city_code": "0875",
      "center": "99.161761,25.112046",
      "parent_id": "2580"
    },
    {
      "area_id": "2617",
      "area_code": "530502",
      "area_name": "隆阳区",
      "level": "3",
      "city_code": "0875",
      "center": "99.165607,25.121154",
      "parent_id": "2616"
    },
    {
      "area_id": "2618",
      "area_code": "530521",
      "area_name": "施甸县",
      "level": "3",
      "city_code": "0875",
      "center": "99.189221,24.723064",
      "parent_id": "2616"
    },
    {
      "area_id": "2619",
      "area_code": "530523",
      "area_name": "龙陵县",
      "level": "3",
      "city_code": "0875",
      "center": "98.689261,24.586794",
      "parent_id": "2616"
    },
    {
      "area_id": "2620",
      "area_code": "530524",
      "area_name": "昌宁县",
      "level": "3",
      "city_code": "0875",
      "center": "99.605142,24.827839",
      "parent_id": "2616"
    },
    {
      "area_id": "2621",
      "area_code": "530581",
      "area_name": "腾冲市",
      "level": "3",
      "city_code": "0875",
      "center": "98.490966,25.020439",
      "parent_id": "2616"
    },
    {
      "area_id": "2622",
      "area_code": "530600",
      "area_name": "昭通市",
      "level": "2",
      "city_code": "0870",
      "center": "103.717465,27.338257",
      "parent_id": "2580"
    },
    {
      "area_id": "2623",
      "area_code": "530602",
      "area_name": "昭阳区",
      "level": "3",
      "city_code": "0870",
      "center": "103.706539,27.320075",
      "parent_id": "2622"
    },
    {
      "area_id": "2624",
      "area_code": "530621",
      "area_name": "鲁甸县",
      "level": "3",
      "city_code": "0870",
      "center": "103.558042,27.186659",
      "parent_id": "2622"
    },
    {
      "area_id": "2625",
      "area_code": "530622",
      "area_name": "巧家县",
      "level": "3",
      "city_code": "0870",
      "center": "102.930164,26.90846",
      "parent_id": "2622"
    },
    {
      "area_id": "2626",
      "area_code": "530623",
      "area_name": "盐津县",
      "level": "3",
      "city_code": "0870",
      "center": "104.234441,28.10871",
      "parent_id": "2622"
    },
    {
      "area_id": "2627",
      "area_code": "530624",
      "area_name": "大关县",
      "level": "3",
      "city_code": "0870",
      "center": "103.891146,27.747978",
      "parent_id": "2622"
    },
    {
      "area_id": "2628",
      "area_code": "530625",
      "area_name": "永善县",
      "level": "3",
      "city_code": "0870",
      "center": "103.638067,28.229112",
      "parent_id": "2622"
    },
    {
      "area_id": "2629",
      "area_code": "530626",
      "area_name": "绥江县",
      "level": "3",
      "city_code": "0870",
      "center": "103.968978,28.592099",
      "parent_id": "2622"
    },
    {
      "area_id": "2630",
      "area_code": "530627",
      "area_name": "镇雄县",
      "level": "3",
      "city_code": "0870",
      "center": "104.87376,27.441622",
      "parent_id": "2622"
    },
    {
      "area_id": "2631",
      "area_code": "530628",
      "area_name": "彝良县",
      "level": "3",
      "city_code": "0870",
      "center": "104.048289,27.625418",
      "parent_id": "2622"
    },
    {
      "area_id": "2632",
      "area_code": "530629",
      "area_name": "威信县",
      "level": "3",
      "city_code": "0870",
      "center": "105.049027,27.8469",
      "parent_id": "2622"
    },
    {
      "area_id": "2633",
      "area_code": "530630",
      "area_name": "水富县",
      "level": "3",
      "city_code": "0870",
      "center": "104.41603,28.62988",
      "parent_id": "2622"
    },
    {
      "area_id": "2634",
      "area_code": "530700",
      "area_name": "丽江市",
      "level": "2",
      "city_code": "0888",
      "center": "100.22775,26.855047",
      "parent_id": "2580"
    },
    {
      "area_id": "2635",
      "area_code": "530702",
      "area_name": "古城区",
      "level": "3",
      "city_code": "0888",
      "center": "100.225784,26.876927",
      "parent_id": "2634"
    },
    {
      "area_id": "2636",
      "area_code": "530721",
      "area_name": "玉龙纳西族自治县",
      "level": "3",
      "city_code": "0888",
      "center": "100.236954,26.821459",
      "parent_id": "2634"
    },
    {
      "area_id": "2637",
      "area_code": "530722",
      "area_name": "永胜县",
      "level": "3",
      "city_code": "0888",
      "center": "100.750826,26.684225",
      "parent_id": "2634"
    },
    {
      "area_id": "2638",
      "area_code": "530723",
      "area_name": "华坪县",
      "level": "3",
      "city_code": "0888",
      "center": "101.266195,26.629211",
      "parent_id": "2634"
    },
    {
      "area_id": "2639",
      "area_code": "530724",
      "area_name": "宁蒗彝族自治县",
      "level": "3",
      "city_code": "0888",
      "center": "100.852001,27.28207",
      "parent_id": "2634"
    },
    {
      "area_id": "2640",
      "area_code": "530800",
      "area_name": "普洱市",
      "level": "2",
      "city_code": "0879",
      "center": "100.966156,22.825155",
      "parent_id": "2580"
    },
    {
      "area_id": "2641",
      "area_code": "530802",
      "area_name": "思茅区",
      "level": "3",
      "city_code": "0879",
      "center": "100.977256,22.787115",
      "parent_id": "2640"
    },
    {
      "area_id": "2642",
      "area_code": "530821",
      "area_name": "宁洱哈尼族彝族自治县",
      "level": "3",
      "city_code": "0879",
      "center": "101.045837,23.048401",
      "parent_id": "2640"
    },
    {
      "area_id": "2643",
      "area_code": "530822",
      "area_name": "墨江哈尼族自治县",
      "level": "3",
      "city_code": "0879",
      "center": "101.692461,23.431894",
      "parent_id": "2640"
    },
    {
      "area_id": "2644",
      "area_code": "530823",
      "area_name": "景东彝族自治县",
      "level": "3",
      "city_code": "0879",
      "center": "100.833877,24.446731",
      "parent_id": "2640"
    },
    {
      "area_id": "2645",
      "area_code": "530824",
      "area_name": "景谷傣族彝族自治县",
      "level": "3",
      "city_code": "0879",
      "center": "100.702871,23.497028",
      "parent_id": "2640"
    },
    {
      "area_id": "2646",
      "area_code": "530825",
      "area_name": "镇沅彝族哈尼族拉祜族自治县",
      "level": "3",
      "city_code": "0879",
      "center": "101.108595,24.004441",
      "parent_id": "2640"
    },
    {
      "area_id": "2647",
      "area_code": "530826",
      "area_name": "江城哈尼族彝族自治县",
      "level": "3",
      "city_code": "0879",
      "center": "101.86212,22.585867",
      "parent_id": "2640"
    },
    {
      "area_id": "2648",
      "area_code": "530827",
      "area_name": "孟连傣族拉祜族佤族自治县",
      "level": "3",
      "city_code": "0879",
      "center": "99.584157,22.329099",
      "parent_id": "2640"
    },
    {
      "area_id": "2649",
      "area_code": "530828",
      "area_name": "澜沧拉祜族自治县",
      "level": "3",
      "city_code": "0879",
      "center": "99.931975,22.555904",
      "parent_id": "2640"
    },
    {
      "area_id": "2650",
      "area_code": "530829",
      "area_name": "西盟佤族自治县",
      "level": "3",
      "city_code": "0879",
      "center": "99.590123,22.644508",
      "parent_id": "2640"
    },
    {
      "area_id": "2651",
      "area_code": "530900",
      "area_name": "临沧市",
      "level": "2",
      "city_code": "0883",
      "center": "100.08879,23.883955",
      "parent_id": "2580"
    },
    {
      "area_id": "2652",
      "area_code": "530902",
      "area_name": "临翔区",
      "level": "3",
      "city_code": "0883",
      "center": "100.082523,23.895137",
      "parent_id": "2651"
    },
    {
      "area_id": "2653",
      "area_code": "530921",
      "area_name": "凤庆县",
      "level": "3",
      "city_code": "0883",
      "center": "99.928459,24.580424",
      "parent_id": "2651"
    },
    {
      "area_id": "2654",
      "area_code": "530922",
      "area_name": "云县",
      "level": "3",
      "city_code": "0883",
      "center": "100.129354,24.44422",
      "parent_id": "2651"
    },
    {
      "area_id": "2655",
      "area_code": "530923",
      "area_name": "永德县",
      "level": "3",
      "city_code": "0883",
      "center": "99.259339,24.018357",
      "parent_id": "2651"
    },
    {
      "area_id": "2656",
      "area_code": "530924",
      "area_name": "镇康县",
      "level": "3",
      "city_code": "0883",
      "center": "98.825284,23.762584",
      "parent_id": "2651"
    },
    {
      "area_id": "2657",
      "area_code": "530925",
      "area_name": "双江拉祜族佤族布朗族傣族自治县",
      "level": "3",
      "city_code": "0883",
      "center": "99.827697,23.473499",
      "parent_id": "2651"
    },
    {
      "area_id": "2658",
      "area_code": "530926",
      "area_name": "耿马傣族佤族自治县",
      "level": "3",
      "city_code": "0883",
      "center": "99.397126,23.538092",
      "parent_id": "2651"
    },
    {
      "area_id": "2659",
      "area_code": "530927",
      "area_name": "沧源佤族自治县",
      "level": "3",
      "city_code": "0883",
      "center": "99.246196,23.146712",
      "parent_id": "2651"
    },
    {
      "area_id": "2660",
      "area_code": "532300",
      "area_name": "楚雄彝族自治州",
      "level": "2",
      "city_code": "0878",
      "center": "101.527992,25.045513",
      "parent_id": "2580"
    },
    {
      "area_id": "2661",
      "area_code": "532301",
      "area_name": "楚雄市",
      "level": "3",
      "city_code": "0878",
      "center": "101.545906,25.032889",
      "parent_id": "2660"
    },
    {
      "area_id": "2662",
      "area_code": "532322",
      "area_name": "双柏县",
      "level": "3",
      "city_code": "0878",
      "center": "101.641937,24.688875",
      "parent_id": "2660"
    },
    {
      "area_id": "2663",
      "area_code": "532323",
      "area_name": "牟定县",
      "level": "3",
      "city_code": "0878",
      "center": "101.546566,25.313121",
      "parent_id": "2660"
    },
    {
      "area_id": "2664",
      "area_code": "532324",
      "area_name": "南华县",
      "level": "3",
      "city_code": "0878",
      "center": "101.273577,25.192293",
      "parent_id": "2660"
    },
    {
      "area_id": "2665",
      "area_code": "532325",
      "area_name": "姚安县",
      "level": "3",
      "city_code": "0878",
      "center": "101.241728,25.504173",
      "parent_id": "2660"
    },
    {
      "area_id": "2666",
      "area_code": "532326",
      "area_name": "大姚县",
      "level": "3",
      "city_code": "0878",
      "center": "101.336617,25.729513",
      "parent_id": "2660"
    },
    {
      "area_id": "2667",
      "area_code": "532327",
      "area_name": "永仁县",
      "level": "3",
      "city_code": "0878",
      "center": "101.666132,26.049464",
      "parent_id": "2660"
    },
    {
      "area_id": "2668",
      "area_code": "532328",
      "area_name": "元谋县",
      "level": "3",
      "city_code": "0878",
      "center": "101.87452,25.704338",
      "parent_id": "2660"
    },
    {
      "area_id": "2669",
      "area_code": "532329",
      "area_name": "武定县",
      "level": "3",
      "city_code": "0878",
      "center": "102.404337,25.530389",
      "parent_id": "2660"
    },
    {
      "area_id": "2670",
      "area_code": "532331",
      "area_name": "禄丰县",
      "level": "3",
      "city_code": "0878",
      "center": "102.079027,25.150111",
      "parent_id": "2660"
    },
    {
      "area_id": "2671",
      "area_code": "532500",
      "area_name": "红河哈尼族彝族自治州",
      "level": "2",
      "city_code": "0873",
      "center": "103.374893,23.363245",
      "parent_id": "2580"
    },
    {
      "area_id": "2672",
      "area_code": "532501",
      "area_name": "个旧市",
      "level": "3",
      "city_code": "0873",
      "center": "103.160034,23.359121",
      "parent_id": "2671"
    },
    {
      "area_id": "2673",
      "area_code": "532502",
      "area_name": "开远市",
      "level": "3",
      "city_code": "0873",
      "center": "103.266624,23.714523",
      "parent_id": "2671"
    },
    {
      "area_id": "2674",
      "area_code": "532503",
      "area_name": "蒙自市",
      "level": "3",
      "city_code": "0873",
      "center": "103.364905,23.396201",
      "parent_id": "2671"
    },
    {
      "area_id": "2675",
      "area_code": "532504",
      "area_name": "弥勒市",
      "level": "3",
      "city_code": "0873",
      "center": "103.414874,24.411912",
      "parent_id": "2671"
    },
    {
      "area_id": "2676",
      "area_code": "532523",
      "area_name": "屏边苗族自治县",
      "level": "3",
      "city_code": "0873",
      "center": "103.687612,22.983559",
      "parent_id": "2671"
    },
    {
      "area_id": "2677",
      "area_code": "532524",
      "area_name": "建水县",
      "level": "3",
      "city_code": "0873",
      "center": "102.826557,23.6347",
      "parent_id": "2671"
    },
    {
      "area_id": "2678",
      "area_code": "532525",
      "area_name": "石屏县",
      "level": "3",
      "city_code": "0873",
      "center": "102.494983,23.705936",
      "parent_id": "2671"
    },
    {
      "area_id": "2679",
      "area_code": "532527",
      "area_name": "泸西县",
      "level": "3",
      "city_code": "0873",
      "center": "103.766196,24.532025",
      "parent_id": "2671"
    },
    {
      "area_id": "2680",
      "area_code": "532528",
      "area_name": "元阳县",
      "level": "3",
      "city_code": "0873",
      "center": "102.835223,23.219932",
      "parent_id": "2671"
    },
    {
      "area_id": "2681",
      "area_code": "532529",
      "area_name": "红河县",
      "level": "3",
      "city_code": "0873",
      "center": "102.4206,23.369161",
      "parent_id": "2671"
    },
    {
      "area_id": "2682",
      "area_code": "532530",
      "area_name": "金平苗族瑶族傣族自治县",
      "level": "3",
      "city_code": "0873",
      "center": "103.226448,22.779543",
      "parent_id": "2671"
    },
    {
      "area_id": "2683",
      "area_code": "532531",
      "area_name": "绿春县",
      "level": "3",
      "city_code": "0873",
      "center": "102.392463,22.993717",
      "parent_id": "2671"
    },
    {
      "area_id": "2684",
      "area_code": "532532",
      "area_name": "河口瑶族自治县",
      "level": "3",
      "city_code": "0873",
      "center": "103.93952,22.529645",
      "parent_id": "2671"
    },
    {
      "area_id": "2685",
      "area_code": "532600",
      "area_name": "文山壮族苗族自治州",
      "level": "2",
      "city_code": "0876",
      "center": "104.216248,23.400733",
      "parent_id": "2580"
    },
    {
      "area_id": "2686",
      "area_code": "532601",
      "area_name": "文山市",
      "level": "3",
      "city_code": "0876",
      "center": "104.232665,23.386527",
      "parent_id": "2685"
    },
    {
      "area_id": "2687",
      "area_code": "532622",
      "area_name": "砚山县",
      "level": "3",
      "city_code": "0876",
      "center": "104.337211,23.605768",
      "parent_id": "2685"
    },
    {
      "area_id": "2688",
      "area_code": "532623",
      "area_name": "西畴县",
      "level": "3",
      "city_code": "0876",
      "center": "104.672597,23.437782",
      "parent_id": "2685"
    },
    {
      "area_id": "2689",
      "area_code": "532624",
      "area_name": "麻栗坡县",
      "level": "3",
      "city_code": "0876",
      "center": "104.702799,23.125714",
      "parent_id": "2685"
    },
    {
      "area_id": "2690",
      "area_code": "532625",
      "area_name": "马关县",
      "level": "3",
      "city_code": "0876",
      "center": "104.394157,23.012915",
      "parent_id": "2685"
    },
    {
      "area_id": "2691",
      "area_code": "532626",
      "area_name": "丘北县",
      "level": "3",
      "city_code": "0876",
      "center": "104.166587,24.051746",
      "parent_id": "2685"
    },
    {
      "area_id": "2692",
      "area_code": "532627",
      "area_name": "广南县",
      "level": "3",
      "city_code": "0876",
      "center": "105.055107,24.046386",
      "parent_id": "2685"
    },
    {
      "area_id": "2693",
      "area_code": "532628",
      "area_name": "富宁县",
      "level": "3",
      "city_code": "0876",
      "center": "105.630999,23.625283",
      "parent_id": "2685"
    },
    {
      "area_id": "2694",
      "area_code": "532800",
      "area_name": "西双版纳傣族自治州",
      "level": "2",
      "city_code": "0691",
      "center": "100.796984,22.009113",
      "parent_id": "2580"
    },
    {
      "area_id": "2695",
      "area_code": "532801",
      "area_name": "景洪市",
      "level": "3",
      "city_code": "0691",
      "center": "100.799545,22.011928",
      "parent_id": "2694"
    },
    {
      "area_id": "2696",
      "area_code": "532822",
      "area_name": "勐海县",
      "level": "3",
      "city_code": "0691",
      "center": "100.452547,21.957353",
      "parent_id": "2694"
    },
    {
      "area_id": "2697",
      "area_code": "532823",
      "area_name": "勐腊县",
      "level": "3",
      "city_code": "0691",
      "center": "101.564635,21.459233",
      "parent_id": "2694"
    },
    {
      "area_id": "2698",
      "area_code": "532900",
      "area_name": "大理白族自治州",
      "level": "2",
      "city_code": "0872",
      "center": "100.267638,25.606486",
      "parent_id": "2580"
    },
    {
      "area_id": "2699",
      "area_code": "532901",
      "area_name": "大理市",
      "level": "3",
      "city_code": "0872",
      "center": "100.30127,25.678068",
      "parent_id": "2698"
    },
    {
      "area_id": "2700",
      "area_code": "532922",
      "area_name": "漾濞彝族自治县",
      "level": "3",
      "city_code": "0872",
      "center": "99.958015,25.670148",
      "parent_id": "2698"
    },
    {
      "area_id": "2701",
      "area_code": "532923",
      "area_name": "祥云县",
      "level": "3",
      "city_code": "0872",
      "center": "100.550945,25.48385",
      "parent_id": "2698"
    },
    {
      "area_id": "2702",
      "area_code": "532924",
      "area_name": "宾川县",
      "level": "3",
      "city_code": "0872",
      "center": "100.590473,25.829828",
      "parent_id": "2698"
    },
    {
      "area_id": "2703",
      "area_code": "532925",
      "area_name": "弥渡县",
      "level": "3",
      "city_code": "0872",
      "center": "100.49099,25.343804",
      "parent_id": "2698"
    },
    {
      "area_id": "2704",
      "area_code": "532926",
      "area_name": "南涧彝族自治县",
      "level": "3",
      "city_code": "0872",
      "center": "100.509035,25.04351",
      "parent_id": "2698"
    },
    {
      "area_id": "2705",
      "area_code": "532927",
      "area_name": "巍山彝族回族自治县",
      "level": "3",
      "city_code": "0872",
      "center": "100.307174,25.227212",
      "parent_id": "2698"
    },
    {
      "area_id": "2706",
      "area_code": "532928",
      "area_name": "永平县",
      "level": "3",
      "city_code": "0872",
      "center": "99.541236,25.464681",
      "parent_id": "2698"
    },
    {
      "area_id": "2707",
      "area_code": "532929",
      "area_name": "云龙县",
      "level": "3",
      "city_code": "0872",
      "center": "99.37112,25.885595",
      "parent_id": "2698"
    },
    {
      "area_id": "2708",
      "area_code": "532930",
      "area_name": "洱源县",
      "level": "3",
      "city_code": "0872",
      "center": "99.951053,26.11116",
      "parent_id": "2698"
    },
    {
      "area_id": "2709",
      "area_code": "532931",
      "area_name": "剑川县",
      "level": "3",
      "city_code": "0872",
      "center": "99.905559,26.537033",
      "parent_id": "2698"
    },
    {
      "area_id": "2710",
      "area_code": "532932",
      "area_name": "鹤庆县",
      "level": "3",
      "city_code": "0872",
      "center": "100.176498,26.560231",
      "parent_id": "2698"
    },
    {
      "area_id": "2711",
      "area_code": "533100",
      "area_name": "德宏傣族景颇族自治州",
      "level": "2",
      "city_code": "0692",
      "center": "98.584895,24.433353",
      "parent_id": "2580"
    },
    {
      "area_id": "2712",
      "area_code": "533102",
      "area_name": "瑞丽市",
      "level": "3",
      "city_code": "0692",
      "center": "97.85559,24.017958",
      "parent_id": "2711"
    },
    {
      "area_id": "2713",
      "area_code": "533103",
      "area_name": "芒市",
      "level": "3",
      "city_code": "0692",
      "center": "98.588086,24.43369",
      "parent_id": "2711"
    },
    {
      "area_id": "2714",
      "area_code": "533122",
      "area_name": "梁河县",
      "level": "3",
      "city_code": "0692",
      "center": "98.296657,24.804232",
      "parent_id": "2711"
    },
    {
      "area_id": "2715",
      "area_code": "533123",
      "area_name": "盈江县",
      "level": "3",
      "city_code": "0692",
      "center": "97.931936,24.705164",
      "parent_id": "2711"
    },
    {
      "area_id": "2716",
      "area_code": "533124",
      "area_name": "陇川县",
      "level": "3",
      "city_code": "0692",
      "center": "97.792104,24.182965",
      "parent_id": "2711"
    },
    {
      "area_id": "2717",
      "area_code": "533300",
      "area_name": "怒江傈僳族自治州",
      "level": "2",
      "city_code": "0886",
      "center": "98.8566,25.817555",
      "parent_id": "2580"
    },
    {
      "area_id": "2718",
      "area_code": "533301",
      "area_name": "泸水市",
      "level": "3",
      "city_code": "0886",
      "center": "98.857977,25.822879",
      "parent_id": "2717"
    },
    {
      "area_id": "2719",
      "area_code": "533323",
      "area_name": "福贡县",
      "level": "3",
      "city_code": "0886",
      "center": "98.869132,26.901831",
      "parent_id": "2717"
    },
    {
      "area_id": "2720",
      "area_code": "533324",
      "area_name": "贡山独龙族怒族自治县",
      "level": "3",
      "city_code": "0886",
      "center": "98.665964,27.740999",
      "parent_id": "2717"
    },
    {
      "area_id": "2721",
      "area_code": "533325",
      "area_name": "兰坪白族普米族自治县",
      "level": "3",
      "city_code": "0886",
      "center": "99.416677,26.453571",
      "parent_id": "2717"
    },
    {
      "area_id": "2722",
      "area_code": "533400",
      "area_name": "迪庆藏族自治州",
      "level": "2",
      "city_code": "0887",
      "center": "99.702583,27.818807",
      "parent_id": "2580"
    },
    {
      "area_id": "2723",
      "area_code": "533401",
      "area_name": "香格里拉市",
      "level": "3",
      "city_code": "0887",
      "center": "99.700904,27.829578",
      "parent_id": "2722"
    },
    {
      "area_id": "2724",
      "area_code": "533422",
      "area_name": "德钦县",
      "level": "3",
      "city_code": "0887",
      "center": "98.911559,28.486163",
      "parent_id": "2722"
    },
    {
      "area_id": "2725",
      "area_code": "533423",
      "area_name": "维西傈僳族自治县",
      "level": "3",
      "city_code": "0887",
      "center": "99.287173,27.177161",
      "parent_id": "2722"
    },
    {
      "area_id": "2726",
      "area_code": "540000",
      "area_name": "西藏自治区",
      "level": "1",
      "city_code": "[]",
      "center": "91.117525,29.647535",
      "parent_id": "0"
    },
    {
      "area_id": "2727",
      "area_code": "540100",
      "area_name": "拉萨市",
      "level": "2",
      "city_code": "0891",
      "center": "91.172148,29.652341",
      "parent_id": "2726"
    },
    {
      "area_id": "2728",
      "area_code": "540102",
      "area_name": "城关区",
      "level": "3",
      "city_code": "0891",
      "center": "91.140552,29.654838",
      "parent_id": "2727"
    },
    {
      "area_id": "2729",
      "area_code": "540103",
      "area_name": "堆龙德庆区",
      "level": "3",
      "city_code": "0891",
      "center": "91.003339,29.646063",
      "parent_id": "2727"
    },
    {
      "area_id": "2730",
      "area_code": "540121",
      "area_name": "林周县",
      "level": "3",
      "city_code": "0891",
      "center": "91.265287,29.893545",
      "parent_id": "2727"
    },
    {
      "area_id": "2731",
      "area_code": "540122",
      "area_name": "当雄县",
      "level": "3",
      "city_code": "0891",
      "center": "91.101162,30.473118",
      "parent_id": "2727"
    },
    {
      "area_id": "2732",
      "area_code": "540123",
      "area_name": "尼木县",
      "level": "3",
      "city_code": "0891",
      "center": "90.164524,29.431831",
      "parent_id": "2727"
    },
    {
      "area_id": "2733",
      "area_code": "540124",
      "area_name": "曲水县",
      "level": "3",
      "city_code": "0891",
      "center": "90.743853,29.353058",
      "parent_id": "2727"
    },
    {
      "area_id": "2734",
      "area_code": "540126",
      "area_name": "达孜县",
      "level": "3",
      "city_code": "0891",
      "center": "91.349867,29.66941",
      "parent_id": "2727"
    },
    {
      "area_id": "2735",
      "area_code": "540127",
      "area_name": "墨竹工卡县",
      "level": "3",
      "city_code": "0891",
      "center": "91.730732,29.834111",
      "parent_id": "2727"
    },
    {
      "area_id": "2736",
      "area_code": "540200",
      "area_name": "日喀则市",
      "level": "2",
      "city_code": "0892",
      "center": "88.880583,29.266869",
      "parent_id": "2726"
    },
    {
      "area_id": "2737",
      "area_code": "540202",
      "area_name": "桑珠孜区",
      "level": "3",
      "city_code": "0892",
      "center": "88.898483,29.24779",
      "parent_id": "2736"
    },
    {
      "area_id": "2738",
      "area_code": "540221",
      "area_name": "南木林县",
      "level": "3",
      "city_code": "0892",
      "center": "89.099242,29.68233",
      "parent_id": "2736"
    },
    {
      "area_id": "2739",
      "area_code": "540222",
      "area_name": "江孜县",
      "level": "3",
      "city_code": "0892",
      "center": "89.605627,28.911626",
      "parent_id": "2736"
    },
    {
      "area_id": "2740",
      "area_code": "540223",
      "area_name": "定日县",
      "level": "3",
      "city_code": "0892",
      "center": "87.12612,28.658743",
      "parent_id": "2736"
    },
    {
      "area_id": "2741",
      "area_code": "540224",
      "area_name": "萨迦县",
      "level": "3",
      "city_code": "0892",
      "center": "88.021674,28.899664",
      "parent_id": "2736"
    },
    {
      "area_id": "2742",
      "area_code": "540225",
      "area_name": "拉孜县",
      "level": "3",
      "city_code": "0892",
      "center": "87.63704,29.081659",
      "parent_id": "2736"
    },
    {
      "area_id": "2743",
      "area_code": "540226",
      "area_name": "昂仁县",
      "level": "3",
      "city_code": "0892",
      "center": "87.236051,29.294802",
      "parent_id": "2736"
    },
    {
      "area_id": "2744",
      "area_code": "540227",
      "area_name": "谢通门县",
      "level": "3",
      "city_code": "0892",
      "center": "88.261664,29.432476",
      "parent_id": "2736"
    },
    {
      "area_id": "2745",
      "area_code": "540228",
      "area_name": "白朗县",
      "level": "3",
      "city_code": "0892",
      "center": "89.261977,29.107688",
      "parent_id": "2736"
    },
    {
      "area_id": "2746",
      "area_code": "540229",
      "area_name": "仁布县",
      "level": "3",
      "city_code": "0892",
      "center": "89.841983,29.230933",
      "parent_id": "2736"
    },
    {
      "area_id": "2747",
      "area_code": "540230",
      "area_name": "康马县",
      "level": "3",
      "city_code": "0892",
      "center": "89.681663,28.555627",
      "parent_id": "2736"
    },
    {
      "area_id": "2748",
      "area_code": "540231",
      "area_name": "定结县",
      "level": "3",
      "city_code": "0892",
      "center": "87.765872,28.364159",
      "parent_id": "2736"
    },
    {
      "area_id": "2749",
      "area_code": "540232",
      "area_name": "仲巴县",
      "level": "3",
      "city_code": "0892",
      "center": "84.03153,29.770279",
      "parent_id": "2736"
    },
    {
      "area_id": "2750",
      "area_code": "540233",
      "area_name": "亚东县",
      "level": "3",
      "city_code": "0892",
      "center": "88.907093,27.484806",
      "parent_id": "2736"
    },
    {
      "area_id": "2751",
      "area_code": "540234",
      "area_name": "吉隆县",
      "level": "3",
      "city_code": "0892",
      "center": "85.297534,28.852393",
      "parent_id": "2736"
    },
    {
      "area_id": "2752",
      "area_code": "540235",
      "area_name": "聂拉木县",
      "level": "3",
      "city_code": "0892",
      "center": "85.982237,28.155186",
      "parent_id": "2736"
    },
    {
      "area_id": "2753",
      "area_code": "540236",
      "area_name": "萨嘎县",
      "level": "3",
      "city_code": "0892",
      "center": "85.232941,29.328818",
      "parent_id": "2736"
    },
    {
      "area_id": "2754",
      "area_code": "540237",
      "area_name": "岗巴县",
      "level": "3",
      "city_code": "0892",
      "center": "88.520031,28.274601",
      "parent_id": "2736"
    },
    {
      "area_id": "2755",
      "area_code": "540300",
      "area_name": "昌都市",
      "level": "2",
      "city_code": "0895",
      "center": "97.17202,31.140969",
      "parent_id": "2726"
    },
    {
      "area_id": "2756",
      "area_code": "540302",
      "area_name": "卡若区",
      "level": "3",
      "city_code": "0895",
      "center": "97.196021,31.112087",
      "parent_id": "2755"
    },
    {
      "area_id": "2757",
      "area_code": "540321",
      "area_name": "江达县",
      "level": "3",
      "city_code": "0895",
      "center": "98.21843,31.499202",
      "parent_id": "2755"
    },
    {
      "area_id": "2758",
      "area_code": "540322",
      "area_name": "贡觉县",
      "level": "3",
      "city_code": "0895",
      "center": "98.27097,30.860099",
      "parent_id": "2755"
    },
    {
      "area_id": "2759",
      "area_code": "540323",
      "area_name": "类乌齐县",
      "level": "3",
      "city_code": "0895",
      "center": "96.600246,31.211601",
      "parent_id": "2755"
    },
    {
      "area_id": "2760",
      "area_code": "540324",
      "area_name": "丁青县",
      "level": "3",
      "city_code": "0895",
      "center": "95.619868,31.409024",
      "parent_id": "2755"
    },
    {
      "area_id": "2761",
      "area_code": "540325",
      "area_name": "察雅县",
      "level": "3",
      "city_code": "0895",
      "center": "97.568752,30.653943",
      "parent_id": "2755"
    },
    {
      "area_id": "2762",
      "area_code": "540326",
      "area_name": "八宿县",
      "level": "3",
      "city_code": "0895",
      "center": "96.917836,30.053209",
      "parent_id": "2755"
    },
    {
      "area_id": "2763",
      "area_code": "540327",
      "area_name": "左贡县",
      "level": "3",
      "city_code": "0895",
      "center": "97.841022,29.671069",
      "parent_id": "2755"
    },
    {
      "area_id": "2764",
      "area_code": "540328",
      "area_name": "芒康县",
      "level": "3",
      "city_code": "0895",
      "center": "98.593113,29.679907",
      "parent_id": "2755"
    },
    {
      "area_id": "2765",
      "area_code": "540329",
      "area_name": "洛隆县",
      "level": "3",
      "city_code": "0895",
      "center": "95.825197,30.741845",
      "parent_id": "2755"
    },
    {
      "area_id": "2766",
      "area_code": "540330",
      "area_name": "边坝县",
      "level": "3",
      "city_code": "0895",
      "center": "94.7078,30.933652",
      "parent_id": "2755"
    },
    {
      "area_id": "2767",
      "area_code": "540400",
      "area_name": "林芝市",
      "level": "2",
      "city_code": "0894",
      "center": "94.36149,29.649128",
      "parent_id": "2726"
    },
    {
      "area_id": "2768",
      "area_code": "540402",
      "area_name": "巴宜区",
      "level": "3",
      "city_code": "0894",
      "center": "94.361094,29.636576",
      "parent_id": "2767"
    },
    {
      "area_id": "2769",
      "area_code": "540421",
      "area_name": "工布江达县",
      "level": "3",
      "city_code": "0894",
      "center": "93.246077,29.88528",
      "parent_id": "2767"
    },
    {
      "area_id": "2770",
      "area_code": "540422",
      "area_name": "米林县",
      "level": "3",
      "city_code": "0894",
      "center": "94.213679,29.213811",
      "parent_id": "2767"
    },
    {
      "area_id": "2771",
      "area_code": "540423",
      "area_name": "墨脱县",
      "level": "3",
      "city_code": "0894",
      "center": "95.333197,29.325298",
      "parent_id": "2767"
    },
    {
      "area_id": "2772",
      "area_code": "540424",
      "area_name": "波密县",
      "level": "3",
      "city_code": "0894",
      "center": "95.767913,29.859028",
      "parent_id": "2767"
    },
    {
      "area_id": "2773",
      "area_code": "540425",
      "area_name": "察隅县",
      "level": "3",
      "city_code": "0894",
      "center": "97.466919,28.66128",
      "parent_id": "2767"
    },
    {
      "area_id": "2774",
      "area_code": "540426",
      "area_name": "朗县",
      "level": "3",
      "city_code": "0894",
      "center": "93.074702,29.046337",
      "parent_id": "2767"
    },
    {
      "area_id": "2775",
      "area_code": "540500",
      "area_name": "山南市",
      "level": "2",
      "city_code": "0893",
      "center": "91.773134,29.237137",
      "parent_id": "2726"
    },
    {
      "area_id": "2776",
      "area_code": "540502",
      "area_name": "乃东区",
      "level": "3",
      "city_code": "0893",
      "center": "91.761538,29.224904",
      "parent_id": "2775"
    },
    {
      "area_id": "2777",
      "area_code": "540521",
      "area_name": "扎囊县",
      "level": "3",
      "city_code": "0893",
      "center": "91.33725,29.245113",
      "parent_id": "2775"
    },
    {
      "area_id": "2778",
      "area_code": "540522",
      "area_name": "贡嘎县",
      "level": "3",
      "city_code": "0893",
      "center": "90.98414,29.289455",
      "parent_id": "2775"
    },
    {
      "area_id": "2779",
      "area_code": "540523",
      "area_name": "桑日县",
      "level": "3",
      "city_code": "0893",
      "center": "92.015818,29.259189",
      "parent_id": "2775"
    },
    {
      "area_id": "2780",
      "area_code": "540524",
      "area_name": "琼结县",
      "level": "3",
      "city_code": "0893",
      "center": "91.683881,29.024625",
      "parent_id": "2775"
    },
    {
      "area_id": "2781",
      "area_code": "540525",
      "area_name": "曲松县",
      "level": "3",
      "city_code": "0893",
      "center": "92.203738,29.062826",
      "parent_id": "2775"
    },
    {
      "area_id": "2782",
      "area_code": "540526",
      "area_name": "措美县",
      "level": "3",
      "city_code": "0893",
      "center": "91.433509,28.438202",
      "parent_id": "2775"
    },
    {
      "area_id": "2783",
      "area_code": "540527",
      "area_name": "洛扎县",
      "level": "3",
      "city_code": "0893",
      "center": "90.859992,28.385713",
      "parent_id": "2775"
    },
    {
      "area_id": "2784",
      "area_code": "540528",
      "area_name": "加查县",
      "level": "3",
      "city_code": "0893",
      "center": "92.593993,29.14029",
      "parent_id": "2775"
    },
    {
      "area_id": "2785",
      "area_code": "540529",
      "area_name": "隆子县",
      "level": "3",
      "city_code": "0893",
      "center": "92.463308,28.408548",
      "parent_id": "2775"
    },
    {
      "area_id": "2786",
      "area_code": "540530",
      "area_name": "错那县",
      "level": "3",
      "city_code": "0893",
      "center": "91.960132,27.991707",
      "parent_id": "2775"
    },
    {
      "area_id": "2787",
      "area_code": "540531",
      "area_name": "浪卡子县",
      "level": "3",
      "city_code": "0893",
      "center": "90.397977,28.968031",
      "parent_id": "2775"
    },
    {
      "area_id": "2788",
      "area_code": "542400",
      "area_name": "那曲地区",
      "level": "2",
      "city_code": "0896",
      "center": "92.052064,31.476479",
      "parent_id": "2726"
    },
    {
      "area_id": "2789",
      "area_code": "542421",
      "area_name": "那曲县",
      "level": "3",
      "city_code": "0896",
      "center": "92.0535,31.469643",
      "parent_id": "2788"
    },
    {
      "area_id": "2790",
      "area_code": "542422",
      "area_name": "嘉黎县",
      "level": "3",
      "city_code": "0896",
      "center": "93.232528,30.640814",
      "parent_id": "2788"
    },
    {
      "area_id": "2791",
      "area_code": "542423",
      "area_name": "比如县",
      "level": "3",
      "city_code": "0896",
      "center": "93.679639,31.480249",
      "parent_id": "2788"
    },
    {
      "area_id": "2792",
      "area_code": "542424",
      "area_name": "聂荣县",
      "level": "3",
      "city_code": "0896",
      "center": "92.303377,32.10775",
      "parent_id": "2788"
    },
    {
      "area_id": "2793",
      "area_code": "542425",
      "area_name": "安多县",
      "level": "3",
      "city_code": "0896",
      "center": "91.68233,32.265176",
      "parent_id": "2788"
    },
    {
      "area_id": "2794",
      "area_code": "542426",
      "area_name": "申扎县",
      "level": "3",
      "city_code": "0896",
      "center": "88.709852,30.930505",
      "parent_id": "2788"
    },
    {
      "area_id": "2795",
      "area_code": "542427",
      "area_name": "索县",
      "level": "3",
      "city_code": "0896",
      "center": "93.785516,31.886671",
      "parent_id": "2788"
    },
    {
      "area_id": "2796",
      "area_code": "542428",
      "area_name": "班戈县",
      "level": "3",
      "city_code": "0896",
      "center": "90.009957,31.392411",
      "parent_id": "2788"
    },
    {
      "area_id": "2797",
      "area_code": "542429",
      "area_name": "巴青县",
      "level": "3",
      "city_code": "0896",
      "center": "94.053438,31.91847",
      "parent_id": "2788"
    },
    {
      "area_id": "2798",
      "area_code": "542430",
      "area_name": "尼玛县",
      "level": "3",
      "city_code": "0896",
      "center": "87.236772,31.784701",
      "parent_id": "2788"
    },
    {
      "area_id": "2799",
      "area_code": "542431",
      "area_name": "双湖县",
      "level": "3",
      "city_code": "0896",
      "center": "88.837641,33.188514",
      "parent_id": "2788"
    },
    {
      "area_id": "2800",
      "area_code": "542500",
      "area_name": "阿里地区",
      "level": "2",
      "city_code": "0897",
      "center": "80.105804,32.501111",
      "parent_id": "2726"
    },
    {
      "area_id": "2801",
      "area_code": "542521",
      "area_name": "普兰县",
      "level": "3",
      "city_code": "0897",
      "center": "81.176237,30.294402",
      "parent_id": "2800"
    },
    {
      "area_id": "2802",
      "area_code": "542522",
      "area_name": "札达县",
      "level": "3",
      "city_code": "0897",
      "center": "79.802706,31.479216",
      "parent_id": "2800"
    },
    {
      "area_id": "2803",
      "area_code": "542523",
      "area_name": "噶尔县",
      "level": "3",
      "city_code": "0897",
      "center": "80.096419,32.491488",
      "parent_id": "2800"
    },
    {
      "area_id": "2804",
      "area_code": "542524",
      "area_name": "日土县",
      "level": "3",
      "city_code": "0897",
      "center": "79.732427,33.381359",
      "parent_id": "2800"
    },
    {
      "area_id": "2805",
      "area_code": "542525",
      "area_name": "革吉县",
      "level": "3",
      "city_code": "0897",
      "center": "81.145433,32.387233",
      "parent_id": "2800"
    },
    {
      "area_id": "2806",
      "area_code": "542526",
      "area_name": "改则县",
      "level": "3",
      "city_code": "0897",
      "center": "84.06259,32.302713",
      "parent_id": "2800"
    },
    {
      "area_id": "2807",
      "area_code": "542527",
      "area_name": "措勤县",
      "level": "3",
      "city_code": "0897",
      "center": "85.151455,31.017312",
      "parent_id": "2800"
    },
    {
      "area_id": "2808",
      "area_code": "610000",
      "area_name": "陕西省",
      "level": "1",
      "city_code": "[]",
      "center": "108.954347,34.265502",
      "parent_id": "0"
    },
    {
      "area_id": "2809",
      "area_code": "610100",
      "area_name": "西安市",
      "level": "2",
      "city_code": "029",
      "center": "108.93977,34.341574",
      "parent_id": "2808"
    },
    {
      "area_id": "2810",
      "area_code": "610102",
      "area_name": "新城区",
      "level": "3",
      "city_code": "029",
      "center": "108.960716,34.266447",
      "parent_id": "2809"
    },
    {
      "area_id": "2811",
      "area_code": "610103",
      "area_name": "碑林区",
      "level": "3",
      "city_code": "029",
      "center": "108.94059,34.256783",
      "parent_id": "2809"
    },
    {
      "area_id": "2812",
      "area_code": "610104",
      "area_name": "莲湖区",
      "level": "3",
      "city_code": "029",
      "center": "108.943895,34.265239",
      "parent_id": "2809"
    },
    {
      "area_id": "2813",
      "area_code": "610111",
      "area_name": "灞桥区",
      "level": "3",
      "city_code": "029",
      "center": "109.064646,34.272793",
      "parent_id": "2809"
    },
    {
      "area_id": "2814",
      "area_code": "610112",
      "area_name": "未央区",
      "level": "3",
      "city_code": "029",
      "center": "108.946825,34.29292",
      "parent_id": "2809"
    },
    {
      "area_id": "2815",
      "area_code": "610113",
      "area_name": "雁塔区",
      "level": "3",
      "city_code": "029",
      "center": "108.944644,34.214113",
      "parent_id": "2809"
    },
    {
      "area_id": "2816",
      "area_code": "610114",
      "area_name": "阎良区",
      "level": "3",
      "city_code": "029",
      "center": "109.226124,34.662232",
      "parent_id": "2809"
    },
    {
      "area_id": "2817",
      "area_code": "610115",
      "area_name": "临潼区",
      "level": "3",
      "city_code": "029",
      "center": "109.214237,34.367069",
      "parent_id": "2809"
    },
    {
      "area_id": "2818",
      "area_code": "610116",
      "area_name": "长安区",
      "level": "3",
      "city_code": "029",
      "center": "108.907173,34.158926",
      "parent_id": "2809"
    },
    {
      "area_id": "2819",
      "area_code": "610117",
      "area_name": "高陵区",
      "level": "3",
      "city_code": "029",
      "center": "109.088297,34.534829",
      "parent_id": "2809"
    },
    {
      "area_id": "2820",
      "area_code": "610122",
      "area_name": "蓝田县",
      "level": "3",
      "city_code": "029",
      "center": "109.32345,34.151298",
      "parent_id": "2809"
    },
    {
      "area_id": "2821",
      "area_code": "610124",
      "area_name": "周至县",
      "level": "3",
      "city_code": "029",
      "center": "108.222162,34.163669",
      "parent_id": "2809"
    },
    {
      "area_id": "2822",
      "area_code": "610118",
      "area_name": "鄠邑区",
      "level": "3",
      "city_code": "029",
      "center": "108.604894,34.109244",
      "parent_id": "2809"
    },
    {
      "area_id": "2823",
      "area_code": "610200",
      "area_name": "铜川市",
      "level": "2",
      "city_code": "0919",
      "center": "108.945019,34.897887",
      "parent_id": "2808"
    },
    {
      "area_id": "2824",
      "area_code": "610202",
      "area_name": "王益区",
      "level": "3",
      "city_code": "0919",
      "center": "109.075578,35.068964",
      "parent_id": "2823"
    },
    {
      "area_id": "2825",
      "area_code": "610203",
      "area_name": "印台区",
      "level": "3",
      "city_code": "0919",
      "center": "109.099974,35.114492",
      "parent_id": "2823"
    },
    {
      "area_id": "2826",
      "area_code": "610204",
      "area_name": "耀州区",
      "level": "3",
      "city_code": "0919",
      "center": "108.980102,34.909793",
      "parent_id": "2823"
    },
    {
      "area_id": "2827",
      "area_code": "610222",
      "area_name": "宜君县",
      "level": "3",
      "city_code": "0919",
      "center": "109.116932,35.398577",
      "parent_id": "2823"
    },
    {
      "area_id": "2828",
      "area_code": "610300",
      "area_name": "宝鸡市",
      "level": "2",
      "city_code": "0917",
      "center": "107.237743,34.363184",
      "parent_id": "2808"
    },
    {
      "area_id": "2829",
      "area_code": "610302",
      "area_name": "渭滨区",
      "level": "3",
      "city_code": "0917",
      "center": "107.155344,34.355068",
      "parent_id": "2828"
    },
    {
      "area_id": "2830",
      "area_code": "610303",
      "area_name": "金台区",
      "level": "3",
      "city_code": "0917",
      "center": "107.146806,34.376069",
      "parent_id": "2828"
    },
    {
      "area_id": "2831",
      "area_code": "610304",
      "area_name": "陈仓区",
      "level": "3",
      "city_code": "0917",
      "center": "107.369987,34.35147",
      "parent_id": "2828"
    },
    {
      "area_id": "2832",
      "area_code": "610322",
      "area_name": "凤翔县",
      "level": "3",
      "city_code": "0917",
      "center": "107.400737,34.521217",
      "parent_id": "2828"
    },
    {
      "area_id": "2833",
      "area_code": "610323",
      "area_name": "岐山县",
      "level": "3",
      "city_code": "0917",
      "center": "107.621053,34.443459",
      "parent_id": "2828"
    },
    {
      "area_id": "2834",
      "area_code": "610324",
      "area_name": "扶风县",
      "level": "3",
      "city_code": "0917",
      "center": "107.900219,34.37541",
      "parent_id": "2828"
    },
    {
      "area_id": "2835",
      "area_code": "610326",
      "area_name": "眉县",
      "level": "3",
      "city_code": "0917",
      "center": "107.749766,34.274246",
      "parent_id": "2828"
    },
    {
      "area_id": "2836",
      "area_code": "610327",
      "area_name": "陇县",
      "level": "3",
      "city_code": "0917",
      "center": "106.864397,34.89305",
      "parent_id": "2828"
    },
    {
      "area_id": "2837",
      "area_code": "610328",
      "area_name": "千阳县",
      "level": "3",
      "city_code": "0917",
      "center": "107.132441,34.642381",
      "parent_id": "2828"
    },
    {
      "area_id": "2838",
      "area_code": "610329",
      "area_name": "麟游县",
      "level": "3",
      "city_code": "0917",
      "center": "107.793524,34.677902",
      "parent_id": "2828"
    },
    {
      "area_id": "2839",
      "area_code": "610330",
      "area_name": "凤县",
      "level": "3",
      "city_code": "0917",
      "center": "106.515803,33.91091",
      "parent_id": "2828"
    },
    {
      "area_id": "2840",
      "area_code": "610331",
      "area_name": "太白县",
      "level": "3",
      "city_code": "0917",
      "center": "107.319116,34.058401",
      "parent_id": "2828"
    },
    {
      "area_id": "2841",
      "area_code": "610400",
      "area_name": "咸阳市",
      "level": "2",
      "city_code": "0910",
      "center": "108.709136,34.32987",
      "parent_id": "2808"
    },
    {
      "area_id": "2842",
      "area_code": "610402",
      "area_name": "秦都区",
      "level": "3",
      "city_code": "0910",
      "center": "108.706272,34.329567",
      "parent_id": "2841"
    },
    {
      "area_id": "2843",
      "area_code": "610403",
      "area_name": "杨陵区",
      "level": "3",
      "city_code": "0910",
      "center": "108.084731,34.272117",
      "parent_id": "2841"
    },
    {
      "area_id": "2844",
      "area_code": "610404",
      "area_name": "渭城区",
      "level": "3",
      "city_code": "0910",
      "center": "108.737204,34.36195",
      "parent_id": "2841"
    },
    {
      "area_id": "2845",
      "area_code": "610422",
      "area_name": "三原县",
      "level": "3",
      "city_code": "0910",
      "center": "108.940509,34.617381",
      "parent_id": "2841"
    },
    {
      "area_id": "2846",
      "area_code": "610423",
      "area_name": "泾阳县",
      "level": "3",
      "city_code": "0910",
      "center": "108.842622,34.527114",
      "parent_id": "2841"
    },
    {
      "area_id": "2847",
      "area_code": "610424",
      "area_name": "乾县",
      "level": "3",
      "city_code": "0910",
      "center": "108.239473,34.527551",
      "parent_id": "2841"
    },
    {
      "area_id": "2848",
      "area_code": "610425",
      "area_name": "礼泉县",
      "level": "3",
      "city_code": "0910",
      "center": "108.425018,34.481764",
      "parent_id": "2841"
    },
    {
      "area_id": "2849",
      "area_code": "610426",
      "area_name": "永寿县",
      "level": "3",
      "city_code": "0910",
      "center": "108.142311,34.691979",
      "parent_id": "2841"
    },
    {
      "area_id": "2850",
      "area_code": "610427",
      "area_name": "彬县",
      "level": "3",
      "city_code": "0910",
      "center": "108.077658,35.043911",
      "parent_id": "2841"
    },
    {
      "area_id": "2851",
      "area_code": "610428",
      "area_name": "长武县",
      "level": "3",
      "city_code": "0910",
      "center": "107.798757,35.205886",
      "parent_id": "2841"
    },
    {
      "area_id": "2852",
      "area_code": "610429",
      "area_name": "旬邑县",
      "level": "3",
      "city_code": "0910",
      "center": "108.333986,35.111978",
      "parent_id": "2841"
    },
    {
      "area_id": "2853",
      "area_code": "610430",
      "area_name": "淳化县",
      "level": "3",
      "city_code": "0910",
      "center": "108.580681,34.79925",
      "parent_id": "2841"
    },
    {
      "area_id": "2854",
      "area_code": "610431",
      "area_name": "武功县",
      "level": "3",
      "city_code": "0910",
      "center": "108.200398,34.260203",
      "parent_id": "2841"
    },
    {
      "area_id": "2855",
      "area_code": "610481",
      "area_name": "兴平市",
      "level": "3",
      "city_code": "0910",
      "center": "108.490475,34.29922",
      "parent_id": "2841"
    },
    {
      "area_id": "2856",
      "area_code": "610500",
      "area_name": "渭南市",
      "level": "2",
      "city_code": "0913",
      "center": "109.471094,34.52044",
      "parent_id": "2808"
    },
    {
      "area_id": "2857",
      "area_code": "610502",
      "area_name": "临渭区",
      "level": "3",
      "city_code": "0913",
      "center": "109.510175,34.499314",
      "parent_id": "2856"
    },
    {
      "area_id": "2858",
      "area_code": "610503",
      "area_name": "华州区",
      "level": "3",
      "city_code": "0913",
      "center": "109.775247,34.495915",
      "parent_id": "2856"
    },
    {
      "area_id": "2859",
      "area_code": "610522",
      "area_name": "潼关县",
      "level": "3",
      "city_code": "0913",
      "center": "110.246349,34.544296",
      "parent_id": "2856"
    },
    {
      "area_id": "2860",
      "area_code": "610523",
      "area_name": "大荔县",
      "level": "3",
      "city_code": "0913",
      "center": "109.941734,34.797259",
      "parent_id": "2856"
    },
    {
      "area_id": "2861",
      "area_code": "610524",
      "area_name": "合阳县",
      "level": "3",
      "city_code": "0913",
      "center": "110.149453,35.237988",
      "parent_id": "2856"
    },
    {
      "area_id": "2862",
      "area_code": "610525",
      "area_name": "澄城县",
      "level": "3",
      "city_code": "0913",
      "center": "109.93235,35.190245",
      "parent_id": "2856"
    },
    {
      "area_id": "2863",
      "area_code": "610526",
      "area_name": "蒲城县",
      "level": "3",
      "city_code": "0913",
      "center": "109.586403,34.955562",
      "parent_id": "2856"
    },
    {
      "area_id": "2864",
      "area_code": "610527",
      "area_name": "白水县",
      "level": "3",
      "city_code": "0913",
      "center": "109.590671,35.177451",
      "parent_id": "2856"
    },
    {
      "area_id": "2865",
      "area_code": "610528",
      "area_name": "富平县",
      "level": "3",
      "city_code": "0913",
      "center": "109.18032,34.751077",
      "parent_id": "2856"
    },
    {
      "area_id": "2866",
      "area_code": "610581",
      "area_name": "韩城市",
      "level": "3",
      "city_code": "0913",
      "center": "110.442846,35.476788",
      "parent_id": "2856"
    },
    {
      "area_id": "2867",
      "area_code": "610582",
      "area_name": "华阴市",
      "level": "3",
      "city_code": "0913",
      "center": "110.092078,34.566079",
      "parent_id": "2856"
    },
    {
      "area_id": "2868",
      "area_code": "610600",
      "area_name": "延安市",
      "level": "2",
      "city_code": "0911",
      "center": "109.494112,36.651381",
      "parent_id": "2808"
    },
    {
      "area_id": "2869",
      "area_code": "610602",
      "area_name": "宝塔区",
      "level": "3",
      "city_code": "0911",
      "center": "109.48976,36.585472",
      "parent_id": "2868"
    },
    {
      "area_id": "2870",
      "area_code": "610621",
      "area_name": "延长县",
      "level": "3",
      "city_code": "0911",
      "center": "110.012334,36.579313",
      "parent_id": "2868"
    },
    {
      "area_id": "2871",
      "area_code": "610622",
      "area_name": "延川县",
      "level": "3",
      "city_code": "0911",
      "center": "110.193514,36.878117",
      "parent_id": "2868"
    },
    {
      "area_id": "2872",
      "area_code": "610623",
      "area_name": "子长县",
      "level": "3",
      "city_code": "0911",
      "center": "109.675264,37.142535",
      "parent_id": "2868"
    },
    {
      "area_id": "2873",
      "area_code": "610603",
      "area_name": "安塞区",
      "level": "3",
      "city_code": "0911",
      "center": "109.328842,36.863853",
      "parent_id": "2868"
    },
    {
      "area_id": "2874",
      "area_code": "610625",
      "area_name": "志丹县",
      "level": "3",
      "city_code": "0911",
      "center": "108.768432,36.822194",
      "parent_id": "2868"
    },
    {
      "area_id": "2875",
      "area_code": "610626",
      "area_name": "吴起县",
      "level": "3",
      "city_code": "0911",
      "center": "108.175933,36.927215",
      "parent_id": "2868"
    },
    {
      "area_id": "2876",
      "area_code": "610627",
      "area_name": "甘泉县",
      "level": "3",
      "city_code": "0911",
      "center": "109.351019,36.276526",
      "parent_id": "2868"
    },
    {
      "area_id": "2877",
      "area_code": "610628",
      "area_name": "富县",
      "level": "3",
      "city_code": "0911",
      "center": "109.379776,35.987953",
      "parent_id": "2868"
    },
    {
      "area_id": "2878",
      "area_code": "610629",
      "area_name": "洛川县",
      "level": "3",
      "city_code": "0911",
      "center": "109.432369,35.761974",
      "parent_id": "2868"
    },
    {
      "area_id": "2879",
      "area_code": "610630",
      "area_name": "宜川县",
      "level": "3",
      "city_code": "0911",
      "center": "110.168963,36.050178",
      "parent_id": "2868"
    },
    {
      "area_id": "2880",
      "area_code": "610631",
      "area_name": "黄龙县",
      "level": "3",
      "city_code": "0911",
      "center": "109.840314,35.584743",
      "parent_id": "2868"
    },
    {
      "area_id": "2881",
      "area_code": "610632",
      "area_name": "黄陵县",
      "level": "3",
      "city_code": "0911",
      "center": "109.262961,35.579427",
      "parent_id": "2868"
    },
    {
      "area_id": "2882",
      "area_code": "610700",
      "area_name": "汉中市",
      "level": "2",
      "city_code": "0916",
      "center": "107.02305,33.067225",
      "parent_id": "2808"
    },
    {
      "area_id": "2883",
      "area_code": "610702",
      "area_name": "汉台区",
      "level": "3",
      "city_code": "0916",
      "center": "107.031856,33.067771",
      "parent_id": "2882"
    },
    {
      "area_id": "2884",
      "area_code": "610721",
      "area_name": "南郑县",
      "level": "3",
      "city_code": "0916",
      "center": "106.93623,32.999333",
      "parent_id": "2882"
    },
    {
      "area_id": "2885",
      "area_code": "610722",
      "area_name": "城固县",
      "level": "3",
      "city_code": "0916",
      "center": "107.33393,33.157131",
      "parent_id": "2882"
    },
    {
      "area_id": "2886",
      "area_code": "610723",
      "area_name": "洋县",
      "level": "3",
      "city_code": "0916",
      "center": "107.545836,33.222738",
      "parent_id": "2882"
    },
    {
      "area_id": "2887",
      "area_code": "610724",
      "area_name": "西乡县",
      "level": "3",
      "city_code": "0916",
      "center": "107.766613,32.983101",
      "parent_id": "2882"
    },
    {
      "area_id": "2888",
      "area_code": "610725",
      "area_name": "勉县",
      "level": "3",
      "city_code": "0916",
      "center": "106.673221,33.153553",
      "parent_id": "2882"
    },
    {
      "area_id": "2889",
      "area_code": "610726",
      "area_name": "宁强县",
      "level": "3",
      "city_code": "0916",
      "center": "106.257171,32.829694",
      "parent_id": "2882"
    },
    {
      "area_id": "2890",
      "area_code": "610727",
      "area_name": "略阳县",
      "level": "3",
      "city_code": "0916",
      "center": "106.156718,33.327281",
      "parent_id": "2882"
    },
    {
      "area_id": "2891",
      "area_code": "610728",
      "area_name": "镇巴县",
      "level": "3",
      "city_code": "0916",
      "center": "107.895035,32.536704",
      "parent_id": "2882"
    },
    {
      "area_id": "2892",
      "area_code": "610729",
      "area_name": "留坝县",
      "level": "3",
      "city_code": "0916",
      "center": "106.920808,33.617571",
      "parent_id": "2882"
    },
    {
      "area_id": "2893",
      "area_code": "610730",
      "area_name": "佛坪县",
      "level": "3",
      "city_code": "0916",
      "center": "107.990538,33.524359",
      "parent_id": "2882"
    },
    {
      "area_id": "2894",
      "area_code": "610800",
      "area_name": "榆林市",
      "level": "2",
      "city_code": "0912",
      "center": "109.734474,38.285369",
      "parent_id": "2808"
    },
    {
      "area_id": "2895",
      "area_code": "610802",
      "area_name": "榆阳区",
      "level": "3",
      "city_code": "0912",
      "center": "109.721069,38.277046",
      "parent_id": "2894"
    },
    {
      "area_id": "2896",
      "area_code": "610803",
      "area_name": "横山区",
      "level": "3",
      "city_code": "0912",
      "center": "109.294346,37.962208",
      "parent_id": "2894"
    },
    {
      "area_id": "2897",
      "area_code": "610881",
      "area_name": "神木市",
      "level": "3",
      "city_code": "0912",
      "center": "110.498939,38.842578",
      "parent_id": "2894"
    },
    {
      "area_id": "2898",
      "area_code": "610822",
      "area_name": "府谷县",
      "level": "3",
      "city_code": "0912",
      "center": "111.067276,39.028116",
      "parent_id": "2894"
    },
    {
      "area_id": "2899",
      "area_code": "610824",
      "area_name": "靖边县",
      "level": "3",
      "city_code": "0912",
      "center": "108.793988,37.599438",
      "parent_id": "2894"
    },
    {
      "area_id": "2900",
      "area_code": "610825",
      "area_name": "定边县",
      "level": "3",
      "city_code": "0912",
      "center": "107.601267,37.594612",
      "parent_id": "2894"
    },
    {
      "area_id": "2901",
      "area_code": "610826",
      "area_name": "绥德县",
      "level": "3",
      "city_code": "0912",
      "center": "110.263362,37.50294",
      "parent_id": "2894"
    },
    {
      "area_id": "2902",
      "area_code": "610827",
      "area_name": "米脂县",
      "level": "3",
      "city_code": "0912",
      "center": "110.183754,37.755416",
      "parent_id": "2894"
    },
    {
      "area_id": "2903",
      "area_code": "610828",
      "area_name": "佳县",
      "level": "3",
      "city_code": "0912",
      "center": "110.491345,38.01951",
      "parent_id": "2894"
    },
    {
      "area_id": "2904",
      "area_code": "610829",
      "area_name": "吴堡县",
      "level": "3",
      "city_code": "0912",
      "center": "110.739673,37.452067",
      "parent_id": "2894"
    },
    {
      "area_id": "2905",
      "area_code": "610830",
      "area_name": "清涧县",
      "level": "3",
      "city_code": "0912",
      "center": "110.121209,37.088878",
      "parent_id": "2894"
    },
    {
      "area_id": "2906",
      "area_code": "610831",
      "area_name": "子洲县",
      "level": "3",
      "city_code": "0912",
      "center": "110.03525,37.610683",
      "parent_id": "2894"
    },
    {
      "area_id": "2907",
      "area_code": "610900",
      "area_name": "安康市",
      "level": "2",
      "city_code": "0915",
      "center": "109.029113,32.68481",
      "parent_id": "2808"
    },
    {
      "area_id": "2908",
      "area_code": "610902",
      "area_name": "汉滨区",
      "level": "3",
      "city_code": "0915",
      "center": "109.026836,32.695172",
      "parent_id": "2907"
    },
    {
      "area_id": "2909",
      "area_code": "610921",
      "area_name": "汉阴县",
      "level": "3",
      "city_code": "0915",
      "center": "108.508745,32.893026",
      "parent_id": "2907"
    },
    {
      "area_id": "2910",
      "area_code": "610922",
      "area_name": "石泉县",
      "level": "3",
      "city_code": "0915",
      "center": "108.247886,33.038408",
      "parent_id": "2907"
    },
    {
      "area_id": "2911",
      "area_code": "610923",
      "area_name": "宁陕县",
      "level": "3",
      "city_code": "0915",
      "center": "108.314283,33.310527",
      "parent_id": "2907"
    },
    {
      "area_id": "2912",
      "area_code": "610924",
      "area_name": "紫阳县",
      "level": "3",
      "city_code": "0915",
      "center": "108.534228,32.520246",
      "parent_id": "2907"
    },
    {
      "area_id": "2913",
      "area_code": "610925",
      "area_name": "岚皋县",
      "level": "3",
      "city_code": "0915",
      "center": "108.902049,32.307001",
      "parent_id": "2907"
    },
    {
      "area_id": "2914",
      "area_code": "610926",
      "area_name": "平利县",
      "level": "3",
      "city_code": "0915",
      "center": "109.361864,32.388854",
      "parent_id": "2907"
    },
    {
      "area_id": "2915",
      "area_code": "610927",
      "area_name": "镇坪县",
      "level": "3",
      "city_code": "0915",
      "center": "109.526873,31.883672",
      "parent_id": "2907"
    },
    {
      "area_id": "2916",
      "area_code": "610928",
      "area_name": "旬阳县",
      "level": "3",
      "city_code": "0915",
      "center": "109.361024,32.832012",
      "parent_id": "2907"
    },
    {
      "area_id": "2917",
      "area_code": "610929",
      "area_name": "白河县",
      "level": "3",
      "city_code": "0915",
      "center": "110.112629,32.809026",
      "parent_id": "2907"
    },
    {
      "area_id": "2918",
      "area_code": "611000",
      "area_name": "商洛市",
      "level": "2",
      "city_code": "0914",
      "center": "109.91857,33.872726",
      "parent_id": "2808"
    },
    {
      "area_id": "2919",
      "area_code": "611002",
      "area_name": "商州区",
      "level": "3",
      "city_code": "0914",
      "center": "109.941839,33.862599",
      "parent_id": "2918"
    },
    {
      "area_id": "2920",
      "area_code": "611021",
      "area_name": "洛南县",
      "level": "3",
      "city_code": "0914",
      "center": "110.148508,34.090837",
      "parent_id": "2918"
    },
    {
      "area_id": "2921",
      "area_code": "611022",
      "area_name": "丹凤县",
      "level": "3",
      "city_code": "0914",
      "center": "110.32733,33.695783",
      "parent_id": "2918"
    },
    {
      "area_id": "2922",
      "area_code": "611023",
      "area_name": "商南县",
      "level": "3",
      "city_code": "0914",
      "center": "110.881807,33.530995",
      "parent_id": "2918"
    },
    {
      "area_id": "2923",
      "area_code": "611024",
      "area_name": "山阳县",
      "level": "3",
      "city_code": "0914",
      "center": "109.882289,33.532172",
      "parent_id": "2918"
    },
    {
      "area_id": "2924",
      "area_code": "611025",
      "area_name": "镇安县",
      "level": "3",
      "city_code": "0914",
      "center": "109.152892,33.423357",
      "parent_id": "2918"
    },
    {
      "area_id": "2925",
      "area_code": "611026",
      "area_name": "柞水县",
      "level": "3",
      "city_code": "0914",
      "center": "109.114206,33.68611",
      "parent_id": "2918"
    },
    {
      "area_id": "2926",
      "area_code": "620000",
      "area_name": "甘肃省",
      "level": "1",
      "city_code": "[]",
      "center": "103.826447,36.05956",
      "parent_id": "0"
    },
    {
      "area_id": "2927",
      "area_code": "620100",
      "area_name": "兰州市",
      "level": "2",
      "city_code": "0931",
      "center": "103.834303,36.061089",
      "parent_id": "2926"
    },
    {
      "area_id": "2928",
      "area_code": "620102",
      "area_name": "城关区",
      "level": "3",
      "city_code": "0931",
      "center": "103.825307,36.057464",
      "parent_id": "2927"
    },
    {
      "area_id": "2929",
      "area_code": "620103",
      "area_name": "七里河区",
      "level": "3",
      "city_code": "0931",
      "center": "103.785949,36.066146",
      "parent_id": "2927"
    },
    {
      "area_id": "2930",
      "area_code": "620104",
      "area_name": "西固区",
      "level": "3",
      "city_code": "0931",
      "center": "103.627951,36.088552",
      "parent_id": "2927"
    },
    {
      "area_id": "2931",
      "area_code": "620105",
      "area_name": "安宁区",
      "level": "3",
      "city_code": "0931",
      "center": "103.719054,36.104579",
      "parent_id": "2927"
    },
    {
      "area_id": "2932",
      "area_code": "620111",
      "area_name": "红古区",
      "level": "3",
      "city_code": "0931",
      "center": "102.859323,36.345669",
      "parent_id": "2927"
    },
    {
      "area_id": "2933",
      "area_code": "620121",
      "area_name": "永登县",
      "level": "3",
      "city_code": "0931",
      "center": "103.26038,36.736513",
      "parent_id": "2927"
    },
    {
      "area_id": "2934",
      "area_code": "620122",
      "area_name": "皋兰县",
      "level": "3",
      "city_code": "0931",
      "center": "103.947377,36.332663",
      "parent_id": "2927"
    },
    {
      "area_id": "2935",
      "area_code": "620123",
      "area_name": "榆中县",
      "level": "3",
      "city_code": "0931",
      "center": "104.112527,35.843056",
      "parent_id": "2927"
    },
    {
      "area_id": "2936",
      "area_code": "620200",
      "area_name": "嘉峪关市",
      "level": "2",
      "city_code": "1937",
      "center": "98.289419,39.772554",
      "parent_id": "2926"
    },
    {
      "area_id": "2937",
      "area_code": "620300",
      "area_name": "金昌市",
      "level": "2",
      "city_code": "0935",
      "center": "102.188117,38.520717",
      "parent_id": "2926"
    },
    {
      "area_id": "2938",
      "area_code": "620302",
      "area_name": "金川区",
      "level": "3",
      "city_code": "0935",
      "center": "102.194015,38.521087",
      "parent_id": "2937"
    },
    {
      "area_id": "2939",
      "area_code": "620321",
      "area_name": "永昌县",
      "level": "3",
      "city_code": "0935",
      "center": "101.984458,38.243434",
      "parent_id": "2937"
    },
    {
      "area_id": "2940",
      "area_code": "620400",
      "area_name": "白银市",
      "level": "2",
      "city_code": "0943",
      "center": "104.138771,36.545261",
      "parent_id": "2926"
    },
    {
      "area_id": "2941",
      "area_code": "620402",
      "area_name": "白银区",
      "level": "3",
      "city_code": "0943",
      "center": "104.148556,36.535398",
      "parent_id": "2940"
    },
    {
      "area_id": "2942",
      "area_code": "620403",
      "area_name": "平川区",
      "level": "3",
      "city_code": "0943",
      "center": "104.825208,36.728304",
      "parent_id": "2940"
    },
    {
      "area_id": "2943",
      "area_code": "620421",
      "area_name": "靖远县",
      "level": "3",
      "city_code": "0943",
      "center": "104.676774,36.571365",
      "parent_id": "2940"
    },
    {
      "area_id": "2944",
      "area_code": "620422",
      "area_name": "会宁县",
      "level": "3",
      "city_code": "0943",
      "center": "105.053358,35.692823",
      "parent_id": "2940"
    },
    {
      "area_id": "2945",
      "area_code": "620423",
      "area_name": "景泰县",
      "level": "3",
      "city_code": "0943",
      "center": "104.063091,37.183804",
      "parent_id": "2940"
    },
    {
      "area_id": "2946",
      "area_code": "620500",
      "area_name": "天水市",
      "level": "2",
      "city_code": "0938",
      "center": "105.724979,34.580885",
      "parent_id": "2926"
    },
    {
      "area_id": "2947",
      "area_code": "620502",
      "area_name": "秦州区",
      "level": "3",
      "city_code": "0938",
      "center": "105.724215,34.580888",
      "parent_id": "2946"
    },
    {
      "area_id": "2948",
      "area_code": "620503",
      "area_name": "麦积区",
      "level": "3",
      "city_code": "0938",
      "center": "105.889556,34.570384",
      "parent_id": "2946"
    },
    {
      "area_id": "2949",
      "area_code": "620521",
      "area_name": "清水县",
      "level": "3",
      "city_code": "0938",
      "center": "106.137293,34.749864",
      "parent_id": "2946"
    },
    {
      "area_id": "2950",
      "area_code": "620522",
      "area_name": "秦安县",
      "level": "3",
      "city_code": "0938",
      "center": "105.674982,34.858916",
      "parent_id": "2946"
    },
    {
      "area_id": "2951",
      "area_code": "620523",
      "area_name": "甘谷县",
      "level": "3",
      "city_code": "0938",
      "center": "105.340747,34.745486",
      "parent_id": "2946"
    },
    {
      "area_id": "2952",
      "area_code": "620524",
      "area_name": "武山县",
      "level": "3",
      "city_code": "0938",
      "center": "104.890587,34.72139",
      "parent_id": "2946"
    },
    {
      "area_id": "2953",
      "area_code": "620525",
      "area_name": "张家川回族自治县",
      "level": "3",
      "city_code": "0938",
      "center": "106.204517,34.988037",
      "parent_id": "2946"
    },
    {
      "area_id": "2954",
      "area_code": "620600",
      "area_name": "武威市",
      "level": "2",
      "city_code": "1935",
      "center": "102.638201,37.928267",
      "parent_id": "2926"
    },
    {
      "area_id": "2955",
      "area_code": "620602",
      "area_name": "凉州区",
      "level": "3",
      "city_code": "1935",
      "center": "102.642184,37.928224",
      "parent_id": "2954"
    },
    {
      "area_id": "2956",
      "area_code": "620621",
      "area_name": "民勤县",
      "level": "3",
      "city_code": "1935",
      "center": "103.093791,38.62435",
      "parent_id": "2954"
    },
    {
      "area_id": "2957",
      "area_code": "620622",
      "area_name": "古浪县",
      "level": "3",
      "city_code": "1935",
      "center": "102.897533,37.47012",
      "parent_id": "2954"
    },
    {
      "area_id": "2958",
      "area_code": "620623",
      "area_name": "天祝藏族自治县",
      "level": "3",
      "city_code": "1935",
      "center": "103.141757,36.97174",
      "parent_id": "2954"
    },
    {
      "area_id": "2959",
      "area_code": "620700",
      "area_name": "张掖市",
      "level": "2",
      "city_code": "0936",
      "center": "100.449913,38.925548",
      "parent_id": "2926"
    },
    {
      "area_id": "2960",
      "area_code": "620702",
      "area_name": "甘州区",
      "level": "3",
      "city_code": "0936",
      "center": "100.415096,38.944662",
      "parent_id": "2959"
    },
    {
      "area_id": "2961",
      "area_code": "620721",
      "area_name": "肃南裕固族自治县",
      "level": "3",
      "city_code": "0936",
      "center": "99.615601,38.836931",
      "parent_id": "2959"
    },
    {
      "area_id": "2962",
      "area_code": "620722",
      "area_name": "民乐县",
      "level": "3",
      "city_code": "0936",
      "center": "100.812629,38.430347",
      "parent_id": "2959"
    },
    {
      "area_id": "2963",
      "area_code": "620723",
      "area_name": "临泽县",
      "level": "3",
      "city_code": "0936",
      "center": "100.164283,39.152462",
      "parent_id": "2959"
    },
    {
      "area_id": "2964",
      "area_code": "620724",
      "area_name": "高台县",
      "level": "3",
      "city_code": "0936",
      "center": "99.819519,39.378311",
      "parent_id": "2959"
    },
    {
      "area_id": "2965",
      "area_code": "620725",
      "area_name": "山丹县",
      "level": "3",
      "city_code": "0936",
      "center": "101.088529,38.784505",
      "parent_id": "2959"
    },
    {
      "area_id": "2966",
      "area_code": "620800",
      "area_name": "平凉市",
      "level": "2",
      "city_code": "0933",
      "center": "106.665061,35.542606",
      "parent_id": "2926"
    },
    {
      "area_id": "2967",
      "area_code": "620802",
      "area_name": "崆峒区",
      "level": "3",
      "city_code": "0933",
      "center": "106.674767,35.542491",
      "parent_id": "2966"
    },
    {
      "area_id": "2968",
      "area_code": "620821",
      "area_name": "泾川县",
      "level": "3",
      "city_code": "0933",
      "center": "107.36785,35.332666",
      "parent_id": "2966"
    },
    {
      "area_id": "2969",
      "area_code": "620822",
      "area_name": "灵台县",
      "level": "3",
      "city_code": "0933",
      "center": "107.595874,35.070027",
      "parent_id": "2966"
    },
    {
      "area_id": "2970",
      "area_code": "620823",
      "area_name": "崇信县",
      "level": "3",
      "city_code": "0933",
      "center": "107.025763,35.305596",
      "parent_id": "2966"
    },
    {
      "area_id": "2971",
      "area_code": "620824",
      "area_name": "华亭县",
      "level": "3",
      "city_code": "0933",
      "center": "106.653158,35.218292",
      "parent_id": "2966"
    },
    {
      "area_id": "2972",
      "area_code": "620825",
      "area_name": "庄浪县",
      "level": "3",
      "city_code": "0933",
      "center": "106.036686,35.202385",
      "parent_id": "2966"
    },
    {
      "area_id": "2973",
      "area_code": "620826",
      "area_name": "静宁县",
      "level": "3",
      "city_code": "0933",
      "center": "105.732556,35.521976",
      "parent_id": "2966"
    },
    {
      "area_id": "2974",
      "area_code": "620900",
      "area_name": "酒泉市",
      "level": "2",
      "city_code": "0937",
      "center": "98.493927,39.732795",
      "parent_id": "2926"
    },
    {
      "area_id": "2975",
      "area_code": "620902",
      "area_name": "肃州区",
      "level": "3",
      "city_code": "0937",
      "center": "98.507843,39.744953",
      "parent_id": "2974"
    },
    {
      "area_id": "2976",
      "area_code": "620921",
      "area_name": "金塔县",
      "level": "3",
      "city_code": "0937",
      "center": "98.901252,39.983955",
      "parent_id": "2974"
    },
    {
      "area_id": "2977",
      "area_code": "620922",
      "area_name": "瓜州县",
      "level": "3",
      "city_code": "0937",
      "center": "95.782318,40.520538",
      "parent_id": "2974"
    },
    {
      "area_id": "2978",
      "area_code": "620923",
      "area_name": "肃北蒙古族自治县",
      "level": "3",
      "city_code": "0937",
      "center": "94.876579,39.51245",
      "parent_id": "2974"
    },
    {
      "area_id": "2979",
      "area_code": "620924",
      "area_name": "阿克塞哈萨克族自治县",
      "level": "3",
      "city_code": "0937",
      "center": "94.340204,39.633943",
      "parent_id": "2974"
    },
    {
      "area_id": "2980",
      "area_code": "620981",
      "area_name": "玉门市",
      "level": "3",
      "city_code": "0937",
      "center": "97.045661,40.292106",
      "parent_id": "2974"
    },
    {
      "area_id": "2981",
      "area_code": "620982",
      "area_name": "敦煌市",
      "level": "3",
      "city_code": "0937",
      "center": "94.661941,40.142089",
      "parent_id": "2974"
    },
    {
      "area_id": "2982",
      "area_code": "621000",
      "area_name": "庆阳市",
      "level": "2",
      "city_code": "0934",
      "center": "107.643571,35.70898",
      "parent_id": "2926"
    },
    {
      "area_id": "2983",
      "area_code": "621002",
      "area_name": "西峰区",
      "level": "3",
      "city_code": "0934",
      "center": "107.651077,35.730652",
      "parent_id": "2982"
    },
    {
      "area_id": "2984",
      "area_code": "621021",
      "area_name": "庆城县",
      "level": "3",
      "city_code": "0934",
      "center": "107.881802,36.016299",
      "parent_id": "2982"
    },
    {
      "area_id": "2985",
      "area_code": "621022",
      "area_name": "环县",
      "level": "3",
      "city_code": "0934",
      "center": "107.308501,36.568434",
      "parent_id": "2982"
    },
    {
      "area_id": "2986",
      "area_code": "621023",
      "area_name": "华池县",
      "level": "3",
      "city_code": "0934",
      "center": "107.990062,36.461306",
      "parent_id": "2982"
    },
    {
      "area_id": "2987",
      "area_code": "621024",
      "area_name": "合水县",
      "level": "3",
      "city_code": "0934",
      "center": "108.019554,35.819194",
      "parent_id": "2982"
    },
    {
      "area_id": "2988",
      "area_code": "621025",
      "area_name": "正宁县",
      "level": "3",
      "city_code": "0934",
      "center": "108.359865,35.49178",
      "parent_id": "2982"
    },
    {
      "area_id": "2989",
      "area_code": "621026",
      "area_name": "宁县",
      "level": "3",
      "city_code": "0934",
      "center": "107.928371,35.502176",
      "parent_id": "2982"
    },
    {
      "area_id": "2990",
      "area_code": "621027",
      "area_name": "镇原县",
      "level": "3",
      "city_code": "0934",
      "center": "107.200832,35.677462",
      "parent_id": "2982"
    },
    {
      "area_id": "2991",
      "area_code": "621100",
      "area_name": "定西市",
      "level": "2",
      "city_code": "0932",
      "center": "104.592225,35.606978",
      "parent_id": "2926"
    },
    {
      "area_id": "2992",
      "area_code": "621102",
      "area_name": "安定区",
      "level": "3",
      "city_code": "0932",
      "center": "104.610668,35.580629",
      "parent_id": "2991"
    },
    {
      "area_id": "2993",
      "area_code": "621121",
      "area_name": "通渭县",
      "level": "3",
      "city_code": "0932",
      "center": "105.24206,35.210831",
      "parent_id": "2991"
    },
    {
      "area_id": "2994",
      "area_code": "621122",
      "area_name": "陇西县",
      "level": "3",
      "city_code": "0932",
      "center": "104.634983,35.00394",
      "parent_id": "2991"
    },
    {
      "area_id": "2995",
      "area_code": "621123",
      "area_name": "渭源县",
      "level": "3",
      "city_code": "0932",
      "center": "104.215467,35.136755",
      "parent_id": "2991"
    },
    {
      "area_id": "2996",
      "area_code": "621124",
      "area_name": "临洮县",
      "level": "3",
      "city_code": "0932",
      "center": "103.859565,35.394988",
      "parent_id": "2991"
    },
    {
      "area_id": "2997",
      "area_code": "621125",
      "area_name": "漳县",
      "level": "3",
      "city_code": "0932",
      "center": "104.471572,34.848444",
      "parent_id": "2991"
    },
    {
      "area_id": "2998",
      "area_code": "621126",
      "area_name": "岷县",
      "level": "3",
      "city_code": "0932",
      "center": "104.03688,34.438075",
      "parent_id": "2991"
    },
    {
      "area_id": "2999",
      "area_code": "621200",
      "area_name": "陇南市",
      "level": "2",
      "city_code": "2935",
      "center": "104.960851,33.37068",
      "parent_id": "2926"
    },
    {
      "area_id": "3000",
      "area_code": "621202",
      "area_name": "武都区",
      "level": "3",
      "city_code": "2935",
      "center": "104.926337,33.392211",
      "parent_id": "2999"
    },
    {
      "area_id": "3001",
      "area_code": "621221",
      "area_name": "成县",
      "level": "3",
      "city_code": "2935",
      "center": "105.742424,33.75061",
      "parent_id": "2999"
    },
    {
      "area_id": "3002",
      "area_code": "621222",
      "area_name": "文县",
      "level": "3",
      "city_code": "2935",
      "center": "104.683433,32.943815",
      "parent_id": "2999"
    },
    {
      "area_id": "3003",
      "area_code": "621223",
      "area_name": "宕昌县",
      "level": "3",
      "city_code": "2935",
      "center": "104.393385,34.047261",
      "parent_id": "2999"
    },
    {
      "area_id": "3004",
      "area_code": "621224",
      "area_name": "康县",
      "level": "3",
      "city_code": "2935",
      "center": "105.609169,33.329136",
      "parent_id": "2999"
    },
    {
      "area_id": "3005",
      "area_code": "621225",
      "area_name": "西和县",
      "level": "3",
      "city_code": "2935",
      "center": "105.298756,34.014215",
      "parent_id": "2999"
    },
    {
      "area_id": "3006",
      "area_code": "621226",
      "area_name": "礼县",
      "level": "3",
      "city_code": "2935",
      "center": "105.17864,34.189345",
      "parent_id": "2999"
    },
    {
      "area_id": "3007",
      "area_code": "621227",
      "area_name": "徽县",
      "level": "3",
      "city_code": "2935",
      "center": "106.08778,33.768826",
      "parent_id": "2999"
    },
    {
      "area_id": "3008",
      "area_code": "621228",
      "area_name": "两当县",
      "level": "3",
      "city_code": "2935",
      "center": "106.304966,33.908917",
      "parent_id": "2999"
    },
    {
      "area_id": "3009",
      "area_code": "622900",
      "area_name": "临夏回族自治州",
      "level": "2",
      "city_code": "0930",
      "center": "103.210655,35.601352",
      "parent_id": "2926"
    },
    {
      "area_id": "3010",
      "area_code": "622901",
      "area_name": "临夏市",
      "level": "3",
      "city_code": "0930",
      "center": "103.243021,35.604376",
      "parent_id": "3009"
    },
    {
      "area_id": "3011",
      "area_code": "622921",
      "area_name": "临夏县",
      "level": "3",
      "city_code": "0930",
      "center": "103.039826,35.478722",
      "parent_id": "3009"
    },
    {
      "area_id": "3012",
      "area_code": "622922",
      "area_name": "康乐县",
      "level": "3",
      "city_code": "0930",
      "center": "103.708354,35.370505",
      "parent_id": "3009"
    },
    {
      "area_id": "3013",
      "area_code": "622923",
      "area_name": "永靖县",
      "level": "3",
      "city_code": "0930",
      "center": "103.285853,35.958306",
      "parent_id": "3009"
    },
    {
      "area_id": "3014",
      "area_code": "622924",
      "area_name": "广河县",
      "level": "3",
      "city_code": "0930",
      "center": "103.575834,35.488051",
      "parent_id": "3009"
    },
    {
      "area_id": "3015",
      "area_code": "622925",
      "area_name": "和政县",
      "level": "3",
      "city_code": "0930",
      "center": "103.350997,35.424603",
      "parent_id": "3009"
    },
    {
      "area_id": "3016",
      "area_code": "622926",
      "area_name": "东乡族自治县",
      "level": "3",
      "city_code": "0930",
      "center": "103.389346,35.663752",
      "parent_id": "3009"
    },
    {
      "area_id": "3017",
      "area_code": "622927",
      "area_name": "积石山保安族东乡族撒拉族自治县",
      "level": "3",
      "city_code": "0930",
      "center": "102.875843,35.71766",
      "parent_id": "3009"
    },
    {
      "area_id": "3018",
      "area_code": "623000",
      "area_name": "甘南藏族自治州",
      "level": "2",
      "city_code": "0941",
      "center": "102.910995,34.983409",
      "parent_id": "2926"
    },
    {
      "area_id": "3019",
      "area_code": "623001",
      "area_name": "合作市",
      "level": "3",
      "city_code": "0941",
      "center": "102.910484,35.000286",
      "parent_id": "3018"
    },
    {
      "area_id": "3020",
      "area_code": "623021",
      "area_name": "临潭县",
      "level": "3",
      "city_code": "0941",
      "center": "103.353919,34.692747",
      "parent_id": "3018"
    },
    {
      "area_id": "3021",
      "area_code": "623022",
      "area_name": "卓尼县",
      "level": "3",
      "city_code": "0941",
      "center": "103.507109,34.589588",
      "parent_id": "3018"
    },
    {
      "area_id": "3022",
      "area_code": "623023",
      "area_name": "舟曲县",
      "level": "3",
      "city_code": "0941",
      "center": "104.251482,33.793631",
      "parent_id": "3018"
    },
    {
      "area_id": "3023",
      "area_code": "623024",
      "area_name": "迭部县",
      "level": "3",
      "city_code": "0941",
      "center": "103.221869,34.055938",
      "parent_id": "3018"
    },
    {
      "area_id": "3024",
      "area_code": "623025",
      "area_name": "玛曲县",
      "level": "3",
      "city_code": "0941",
      "center": "102.072698,33.997712",
      "parent_id": "3018"
    },
    {
      "area_id": "3025",
      "area_code": "623026",
      "area_name": "碌曲县",
      "level": "3",
      "city_code": "0941",
      "center": "102.487327,34.590944",
      "parent_id": "3018"
    },
    {
      "area_id": "3026",
      "area_code": "623027",
      "area_name": "夏河县",
      "level": "3",
      "city_code": "0941",
      "center": "102.521807,35.202503",
      "parent_id": "3018"
    },
    {
      "area_id": "3027",
      "area_code": "630000",
      "area_name": "青海省",
      "level": "1",
      "city_code": "[]",
      "center": "101.780268,36.620939",
      "parent_id": "0"
    },
    {
      "area_id": "3028",
      "area_code": "630100",
      "area_name": "西宁市",
      "level": "2",
      "city_code": "0971",
      "center": "101.778223,36.617134",
      "parent_id": "3027"
    },
    {
      "area_id": "3029",
      "area_code": "630102",
      "area_name": "城东区",
      "level": "3",
      "city_code": "0971",
      "center": "101.803717,36.599744",
      "parent_id": "3028"
    },
    {
      "area_id": "3030",
      "area_code": "630103",
      "area_name": "城中区",
      "level": "3",
      "city_code": "0971",
      "center": "101.705298,36.545652",
      "parent_id": "3028"
    },
    {
      "area_id": "3031",
      "area_code": "630104",
      "area_name": "城西区",
      "level": "3",
      "city_code": "0971",
      "center": "101.765843,36.628304",
      "parent_id": "3028"
    },
    {
      "area_id": "3032",
      "area_code": "630105",
      "area_name": "城北区",
      "level": "3",
      "city_code": "0971",
      "center": "101.766228,36.650038",
      "parent_id": "3028"
    },
    {
      "area_id": "3033",
      "area_code": "630121",
      "area_name": "大通回族土族自治县",
      "level": "3",
      "city_code": "0971",
      "center": "101.685643,36.926954",
      "parent_id": "3028"
    },
    {
      "area_id": "3034",
      "area_code": "630122",
      "area_name": "湟中县",
      "level": "3",
      "city_code": "0971",
      "center": "101.571667,36.500879",
      "parent_id": "3028"
    },
    {
      "area_id": "3035",
      "area_code": "630123",
      "area_name": "湟源县",
      "level": "3",
      "city_code": "0971",
      "center": "101.256464,36.682426",
      "parent_id": "3028"
    },
    {
      "area_id": "3036",
      "area_code": "630200",
      "area_name": "海东市",
      "level": "2",
      "city_code": "0972",
      "center": "102.104287,36.502039",
      "parent_id": "3027"
    },
    {
      "area_id": "3037",
      "area_code": "630202",
      "area_name": "乐都区",
      "level": "3",
      "city_code": "0972",
      "center": "102.401724,36.482058",
      "parent_id": "3036"
    },
    {
      "area_id": "3038",
      "area_code": "630203",
      "area_name": "平安区",
      "level": "3",
      "city_code": "0972",
      "center": "102.108834,36.500563",
      "parent_id": "3036"
    },
    {
      "area_id": "3039",
      "area_code": "630222",
      "area_name": "民和回族土族自治县",
      "level": "3",
      "city_code": "0972",
      "center": "102.830892,36.320321",
      "parent_id": "3036"
    },
    {
      "area_id": "3040",
      "area_code": "630223",
      "area_name": "互助土族自治县",
      "level": "3",
      "city_code": "0972",
      "center": "101.959271,36.844248",
      "parent_id": "3036"
    },
    {
      "area_id": "3041",
      "area_code": "630224",
      "area_name": "化隆回族自治县",
      "level": "3",
      "city_code": "0972",
      "center": "102.264143,36.094908",
      "parent_id": "3036"
    },
    {
      "area_id": "3042",
      "area_code": "630225",
      "area_name": "循化撒拉族自治县",
      "level": "3",
      "city_code": "0972",
      "center": "102.489135,35.851152",
      "parent_id": "3036"
    },
    {
      "area_id": "3043",
      "area_code": "632200",
      "area_name": "海北藏族自治州",
      "level": "2",
      "city_code": "0970",
      "center": "100.900997,36.954413",
      "parent_id": "3027"
    },
    {
      "area_id": "3044",
      "area_code": "632221",
      "area_name": "门源回族自治县",
      "level": "3",
      "city_code": "0970",
      "center": "101.611539,37.388746",
      "parent_id": "3043"
    },
    {
      "area_id": "3045",
      "area_code": "632222",
      "area_name": "祁连县",
      "level": "3",
      "city_code": "0970",
      "center": "100.253211,38.177112",
      "parent_id": "3043"
    },
    {
      "area_id": "3046",
      "area_code": "632223",
      "area_name": "海晏县",
      "level": "3",
      "city_code": "0970",
      "center": "100.99426,36.896359",
      "parent_id": "3043"
    },
    {
      "area_id": "3047",
      "area_code": "632224",
      "area_name": "刚察县",
      "level": "3",
      "city_code": "0970",
      "center": "100.145833,37.32547",
      "parent_id": "3043"
    },
    {
      "area_id": "3048",
      "area_code": "632300",
      "area_name": "黄南藏族自治州",
      "level": "2",
      "city_code": "0973",
      "center": "102.015248,35.519548",
      "parent_id": "3027"
    },
    {
      "area_id": "3049",
      "area_code": "632321",
      "area_name": "同仁县",
      "level": "3",
      "city_code": "0973",
      "center": "102.018323,35.516063",
      "parent_id": "3048"
    },
    {
      "area_id": "3050",
      "area_code": "632322",
      "area_name": "尖扎县",
      "level": "3",
      "city_code": "0973",
      "center": "102.04014,35.943156",
      "parent_id": "3048"
    },
    {
      "area_id": "3051",
      "area_code": "632323",
      "area_name": "泽库县",
      "level": "3",
      "city_code": "0973",
      "center": "101.466689,35.035313",
      "parent_id": "3048"
    },
    {
      "area_id": "3052",
      "area_code": "632324",
      "area_name": "河南蒙古族自治县",
      "level": "3",
      "city_code": "0973",
      "center": "101.617503,34.734568",
      "parent_id": "3048"
    },
    {
      "area_id": "3053",
      "area_code": "632500",
      "area_name": "海南藏族自治州",
      "level": "2",
      "city_code": "0974",
      "center": "100.622692,36.296529",
      "parent_id": "3027"
    },
    {
      "area_id": "3054",
      "area_code": "632521",
      "area_name": "共和县",
      "level": "3",
      "city_code": "0974",
      "center": "100.620031,36.284107",
      "parent_id": "3053"
    },
    {
      "area_id": "3055",
      "area_code": "632522",
      "area_name": "同德县",
      "level": "3",
      "city_code": "0974",
      "center": "100.578051,35.25479",
      "parent_id": "3053"
    },
    {
      "area_id": "3056",
      "area_code": "632523",
      "area_name": "贵德县",
      "level": "3",
      "city_code": "0974",
      "center": "101.433391,36.040166",
      "parent_id": "3053"
    },
    {
      "area_id": "3057",
      "area_code": "632524",
      "area_name": "兴海县",
      "level": "3",
      "city_code": "0974",
      "center": "99.987965,35.588612",
      "parent_id": "3053"
    },
    {
      "area_id": "3058",
      "area_code": "632525",
      "area_name": "贵南县",
      "level": "3",
      "city_code": "0974",
      "center": "100.747503,35.586714",
      "parent_id": "3053"
    },
    {
      "area_id": "3059",
      "area_code": "632600",
      "area_name": "果洛藏族自治州",
      "level": "2",
      "city_code": "0975",
      "center": "100.244808,34.471431",
      "parent_id": "3027"
    },
    {
      "area_id": "3060",
      "area_code": "632621",
      "area_name": "玛沁县",
      "level": "3",
      "city_code": "0975",
      "center": "100.238888,34.477433",
      "parent_id": "3059"
    },
    {
      "area_id": "3061",
      "area_code": "632622",
      "area_name": "班玛县",
      "level": "3",
      "city_code": "0975",
      "center": "100.737138,32.932723",
      "parent_id": "3059"
    },
    {
      "area_id": "3062",
      "area_code": "632623",
      "area_name": "甘德县",
      "level": "3",
      "city_code": "0975",
      "center": "99.900923,33.969216",
      "parent_id": "3059"
    },
    {
      "area_id": "3063",
      "area_code": "632624",
      "area_name": "达日县",
      "level": "3",
      "city_code": "0975",
      "center": "99.651392,33.74892",
      "parent_id": "3059"
    },
    {
      "area_id": "3064",
      "area_code": "632625",
      "area_name": "久治县",
      "level": "3",
      "city_code": "0975",
      "center": "101.482831,33.429471",
      "parent_id": "3059"
    },
    {
      "area_id": "3065",
      "area_code": "632626",
      "area_name": "玛多县",
      "level": "3",
      "city_code": "0975",
      "center": "98.209206,34.915946",
      "parent_id": "3059"
    },
    {
      "area_id": "3066",
      "area_code": "632700",
      "area_name": "玉树藏族自治州",
      "level": "2",
      "city_code": "0976",
      "center": "97.091934,33.011674",
      "parent_id": "3027"
    },
    {
      "area_id": "3067",
      "area_code": "632701",
      "area_name": "玉树市",
      "level": "3",
      "city_code": "0976",
      "center": "97.008784,32.993106",
      "parent_id": "3066"
    },
    {
      "area_id": "3068",
      "area_code": "632722",
      "area_name": "杂多县",
      "level": "3",
      "city_code": "0976",
      "center": "95.300723,32.893185",
      "parent_id": "3066"
    },
    {
      "area_id": "3069",
      "area_code": "632723",
      "area_name": "称多县",
      "level": "3",
      "city_code": "0976",
      "center": "97.110831,33.369218",
      "parent_id": "3066"
    },
    {
      "area_id": "3070",
      "area_code": "632724",
      "area_name": "治多县",
      "level": "3",
      "city_code": "0976",
      "center": "95.61896,33.844956",
      "parent_id": "3066"
    },
    {
      "area_id": "3071",
      "area_code": "632725",
      "area_name": "囊谦县",
      "level": "3",
      "city_code": "0976",
      "center": "96.48943,32.203432",
      "parent_id": "3066"
    },
    {
      "area_id": "3072",
      "area_code": "632726",
      "area_name": "曲麻莱县",
      "level": "3",
      "city_code": "0976",
      "center": "95.797367,34.126428",
      "parent_id": "3066"
    },
    {
      "area_id": "3073",
      "area_code": "632800",
      "area_name": "海西蒙古族藏族自治州",
      "level": "2",
      "city_code": "0977",
      "center": "97.369751,37.377139",
      "parent_id": "3027"
    },
    {
      "area_id": "3074",
      "area_code": "632801",
      "area_name": "格尔木市",
      "level": "3",
      "city_code": "0977",
      "center": "94.928453,36.406367",
      "parent_id": "3073"
    },
    {
      "area_id": "3075",
      "area_code": "632802",
      "area_name": "德令哈市",
      "level": "3",
      "city_code": "0977",
      "center": "97.360984,37.369436",
      "parent_id": "3073"
    },
    {
      "area_id": "3076",
      "area_code": "632821",
      "area_name": "乌兰县",
      "level": "3",
      "city_code": "0977",
      "center": "98.480195,36.929749",
      "parent_id": "3073"
    },
    {
      "area_id": "3077",
      "area_code": "632822",
      "area_name": "都兰县",
      "level": "3",
      "city_code": "0977",
      "center": "98.095844,36.302496",
      "parent_id": "3073"
    },
    {
      "area_id": "3078",
      "area_code": "632823",
      "area_name": "天峻县",
      "level": "3",
      "city_code": "0977",
      "center": "99.022984,37.300851",
      "parent_id": "3073"
    },
    {
      "area_id": "3079",
      "area_code": "632825",
      "area_name": "海西蒙古族藏族自治州直辖",
      "level": "3",
      "city_code": "0977",
      "center": "95.356546,37.853328",
      "parent_id": "3073"
    },
    {
      "area_id": "3080",
      "area_code": "640000",
      "area_name": "宁夏回族自治区",
      "level": "1",
      "city_code": "[]",
      "center": "106.259126,38.472641",
      "parent_id": "0"
    },
    {
      "area_id": "3081",
      "area_code": "640100",
      "area_name": "银川市",
      "level": "2",
      "city_code": "0951",
      "center": "106.230909,38.487193",
      "parent_id": "3080"
    },
    {
      "area_id": "3082",
      "area_code": "640104",
      "area_name": "兴庆区",
      "level": "3",
      "city_code": "0951",
      "center": "106.28865,38.473609",
      "parent_id": "3081"
    },
    {
      "area_id": "3083",
      "area_code": "640105",
      "area_name": "西夏区",
      "level": "3",
      "city_code": "0951",
      "center": "106.161106,38.502605",
      "parent_id": "3081"
    },
    {
      "area_id": "3084",
      "area_code": "640106",
      "area_name": "金凤区",
      "level": "3",
      "city_code": "0951",
      "center": "106.239679,38.47436",
      "parent_id": "3081"
    },
    {
      "area_id": "3085",
      "area_code": "640121",
      "area_name": "永宁县",
      "level": "3",
      "city_code": "0951",
      "center": "106.253145,38.277372",
      "parent_id": "3081"
    },
    {
      "area_id": "3086",
      "area_code": "640122",
      "area_name": "贺兰县",
      "level": "3",
      "city_code": "0951",
      "center": "106.349861,38.554599",
      "parent_id": "3081"
    },
    {
      "area_id": "3087",
      "area_code": "640181",
      "area_name": "灵武市",
      "level": "3",
      "city_code": "0951",
      "center": "106.340053,38.102655",
      "parent_id": "3081"
    },
    {
      "area_id": "3088",
      "area_code": "640200",
      "area_name": "石嘴山市",
      "level": "2",
      "city_code": "0952",
      "center": "106.383303,38.983236",
      "parent_id": "3080"
    },
    {
      "area_id": "3089",
      "area_code": "640202",
      "area_name": "大武口区",
      "level": "3",
      "city_code": "0952",
      "center": "106.367958,39.01918",
      "parent_id": "3088"
    },
    {
      "area_id": "3090",
      "area_code": "640205",
      "area_name": "惠农区",
      "level": "3",
      "city_code": "0952",
      "center": "106.781176,39.239302",
      "parent_id": "3088"
    },
    {
      "area_id": "3091",
      "area_code": "640221",
      "area_name": "平罗县",
      "level": "3",
      "city_code": "0952",
      "center": "106.523474,38.913544",
      "parent_id": "3088"
    },
    {
      "area_id": "3092",
      "area_code": "640300",
      "area_name": "吴忠市",
      "level": "2",
      "city_code": "0953",
      "center": "106.198913,37.997428",
      "parent_id": "3080"
    },
    {
      "area_id": "3093",
      "area_code": "640302",
      "area_name": "利通区",
      "level": "3",
      "city_code": "0953",
      "center": "106.212613,37.98349",
      "parent_id": "3092"
    },
    {
      "area_id": "3094",
      "area_code": "640303",
      "area_name": "红寺堡区",
      "level": "3",
      "city_code": "0953",
      "center": "106.062113,37.425702",
      "parent_id": "3092"
    },
    {
      "area_id": "3095",
      "area_code": "640323",
      "area_name": "盐池县",
      "level": "3",
      "city_code": "0953",
      "center": "107.407358,37.783205",
      "parent_id": "3092"
    },
    {
      "area_id": "3096",
      "area_code": "640324",
      "area_name": "同心县",
      "level": "3",
      "city_code": "0953",
      "center": "105.895309,36.95449",
      "parent_id": "3092"
    },
    {
      "area_id": "3097",
      "area_code": "640381",
      "area_name": "青铜峡市",
      "level": "3",
      "city_code": "0953",
      "center": "106.078817,38.021302",
      "parent_id": "3092"
    },
    {
      "area_id": "3098",
      "area_code": "640400",
      "area_name": "固原市",
      "level": "2",
      "city_code": "0954",
      "center": "106.24261,36.015855",
      "parent_id": "3080"
    },
    {
      "area_id": "3099",
      "area_code": "640402",
      "area_name": "原州区",
      "level": "3",
      "city_code": "0954",
      "center": "106.287781,36.003739",
      "parent_id": "3098"
    },
    {
      "area_id": "3100",
      "area_code": "640422",
      "area_name": "西吉县",
      "level": "3",
      "city_code": "0954",
      "center": "105.729085,35.963912",
      "parent_id": "3098"
    },
    {
      "area_id": "3101",
      "area_code": "640423",
      "area_name": "隆德县",
      "level": "3",
      "city_code": "0954",
      "center": "106.111595,35.625914",
      "parent_id": "3098"
    },
    {
      "area_id": "3102",
      "area_code": "640424",
      "area_name": "泾源县",
      "level": "3",
      "city_code": "0954",
      "center": "106.330646,35.498159",
      "parent_id": "3098"
    },
    {
      "area_id": "3103",
      "area_code": "640425",
      "area_name": "彭阳县",
      "level": "3",
      "city_code": "0954",
      "center": "106.631809,35.858815",
      "parent_id": "3098"
    },
    {
      "area_id": "3104",
      "area_code": "640500",
      "area_name": "中卫市",
      "level": "2",
      "city_code": "1953",
      "center": "105.196902,37.499972",
      "parent_id": "3080"
    },
    {
      "area_id": "3105",
      "area_code": "640502",
      "area_name": "沙坡头区",
      "level": "3",
      "city_code": "1953",
      "center": "105.173721,37.516883",
      "parent_id": "3104"
    },
    {
      "area_id": "3106",
      "area_code": "640521",
      "area_name": "中宁县",
      "level": "3",
      "city_code": "1953",
      "center": "105.685218,37.491546",
      "parent_id": "3104"
    },
    {
      "area_id": "3107",
      "area_code": "640522",
      "area_name": "海原县",
      "level": "3",
      "city_code": "1953",
      "center": "105.643487,36.565033",
      "parent_id": "3104"
    },
    {
      "area_id": "3108",
      "area_code": "650000",
      "area_name": "新疆维吾尔自治区",
      "level": "1",
      "city_code": "[]",
      "center": "87.627704,43.793026",
      "parent_id": "0"
    },
    {
      "area_id": "3109",
      "area_code": "659002",
      "area_name": "阿拉尔市",
      "level": "2",
      "city_code": "1997",
      "center": "81.280527,40.547653",
      "parent_id": "3108"
    },
    {
      "area_id": "3110",
      "area_code": "659005",
      "area_name": "北屯市",
      "level": "2",
      "city_code": "1906",
      "center": "87.837075,47.332643",
      "parent_id": "3108"
    },
    {
      "area_id": "3111",
      "area_code": "659008",
      "area_name": "可克达拉市",
      "level": "2",
      "city_code": "1999",
      "center": "81.044542,43.944798",
      "parent_id": "3108"
    },
    {
      "area_id": "3112",
      "area_code": "659009",
      "area_name": "昆玉市",
      "level": "2",
      "city_code": "1903",
      "center": "79.291083,37.209642",
      "parent_id": "3108"
    },
    {
      "area_id": "3113",
      "area_code": "659001",
      "area_name": "石河子市",
      "level": "2",
      "city_code": "0993",
      "center": "86.080602,44.306097",
      "parent_id": "3108"
    },
    {
      "area_id": "3114",
      "area_code": "659007",
      "area_name": "双河市",
      "level": "2",
      "city_code": "1909",
      "center": "82.353656,44.840524",
      "parent_id": "3108"
    },
    {
      "area_id": "3115",
      "area_code": "650100",
      "area_name": "乌鲁木齐市",
      "level": "2",
      "city_code": "0991",
      "center": "87.616848,43.825592",
      "parent_id": "3108"
    },
    {
      "area_id": "3116",
      "area_code": "650102",
      "area_name": "天山区",
      "level": "3",
      "city_code": "0991",
      "center": "87.631676,43.794399",
      "parent_id": "3115"
    },
    {
      "area_id": "3117",
      "area_code": "650103",
      "area_name": "沙依巴克区",
      "level": "3",
      "city_code": "0991",
      "center": "87.598195,43.800939",
      "parent_id": "3115"
    },
    {
      "area_id": "3118",
      "area_code": "650104",
      "area_name": "新市区",
      "level": "3",
      "city_code": "0991",
      "center": "87.569431,43.855378",
      "parent_id": "3115"
    },
    {
      "area_id": "3119",
      "area_code": "650105",
      "area_name": "水磨沟区",
      "level": "3",
      "city_code": "0991",
      "center": "87.642481,43.832459",
      "parent_id": "3115"
    },
    {
      "area_id": "3120",
      "area_code": "650106",
      "area_name": "头屯河区",
      "level": "3",
      "city_code": "0991",
      "center": "87.428141,43.877664",
      "parent_id": "3115"
    },
    {
      "area_id": "3121",
      "area_code": "650107",
      "area_name": "达坂城区",
      "level": "3",
      "city_code": "0991",
      "center": "88.311099,43.363668",
      "parent_id": "3115"
    },
    {
      "area_id": "3122",
      "area_code": "650109",
      "area_name": "米东区",
      "level": "3",
      "city_code": "0991",
      "center": "87.655935,43.974784",
      "parent_id": "3115"
    },
    {
      "area_id": "3123",
      "area_code": "650121",
      "area_name": "乌鲁木齐县",
      "level": "3",
      "city_code": "0991",
      "center": "87.409417,43.47136",
      "parent_id": "3115"
    },
    {
      "area_id": "3124",
      "area_code": "650200",
      "area_name": "克拉玛依市",
      "level": "2",
      "city_code": "0990",
      "center": "84.889207,45.579888",
      "parent_id": "3108"
    },
    {
      "area_id": "3125",
      "area_code": "650202",
      "area_name": "独山子区",
      "level": "3",
      "city_code": "0990",
      "center": "84.886974,44.328095",
      "parent_id": "3124"
    },
    {
      "area_id": "3126",
      "area_code": "650203",
      "area_name": "克拉玛依区",
      "level": "3",
      "city_code": "0990",
      "center": "84.867844,45.602525",
      "parent_id": "3124"
    },
    {
      "area_id": "3127",
      "area_code": "650204",
      "area_name": "白碱滩区",
      "level": "3",
      "city_code": "0990",
      "center": "85.131696,45.687854",
      "parent_id": "3124"
    },
    {
      "area_id": "3128",
      "area_code": "650205",
      "area_name": "乌尔禾区",
      "level": "3",
      "city_code": "0990",
      "center": "85.693742,46.089148",
      "parent_id": "3124"
    },
    {
      "area_id": "3129",
      "area_code": "650400",
      "area_name": "吐鲁番市",
      "level": "2",
      "city_code": "0995",
      "center": "89.189752,42.951303",
      "parent_id": "3108"
    },
    {
      "area_id": "3130",
      "area_code": "650402",
      "area_name": "高昌区",
      "level": "3",
      "city_code": "0995",
      "center": "89.185877,42.942327",
      "parent_id": "3129"
    },
    {
      "area_id": "3131",
      "area_code": "650421",
      "area_name": "鄯善县",
      "level": "3",
      "city_code": "0995",
      "center": "90.21333,42.868744",
      "parent_id": "3129"
    },
    {
      "area_id": "3132",
      "area_code": "650422",
      "area_name": "托克逊县",
      "level": "3",
      "city_code": "0995",
      "center": "88.653827,42.792526",
      "parent_id": "3129"
    },
    {
      "area_id": "3133",
      "area_code": "650500",
      "area_name": "哈密市",
      "level": "2",
      "city_code": "0902",
      "center": "93.515224,42.819541",
      "parent_id": "3108"
    },
    {
      "area_id": "3134",
      "area_code": "650502",
      "area_name": "伊州区",
      "level": "3",
      "city_code": "0902",
      "center": "93.514797,42.827254",
      "parent_id": "3133"
    },
    {
      "area_id": "3135",
      "area_code": "650521",
      "area_name": "巴里坤哈萨克自治县",
      "level": "3",
      "city_code": "0902",
      "center": "93.010383,43.599929",
      "parent_id": "3133"
    },
    {
      "area_id": "3136",
      "area_code": "650522",
      "area_name": "伊吾县",
      "level": "3",
      "city_code": "0902",
      "center": "94.697074,43.254978",
      "parent_id": "3133"
    },
    {
      "area_id": "3137",
      "area_code": "652300",
      "area_name": "昌吉回族自治州",
      "level": "2",
      "city_code": "0994",
      "center": "87.308224,44.011182",
      "parent_id": "3108"
    },
    {
      "area_id": "3138",
      "area_code": "652301",
      "area_name": "昌吉市",
      "level": "3",
      "city_code": "0994",
      "center": "87.267532,44.014435",
      "parent_id": "3137"
    },
    {
      "area_id": "3139",
      "area_code": "652302",
      "area_name": "阜康市",
      "level": "3",
      "city_code": "0994",
      "center": "87.952991,44.164402",
      "parent_id": "3137"
    },
    {
      "area_id": "3140",
      "area_code": "652323",
      "area_name": "呼图壁县",
      "level": "3",
      "city_code": "0994",
      "center": "86.871584,44.179361",
      "parent_id": "3137"
    },
    {
      "area_id": "3141",
      "area_code": "652324",
      "area_name": "玛纳斯县",
      "level": "3",
      "city_code": "0994",
      "center": "86.20368,44.284722",
      "parent_id": "3137"
    },
    {
      "area_id": "3142",
      "area_code": "652325",
      "area_name": "奇台县",
      "level": "3",
      "city_code": "0994",
      "center": "89.593967,44.022066",
      "parent_id": "3137"
    },
    {
      "area_id": "3143",
      "area_code": "652327",
      "area_name": "吉木萨尔县",
      "level": "3",
      "city_code": "0994",
      "center": "89.180437,44.000497",
      "parent_id": "3137"
    },
    {
      "area_id": "3144",
      "area_code": "652328",
      "area_name": "木垒哈萨克自治县",
      "level": "3",
      "city_code": "0994",
      "center": "90.286028,43.834689",
      "parent_id": "3137"
    },
    {
      "area_id": "3145",
      "area_code": "652700",
      "area_name": "博尔塔拉蒙古自治州",
      "level": "2",
      "city_code": "0909",
      "center": "82.066363,44.906039",
      "parent_id": "3108"
    },
    {
      "area_id": "3146",
      "area_code": "652701",
      "area_name": "博乐市",
      "level": "3",
      "city_code": "0909",
      "center": "82.051004,44.853869",
      "parent_id": "3145"
    },
    {
      "area_id": "3147",
      "area_code": "652702",
      "area_name": "阿拉山口市",
      "level": "3",
      "city_code": "0909",
      "center": "82.559396,45.172227",
      "parent_id": "3145"
    },
    {
      "area_id": "3148",
      "area_code": "652722",
      "area_name": "精河县",
      "level": "3",
      "city_code": "0909",
      "center": "82.890656,44.599393",
      "parent_id": "3145"
    },
    {
      "area_id": "3149",
      "area_code": "652723",
      "area_name": "温泉县",
      "level": "3",
      "city_code": "0909",
      "center": "81.024816,44.968856",
      "parent_id": "3145"
    },
    {
      "area_id": "3150",
      "area_code": "652800",
      "area_name": "巴音郭楞蒙古自治州",
      "level": "2",
      "city_code": "0996",
      "center": "86.145297,41.764115",
      "parent_id": "3108"
    },
    {
      "area_id": "3151",
      "area_code": "652801",
      "area_name": "库尔勒市",
      "level": "3",
      "city_code": "0996",
      "center": "86.174633,41.725891",
      "parent_id": "3150"
    },
    {
      "area_id": "3152",
      "area_code": "652822",
      "area_name": "轮台县",
      "level": "3",
      "city_code": "0996",
      "center": "84.252156,41.777702",
      "parent_id": "3150"
    },
    {
      "area_id": "3153",
      "area_code": "652823",
      "area_name": "尉犁县",
      "level": "3",
      "city_code": "0996",
      "center": "86.261321,41.343933",
      "parent_id": "3150"
    },
    {
      "area_id": "3154",
      "area_code": "652824",
      "area_name": "若羌县",
      "level": "3",
      "city_code": "0996",
      "center": "88.167152,39.023241",
      "parent_id": "3150"
    },
    {
      "area_id": "3155",
      "area_code": "652825",
      "area_name": "且末县",
      "level": "3",
      "city_code": "0996",
      "center": "85.529702,38.145485",
      "parent_id": "3150"
    },
    {
      "area_id": "3156",
      "area_code": "652826",
      "area_name": "焉耆回族自治县",
      "level": "3",
      "city_code": "0996",
      "center": "86.574067,42.059759",
      "parent_id": "3150"
    },
    {
      "area_id": "3157",
      "area_code": "652827",
      "area_name": "和静县",
      "level": "3",
      "city_code": "0996",
      "center": "86.384065,42.323625",
      "parent_id": "3150"
    },
    {
      "area_id": "3158",
      "area_code": "652828",
      "area_name": "和硕县",
      "level": "3",
      "city_code": "0996",
      "center": "86.876799,42.284331",
      "parent_id": "3150"
    },
    {
      "area_id": "3159",
      "area_code": "652829",
      "area_name": "博湖县",
      "level": "3",
      "city_code": "0996",
      "center": "86.631997,41.980152",
      "parent_id": "3150"
    },
    {
      "area_id": "3160",
      "area_code": "652900",
      "area_name": "阿克苏地区",
      "level": "2",
      "city_code": "0997",
      "center": "80.260605,41.168779",
      "parent_id": "3108"
    },
    {
      "area_id": "3161",
      "area_code": "652901",
      "area_name": "阿克苏市",
      "level": "3",
      "city_code": "0997",
      "center": "80.263387,41.167548",
      "parent_id": "3160"
    },
    {
      "area_id": "3162",
      "area_code": "652922",
      "area_name": "温宿县",
      "level": "3",
      "city_code": "0997",
      "center": "80.238959,41.276688",
      "parent_id": "3160"
    },
    {
      "area_id": "3163",
      "area_code": "652923",
      "area_name": "库车县",
      "level": "3",
      "city_code": "0997",
      "center": "82.987312,41.714696",
      "parent_id": "3160"
    },
    {
      "area_id": "3164",
      "area_code": "652924",
      "area_name": "沙雅县",
      "level": "3",
      "city_code": "0997",
      "center": "82.781818,41.221666",
      "parent_id": "3160"
    },
    {
      "area_id": "3165",
      "area_code": "652925",
      "area_name": "新和县",
      "level": "3",
      "city_code": "0997",
      "center": "82.618736,41.551206",
      "parent_id": "3160"
    },
    {
      "area_id": "3166",
      "area_code": "652926",
      "area_name": "拜城县",
      "level": "3",
      "city_code": "0997",
      "center": "81.85148,41.795912",
      "parent_id": "3160"
    },
    {
      "area_id": "3167",
      "area_code": "652927",
      "area_name": "乌什县",
      "level": "3",
      "city_code": "0997",
      "center": "79.224616,41.222319",
      "parent_id": "3160"
    },
    {
      "area_id": "3168",
      "area_code": "652928",
      "area_name": "阿瓦提县",
      "level": "3",
      "city_code": "0997",
      "center": "80.375053,40.643647",
      "parent_id": "3160"
    },
    {
      "area_id": "3169",
      "area_code": "652929",
      "area_name": "柯坪县",
      "level": "3",
      "city_code": "0997",
      "center": "79.054497,40.501936",
      "parent_id": "3160"
    },
    {
      "area_id": "3170",
      "area_code": "653000",
      "area_name": "克孜勒苏柯尔克孜自治州",
      "level": "2",
      "city_code": "0908",
      "center": "76.167819,39.714526",
      "parent_id": "3108"
    },
    {
      "area_id": "3171",
      "area_code": "653001",
      "area_name": "阿图什市",
      "level": "3",
      "city_code": "0908",
      "center": "76.1684,39.71616",
      "parent_id": "3170"
    },
    {
      "area_id": "3172",
      "area_code": "653022",
      "area_name": "阿克陶县",
      "level": "3",
      "city_code": "0908",
      "center": "75.947396,39.147785",
      "parent_id": "3170"
    },
    {
      "area_id": "3173",
      "area_code": "653023",
      "area_name": "阿合奇县",
      "level": "3",
      "city_code": "0908",
      "center": "78.446253,40.936936",
      "parent_id": "3170"
    },
    {
      "area_id": "3174",
      "area_code": "653024",
      "area_name": "乌恰县",
      "level": "3",
      "city_code": "0908",
      "center": "75.259227,39.71931",
      "parent_id": "3170"
    },
    {
      "area_id": "3175",
      "area_code": "653100",
      "area_name": "喀什地区",
      "level": "2",
      "city_code": "0998",
      "center": "75.989741,39.47046",
      "parent_id": "3108"
    },
    {
      "area_id": "3176",
      "area_code": "653101",
      "area_name": "喀什市",
      "level": "3",
      "city_code": "0998",
      "center": "75.99379,39.467685",
      "parent_id": "3175"
    },
    {
      "area_id": "3177",
      "area_code": "653121",
      "area_name": "疏附县",
      "level": "3",
      "city_code": "0998",
      "center": "75.862813,39.375043",
      "parent_id": "3175"
    },
    {
      "area_id": "3178",
      "area_code": "653122",
      "area_name": "疏勒县",
      "level": "3",
      "city_code": "0998",
      "center": "76.048139,39.401384",
      "parent_id": "3175"
    },
    {
      "area_id": "3179",
      "area_code": "653123",
      "area_name": "英吉沙县",
      "level": "3",
      "city_code": "0998",
      "center": "76.175729,38.930381",
      "parent_id": "3175"
    },
    {
      "area_id": "3180",
      "area_code": "653124",
      "area_name": "泽普县",
      "level": "3",
      "city_code": "0998",
      "center": "77.259675,38.18529",
      "parent_id": "3175"
    },
    {
      "area_id": "3181",
      "area_code": "653125",
      "area_name": "莎车县",
      "level": "3",
      "city_code": "0998",
      "center": "77.245761,38.41422",
      "parent_id": "3175"
    },
    {
      "area_id": "3182",
      "area_code": "653126",
      "area_name": "叶城县",
      "level": "3",
      "city_code": "0998",
      "center": "77.413836,37.882989",
      "parent_id": "3175"
    },
    {
      "area_id": "3183",
      "area_code": "653127",
      "area_name": "麦盖提县",
      "level": "3",
      "city_code": "0998",
      "center": "77.610125,38.898001",
      "parent_id": "3175"
    },
    {
      "area_id": "3184",
      "area_code": "653128",
      "area_name": "岳普湖县",
      "level": "3",
      "city_code": "0998",
      "center": "76.8212,39.2198",
      "parent_id": "3175"
    },
    {
      "area_id": "3185",
      "area_code": "653129",
      "area_name": "伽师县",
      "level": "3",
      "city_code": "0998",
      "center": "76.723719,39.488181",
      "parent_id": "3175"
    },
    {
      "area_id": "3186",
      "area_code": "653130",
      "area_name": "巴楚县",
      "level": "3",
      "city_code": "0998",
      "center": "78.549296,39.785155",
      "parent_id": "3175"
    },
    {
      "area_id": "3187",
      "area_code": "653131",
      "area_name": "塔什库尔干塔吉克自治县",
      "level": "3",
      "city_code": "0998",
      "center": "75.229889,37.772094",
      "parent_id": "3175"
    },
    {
      "area_id": "3188",
      "area_code": "653200",
      "area_name": "和田地区",
      "level": "2",
      "city_code": "0903",
      "center": "79.922211,37.114157",
      "parent_id": "3108"
    },
    {
      "area_id": "3189",
      "area_code": "653201",
      "area_name": "和田市",
      "level": "3",
      "city_code": "0903",
      "center": "79.913534,37.112148",
      "parent_id": "3188"
    },
    {
      "area_id": "3190",
      "area_code": "653221",
      "area_name": "和田县",
      "level": "3",
      "city_code": "0903",
      "center": "79.81907,37.120031",
      "parent_id": "3188"
    },
    {
      "area_id": "3191",
      "area_code": "653222",
      "area_name": "墨玉县",
      "level": "3",
      "city_code": "0903",
      "center": "79.728683,37.277143",
      "parent_id": "3188"
    },
    {
      "area_id": "3192",
      "area_code": "653223",
      "area_name": "皮山县",
      "level": "3",
      "city_code": "0903",
      "center": "78.283669,37.62145",
      "parent_id": "3188"
    },
    {
      "area_id": "3193",
      "area_code": "653224",
      "area_name": "洛浦县",
      "level": "3",
      "city_code": "0903",
      "center": "80.188986,37.073667",
      "parent_id": "3188"
    },
    {
      "area_id": "3194",
      "area_code": "653225",
      "area_name": "策勒县",
      "level": "3",
      "city_code": "0903",
      "center": "80.806159,36.998335",
      "parent_id": "3188"
    },
    {
      "area_id": "3195",
      "area_code": "653226",
      "area_name": "于田县",
      "level": "3",
      "city_code": "0903",
      "center": "81.677418,36.85708",
      "parent_id": "3188"
    },
    {
      "area_id": "3196",
      "area_code": "653227",
      "area_name": "民丰县",
      "level": "3",
      "city_code": "0903",
      "center": "82.695861,37.06408",
      "parent_id": "3188"
    },
    {
      "area_id": "3197",
      "area_code": "654000",
      "area_name": "伊犁哈萨克自治州",
      "level": "2",
      "city_code": "0999",
      "center": "81.324136,43.916823",
      "parent_id": "3108"
    },
    {
      "area_id": "3198",
      "area_code": "654002",
      "area_name": "伊宁市",
      "level": "3",
      "city_code": "0999",
      "center": "81.27795,43.908558",
      "parent_id": "3197"
    },
    {
      "area_id": "3199",
      "area_code": "654003",
      "area_name": "奎屯市",
      "level": "3",
      "city_code": "0999",
      "center": "84.903267,44.426529",
      "parent_id": "3197"
    },
    {
      "area_id": "3200",
      "area_code": "654004",
      "area_name": "霍尔果斯市",
      "level": "3",
      "city_code": "0999",
      "center": "80.411271,44.213941",
      "parent_id": "3197"
    },
    {
      "area_id": "3201",
      "area_code": "654021",
      "area_name": "伊宁县",
      "level": "3",
      "city_code": "0999",
      "center": "81.52745,43.977119",
      "parent_id": "3197"
    },
    {
      "area_id": "3202",
      "area_code": "654022",
      "area_name": "察布查尔锡伯自治县",
      "level": "3",
      "city_code": "0999",
      "center": "81.151337,43.840726",
      "parent_id": "3197"
    },
    {
      "area_id": "3203",
      "area_code": "654023",
      "area_name": "霍城县",
      "level": "3",
      "city_code": "0999",
      "center": "80.87898,44.055984",
      "parent_id": "3197"
    },
    {
      "area_id": "3204",
      "area_code": "654024",
      "area_name": "巩留县",
      "level": "3",
      "city_code": "0999",
      "center": "82.231718,43.482628",
      "parent_id": "3197"
    },
    {
      "area_id": "3205",
      "area_code": "654025",
      "area_name": "新源县",
      "level": "3",
      "city_code": "0999",
      "center": "83.232848,43.433896",
      "parent_id": "3197"
    },
    {
      "area_id": "3206",
      "area_code": "654026",
      "area_name": "昭苏县",
      "level": "3",
      "city_code": "0999",
      "center": "81.130974,43.157293",
      "parent_id": "3197"
    },
    {
      "area_id": "3207",
      "area_code": "654027",
      "area_name": "特克斯县",
      "level": "3",
      "city_code": "0999",
      "center": "81.836206,43.217183",
      "parent_id": "3197"
    },
    {
      "area_id": "3208",
      "area_code": "654028",
      "area_name": "尼勒克县",
      "level": "3",
      "city_code": "0999",
      "center": "82.511809,43.800247",
      "parent_id": "3197"
    },
    {
      "area_id": "3209",
      "area_code": "654200",
      "area_name": "塔城地区",
      "level": "2",
      "city_code": "0901",
      "center": "82.980316,46.745364",
      "parent_id": "3108"
    },
    {
      "area_id": "3210",
      "area_code": "654201",
      "area_name": "塔城市",
      "level": "3",
      "city_code": "0901",
      "center": "82.986978,46.751428",
      "parent_id": "3209"
    },
    {
      "area_id": "3211",
      "area_code": "654202",
      "area_name": "乌苏市",
      "level": "3",
      "city_code": "0901",
      "center": "84.713396,44.41881",
      "parent_id": "3209"
    },
    {
      "area_id": "3212",
      "area_code": "654221",
      "area_name": "额敏县",
      "level": "3",
      "city_code": "0901",
      "center": "83.628303,46.524673",
      "parent_id": "3209"
    },
    {
      "area_id": "3213",
      "area_code": "654223",
      "area_name": "沙湾县",
      "level": "3",
      "city_code": "0901",
      "center": "85.619416,44.326388",
      "parent_id": "3209"
    },
    {
      "area_id": "3214",
      "area_code": "654224",
      "area_name": "托里县",
      "level": "3",
      "city_code": "0901",
      "center": "83.60695,45.947638",
      "parent_id": "3209"
    },
    {
      "area_id": "3215",
      "area_code": "654225",
      "area_name": "裕民县",
      "level": "3",
      "city_code": "0901",
      "center": "82.982667,46.201104",
      "parent_id": "3209"
    },
    {
      "area_id": "3216",
      "area_code": "654226",
      "area_name": "和布克赛尔蒙古自治县",
      "level": "3",
      "city_code": "0901",
      "center": "85.728328,46.793235",
      "parent_id": "3209"
    },
    {
      "area_id": "3217",
      "area_code": "654300",
      "area_name": "阿勒泰地区",
      "level": "2",
      "city_code": "0906",
      "center": "88.141253,47.844924",
      "parent_id": "3108"
    },
    {
      "area_id": "3218",
      "area_code": "654301",
      "area_name": "阿勒泰市",
      "level": "3",
      "city_code": "0906",
      "center": "88.131842,47.827308",
      "parent_id": "3217"
    },
    {
      "area_id": "3219",
      "area_code": "654321",
      "area_name": "布尔津县",
      "level": "3",
      "city_code": "0906",
      "center": "86.874923,47.702163",
      "parent_id": "3217"
    },
    {
      "area_id": "3220",
      "area_code": "654322",
      "area_name": "富蕴县",
      "level": "3",
      "city_code": "0906",
      "center": "89.525504,46.994115",
      "parent_id": "3217"
    },
    {
      "area_id": "3221",
      "area_code": "654323",
      "area_name": "福海县",
      "level": "3",
      "city_code": "0906",
      "center": "87.486703,47.111918",
      "parent_id": "3217"
    },
    {
      "area_id": "3222",
      "area_code": "654324",
      "area_name": "哈巴河县",
      "level": "3",
      "city_code": "0906",
      "center": "86.418621,48.060846",
      "parent_id": "3217"
    },
    {
      "area_id": "3223",
      "area_code": "654325",
      "area_name": "青河县",
      "level": "3",
      "city_code": "0906",
      "center": "90.37555,46.679113",
      "parent_id": "3217"
    },
    {
      "area_id": "3224",
      "area_code": "654326",
      "area_name": "吉木乃县",
      "level": "3",
      "city_code": "0906",
      "center": "85.874096,47.443101",
      "parent_id": "3217"
    },
    {
      "area_id": "3225",
      "area_code": "659006",
      "area_name": "铁门关市",
      "level": "2",
      "city_code": "1996",
      "center": "85.501217,41.82725",
      "parent_id": "3108"
    },
    {
      "area_id": "3226",
      "area_code": "659003",
      "area_name": "图木舒克市",
      "level": "2",
      "city_code": "1998",
      "center": "79.073963,39.868965",
      "parent_id": "3108"
    },
    {
      "area_id": "3227",
      "area_code": "659004",
      "area_name": "五家渠市",
      "level": "2",
      "city_code": "1994",
      "center": "87.54324,44.166756",
      "parent_id": "3108"
    },
    {
      "area_id": "3228",
      "area_code": "710000",
      "area_name": "台湾省",
      "level": "1",
      "city_code": "1886",
      "center": "121.509062,25.044332",
      "parent_id": "0"
    },
    {
      "area_id": "3229",
      "area_code": "810000",
      "area_name": "香港特别行政区",
      "level": "1",
      "city_code": "1852",
      "center": "114.171203,22.277468",
      "parent_id": "0"
    },
    {
      "area_id": "3230",
      "area_code": "810001",
      "area_name": "中西区",
      "level": "3",
      "city_code": "1852",
      "center": "114.154373,22.281981",
      "parent_id": "3229"
    },
    {
      "area_id": "3231",
      "area_code": "810002",
      "area_name": "湾仔区",
      "level": "3",
      "city_code": "1852",
      "center": "114.182915,22.276389",
      "parent_id": "3229"
    },
    {
      "area_id": "3232",
      "area_code": "810003",
      "area_name": "东区",
      "level": "3",
      "city_code": "1852",
      "center": "114.226003,22.279693",
      "parent_id": "3229"
    },
    {
      "area_id": "3233",
      "area_code": "810004",
      "area_name": "南区",
      "level": "3",
      "city_code": "1852",
      "center": "114.160012,22.245897",
      "parent_id": "3229"
    },
    {
      "area_id": "3234",
      "area_code": "810005",
      "area_name": "油尖旺区",
      "level": "3",
      "city_code": "1852",
      "center": "114.173332,22.311704",
      "parent_id": "3229"
    },
    {
      "area_id": "3235",
      "area_code": "810006",
      "area_name": "深水埗区",
      "level": "3",
      "city_code": "1852",
      "center": "114.163242,22.333854",
      "parent_id": "3229"
    },
    {
      "area_id": "3236",
      "area_code": "810007",
      "area_name": "九龙城区",
      "level": "3",
      "city_code": "1852",
      "center": "114.192847,22.31251",
      "parent_id": "3229"
    },
    {
      "area_id": "3237",
      "area_code": "810008",
      "area_name": "黄大仙区",
      "level": "3",
      "city_code": "1852",
      "center": "114.203886,22.336321",
      "parent_id": "3229"
    },
    {
      "area_id": "3238",
      "area_code": "810009",
      "area_name": "观塘区",
      "level": "3",
      "city_code": "1852",
      "center": "114.214054,22.320838",
      "parent_id": "3229"
    },
    {
      "area_id": "3239",
      "area_code": "810010",
      "area_name": "荃湾区",
      "level": "3",
      "city_code": "1852",
      "center": "114.121079,22.368306",
      "parent_id": "3229"
    },
    {
      "area_id": "3240",
      "area_code": "810011",
      "area_name": "屯门区",
      "level": "3",
      "city_code": "1852",
      "center": "113.976574,22.393844",
      "parent_id": "3229"
    },
    {
      "area_id": "3241",
      "area_code": "810012",
      "area_name": "元朗区",
      "level": "3",
      "city_code": "1852",
      "center": "114.032438,22.441428",
      "parent_id": "3229"
    },
    {
      "area_id": "3243",
      "area_code": "810013",
      "area_name": "北区",
      "level": "3",
      "city_code": "1852",
      "center": "114.147364,22.496104",
      "parent_id": "3229"
    },
    {
      "area_id": "3245",
      "area_code": "810014",
      "area_name": "大埔区",
      "level": "3",
      "city_code": "1852",
      "center": "114.171743,22.445653",
      "parent_id": "3229"
    },
    {
      "area_id": "3246",
      "area_code": "810015",
      "area_name": "西贡区",
      "level": "3",
      "city_code": "1852",
      "center": "114.264645,22.314213",
      "parent_id": "3229"
    },
    {
      "area_id": "3247",
      "area_code": "810016",
      "area_name": "沙田区",
      "level": "3",
      "city_code": "1852",
      "center": "114.195365,22.379532",
      "parent_id": "3229"
    },
    {
      "area_id": "3248",
      "area_code": "810017",
      "area_name": "葵青区",
      "level": "3",
      "city_code": "1852",
      "center": "114.139319,22.363877",
      "parent_id": "3229"
    },
    {
      "area_id": "3249",
      "area_code": "810018",
      "area_name": "离岛区",
      "level": "3",
      "city_code": "1852",
      "center": "113.94612,22.286408",
      "parent_id": "3229"
    },
    {
      "area_id": "3251",
      "area_code": "820000",
      "area_name": "澳门特别行政区",
      "level": "1",
      "city_code": "1853",
      "center": "113.543028,22.186835",
      "parent_id": "0"
    },
    {
      "area_id": "3252",
      "area_code": "820001",
      "area_name": "花地玛堂区",
      "level": "3",
      "city_code": "1853",
      "center": "113.552896,22.20787",
      "parent_id": "3251"
    },
    {
      "area_id": "3253",
      "area_code": "820002",
      "area_name": "花王堂区",
      "level": "3",
      "city_code": "1853",
      "center": "113.548961,22.199207",
      "parent_id": "3251"
    },
    {
      "area_id": "3254",
      "area_code": "820003",
      "area_name": "望德堂区",
      "level": "3",
      "city_code": "1853",
      "center": "113.550183,22.193721",
      "parent_id": "3251"
    },
    {
      "area_id": "3255",
      "area_code": "820004",
      "area_name": "大堂区",
      "level": "3",
      "city_code": "1853",
      "center": "113.553647,22.188539",
      "parent_id": "3251"
    },
    {
      "area_id": "3256",
      "area_code": "820005",
      "area_name": "风顺堂区",
      "level": "3",
      "city_code": "1853",
      "center": "113.541928,22.187368",
      "parent_id": "3251"
    },
    {
      "area_id": "3257",
      "area_code": "820006",
      "area_name": "嘉模堂区",
      "level": "3",
      "city_code": "1853",
      "center": "113.558705,22.15376",
      "parent_id": "3251"
    },
    {
      "area_id": "3258",
      "area_code": "820007",
      "area_name": "路凼填海区",
      "level": "3",
      "city_code": "1853",
      "center": "113.569599,22.13663",
      "parent_id": "3251"
    },
    {
      "area_id": "3259",
      "area_code": "820008",
      "area_name": "圣方济各堂区",
      "level": "3",
      "city_code": "1853",
      "center": "113.559954,22.123486",
      "parent_id": "3251"
    }
  ]
}
