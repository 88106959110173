import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  newsList: [],
  total: 0,
  page: 1
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.PAGE:
      return state.set('page', action.value)
    case constants.LIST:
      return state.merge({ newsList: fromJS(action.list), total: action.total })
    default:
      return state
  }
}
