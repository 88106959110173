import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  selected_group_id: undefined, // 选择的群Id
  selected_member_id: undefined, // 选择的成员Id
  group_list: [], // 群列表
  group_member_list: [] // 群成员列表
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SELECTED_GROUP_LIST:
      return state.set('selected_group_id', action.id)
    case constants.SELECTED_MEMBER_LIST:
      return state.set('selected_member_id', action.id)
    case constants.GROUP_LIST:
      return state.set('group_list', fromJS(action.list))
    case constants.GROUP_MEMBER_LIST:
      return state.set('group_member_list', fromJS(action.list))
    default:
      return state
  }
}
