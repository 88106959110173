import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  beanQuantity: 0, // 易豆数量
  count: 0, // 充值策略总数
  over: false, // 是否为最后一页
  recharge_strategy_list: [], // 充值策略列表
  recharge_record_list: [] // 充值记录列表
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.BEAN_QUANTITY:
      return state.set('beanQuantity', action.value)
    case constants.RECHARGE_STRATEGY_LIST:
      return state.merge({
        recharge_strategy_list: fromJS(action.list),
        count: action.count,
        over: action.over
      })
    case constants.RECHARGE_RECORD_LIST:
      return state.set('recharge_record_list', fromJS(action.list))
    default:
      return state
  }
}
