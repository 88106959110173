import axios from 'axios'
import qs from 'qs'
import md5 from 'blueimp-md5'
import Cookies from 'js-cookie'
import { message } from 'antd'
import { objKeySort } from '@/utils/method'

// 本地
// const baseApi = 'http://192.168.1.133:70/index.php/'
// export const web_site_url = 'http://localhost:9521/'
// 线上
const baseApi = 'https://demo.meooh.com/'
export const web_site_url = 'http://index.meooh.com/'

// 创建axios实例
const service = axios.create({
  baseURL: baseApi, // api 的 base_url
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  },
  timeout: 5000 // 请求超时时间
})

//request拦截器
service.interceptors.request.use(
  config => {
    if (config.url.indexOf('uploadImg') !== -1) {
      config.withCredentials = true
      config.headers['Content-Type'] = {
        'Content-Type': 'multipart/form-data'
      }
    } else if (config.method === 'post' && config.data) {
      // 数字转字符串
      for (let k in config.data) {
        typeof config.data[k] === 'number' &&
          (config.data[k] = `${config.data[k]}`)
        if (config.data[k] instanceof Array) {
          if (config.data[k].length <= 0) delete config.data[k]
          else config.data[k] = JSON.stringify(config.data[k])
        }
        if (config.data[k] !== '0' && !config.data[k]) delete config.data[k]
      }
      let isData = { ...config.data }
      if (config.url.indexOf('rent') !== -1) {
        delete isData.image_url
        delete isData.latLng
      } else if (config.url.indexOf('user_edit') !== -1) {
        delete isData.avator
        delete isData.identity_card_positive_img
        delete isData.identity_card_other_img
        delete isData.business_licence
        delete isData.business_card
      }
      // 排序
      const data = JSON.stringify(
        objKeySort({
          ...isData,
          key: 'B2xeogRE7Rp4q8y1uCDHvWse3QKIKLQF'
        })
      )
      // 加密
      const sign = md5(data)
      sign && (config.data.sign = sign)
      // 序列化
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

//响应拦截器
service.interceptors.response.use(
  function(response) {
    // 用户信息是否超时，重定向到登录页面
    if (response.data.code === 6666) {
      Cookies.remove('access_token')
      Cookies.remove('user_name')
      Cookies.remove('user_id')
      localStorage.removeItem('area_id')
      localStorage.removeItem('areacode')
      localStorage.removeItem('areaname')
      localStorage.removeItem('level')
      window.location.href = '/login'
      message.error('登录信息已过期，请重新登录！', 3)
    }
    return response.data
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error)
  }
)
export default service
