import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FooterWrapper, Nav, Contact, Qrcode, Bottom } from './style';

class HomeFooter extends PureComponent {
  render() {
    const { location } = this.props;
    const pathname = location.pathname;
    const path =
      pathname.lastIndexOf('/') !== 0 ? `/${pathname.split('/')[1]}` : pathname;
    if (
      path === '/login' ||
      path === '/register' ||
      path === '/forgot' ||
      path === '/agreement'
    ) {
      return null;
    } else {
      return (
        <FooterWrapper>
          <Nav>
            <Contact>
              <p style={{ fontSize: '22px' }}>合作</p>
              <p style={{ fontSize: '18px' }}>合作咨询：400- 652-6680</p>
              <p style={{ fontSize: '18px' }}>邮箱：434811370@meooh.com</p>
              <Qrcode>
                <img
                  alt="二维码"
                  style={{ width: '84px', height: '84px' }}
                  src={require('@/statics/qr.png')}
                />
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '6px'
                  }}
                >
                  前往公众号
                </p>
              </Qrcode>
            </Contact>
          </Nav>
          <Bottom>
            广告云媒体平台 版权所有©2008-2014 | 福州易户外网络科技有限公司 |
            闽ICP备08105429-2
          </Bottom>
        </FooterWrapper>
      );
    }
  }
}

const mapState = state => ({
  path: state.getIn(['header', 'path'])
});

export default connect(
  mapState,
  null
)(withRouter(HomeFooter));
