// 第一页招租值
export const PAGE_FIRST_FORM_VAL = 'releases/PAGE_FIRST_FORM_VAL'
// 第二页招租值
export const RENT_STEP_LAST = 'releases/RENT_STEP_LAST'
export const CURRENT_STEPS = 'releases/CURRENT_STEPS'
export const SITE_ATTRIBUTE_LIST = 'releases/SITE_ATTRIBUTE_LIST'
export const ALL_LEVEL_List = 'releases/ALL_LEVEL_List'
export const TYPE_LEVEL_ONE = 'releases/TYPE_LEVEL_ONE'
export const TYPE_LEVEL_TWO = 'releases/TYPE_LEVEL_TWO'
export const TYPE_LEVEL_THREE = 'releases/TYPE_LEVEL_THREE'
export const RENT_STEP_1 = 'releases/RENT_STEP_1'
// 媒体类型值
export const MEDIA_TYPE = 'releases/MEDIA_TYPE'
