import { fromJS } from "immutable";
import * as constants from "./constants";

const defaultState = fromJS({
  sliderList: [],
  hireList: [],
  demandList: []
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SLIDERLIST:
      return state.set("sliderList", fromJS(action.list));
    case constants.HIRELIST:
      return state.set("hireList", fromJS(action.list));
    case constants.DEMANDLIST:
      return state.set("demandList", fromJS(action.list));
    default:
      return state;
  }
};
