import styled from 'styled-components'
import logoPic from '@/statics/logo.jpg'
import locationBg from '@/statics/locationBg.png'

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 999;
  height: 95px;

  /* background-color: rgba(255,255,255,0); */
`
export const Nav = styled.div`
  position: relative;
  width: 1200px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 85px;
  margin: 0 auto;
  font-size: 40px;
`
export const Logo = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -38px;
  display: block;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background: url(${logoPic}) no-repeat;
  background-size: contain;
`
export const Title = styled.span`
  font-size: 32px;
  font-weight: 500;
  margin-left: 6px;
  color: #fff;
  line-height: 95px;
`
export const Location = styled.div`
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
`
export const Navmenu = styled.ul`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  & > a:hover {
    color: rgb(168, 211, 226);
  }
`
export const Navmenuli = styled.li`
  cursor: pointer;
  font-size: 21px !important;
  color: rgb(168, 211, 226) !important;
  font-weight: 300;
  .active {
    color: #fff !important;
    border-bottom: 4px solid #fff;
  }
  .omits {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & > p {
    margin-bottom: 0;
  }
`
export const LocationBox = styled.div`
  padding: 30px 0 0 4px;
  height: 292px;
  width: 420px;
  background: url(${locationBg}) no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 9999;
  display: ${props => (props.shows ? 'block' : 'none')};
`
