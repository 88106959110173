import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  searchVal: {
    address: '',
    media_type: [undefined, undefined, undefined], // 0: 等级1
    journal_price: '',
    sale_situation: '',
    field_properties_id: '',
    launch_time: '',
    proince: undefined,
    city: undefined,
    area: undefined
  },
  allLevelList: [], // 全部媒体类型数据
  typeLevelOne: [], // 媒体类型 分类1
  typeLevelTwo: [], // 媒体类型 分类2
  typeLevelThree: [], // 媒体类型 分类3
  siteAttributeList: [], // 场地属性列表
  modalShow: false,
  selectAllType: 0,
  selectList: [],
  page: 1,
  total: 0,
  rentList: []
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.ALL_LEVEL_List:
      return state.set('allLevelList', action.list)
    case constants.TYPE_LEVEL_ONE:
      return state.set('typeLevelOne', action.list)
    case constants.TYPE_LEVEL_TWO:
      return state.set('typeLevelTwo', action.list)
    case constants.TYPE_LEVEL_THREE:
      return state.set('typeLevelThree', action.list)
    case constants.SITE_ATTRIBUTE_LIST:
      return state.set('siteAttributeList', action.list)
    case constants.MODAL_SHOW:
      return state.set('modalShow', action.show)
    case constants.SEARCH_VAL:
      return state.merge({
        page: 1,
        searchVal: action.value
      })
    case constants.SELECT_LINE:
      return state.set('selectList', action.value)
    case constants.PAGE:
      return state.set('page', action.page)
    case constants.SELECT_ALL_TYPE:
      return state.set('selectAllType', action.value)
    case constants.RENT_LIST:
      return state.merge({
        rentList: fromJS(action.list),
        total: action.total
      })
    default:
      return state
  }
}
