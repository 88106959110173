import * as constants from './constants'
import Cookies from 'js-cookie'
import moment from 'moment'
import { userCollectionDemand, userCollectionRent } from '@api'

// 设置页数
export const setPage = page => ({
  type: constants.PAGE,
  page
})

// 设置列表
export const setList = (list, total) => ({
  type: constants.LIST,
  list,
  total
})

// 获取我的发布需求
export const getDemand = cond => async dispatch => {
  const res = await userCollectionDemand({
    user_id: Cookies.get('user_id'),
    ...cond
  })
  if (res.code === 0) {
    const { list, count } = res.data
    list.filter(item => {
      if (item.type === 1) {
        item.create_time_str = moment
          .unix(item.create_time)
          .format('YYYY/MM/DD')
        return item
      }
    })
    dispatch(setList(list, count))
  }
}

// 获取我的招租列表
export const getRent = cond => async dispatch => {
  const res = await userCollectionRent({
    user_id: Cookies.get('user_id'),
    ...cond
  })
  if (res.code === 0) {
    const { list, count } = res.data
    list.map(
      item =>
        (item.create_time_str = moment
          .unix(item.create_time)
          .format('YYYY/MM/DD'))
    )
    dispatch(setList(list, count))
  }
}
