import * as constants from './constants'
import { advertisementPoint } from '@api'
import { message } from 'antd'

export const setPointList = list => ({
  type: constants.POINTLIST,
  list
})

// 获取对应经纬度
export const getAdvertisementPoint = condition => async dispatch => {
  const hide = message.loading('获取广告经纬度中...', 0)
  const res = await advertisementPoint(condition.toJS())
  hide()
  if (res.code === 0) {
    const data = res.data
    dispatch(
      setPointList(
        data.map(item => ({
          id: item.id,
          lat: item.streetscape_positioning_latitude,
          lng: item.streetscape_positioning_longitude
        }))
      )
    )
  }
}
