import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  isLogin: false,
  loginType: '0',
  liked: true,
  count: 60,
  access_token: ''
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.LOGIN_STATE:
      return state.set('isLogin', action.value)
    case constants.TOKEN:
      return state.set('access_token', action.token)
    case constants.CHANGE_LOGIN_TYPE:
      return state.set('loginType', action.value)
    case constants.COUNTDOWN:
      return state.merge({ liked: action.liked, count: action.count })
    default:
      return state
  }
}
