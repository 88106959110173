import * as constants from './constants'
import { homeData } from '@api'
// import { fromJS } from "immutable";
// import { message } from "antd";

const changeSliderList = list => ({
  type: constants.SLIDERLIST,
  list
})

const changeHireList = list => ({
  type: constants.HIRELIST,
  list
})

const changeDemandList = list => ({
  type: constants.DEMANDLIST,
  list
})

// 获取首页 全部数据
export const getHomeData = code => dispatch => {
  homeData({
    code
  }).then(res => {
    if (res.code === 0) {
      const data = res.data
      // 设置轮播图
      dispatch(changeSliderList(data.image))
      // 设置热门招租
      dispatch(changeHireList(data.regionS))
      // 设置热门需求
      dispatch(changeDemandList(data.demand))
    }
  })
}
