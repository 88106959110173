import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import PageScrollTop from './common/PageScrollTop'
import store from './store'
import { LocaleProvider, message } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import ListerentLogin from './pages/listerent-login'
import Header from './common/homeHeader'
import Router from './router'
import Footer from './common/homeFooter'
import moment from 'moment'
import 'moment/locale/zh-cn'
import './style.css'

message.config({
  top: 240,
  duration: 2,
  maxCount: 3
})

moment.locale('zh-cn')

const App = () => (
  <>
    <LocaleProvider locale={zhCN}>
      <Provider store={store}>
        <BrowserRouter>
          <ListerentLogin />
          <div style={{ minWidth: 1200 }}>
            <Header />
            <div style={{ minHeight: window.innerHeight - 233 }}>
              <PageScrollTop>
                <Router />
              </PageScrollTop>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </Provider>
    </LocaleProvider>
  </>
)

export default App
