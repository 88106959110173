import * as constants from './constants'
import { getMediaType, createDemand, createRent, uploadToImage } from '@api'
import { fromJS } from 'immutable'
import Cookies from 'js-cookie'
import { message } from 'antd'

// 0: 媒体信息 1：数据信息
export const changeCurrentSteps = current => dispath => {
  window.scrollTo(0, 0)
  return dispath({
    type: constants.CURRENT_STEPS,
    current
  })
}

// 设置媒体类型值 value === init 重置值
export const setMediaTypeVal = value => ({
  type: constants.MEDIA_TYPE,
  value: value === 'init' ? fromJS([(undefined, undefined, undefined)]) : value
})

export const setPageFirstFormVal = value => ({
  type: constants.PAGE_FIRST_FORM_VAL,
  value
})

// 存储招租第一页的值
export const setRentStep1 = value => ({
  type: constants.RENT_STEP_1,
  value
})
// 存储招租第二页的值
export const setRentStepLast = value => ({
  type: constants.RENT_STEP_LAST,
  value
})

// 设置全部等级媒体类型列表
export const setAllLevelList = list => ({
  type: constants.ALL_LEVEL_List,
  list
})

// 设置媒体类型等级1
export const setTypeLevelOne = list => ({
  type: constants.TYPE_LEVEL_ONE,
  list
})

// 设置媒体类型等级2
export const setTypeLevelTwo = list => ({
  type: constants.TYPE_LEVEL_TWO,
  list
})

// 设置媒体类型等级3
export const setTypeLevelThree = list => ({
  type: constants.TYPE_LEVEL_THREE,
  list
})

// 设置场地属性
export const setSiteAttribute = list => ({
  type: constants.SITE_ATTRIBUTE_LIST,
  list
})

// 获取媒体类型 场地属性
export const getMediaTypeMessage = siteAttribute => async dispath => {
  const res = await getMediaType({})
  if (res.code === 0) {
    const { type, properties } = res.data
    if (siteAttribute) {
      //设置场地属性列表
      dispath(setSiteAttribute(properties))
    }
    // 设置媒体类型全部等级列表
    dispath(setAllLevelList(type))
    const level_1 = type.filter(item => item.level * 1 === 1)
    // 设置媒体类型第一等级列表
    dispath(setTypeLevelOne(level_1))
  }
}

// 上传图片
export const uploadImage = (params, setFieldsValue, token) => dispath => {
  let formData = new FormData()
  formData.append('image', params)
  const hide = message.loading('加载图片中...', 0)
  uploadToImage(formData, token, Cookies.get('user_id')).then(res => {
    hide()
    if (res.code === 0) {
      setFieldsValue({ image_url: res.data.img_url })
    }
  })
}

// 提交发布需求
export const addDemand = value => dispath =>
  new Promise(async (resolve, reject) => {
    value.user_id = Cookies.get('user_id')
    const res = await createDemand(value)
    if (res.code === 0) {
      message.success('发布需求成功')
    } else {
      message.error(res.msg)
    }
    resolve(res.code)
  })

// 提交发布招租
export const addRent = value => dispath =>
  new Promise(async (resolve, reject) => {
    value.user_id = Cookies.get('user_id')
    const res = await createRent(value)
    if (res.code === 0) {
      message.success('发布招租成功')
    } else {
      message.error(res.msg)
    }
    resolve(res.code)
  })
