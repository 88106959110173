import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  pointList: []
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.POINTLIST:
      return state.set('pointList', fromJS(action.list))
    default:
      return state
  }
}
