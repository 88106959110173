import * as constants from './constants'
import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'
import { Area } from '@/statics/area.js'

export const changePath = path => ({
  type: constants.PATH_STATE,
  path
})

export const changeLocshow = show => ({ type: constants.LOCSHOW, show })

export const username = name => ({ type: constants.USERNAME, name })

export const changeCityIndex = index => ({ type: constants.CITYINDEX, index })
export const changeDistrictIndex = index => ({
  type: constants.DISTRICTINDEX,
  index
})

export const changeProvinceName = name => ({
  type: constants.PROVINCENAME,
  name
})
export const changeCityName = name => ({ type: constants.CITYNAME, name })
export const changeDistrictName = name => ({
  type: constants.DISTRICTNAME,
  name
})

export const changeProvinceCode = code => ({
  type: constants.PROVINCECODE,
  code
})
export const changeCityCode = code => ({ type: constants.CITYCODE, code })
export const changeDistrictCode = code => ({
  type: constants.DISTRICTCODE,
  code
})

export const changeLocationCode = code => ({
  type: constants.LOCATIONCODE,
  code
})
export const changeLocationName = name => ({
  type: constants.LOCATIONNAME,
  name
})

export const changeAreaId = areaId => ({ type: constants.AREAID, areaId })
export const changeAreaLevel = areaLevel => ({
  type: constants.AREALEVEL,
  areaLevel
})

export const setAreaSession = (areaname, areacode) => {
  return dispatch => {
    sessionStorage.setItem('areaname', areaname)
    sessionStorage.setItem('areacode', areacode)
    dispatch(changeLocationCode(areacode))
    dispatch(changeLocationName(areaname))
    Area.RECORDS.forEach(function(item) {
      if (item.area_code === areacode) {
        dispatch(changeAreaId(item.area_id))
        dispatch(changeAreaLevel(item.level))
        sessionStorage.setItem('area_id', item.area_id)
        sessionStorage.setItem('level', item.level)
        return
      }
    })
  }
}

// 获取当前位置
export const getCurrLocation = (lng, lat) => {
  return dispatch => {
    // lng = '119.33022110999997'
    // lat = '26.047125489795034'
    axios({
      url:
        'http://api.map.baidu.com/geocoder/v2/?ak=53lUQxWhF5aKEKfF70RU6KnxxWPTTtnr&latest_admin=1&callback=renderReverse&location=' +
        lat +
        ',' +
        lng +
        '&output=json',
      adapter: jsonpAdapter
    }).then(res => {
      if (res.status === 200) {
        if (res.data.result.addressComponent.adcode * 1 !== 0) {
          let areacode = res.data.result.addressComponent.adcode
          let areaname = res.data.result.addressComponent.district
          dispatch(changeLocationCode(areacode))
          dispatch(changeLocationName(areaname))
          localStorage.setItem('areaname', areaname)
          localStorage.setItem('areacode', areacode)
          // 获取文章等的地区ID和地区等级
          Area.RECORDS.forEach(function(item) {
            if (item.area_code === areacode) {
              dispatch(changeAreaId(item.area_id))
              dispatch(changeAreaLevel(item.level))
              localStorage.setItem('area_id', item.area_id)
              localStorage.setItem('level', item.level)
              return
            }
          })
        }
      }
    })
  }
}
