import Cookies from 'js-cookie'
import { loginApi, smsApi } from '@api'
import { actionCreators as headerActionCreators } from '@common/homeHeader/store'
import * as constants from './constants'
import { message } from 'antd'

// import {getCookie,setCookie} from '@/utils/cookie';

export const changeLogin = () => ({
  type: constants.LOGIN_STATE,
  value: true
})

export const changeLogout = () => ({
  type: constants.LOGIN_STATE,
  value: false
})

export const accessToken = token => ({
  type: constants.TOKEN,
  token
})

const changeLoginType = types => ({
  type: constants.CHANGE_LOGIN_TYPE,
  value: types
})

export const countdown = (count, liked) => ({
  type: constants.COUNTDOWN,
  count,
  liked
})

export const logout = () => {
  return dispatch => {
    Cookies.remove('access_token')
    Cookies.remove('user_name')
    Cookies.remove('user_id')
    localStorage.removeItem('area_id')
    localStorage.removeItem('areacode')
    localStorage.removeItem('areaname')
    localStorage.removeItem('level')
    dispatch(changeLogout())
  }
}

// 登录动作
export const LoginAction = async (res, dispatch) => {
  const data = res.data
  const userName = data.name || data.mobile
  Cookies.set('access_token', data.access_token, { expires: 7 })
  Cookies.set('user_id', data.user_id, {
    expires: 7
  })
  Cookies.set('user_name', userName, {
    expires: 7
  })
  await dispatch(headerActionCreators.username(userName))
  await dispatch(accessToken(data.access_token))
  dispatch(changeLogin())
}

// 账号登录
export const normalLogin = (mobile, password) => {
  return dispatch => {
    loginApi({
      tel: mobile,
      type: 0,
      password
    }).then(async res => {
      if (res.code === 0) {
        message.success('登录成功', 1)
        LoginAction(res, dispatch)
      } else {
        message.error(res.msg)
      }
    })
  }
}
// 手机登录
export const mobileLogin = (mobile, login_sms) => {
  return dispatch => {
    loginApi({
      tel: mobile,
      type: 1,
      code: login_sms
    }).then(async res => {
      if (res.code === 0) {
        message.success('登录成功', 1)
        LoginAction(res, dispatch)
      } else {
        message.error(res.msg)
      }
    })
  }
}
// 切换登录方式
export const changeType = type => {
  return dispatch => {
    type === '1' ? (type = '0') : (type = '1')
    dispatch(changeLoginType(type))
    localStorage.setItem('timer', '0')
    setTimeout(() => {
      localStorage.setItem('timer', '1')
    }, 1000)
  }
}
// 获取验证码
export const getVerify = (mobile, liked, count, sms_type) => {
  return dispatch => {
    smsApi({
      tel: mobile,
      type: sms_type
    }).then(res => {
      if (res.data.status === -1) {
        message.error('请勿频繁发送短信，请稍候再进行发送')
      } else {
        if (!liked) {
          return
        }
        message.success('短信发送成功')
        const timer = setInterval(() => {
          let can = localStorage.getItem('timer')
          count--
          liked = false
          if (count === 0 || can === '0') {
            clearInterval(timer)
            count = 60
            liked = true
          }
          dispatch(countdown(count, liked))
        }, 1000)
      }
    })
  }
}
