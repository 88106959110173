import { combineReducers } from 'redux-immutable'
import { reducer as headerReducer } from '@common/homeHeader/store'
import { reducer as rentListReducer } from '@common/Rent/store'
import { reducer as loginReducer } from '@pages/login/store'
import { reducer as registerReducer } from '@pages/register/store'
import { reducer as forgotReducer } from '@pages/forgot/store'
import { reducer as homeReducer } from '@pages/home/store'
import { reducer as releasesReducer } from '@pages/releases/store'
import { reducer as rentDetailReducer } from '@pages/rent-detail/store'
import { reducer as mediaDemandReducer } from '@pages/media-demand/store'
import { reducer as demandDetailReducer } from '@pages/demand-detail/store'
import { reducer as advHeaderNewsReducer } from '@pages/adv-header-news/store'
import { reducer as userReleaseReducer } from '@pages/user-release/store'
import { reducer as userSettingReducer } from '@pages/user-setting/store'
import { reducer as advMapReducer } from '@pages/adv-map/store'
import { reducer as userCollectionReducer } from '@pages/user-collection/store'
import { reducer as easyBeanReducer } from '@pages/easy-bean/store'
import { reducer as employeeTrackReducer } from '@pages/employee-track/store'

const reducer = combineReducers({
  header: headerReducer,
  login: loginReducer,
  register: registerReducer,
  forgot: forgotReducer,
  home: homeReducer,
  releases: releasesReducer,
  rent: rentListReducer,
  rentDetail: rentDetailReducer,
  mediaDemand: mediaDemandReducer,
  demandDetail: demandDetailReducer,
  advHeaderNews: advHeaderNewsReducer,
  userRelease: userReleaseReducer,
  userSetting: userSettingReducer,
  advMap: advMapReducer,
  userCollection: userCollectionReducer,
  easyBean: easyBeanReducer,
  employeeTrack: employeeTrackReducer
})

export default reducer
