import * as constants from './constants'
import { reservedIsTrue } from '@/utils/method'
import moment from 'moment'
import { fromJS } from 'immutable'
import { getMediaType, rentList } from '@api'

// 设置搜索值 传init时重置
export const handleSearchVal = value => ({
  type: constants.SEARCH_VAL,
  value:
    value === 'init'
      ? fromJS({
          name: '',
          media_type: [undefined, undefined, undefined],
          journal_price: '',
          sale_situation: '',
          field_properties_id: '',
          launch_time: '',
          proince: undefined,
          city: undefined,
          area: undefined
        })
      : value
})

export const handleSelectList = value => ({
  type: constants.SELECT_LINE,
  value: fromJS(value)
})

export const updateSelectAllType = value => ({
  type: constants.SELECT_ALL_TYPE,
  value
})

// 更新已选列表框显示
export const updateModalShow = show => ({
  type: constants.MODAL_SHOW,
  show
})

// 设置页数
export const setPage = page => ({
  type: constants.PAGE,
  page
})

// 设置招租列表
export const setRentList = (list, total) => ({
  type: constants.RENT_LIST,
  list,
  total
})

// 设置全部等级媒体类型列表
export const setAllLevelList = list => ({
  type: constants.ALL_LEVEL_List,
  list
})

// 设置媒体类型等级1
export const setTypeLevelOne = list => ({
  type: constants.TYPE_LEVEL_ONE,
  list
})

// 设置媒体类型等级2
export const setTypeLevelTwo = list => ({
  type: constants.TYPE_LEVEL_TWO,
  list
})

// 设置媒体类型等级3
export const setTypeLevelThree = list => ({
  type: constants.TYPE_LEVEL_THREE,
  list
})

// 设置场地属性
export const setSiteAttribute = list => ({
  type: constants.SITE_ATTRIBUTE_LIST,
  list
})

// 获取媒体类型 场地属性
export const getMediaTypeMessage = () => async dispath => {
  const res = await getMediaType({})
  if (res.code === 0) {
    const { type, properties } = res.data
    // 设置媒体类型全部等级列表
    dispath(setAllLevelList(type))
    const level_1 = type.filter(item => item.level === '1')
    // 设置媒体类型第一等级列表
    dispath(setTypeLevelOne(level_1))

    //设置场地属性列表
    dispath(setSiteAttribute(properties))
  }
}

// 获取招租列表
export const getRentList = condition => async dispath => {
  condition.media_type = condition.media_type.filter(item => item).toString()

  const res = await rentList(reservedIsTrue(condition))
  if (res.code === 0) {
    const { list, count } = res.data
    list.map(item => {
      item.launch_time_str = moment.unix(item.launch_time).format('YYYY/MM/DD')
      item.selected = false
      return item
    })
    dispath(setRentList(list, count))
  }
}
