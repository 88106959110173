import * as constants from './constants'
import Cookie from 'js-cookie'
import { message } from 'antd'
import {
  userMessage,
  userMessageEdit,
  userAuthentication,
  uploadToImage
} from '@api'

// 设置账号信息
export const setUserMessage = value => ({
  type: constants.USER_MESSAGE,
  value
})

// 设置个人认证
export const setUserCertificatoinMsg = value => ({
  type: constants.USER_CERTIFICATOIN_MSG,
  value
})

// 设置企业认证
export const setEnterpriseCertificatoinMsg = value => ({
  type: constants.ENTERPRISE_CERTIFICATOIN_MSG,
  value
})

// 获取账号信息
export const getUserMessage = token => async dispatch => {
  const res = await userMessage({
    user_id: Cookie.get('user_id'),
    token
  })
  if (res.code === 0) {
    dispatch(setUserMessage(res.data))
  }
}

// 获取认证信息 type 传 0：企业 1：个人
export const getCerification = (type, token) => async dispatch => {
  const res = await userAuthentication({
    user_id: Cookie.get('user_id'),
    type,
    token
  })
  if (res.code === 0) {
    if (type === 1) {
      dispatch(setUserCertificatoinMsg(res.data))
    } else if (type === 0) {
      dispatch(setEnterpriseCertificatoinMsg(res.data))
    }
  }
}

// 更新账号信息
export const updateUserMessage = value => dispatch =>
  new Promise(async (resolve, reject) => {
    value.user_id = Cookie.get('user_id')
    const res = await userMessageEdit(value)
    if (res.code === 0) message.success('保存成功')
    else message.error(res.msg)
    resolve(res.code)
  })

// 上传图片
export const uploadImage = (
  params,
  index,
  setFieldsValue,
  token
) => dispatch => {
  const hide = message.loading('加载图片中...', 0)
  let formData = new FormData()
  formData.append('image', params)
  uploadToImage(formData, token, Cookie.get('user_id')).then(res => {
    hide()
    if (res.code === 0) {
      setFieldsValue({ [index]: res.data.img_url })
    }
  })
}
