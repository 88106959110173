import * as constants from './constants'
import { getMediaType, demandList } from '@api'
import { reservedIsTrue } from '@/utils/method'
import { fromJS } from 'immutable'
import moment from 'moment'

export const handleSearchVal = value => ({
  type: constants.SEARCH_VAL,
  value:
    value === 'init'
      ? fromJS({
          name: '',
          media_type: [undefined, undefined, undefined],
          journal_price: '',
          proince: undefined,
          city: undefined,
          area: undefined
        })
      : value
})

// 设置页数
export const setPage = page => ({
  type: constants.PAGE,
  page
})

// 设置需求列表
export const setDemandList = (list, total) => ({
  type: constants.DEMAND_LIST,
  list,
  total
})

// 获取需求列表
export const getDemandList = condition => async dispath => {
  condition.media_type = condition.media_type.filter(item => item).toString()
  const res = await demandList(reservedIsTrue(condition))
  if (res.code === 0) {
    const { list, count } = res.data
    list.map(
      item =>
        (item.launch_time_str = moment
          .unix(item.launch_time)
          .format('YYYY/MM/DD'))
    )
    dispath(setDemandList(list, count))
  }
}

// 设置全部等级媒体类型列表
export const setAllLevelList = list => ({
  type: constants.ALL_LEVEL_List,
  list
})

// 设置媒体类型等级1
export const setTypeLevelOne = list => ({
  type: constants.TYPE_LEVEL_ONE,
  list
})

// 设置媒体类型等级2
export const setTypeLevelTwo = list => ({
  type: constants.TYPE_LEVEL_TWO,
  list
})

// 设置媒体类型等级3
export const setTypeLevelThree = list => ({
  type: constants.TYPE_LEVEL_THREE,
  list
})

// 获取媒体类型
export const getMediaTypeMessage = () => async dispath => {
  const res = await getMediaType({})
  if (res.code === 0) {
    const { type } = res.data
    // 设置媒体类型全部等级列表
    dispath(setAllLevelList(type))
    const level_1 = type.filter(item => item.level * 1 === 1)
    // 设置媒体类型第一等级列表
    dispath(setTypeLevelOne(level_1))
  }
}
