import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  currentSteps: 0,
  rent_step_1: {
    contacts: '',
    mobile: '',
    media_name: '',
    journal_price: '',
    number: undefined,
    province: undefined,
    city: undefined,
    area: undefined,
    address: '',
    latLng: null
  },
  rent_step_last: {
    is_monitor: undefined,
    device_serial: '',
    image_url: '',
    media_number: '',
    ehicle_flowrate: '',
    media_specifications: '',
    light_mode: undefined,
    field_properties_id: undefined,
    launch_time: undefined,
    end_time: undefined
  },
  pageFirstFormVal: null,
  siteAttributeList: [], // 场地属性列表
  allLevelList: [], // 全部媒体类型数据
  typeLevelOne: [], // 媒体类型 分类1
  typeLevelTwo: [], // 媒体类型 分类2
  typeLevelThree: [], // 媒体类型 分类3
  media_type: [undefined, undefined, undefined]
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RENT_STEP_1:
      return state.set('rent_step_1', fromJS(action.value))
    case constants.RENT_STEP_LAST:
      return state.set('rent_step_last', fromJS(action.value))
    case constants.PAGE_FIRST_FORM_VAL:
      return state.set('pageFirstFormVal', action.value)
    case constants.CURRENT_STEPS:
      return state.set('currentSteps', action.current)
    case constants.SITE_ATTRIBUTE_LIST:
      return state.set('siteAttributeList', action.list)
    case constants.ALL_LEVEL_List:
      return state.set('allLevelList', action.list)
    case constants.TYPE_LEVEL_ONE:
      return state.set('typeLevelOne', action.list)
    case constants.TYPE_LEVEL_TWO:
      return state.set('typeLevelTwo', action.list)
    case constants.TYPE_LEVEL_THREE:
      return state.set('typeLevelThree', action.list)
    case constants.MEDIA_TYPE:
      return state.set('media_type', action.value)
    default:
      return state
  }
}
