import * as constants from './constants'
import moment from 'moment'
import Cookie from 'js-cookie'
import { rentDetail } from '@api'

export const setRentDetail = value => ({
  type: constants.DETAIL,
  value
})

// 获取详情
export const getRentDetail = (id, token) => async dispath => {
  const res = await rentDetail({
    id,
    user_id: Cookie.get('user_id'),
    token
  })
  if (res.code === 0) {
    const data = res.data
    const detail = data.details[0]
    detail.type = 0
    detail.pay = 0
    if (data.pay && data.pay.type) detail.pay = data.pay.type * 1
    if (data.status && data.status.length) {
      detail.type = data.status[0].type * 1
    }
    const format = 'YYYY-MM-DD'
    detail.releaseTime = moment.unix(detail.launch_time).format(format)
    detail.releasePeriod = `${moment
      .unix(detail.launch_time)
      .format(format)}~${moment.unix(detail.end_time).format(format)}`
    detail.point = {
      lng: detail.streetscape_positioning_longitude,
      lat: detail.streetscape_positioning_latitude
    }
    detail.carousel = detail.image_url ? [{ img_url: detail.image_url }] : []
    dispath(setRentDetail(detail))
    if (detail.pay === 1) {
      dispath(
        setLinkMessage({
          linkMan: detail.contacts,
          linkMobile: detail.mobile
        })
      )
    }
  }
}

export const setLinkMessage = value => ({
  type: constants.LINK_MESSAGE,
  value
})
