import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  list: [],
  page: 1,
  total: 0
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.PAGE:
      return state.set('page', action.page)
    case constants.LIST:
      return state.merge({
        list: fromJS(action.list),
        total: action.total || state.total
      })
    default:
      return state
  }
}
