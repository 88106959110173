import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  searchVal: {
    name: '',
    media_type: [undefined, undefined, undefined],
    journal_price: '',
    proince: undefined,
    city: undefined,
    area: undefined
  },
  allLevelList: [], // 全部媒体类型数据
  typeLevelOne: [], // 媒体类型 分类1
  typeLevelTwo: [], // 媒体类型 分类2
  typeLevelThree: [], // 媒体类型 分类3
  page: 1,
  total: 0,
  demandList: []
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.ALL_LEVEL_List:
      return state.set('allLevelList', action.list)
    case constants.TYPE_LEVEL_ONE:
      return state.set('typeLevelOne', action.list)
    case constants.TYPE_LEVEL_TWO:
      return state.set('typeLevelTwo', action.list)
    case constants.TYPE_LEVEL_THREE:
      return state.set('typeLevelThree', action.list)
    case constants.SEARCH_VAL:
      return state.merge({
        page: 1,
        searchVal: action.value
      })
    case constants.PAGE:
      return state.set('page', action.page)
    case constants.DEMAND_LIST:
      return state.merge({
        demandList: fromJS(action.list),
        total: action.total
      })
    default:
      return state
  }
}
