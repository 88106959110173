import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  userMessage: {
    avator: '',
    email: '',
    id: 32,
    is_complete: 0,
    is_personal: 0,
    mobile: '17689608444',
    name: '',
    news: null
  },
  userCertificatoinMsg: {
    auth_name: '',
    sex: undefined,
    identity_card: '',
    identity_card_time: '',
    identity_card_positive_img: '',
    identity_card_other_img: '',
    business_card: ''
  },
  enterpriseCertificatoinMsg: {
    company: '',
    legal_name: '',
    identity_card: '',
    identity_card_time: '',
    identity_card_positive_img: '',
    identity_card_other_img: '',
    business_lincence: ''
  }
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.USER_MESSAGE:
      return state.set('userMessage', fromJS(action.value))
    case constants.USER_CERTIFICATOIN_MSG:
      return state.set('userCertificatoinMsg', fromJS(action.value))
    case constants.ENTERPRISE_CERTIFICATOIN_MSG:
      return state.set('enterpriseCertificatoinMsg', fromJS(action.value))
    default:
      return state
  }
}
