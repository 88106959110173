import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  detail: fromJS({
    carousel: [], // 轮播图
    mediaName: '', // 媒体名称
    mediaType: '', // 媒体类型
    mediaNumber: '', // 媒体编号
    mediaLocation: '', // 媒体定位
    flow: '', // 人车流量
    mediaSpecification: '', // 媒体规格
    lightingMode: '', // 亮灯模式
    siteAttribute: '', // 场地属性
    releaseTime: '', // 发布时间
    releasePeriod: '', // 发布时段
    publicationPrice: '', // 刊例价
    point: null // 经纬度
  }),
  linkMessage: fromJS({
    linkMan: '', // 联系人
    linkMobile: '' // 联系电话
  })
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.DETAIL:
      return state.set('detail', fromJS(action.value))
    case constants.LINK_MESSAGE:
      return state.set('linkMessage', fromJS(action.value))
    default:
      return state
  }
}
