import { registerApi, checkMesCode } from '@api'
import * as constants from './constants'
import { message } from 'antd'

export const changeRegister = () => ({
  type: constants.REGISTER_STATE,
  value: true
})

export const changeSetPwd = value => ({
  type: constants.SETPWD,
  value
})

const changeLoginNow = () => ({
  type: constants.LOGIN_NOW,
  value: true
})

// 验证验证码是否正确
export const checkCode = ({ mobile: tel, verifiy: code }) => {
  return dispatch => {
    checkMesCode({
      tel,
      code,
      type: 1
    }).then(res => {
      if (res.code === 0) {
        dispatch(changeSetPwd(true))
      } else {
        message.error(res.msg)
      }
    })
  }
}

// 手机注册
export const mobileRegister = ({
  mobile: tel,
  verifiy: code,
  pwd: password
}) => dispatch =>
  new Promise(async (resolve, reject) => {
    const res = await registerApi({
      tel,
      code,
      password
    })
    if (res.code === 0) {
      message.success('注册成功，前往登陆页面')
      // loginActionCreators.LoginAction(res, dispatch)
    } else {
      message.error(res.msg)
    }
    resolve(res.code)
  })

// 设置密码
export const setPassword = (register_token, password) => {
  return dispatch => {
    dispatch(changeLoginNow())
    // passwordApi({
    //   register_token,
    //   password
    // }).then(res => {
    //   res = res.data
    //   if (res.code === 0) {
    //     // message.success('请设置登录密码');
    //     dispatch(changeLoginNow())
    //   } else {
    //     message.error(res.msg)
    //   }
    //   console.log(res)
    // })
  }
}
