import * as constants from './constants'
import Cookies from 'js-cookie'
import { userEmployeeTrack } from '@api'

// 设置群列表
export const setGroupList = list => ({
  type: constants.GROUP_LIST,
  list
})

// 设置选择群的ID
export const setSelectedGroupId = id => ({
  type: constants.SELECTED_GROUP_LIST,
  id
})

// 设置选择成员的ID
export const setSelectedMemberId = id => ({
  type: constants.SELECTED_MEMBER_LIST,
  id
})

// 设置成员列表
export const setGroupMemberList = list => ({
  type: constants.GROUP_MEMBER_LIST,
  list
})

// 获取群列表
export const getGroupList = token => async dispatch => {
  const res = await userEmployeeTrack({
    user_id: Cookies.get('user_id'),
    token,
    type: 1
  })
  if (res.code === 0) {
    const data = res.data
    data.map(item => (item.id = `${item.id}`))
    // dispatch(setSelectedGroupId(data[0].id))
    dispatch(setGroupList(data))
  }
}

// 获取成员列表
export const getGroupMemberList = params => async dispatch => {
  const res = await userEmployeeTrack({
    ...params,
    user_id: Cookies.get('user_id'),
    type: 2
  })
  if (res.code === 0) {
    const data = res.data
    data.map(item => (item.id = `${item.id}`))
    dispatch(setGroupMemberList(data))
    data.length && dispatch(setSelectedMemberId('all'))
  }
}

// 获取成员成员详情
export const getMemberItem = params => dispatch =>
  new Promise(async (resolve, reject) => {
    const res = await userEmployeeTrack({
      ...params,
      user_id: Cookies.get('user_id'),
      type: 3
    })
    resolve(res)
  })
