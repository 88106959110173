import * as constants from './constants'
import Cookies from 'js-cookie'
import moment from 'moment'
import { message } from 'antd'
import {
  userReleasesDemand,
  userReleasesRent,
  deleteUserReleasesItem
} from '@api'

// 设置详情
export const setItemMessage = (value, index) => ({
  type: constants.ITEM_MESSAGE,
  value: value.merge({
    value,
    index
  })
})

// 获取详情
export const getItemMessage = (value, index) => dispath => {
  dispath(setItemMessage(value, index))
  dispath(updateModalShow(true))
}

// 更新编辑框显示
export const updateModalShow = show => ({
  type: constants.MODAL_SHOW,
  show
})

// 设置页数
export const setPage = page => ({
  type: constants.PAGE,
  page
})

// 设置列表
export const setList = (list, total, reload) => ({
  type: constants.LIST,
  list,
  total,
  reload
})

// 获取我的发布需求 type: 1:需求 2：招租
export const deleteItem = (value, list, index) => async dispatch => {
  const res = await deleteUserReleasesItem(value)
  if (res.code === 0) {
    const _list = list.delete(index)
    dispatch(setList(_list, false, true))
    message.success('删除成功')
  } else {
    message.error(res.msg)
  }
  return res.code
}

// 获取我的发布需求
export const getDemand = (page, reload, token) => async dispatch => {
  const res = await userReleasesDemand({
    user_id: Cookies.get('user_id'),
    page,
    token
  })
  if (res.code === 0) {
    const { list, count } = res.data
    list.map(
      item =>
        (item.create_time_str = moment
          .unix(item.create_time)
          .format('YYYY/MM/DD'))
    )
    dispatch(setList(list, count, reload))
  }
}

// 获取我的招租列表
export const getRent = (page, reload, token) => async dispatch => {
  const res = await userReleasesRent({
    user_id: Cookies.get('user_id'),
    page,
    token
  })
  if (res.code === 0) {
    const { list, count } = res.data
    list.map(item => {
      item.create_time_str = moment.unix(item.create_time).format('YYYY/MM/DD')
      item.launch_time_str = moment.unix(item.launch_time).format('YYYY/MM/DD')
      item.end_time_str = moment.unix(item.end_time).format('YYYY/MM/DD')
      return item
    })
    dispatch(setList(list, count, reload))
  }
}
