import React, { PureComponent } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Menus from './components/Menus'
import Positioning from './components/Location'
import { HeaderWrapper, Logo, Nav, Title } from './style'

const mapState = state => ({
  isLogin: state.getIn(['login', 'isLogin']),
  path: state.getIn(['header', 'path']),
  access_token: state.getIn(['login', 'access_token']),
  username: state.getIn(['header', 'userName'])
})

@withRouter
@connect(mapState)
class HomeHeader extends PureComponent {
  render() {
    const { location } = this.props
    const pathname = location.pathname
    const path =
      pathname.lastIndexOf('/') !== 0 ? `/${pathname.split('/')[1]}` : pathname
    if (
      path === '/login' ||
      path === '/register' ||
      path === '/forgot' ||
      path === '/agreement'
    ) {
      return null
    } else {
      return (
        <HeaderWrapper>
          <Nav>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Logo />
              <Title>广告云</Title>
            </Link>
            <Positioning />
            <Menus />
          </Nav>
        </HeaderWrapper>
      )
    }
  }
}

export default HomeHeader
