// 数组对象去重
export const objUpdate = arr => {
  let obj = {}

  let peon = arr.reduce((cur, next) => {
    !obj[next.id] && (obj[next.id] = true && cur.push(next))
    return cur
  }, []) //设置cur默认类型为数组，并且初始值为空的数组
  return peon
}
// 对象按字母排序
export const objKeySort = obj => {
  if (!obj) return obj
  var newkey = Object.keys(obj).sort()
  var newObj = {}
  for (var i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]]
  }
  return newObj
}

// 保留有值的对象
export const reservedIsTrue = condition => {
  const obj = {}
  for (let k in condition) {
    ;(condition[k] || condition[k] === 0) && (obj[k] = condition[k])
  }
  return obj
}

// 返回当前月份
export const currentMonth = () => {
  const data = new Date()
  return `${data.getFullYear()}-${data.getMonth() + 1}`
}

// 手机中间4位替换 “ * ”
export const mobileReplace = str =>
  str.replace(/^(\d{3})\d*(\d{4})$/, '$1****$2')

export const check = () => {
  var userAgentInfo = navigator.userAgent
  var Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  ]
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}
