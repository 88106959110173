import { advHeaderNewslist } from '@api'
import * as constants from './constants'

export const updatePage = value => ({
  type: constants.PAGE,
  value
})

export const updateNewsList = (list, total) => ({
  type: constants.LIST,
  list,
  total
})

export const getNewsList = page => async dispatch => {
  const res = await advHeaderNewslist({ page })
  if (res.code === 0) {
    const data = res.data
    dispatch(updateNewsList(data.list, data.count))
  }
}
