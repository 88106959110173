import { resetPasswordApi, checkMesCode } from '@api'
import * as constants from './constants'
import { message } from 'antd'

export const changeForgotType = state => ({
  type: constants.FORGOT_STATE,
  state
})

// 获取重置密码token
export const getToken = (mobile, sms_code, num) => {
  return dispatch => {
    // getResetTokenApi({
    //   mobile,
    //   sms_code
    // }).then(res => {
    //   res = res.data
    //   if (res.code === 0) {
    //     setCookie('reset_password_token', res.data.reset_password_token)
    //     dispatch(changeForgotType(num))
    //   } else {
    //     message.error(res.msg)
    //   }
    //   console.log(res)
    // })
  }
}

// 验证验证码是否正确
export const checkCode = ({ tel, code }) => {
  return dispatch => {
    checkMesCode({
      tel,
      code,
      type: 3
    }).then(res => {
      if (res.code === 0) {
        dispatch(changeForgotType(3))
      } else {
        message.error(res.msg)
      }
    })
  }
}

// 重置密码
export const resetPassword = value => dispatch => {
  resetPasswordApi(value).then(res => {
    if (res.code === 0) {
      dispatch(changeForgotType(4))
      message.success('重置密码成功，请登录')
    } else {
      message.error(res.msg)
    }
  })
}
