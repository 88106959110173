import request from './request'

//登录接口
export function loginApi(param) {
  return request({
    url: 'api/login',
    method: 'post',
    data: param
  })
}
// 验证验证码 接口
export function checkMesCode(param) {
  return request({
    url: 'api/code',
    method: 'post',
    data: param
  })
}
//短信接口
export function smsApi(param) {
  return request({
    url: 'api/sendSms',
    method: 'post',
    data: param
  })
}
//用户注册接口
export function registerApi(param) {
  return request({
    url: 'api/register',
    method: 'post',
    data: param
  })
}
//用户注册 密码确认 接口
export function passwordApi(param) {
  return request({
    url: 'password',
    method: 'post',
    data: param
  })
}
//密码重设短信 验证
export function resetPasswordApi(param) {
  return request({
    url: 'api/Reset',
    method: 'post',
    data: param
  })
}

// 首页 数据 接口
export function homeData(param) {
  return request({
    url: 'api/index',
    method: 'post',
    data: param
  })
}

// 媒体类型 场地属性
export function getMediaType(param) {
  return request({
    url: 'api/media_type',
    method: 'post',
    data: param
  })
}

//招租 列表 接口
export function rentList(param) {
  return request({
    url: 'api/media',
    method: 'post',
    data: param
  })
}

//招租 列表 接口
export function createRent(param) {
  return request({
    url: 'api/rent',
    method: 'post',
    data: param
  })
}

// 招租详情 接口
export function rentDetail(param) {
  return request({
    url: 'api/details_m',
    method: 'post',
    data: param
  })
}

// 需求列表 接口
export function demandList(param) {
  return request({
    url: 'api/demand',
    method: 'post',
    data: param
  })
}

// 需求详情 接口
export function demandDetail(param) {
  return request({
    url: 'api/details',
    method: 'post',
    data: param
  })
}

// 发布需求 接口
export function createDemand(param) {
  return request({
    url: 'api/create',
    method: 'post',
    data: param
  })
}

// 广告头条 列表 接口
export function advHeaderNewslist(param) {
  return request({
    url: 'api/articleList',
    method: 'post',
    data: param
  })
}

// 广告头条 详情 接口
export function advHeaderNewsDetail(param) {
  return request({
    url: 'api/article',
    method: 'post',
    data: param
  })
}

// 监控服务 接口
export function monitioringService(param) {
  return request({
    url: 'api/service',
    method: 'post',
    data: param
  })
}

// 关于我们 接口
export function aboutUs(param) {
  return request({
    url: 'api/us',
    method: 'post',
    data: param
  })
}

// 消息 接口
export function aboutNews(param) {
  return request({
    url: 'api/news',
    method: 'post',
    data: param
  })
}

// 消息详情 接口
export function aboutNewsDetail(param) {
  return request({
    url: 'api/new_detail',
    method: 'post',
    data: param
  })
}

// 图片上传 接口
export function uploadToImage(param, token, id) {
  return request({
    url: 'api/uploadImg?token=' + token + '&user_id=' + id,
    method: 'post',
    data: param
  })
}

// 我的发布需求 接口
export function userReleasesDemand(param) {
  return request({
    url: 'api/Personal',
    method: 'post',
    data: param
  })
}

// 我的发布招租 接口
export function userReleasesRent(param) {
  return request({
    url: 'api/release',
    method: 'post',
    data: param
  })
}

// 修改我的发布招租 接口
export function editUserReleasesRent(param) {
  return request({
    url: 'api/edit',
    method: 'post',
    data: param
  })
}

// 删除 我的发布 招租 需求 接口
export function deleteUserReleasesItem(param) {
  return request({
    url: 'api/delete',
    method: 'post',
    data: param
  })
}

// 收藏 接口
export function updateCollection(param) {
  return request({
    url: 'api/collection',
    method: 'post',
    data: param
  })
}

// 我的收藏  户外媒体 接口
export function userCollectionRent(param) {
  return request({
    url: 'api/collectionS',
    method: 'post',
    data: param
  })
}

// 我的收藏  媒体需求 接口
export function userCollectionDemand(param) {
  return request({
    url: 'api/collection_d',
    method: 'post',
    data: param
  })
}

// 广告地图 接口
export function advertisementPoint(param) {
  return request({
    url: 'api/Advertisement',
    method: 'post',
    data: param
  })
}

// 用户信息 接口
export function userMessage(param) {
  return request({
    url: 'api/User',
    method: 'post',
    data: param
  })
}

// 用户信息编辑 接口
export function userMessageEdit(param) {
  return request({
    url: 'api/user_edit',
    method: 'post',
    data: param
  })
}

// 认证信息接口
export function userAuthentication(param) {
  return request({
    url: 'api/user_authentication',
    method: 'post',
    data: param
  })
}

// 获取有易豆消耗接口
export function userBean(param) {
  return request({
    url: 'api/consumption',
    method: 'post',
    data: param
  })
}

// 消耗易豆接口
export function userBeanPay(param) {
  return request({
    url: 'api/consume',
    method: 'post',
    data: param
  })
}

// 消耗易豆接口
export function userBeanPage(param) {
  return request({
    url: 'api/obatin',
    method: 'post',
    data: param
  })
}

// 充值记录接口
export function userRechargeRecord(param) {
  return request({
    url: 'api/order',
    method: 'post',
    data: param
  })
}

// 消费记录接口
export function userExpensesRecord(param) {
  return request({
    url: 'api/consumption_s',
    method: 'post',
    data: param
  })
}

// 员工轨迹接口
export function userEmployeeTrack(param) {
  return request({
    url: 'api/trajectory',
    method: 'post',
    data: param
  })
}

// 充值策略接口
export function sysAccounting(param) {
  return request({
    url: 'api/account',
    method: 'post',
    data: param
  })
}

// 充值策略详情接口
export function sysRechargeDetail(param) {
  return request({
    url: 'api/recharge',
    method: 'post',
    data: param
  })
}

// 充值策略详情接口
export function rechargeAddOrder(param) {
  return request({
    url: 'api/add_order',
    method: 'post',
    data: param
  })
}

// 微信接口
export function wechatQrcode(param) {
  return request({
    url: 'api/url_weixin',
    method: 'post',
    data: param
  })
}

// 微信订单支付状态接口
export function wechatOrderStatus(param) {
  return request({
    url: 'api/call',
    method: 'post',
    data: param
  })
}

// 关闭微信订单接口
export function closeWechatOrder(param) {
  return request({
    url: 'api/close',
    method: 'post',
    data: param
  })
}
