import styled from 'styled-components'

export const LoadingSpin = styled.div`
  width: 100%;
  text-align: center;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255, 0.2);
  .file-loading {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
`
