import React, { lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import FileLoading from '@common/FileLoading'

// 首页
const Home = lazy(() => import('@pages/home'))
// 登录
const Login = lazy(() => import('@pages/login'))
// 注册
const Register = lazy(() => import('@pages/register'))
// 忘记密码
const Forgot = lazy(() => import('@pages/forgot'))
// 发布
const Releases = lazy(() => import('@pages/releases'))
// 招租详情
const RentDetail = lazy(() => import('@pages/rent-detail'))
// 需求详情
const DemandDetail = lazy(() => import('@pages/demand-detail'))
// 媒体需求
const MediaDemand = lazy(() => import('@pages/media-demand'))
// 户外媒体
const Outdoor = lazy(() => import('@pages/outdoor'))
// 广告头条
const AdvHeaderNews = lazy(() => import('@pages/adv-header-news'))
// 广告头条 详情
const AdvHeaderNewsDetails = lazy(() =>
  import('@pages/adv-header-news-details')
)
// 员工轨迹
const EmployeeTrack = lazy(() => import('@pages/employee-track'))
// 广告地图
const AdvMap = lazy(() => import('@pages/adv-map'))
// 用户协议
const Agreement = lazy(() => import('@pages/agreement'))
// 未找到页面
const NoFindPage = lazy(() => import('@pages/404'))
// 我的发布
const UserRelease = lazy(() => import('@pages/user-release'))
// 易豆
const EasyBean = lazy(() => import('@pages/easy-bean'))
// 充值
const Recharge = lazy(() => import('@pages/recharge'))
// 充值记录
const RechargeRecord = lazy(() => import('@pages/recharge-record'))
// 消费记录
const ExpensesRecord = lazy(() => import('@pages/expenses-record'))
// 我的收藏
const UserCollection = lazy(() => import('@pages/user-collection'))
// 账号设置
const UserSetting = lazy(() => import('@pages/user-setting'))
//  监控服务
const MonitoringService = lazy(() => import('@pages/monitoring-service'))
// 关于我们
const AboutUs = lazy(() => import('@pages/about-us'))
// 消息
const Message = lazy(() => import('@pages/message'))
// 消息详情
const MessageDetail = lazy(() => import('@pages/message-detail'))
// 代理商
const Agent = lazy(() => import('@pages/agent'))

const Router = () => (
  <FileLoading>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/forgot" component={Forgot} />

      <Route path="/rent/:id" component={RentDetail} />
      <Route path="/demand/:id" component={DemandDetail} />
      <Route path="/recharge/:id" component={Recharge} />
      <Route path="/adv_header_news/:id" component={AdvHeaderNewsDetails} />
      <Route path="/message/:id" component={MessageDetail} />

      <Route path="/agent" component={Agent} />
      <Route path="/message" component={Message} />
      <Route path="/monitoring_service" component={MonitoringService} />
      <Route path="/about_us" component={AboutUs} />
      <Route path="/user_release" component={UserRelease} />
      <Route path="/easy_bean" component={EasyBean} />
      <Route path="/recharge_record" component={RechargeRecord} />
      <Route path="/expenses_record" component={ExpensesRecord} />
      <Route path="/user_collection" component={UserCollection} />
      <Route path="/user_setting" component={UserSetting} />
      <Route path="/adv_map" component={AdvMap} />
      <Route path="/outdoor" component={Outdoor} />
      <Route path="/media_demand" component={MediaDemand} />
      <Route path="/adv_header_news" component={AdvHeaderNews} />
      <Route path="/employee_track" component={EmployeeTrack} />
      <Route path="/releases_demand" component={Releases} />
      <Route path="/releases_rent" component={Releases} />
      <Route path="/agreement" component={Agreement} />
      <Route path="/404" component={NoFindPage} />
      <Redirect to="/404" />
    </Switch>
  </FileLoading>
)

export default Router
