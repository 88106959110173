import styled from "styled-components";

export const FooterWrapper = styled.div`
  padding-top: 32px;
  height: 234px;
  overflow: hidden;
  color: #c3c1c1;
  font-size: 18px;
  background: rgba(40, 40, 41, 1);
`;

export const Nav = styled.div`
  width: 1200px;
  margin: 0 auto;
`;

export const Contact = styled.div`
  width: 450px;
  margin: 0 auto;
  position: relative;
`;

export const Qrcode = styled.div`
  position: absolute;
  right: 0;
  top: 14px;
`;
export const Bottom = styled.div`
  height: 58px;
  border-top: 1px solid #5b5b5b;
  line-height: 58px;
  text-align: center;
  font-size: 14px;
`;
