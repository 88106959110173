import { fromJS } from "immutable";
import * as constants from "./constants";

const defaultState = fromJS({
  forgotState: 1
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FORGOT_STATE:
      return state.set("forgotState", action.state);
    default:
      return state;
  }
};
