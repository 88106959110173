import { fromJS } from 'immutable'
import * as constants from './constants'

const defaultState = fromJS({
  detail: fromJS({
    demandName: '', // 媒体名称
    mediaType: '', // 媒体类型
    mediaLocation: '', // 媒体定位
    number: '', // 数量
    monitorDevice: '', // 监控设备
    mediaSpecification: '', // 媒体规格
    lightingMode: '', // 亮灯模式
    releaseTime: '', // 发布时间
    releasePeriod: '', // 发布时段
    publicationPrice: '' // 刊例价
  }),
  linkMessage: fromJS({
    linkMan: '', // 联系人
    linkMobile: '', // 联系电话
    linkDesc: '' // 详细信息
  }),
  collection: false // 收藏 true为已收藏
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.COLLECTION:
      return state.set('collection', action.value)
    case constants.DETAIL:
      return state.set('detail', fromJS(action.value))
    case constants.LINK_MESSAGE:
      return state.set('linkMessage', fromJS(action.value))
    default:
      return state
  }
}
