export const PATH_STATE = "homeheader/PATH_STATE";
export const USERNAME = "homeheader/USERNAME";
export const LOCSHOW = "homeheader/LOCSHOW";
export const CITYINDEX = "homeheader/CITYINDEX";
export const PROVINCEINDEX = "homeheader/PROVINCEINDEX";
export const DISTRICTINDEX = "homeheader/DISTRICTINDEX";
export const PROVINCENAME = "homeheader/PROVINCENAME";
export const CITYNAME = "homeheader/CITYNAME";
export const DISTRICTNAME = "homeheader/DISTRICTNAME";
export const PROVINCECODE = "homeheader/PROVINCECODE";
export const CITYCODE = "homeheader/CITYCODE";
export const DISTRICTCODE = "homeheader/DISTRICTCODE";
export const LOCATIONNAME = "homeheader/LOCATIONNAME";
export const LOCATIONCODE = "homeheader/LOCATIONCODE";
export const AREAID = "homeheader/AREAID";
export const AREALEVEL = "homeheader/AREALEVEL";
