import Cookies from 'js-cookie'
import * as constants from './constants'
import { fromJS } from 'immutable'

const defaultState = fromJS({
  path: '',
  userName: Cookies.get('user_name') || '',
  locShows: false,
  provinceIndex: '',
  cityIndex: '',
  districtIndex: '',
  proinceName: '省',
  cityName: '市',
  districtName: '区/县',
  provinceCode: '',
  cityCode: '',
  districtCode: '',
  locationName: '定位中...',
  locationCode: '',
  areaId: '',
  areaLevel: ''
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.PATH_STATE:
      return state.set('path', action.path)
    case constants.USERNAME:
      return state.set('userName', action.name)
    case constants.LOCSHOW:
      return state.set('locShows', action.show)
    case constants.PROVINCEINDEX:
      return state.set('provinceIndex', action.index)
    case constants.CITYINDEX:
      return state.set('cityIndex', action.index)
    case constants.DISTRICTINDEX:
      return state.set('districtIndex', action.index)
    case constants.PROVINCENAME:
      return state.set('proinceName', action.name)
    case constants.CITYNAME:
      return state.set('cityName', action.name)
    case constants.DISTRICTNAME:
      return state.set('districtName', action.name)
    case constants.PROVINCECODE:
      return state.set('provinceCode', action.code)
    case constants.CITYCODE:
      return state.set('cityCode', action.code)
    case constants.DISTRICTCODE:
      return state.set('districtCode', action.code)
    case constants.LOCATIONNAME:
      return state.set('locationName', action.name)
    case constants.LOCATIONCODE:
      return state.set('locationCode', action.code)
    case constants.AREAID:
      return state.set('areaId', action.areaId)
    case constants.AREALEVEL:
      return state.set('areaLevel', action.areaLevel)
    default:
      return state
  }
}
