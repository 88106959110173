import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import { actionCreators as loginActionCreators } from '@pages/login/store'
import { actionCreators as homeHeaderActionCreators } from '@common/homeHeader/store'

const mapState = state => ({
  isLogin: state.getIn(['login', 'isLogin'])
})

const ListerentLogin = ({
  isLogin,
  location: { pathname },
  history,
  setUserName,
  setAccessToken,
  changeLogin
}) => {
  const path =
    pathname.lastIndexOf('/') !== 0 ? `/${pathname.split('/')[1]}` : pathname

  useEffect(() => {
    if (
      path !== '/' &&
      path !== '/login' &&
      path !== '/register' &&
      path !== '/agreement' &&
      path !== '/forgot' &&
      path !== '/adv_map' &&
      path !== '/outdoor' &&
      path !== '/media_demand' &&
      path !== '/monitoring_service' &&
      path !== '/shop' &&
      path !== '/adv_header_news' &&
      path !== '/about_us' &&
      path !== '/rent' &&
      path !== '/demand' &&
      path !== '/404'
    ) {
      const _access_token = Cookies.get('access_token')
      if (_access_token && _access_token !== '') {
        setUserName(Cookies.get('user_name'))
        setAccessToken(_access_token)
        changeLogin()
      } else {
        history.replace('/login')
      }
    }
  }, [path])

  return null
}

export default connect(
  mapState,
  {
    setAccessToken: loginActionCreators.accessToken,
    changeLogin: loginActionCreators.changeLogin,
    setUserName: homeHeaderActionCreators.username
  }
)(withRouter(ListerentLogin))
