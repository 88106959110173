import * as constants from './constants'
import { demandDetail } from '@api'
import moment from 'moment'

export const updateCollectionType = value => ({
  type: constants.COLLECTION,
  value
})
export const setDemandDetail = value => ({
  type: constants.DETAIL,
  value
})

// 获取详情
export const getDemandDetail = params => async dispath => {
  const res = await demandDetail(params)
  if (res.code === 0) {
    const data = res.data.details[0]
    data.type = 0
    data.pay = 0
    if (res.data.pay && res.data.pay.type) data.pay = res.data.pay.type * 1
    if (res.data.status && res.data.status.type) {
      data.type = res.data.status.type * 1
      if (data.type === 1) {
        dispath(updateCollectionType(true))
      }
    }
    const format = 'YYYY-MM-DD'
    data.releaseTime = moment.unix(data.launch_time).format(format)
    data.releasePeriod = `${moment
      .unix(data.launch_time)
      .format(format)}~${moment.unix(data.end_time).format(format)}`
    dispath(setDemandDetail(data))
    if (+data.age_limit === 1) {
      const { contacts: linkMan, mobile: linkMobile, content: linkDesc } = data
      dispath(
        setLinkMessage({
          linkMan,
          linkMobile,
          linkDesc
        })
      )
    }
  }
}

export const setLinkMessage = value => ({
  type: constants.LINK_MESSAGE,
  value
})

export const collectionItem = id => async dispath => {
  // const res = await rentList(condition)
  const isLink = {
    linkMan: '陈先谢谢',
    linkMobile: '1837293821'
  }
  dispath(setLinkMessage(isLink))
}
