import React, { PureComponent } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import { actionCreators as homeHeaderActionCreators } from '@common/homeHeader/store'
import { actionCreators as loginActionCreators } from '@pages/login/store'
import { Navmenu, Navmenuli } from '../style'
import { Menu, Dropdown } from 'antd'

class Menus extends PureComponent {
  componentDidMount() {
    const {
      access_token,
      setUserName,
      setAccessToken,
      changeLogin
    } = this.props
    if (access_token === '') {
      const access_token = Cookies.get('access_token')
      if (access_token && access_token !== '') {
        setUserName(Cookies.get('user_name'))
        setAccessToken(access_token)
        changeLogin()
      }
    }
  }

  render() {
    const { username, isLogin, loginOut, location } = this.props
    const pathname = location.pathname
    const path =
      pathname.lastIndexOf('/') !== 0 ? `/${pathname.split('/')[1]}` : pathname
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <Link to="/user_release" style={{ color: '#000' }}>
            <span>我的发布</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/monitoring_service" style={{ color: '#000' }}>
            <span>监控服务</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/easy_bean" style={{ color: '#000' }}>
            <span>易豆</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/expenses_record" style={{ color: '#000' }}>
            <span>消费记录</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to="/agent" style={{ color: '#000' }}>
            <span>代理商</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to="/user_collection" style={{ color: '#000' }}>
            <span>收藏</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link to="/message" style={{ color: '#000' }}>
            <span>消息</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="8">
          <Link to="/about_us" style={{ color: '#000' }}>
            <span>关于我们</span>
          </Link>
        </Menu.Item> */}
        <Menu.Item key="9">
          <Link to="/user_setting" style={{ color: '#000' }}>
            <span>账号设置</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="10">
          <Link to="/login" onClick={loginOut} style={{ color: '#000' }}>
            <span>退出</span>
          </Link>
        </Menu.Item>
      </Menu>
    )
    const menus = (
      <Menu>
        <Menu.Item key="a">
          <Link to="/releases_demand" style={{ color: '#000' }}>
            <span>发布需求</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="b">
          <Link to="/releases_rent" style={{ color: '#000' }}>
            <span>发布招租</span>
          </Link>
        </Menu.Item>
      </Menu>
    )
    return (
      <Navmenu className="menu-container">
        <Navmenuli as={Link} to="/">
          <p className={path === '/' ? 'active' : ''}>首页</p>
        </Navmenuli>
        <Navmenuli as={Link} to="/adv_map">
          <Navmenuli>
            <p className={path === '/adv_map' ? 'active' : ''}>广告地图</p>
          </Navmenuli>
        </Navmenuli>
        <Navmenuli as={Link} to="/outdoor">
          <Navmenuli>
            <p className={path === '/outdoor' ? 'active' : ''}>户外媒体</p>
          </Navmenuli>
        </Navmenuli>
        <Navmenuli as={Link} to="/media_demand">
          <Navmenuli>
            <p className={path === '/media_demand' ? 'active' : ''}>媒体需求</p>
          </Navmenuli>
        </Navmenuli>
        {!isLogin ? (
          <Navmenuli as={Link} to="/login">
            <Navmenuli>
              <p>发布</p>
            </Navmenuli>
          </Navmenuli>
        ) : (
          <Navmenuli>
            <Dropdown overlay={menus}>
              <p
                className={
                  path === '/releases_rent' || path === '/releases_demand'
                    ? 'active'
                    : ''
                }
              >
                发布
              </p>
            </Dropdown>
          </Navmenuli>
        )}
        <Navmenuli as={Link} to="/employee_track">
          <Navmenuli>
            <p className={path === '/employee_track' ? 'active' : ''}>
              员工轨迹
            </p>
          </Navmenuli>
        </Navmenuli>
        <Navmenuli as={Link} to="/adv_header_news">
          <Navmenuli>
            <p className={path === '/adv_header_news' ? 'active' : ''}>
              广告头条
            </p>
          </Navmenuli>
        </Navmenuli>
        <Navmenuli as={Link} to="/about_us">
          <Navmenuli>
            <p className={path === '/about_us' ? 'active' : ''}>关于我们</p>
          </Navmenuli>
        </Navmenuli>
        {!isLogin ? (
          <Navmenuli as={Link} to="/login">
            <Navmenuli
              style={{ verticalAlign: 'bottom', paddingBottom: '3px' }}
            >
              <p
                className="omits"
                style={{ color: 'rgb(168, 211, 226)', width: '97px' }}
              >
                登录/注册
              </p>
            </Navmenuli>
          </Navmenuli>
        ) : (
          <Navmenuli>
            <Dropdown overlay={menu}>
              <p
                style={{ color: 'rgb(168, 211, 226)', width: '97px' }}
                className={`omits ${
                  path === '/user_release' ||
                  path === '/monitoring_service' ||
                  path === '/easy_bean' ||
                  path === '/expenses_record' ||
                  path === '/agent' ||
                  path === '/user_collection' ||
                  path === '/message' ||
                  path === '/user_setting'
                    ? 'active'
                    : ''
                }`}
              >
                {username}
              </p>
            </Dropdown>
          </Navmenuli>
        )}
      </Navmenu>
    )
  }
}

const mapState = state => ({
  isLogin: state.getIn(['login', 'isLogin']),
  access_token: state.getIn(['login', 'access_token']),
  username: state.getIn(['header', 'userName'])
})

export default connect(
  mapState,
  {
    setAccessToken: loginActionCreators.accessToken,
    changeLogin: loginActionCreators.changeLogin,
    setUserName: homeHeaderActionCreators.username,
    loginOut: loginActionCreators.logout
  }
)(withRouter(Menus))
