import { userBeanPage, userRechargeRecord, sysAccounting } from '@api'
import * as constants from './constants'
import { message } from 'antd'
import moment from 'moment'

// 获取易豆
export const getUserBean = params => {
  return dispatch => {
    userBeanPage(params).then(res => {
      if (res.code === 0) {
        dispatch(setBeanQuantity(res.data.bean))
      } else {
        message.error(res.msg)
      }
    })
  }
}

// 设置易豆
export const setBeanQuantity = value => ({
  type: constants.BEAN_QUANTITY,
  value
})

// 设置充值策略记录
export const setRechargeStrategyList = (list, count, over) => ({
  type: constants.RECHARGE_STRATEGY_LIST,
  list,
  count,
  over
})

// 设置充值记录
export const setRechargeRecordList = list => ({
  type: constants.RECHARGE_RECORD_LIST,
  list
})

// 获取充值策略列表
export const getRechargeStrategyList = params => async dispatch => {
  const res = await sysAccounting(params)
  if (res.code === 0) {
    const { money: _list, count } = res.data
    dispatch(
      setRechargeStrategyList(
        _list,
        count,
        !(params.page * 1 === 1 && count > 4)
      )
    )
  }
}

// 获取充值记录列表
export const getRechargeRecordList = params => async dispatch => {
  const res = await userRechargeRecord(params)
  if (res.code === 0) {
    const { order: _list } = res.data
    _list.map(item => {
      item.pay_status = item.pay_status * 1
      item.pay_type = item.pay_type * 1
      switch (item.pay_type) {
        case 1:
          item.payType_txt = '微信支付'
          break
        case 2:
          item.payType_txt = '支付宝支付'
          break
        default:
          item.payType_txt = '未知'
          break
      }
      switch (item.pay_status) {
        case 0:
          item.type_txt = '交易成功'
          break
        case 1:
          item.type_txt = '等待交易'
          break
        case 2:
          item.type_txt = '交易失败'
          break
        case 3:
          item.type_txt = '交易关闭'
          break
        default:
          item.type_txt = '未知'
          break
      }
      item.pay_time = moment.unix(item.pay_time).format('YYYY/MM/DD')
      return item
    })
    dispatch(setRechargeRecordList(_list))
  }
}
