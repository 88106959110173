export const SEARCH_VAL = 'rent/SEARCH_VAL'
export const SELECT_LINE = 'rent/SELECT_LINE'
export const RENT_LIST = 'rent/RENT_LIST'
export const PAGE = 'rent/PAGE'
export const SELECT_ALL_TYPE = 'rent/SELECT_ALL_TYPE'
export const MODAL_SHOW = 'rent/MODAL_SHOW'
export const ALL_LEVEL_List = 'rent/ALL_LEVEL_List'
export const TYPE_LEVEL_ONE = 'rent/TYPE_LEVEL_ONE'
export const TYPE_LEVEL_TWO = 'rent/TYPE_LEVEL_TWO'
export const TYPE_LEVEL_THREE = 'rent/TYPE_LEVEL_THREE'
export const SITE_ATTRIBUTE_LIST = 'rent/SITE_ATTRIBUTE_LIST'
