import React, { Suspense } from 'react'
import { Spin, Icon } from 'antd'
import { LoadingSpin } from './style'

const antIcon = <Icon type="loading" style={{ fontSize: 50 }} spin />

// 文件加载组件
const FileLoading = ({ children }) => (
  <Suspense
    fallback={
      <LoadingSpin>
        <Spin className="file-loading" indicator={antIcon} />
      </LoadingSpin>
    }
  >
    {children}
  </Suspense>
)

export default FileLoading
